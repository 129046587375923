import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { ECOMMERCE } from '../../Consts';
import { createEcommerceGA4, getPlaceCodeNdState, prepareSelectedItemList, prepareViewItemList } from '../../Utilities/helpers';
import { Button, Modal } from 'react-bootstrap';
import othervehicle from "../../assets/images/Other.png"
import ConfigurationService from '../../services/ConfigurationService';
import VehicleConfirmationPopup from './vehicle-confirm-popup';

export default class VehicleListHome extends React.Component<any, any>{

    constructor(props: any) {
        super(props);
        this.state = {
            showLoginPopup: false,
            configurationsNamesObj: {},
            showVehicleConfirmPopup: false,
            selectedVehicle: {},
            selectedVehicleIndex: -1,
        }
    }

    handleSelectChange = (vehicle: any, index: number) => {
        // this.setState({ selectedIndex: index });
        this.props.selectVehicle(vehicle, index);
        vehicle.item_list_name = 'home_list';
        let selectedVehicle: any = prepareSelectedItemList(vehicle, index);
        selectedVehicle.pickup = getPlaceCodeNdState(this.props.pickup);
        selectedVehicle.dropoff = getPlaceCodeNdState(this.props.dropoff);
        createEcommerceGA4(selectedVehicle, ECOMMERCE.SELECT);
        selectedVehicle.pickup = getPlaceCodeNdState(this.props.pickup);
        selectedVehicle.dropoff = getPlaceCodeNdState(this.props.dropoff);
        createEcommerceGA4(selectedVehicle, ECOMMERCE.ADD);
    }

    showConfirmationPopup = (vehicle: any, index: number) => {
        this.setState({selectedVehicle: vehicle, selectedVehicleIndex: index}, () => {
            this.setState({ showVehicleConfirmPopup: true });
        })
    }

    async componentDidMount() {
        // console.log("vehicles", this.props.pickup, this.props.dropoff);
        if (this.props.vehiclesList && this.props.vehiclesList.length) {
            let viewItemList: any = prepareViewItemList(this.props.vehiclesList, 'home_list');
            viewItemList.pickup = getPlaceCodeNdState(this.props.pickup);
            viewItemList.dropoff = getPlaceCodeNdState(this.props.dropoff);
            createEcommerceGA4(viewItemList, ECOMMERCE.VIEW);
        } 
        let response = await ConfigurationService.getConfigurationByName("OTHER");
        if(response){
            this.setState({ configurationsNamesObj : response}, () => {
        })
        }
    }

    closeModal = async () => {
        this.setState({ showLoginPopup: false });
    }

    vehicleConfirm = () => {
        this.handleSelectChange(this.state.selectedVehicle, this.state.selectedVehicleIndex);
        this.setState({ showVehicleConfirmPopup: false, selectedVehicle: {}, selectedVehicleIndex: -1 });
    }

    closeVehicleConfirm = () => {
        this.setState({ showVehicleConfirmPopup: false, selectedVehicle: {}, selectedVehicleIndex: -1 });
    }

    VehicleConfirmPopup = () => {
        return ( <VehicleConfirmationPopup
            show={true}
            closeConfirmPopup={() => this.closeVehicleConfirm()}
            title='Confirm your vehicle'
            // bodyText={"Please note that this vehicle can accommodate a maximum of"}
            callbackMethod={() => { this.vehicleConfirm() }}
            variant="success"
            selectedVehicle={this.state.selectedVehicle}
            selectedVehicleIndex={this.state.selectedVehicleIndex}
        /> )
    }

    render() {
        
        let maxWidth = '';
        let bgColor: any = "";
        const { selectedVehicleIndex } = this.props;

        return <div className="px-1 vhicle-list-container" >
        <div className="row">
                    <h5 className="block-head mx-2" >Choose your vehicle</h5>
        </div>
        
        <div className="row">
            {
                (this.props.vehiclesList && this.props.vehiclesList.length) ?
                    this.props.vehiclesList.map((vehicle: any, i: number) => {
                        bgColor = (selectedVehicleIndex == i) ? '#efefef' : '';
                        maxWidth = '100%'
                        if (this.props.vehiclesList.length === 1 && window.innerWidth > 1224) {
                            maxWidth ='50%'
                        }
                        if (this.props.vehiclesList.length === 5 && window.innerWidth > 1224) {
                            maxWidth ='212px'
                        }
                        
                        return <div onClick={() => this.showConfirmationPopup(vehicle, i)} key={i} className="col vehicle-block vehicle-block-home mx-1"
                            style={{ background: bgColor ,maxWidth:maxWidth}}
                        >
                            <Row>
                                <Col xs={12}> 
                                <div className="vehicle-name vehicle-name-bold"> {vehicle.vehicle_type_name}</div>
                                    
                                </Col>
                            </Row> 
                                <Row className="vehicle-min-height">
                                    <Col>
                                        <div className="media">
                                            <div className="media-left">

                                                {/* `../${vehicle.vehicle_image}` */}
                                                <img className="media-object vehicle-max-height" src={`${vehicle.vehicle_image}`} alt={`../${vehicle.vehicle_image}`} />

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>  
                                    <Col className="laggage pull-left">
                                        <div className="pull-left">
                                            <span>{vehicle.passenger_capacity}</span> <i className="fa fa-user" aria-hidden="true"></i>
                                        &nbsp;&nbsp;
                                        <span>{vehicle.luggage_capacity}</span> <i className="fa fa-suitcase" aria-hidden="true"></i>
                                        </div>
                                    </Col>
                                    <Col>
                                    <div className="vehicle-price pull-left font-weight-bold font-13">{vehicle.base_price}</div>
                                    </Col>
                                </Row>
                              
                        </div>
                    }) : null
            }
            {this.state.configurationsNamesObj.is_active  ?
            <div onClick={() => this.setState({showLoginPopup: true})} className="col vehicle-block vehicle-block-home mx-1"
                            style={{ background: bgColor ,maxWidth:maxWidth}}
                        >
                            <Row>
                                <Col xs={12}> 
                                <div className="vehicle-name vehicle-name-bold"> 
                                {this.state.configurationsNamesObj.name} </div>
                                    
                                </Col>
                            </Row> 
                                <Row className="vehicle-min-height">
                                    <Col>
                                        <div className="media">
                                            <div className="media-left">

                                                {/* `../${vehicle.vehicle_image}` */}
                                                <img className="media-object vehicle-max-height" src={othervehicle} alt={othervehicle} style={{paddingTop: "10px"}}/>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{margin: "15px"}}>  
                                    <Col className="laggage pull-left">
                                        {/* <div className="pull-left">
                                            <span>{vehicle.passenger_capacity}</span> <i className="fa fa-user" aria-hidden="true"></i>
                                        &nbsp;&nbsp;
                                        <span>{vehicle.luggage_capacity}</span> <i className="fa fa-suitcase" aria-hidden="true"></i>
                                        </div> */}
                                    </Col>
                                    <Col>
                                    {/* <div className="vehicle-price pull-left font-weight-bold font-13">{vehicle.base_price}</div> */}
                                    </Col>
                                </Row>
                              
                        </div> : ""}
            {(this.state.showLoginPopup) ?
                <Modal show={true} style={{ top: '50px' }} >
                        <div className="float-right">
                            <i className="fa fa-times float-right pointer"
                                onClick={() => this.closeModal()}
                                title="close"></i>
                        </div>
                        <Modal.Body style={{position: "relative",
                            flex: "1 1 auto",
                            padding: "2rem",
                            textAlign: "center"}}>
                            <h1 style={{textAlign:"center", paddingBottom: "1rem"}}>Need more seats?</h1>
                            <span>Please contact LUXY <a href="tel:+1 833-438-5899"> 833.438.5899</a></span><br></br>
                            <Button style={{marginTop: "1rem", color:"white"}}
                                    onClick={() => this.closeModal()}
                                    variant="submit">Ok</Button>
                        </Modal.Body>
                </Modal>
               : null }
        </div>
        {
            this.state.showVehicleConfirmPopup && this.VehicleConfirmPopup()
        }
        </div>
    }
}