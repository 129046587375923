import React from 'react'
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import moment from 'moment';
import Period from '../../assets/images/ellipse.svg';
import shield from '../../assets/images/shield.svg';
import iconCalendar from '../../assets/images/icon_calendar.svg';
import clock from '../../assets/images/clock.svg';
import plane from '../../assets/images/plane.svg';
import user from '../../assets/images/user.svg';
import email from '../../assets/images/email.svg';
import telephone from '../../assets/images/telephone.svg';
import checked from '../../assets/images/checked.svg';
import luggage from '../../assets/images/luggage.svg';
import star from '../../assets/images/star.svg';



import { formatDuration } from '../../services/AddressService';

const ProfileImg = require('../../assets/images/user-profile.png');

// const summaryCard = {
//     height: '120px',
//     width: '280 !important',
//     padding: '5px !important',
//     borderRadius: '4px !important',
//     boxShadow: '0 2px 3px #c8d0d8',
//     backgroundColor: 'white'
// }

class PdfContent extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
    }

    renderAddon = (addon: any) => {
        const addonsList = this.props.addonsList;
        const list = addonsList.filter((item: any) => item.id == addon.id);
        return <Col key={'addon_' + addon.id} xs={4}>  <p className="summanry-data">  <img src={checked} height="15px" width="15px" className="mr-2" /> {list[0].addon_name}</p></Col>
    }

    render() {
        const { trip, vehicleTypeSrc } = this.props;
        return <div ref={this.props.reference} style={{ width: '100%' }}>
            <Row> <h4 className="payment-span-text"> Trip Summary </h4></Row>
            <Row >
                <Col xs={6}>
                    <div style={{
                        height: '120px',
                        width: '280px !important',
                        padding: '5px !important',
                        borderRadius: '4px !important',
                        boxShadow: '0 2px 3px #c8d0d8',
                        backgroundColor: 'white'
                    }}>
                        <Row>
                            <Col><h6>Driver Profile</h6></Col>
                        </Row>
                        <Row>
                            <Col xs={2}>
                                <img src={ProfileImg} height="20px" width="20px" />
                            </Col>
                            <Col xs={7}>
                                {
                                    (trip.driver_id) ? <> <span className="summanry-data"> {(trip.driver_name && trip.driver_name != '') ? trip.driver_name : 'N/A'} </span> <br />
                                        <small>{(trip.affiliate_company_name) ? trip.affiliate_company_name : 'N/A'}</small> </> : 'N/A'
                                }

                            </Col>
                            <Col xs={3}>
                                {/* <i className="fa fa-star" style={{ color: '#FFDA44' }} aria-hidden="true"></i> */}
                                <img src={star} height="15px" width="15px" className="mr-2" />
                                <span className="summanry-data"> {(trip.rating && trip.rating != null) ? trip.rating : '4.8'} </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <img src={vehicleTypeSrc} height="15px" width="15px" className="mr-2" />
                                <span className="summary-mini-text">{(trip.vehicle_type.vehicle_type_name) ? trip.vehicle_type.vehicle_type_name : 'N/A'}</span>
                            </Col>
                            <Col xs={2}>
                                <span className="summary-mini-text">  {(trip.color) ? <span>{trip.color} </span>  : 'N/A'}</span>
                            </Col>
                            <Col xs={3}>
                                {/* <img src={ProfileImg} height="10px" width="10px" />  */}
                                <span className="summary-mini-text"> {(trip.year) ? trip.year : 'N/A'} </span>
                            </Col>
                            <Col xs={3}>
                                <img src={user} height="15px" width="15px" className="mr-2" />
                                {trip.vehicle_type.number_of_seats}
                            </Col>
                        </Row>

                    </div>
                </Col>

                <Col xs={6}>
                    <div style={{
                        height: '120px',
                        width: '280px !important',
                        padding: '5px !important',
                        borderRadius: '4px !important',
                        boxShadow: '0 2px 3px #c8d0d8',
                        backgroundColor: 'white'
                    }}>
                        <Row>
                            <Col> <h6>Trip Date & Time</h6></Col>
                        </Row>
                        <Row>
                            <Col xs={2}>
                                {/* <i className="las la-calendar-check ride-type-icon" style={{ fontSize: '16px', marginLeft: '10px', marginRight: '2px' }}></i> */}
                                <img src={iconCalendar} height="15px" width="15px" className="mr-2" />
                            </Col>
                            <Col xs={7}>
                                <span className="summanry-data">  {moment.tz(trip.pick_up_date, trip.pick_up_address.timezone).format('MMM D, YYYY')} </span>
                            </Col>
                            <Col xs={3}>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={2}>
                                {/* <i className="far fa-clock" style={{ fontSize: '14px', marginLeft: '10px', marginRight: '2px' }}></i> */}
                                <img src={clock} height="15px" width="15px" className="mr-2" />
                            </Col>
                            <Col xs={7}>
                                <span className="summanry-data">  {moment.tz(trip.pick_up_date, trip.pick_up_address.timezone).format('hh:mm A')} </span>
                            </Col>
                            <Col xs={3}>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={2}>

                                {/* <i className="fa fa-shield" style={{ fontSize: '16px', marginLeft: '10px', marginRight: '2px' }}></i> */}
                                <img src={shield} height="15px" width="15px" className="mr-2" />
                            </Col>
                            <Col xs={7}>
                                <span className="summanry-data text-success"> COVID SAFE</span>
                            </Col>
                            <Col xs={3}>

                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col> <h6 className="payment-span-text float-left"> Trip Details </h6></Col>
            </Row>

            <Row>
                <Col xs={6}>
                    <div className="treeview"></div>
                    <Row className="align-items-left no-gutters">
                        <div className="col-auto">
                            <img height="10px" width="10px" className="period-icon  img-fluid  img-fluid" src={Period} />
                        </div>
                        <div className="col ">
                            <span className="summary-mini-text">Pick Up Address</span>
                            <p className="summanry-data">{trip.pick_up_address.address.full_address}</p>

                        </div>
                    </Row>

                    {
                        (trip.extra_stop_address && trip.extra_stop_address.length) ? trip.extra_stop_address.map((stop: any, index: number) => {
                            return <Row key={'extra_' + index} className="align-items-left no-gutters">
                                <div className="col-auto">
                                    <img height="10px" width="10px" className="period-icon  img-fluid  img-fluid" src={Period} />
                                </div>
                                <div className="col ">
                                    <p className="summanry-data">{stop.address.full_address}</p>
                                </div>
                            </Row>
                        }) : null
                    }

                    <Row className="align-items-left no-gutters">
                        <div className="col-auto">
                            <img height="10px" width="10px" className="period-icon  img-fluid  img-fluid" src={Period} />
                        </div>
                        <div className="col ">
                            <span className="summary-mini-text">Dropoff Address</span>
                            <p className="summanry-data">{trip.drop_off_address.address.full_address}</p>
                        </div>
                    </Row>
                </Col>
                <Col xs={6}>
                    <Row>
                        <div className="col ">
                            <span className="summary-mini-text">Trip Duration</span>

                            <p className="summanry-data">
                                {/* <i className="far fa-clock" style={{ fontSize: '14px', marginLeft: '10px', marginRight: '5px' }}></i> */}
                                <img src={clock} height="15px" width="15px" className="mr-2" />
                                {formatDuration(trip.ride_total_hours)} (hrs)
                        </p>
                        </div>
                    </Row>
                    <Row>
                        <div className="col ">
                            <span className="summary-mini-text">Flight Information</span>

                            <p className="summanry-data">
                                {/* <i className="fa fa-plane" style={{ fontSize: '14px', marginLeft: '10px', marginRight: '5px' }} aria-hidden="true"></i> */}
                                <img src={plane} height="15px" width="15px" className="mr-2" />
                                {(trip.airline_name != '') ? trip.airline_name + ' ' + trip.flight_number : 'N/A'} </p>
                        </div>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col> <h6 className="payment-span-text float-left"> Primary Passenger Details </h6></Col>
            </Row>

            <Row>
                <Col xs={4}>  <p className="summanry-data"> <img src={user} height="15px" width="15px" className="mr-2" /> {trip.passenger_name}</p></Col>
                <Col xs={4}> <p className="summanry-data">
                    {/* <i className="fa fa-phone mr-2" aria-hidden="true"></i> */}
                    <img src={email} height="15px" width="15px" className="mr-2" />
                    {trip.passenger_email}</p></Col>
                <Col xs={4}> <p className="summanry-data">
                    {/* <i className="fa fa-envelope mr-2" aria-hidden="true"></i>  */}
                    <img src={telephone} height="15px" width="15px" className="mr-2" />
                    {trip.passenger_mobile}</p></Col>

            </Row>

            {/* based on the key we need to add condition. */}
            {
                (trip.secondary_passenger_email != null && trip.secondary_passenger_email != '') ? <>
                    <Row className="mt-2">
                        <Col> <h6 className="payment-span-text float-left"> Secondary Passenger Details </h6></Col>
                    </Row>

                    <Row>
                        <Col xs={4}> <p className="summanry-data"> <img src={user} height="15px" width="15px" className="mr-2" /> {trip.secondary_passenger_name}</p></Col>
                        <Col xs={4}> <p className="summanry-data">
                            {/* <i className="fa fa-phone mr-2" aria-hidden="true"></i>  */}
                            <img src={email} height="15px" width="15px" className="mr-2" />
                            {trip.secondary_passenger_email}</p></Col>
                        <Col xs={4}> <p className="summanry-data">
                            {/* <i className="fa fa-envelope mr-2" aria-hidden="true"></i> */}
                            <img src={telephone} height="15px" width="15px" className="mr-2" />
                            {trip.secondary_passenger_mobile}</p></Col>

                    </Row>
                </> : null
            }


            {
                (trip.trip_protection || (trip.extra_services != null && trip.extra_services.length)) ? <>
                    <Row className="mt-2">
                        <Col> <h6 className="payment-span-text float-left">Extra Services </h6></Col>
                    </Row>
                    <Row>

                        {
                            (trip.trip_protection) ? <Col xs={4}>  <p className="summanry-data">
                                {/* <i className="fa fa-check-circle icon-yellow mr-2" aria-hidden="true"></i> */}
                                <img src={checked} height="15px" width="15px" className="mr-2" />
            Trip Protection </p></Col> : null
                        }


                        {
                            (trip.extra_services != null && trip.extra_services.length) ? trip.extra_services.map((service: any) => {
                                return (service.id) ? this.renderAddon(service) : null;
                            }) : null
                        }
                    </Row>
                </> : null
            }


            <Row className="mt-2">
                <Col> <h6 className="payment-span-text float-left">vehicle Details </h6></Col>
            </Row>
            <Row>
                <Col xs={4}> <p className="summanry-data">
                    <img src={vehicleTypeSrc} height="15px" width="15px" style={{ marginRight: '5px' }} />
                    {trip.vehicle_type_name}</p></Col>
                <Col xs={4}>  <p className="summanry-data">
                    <img src={user} height="15px" width="15px" className="mr-2" /> 3</p></Col>
                <Col xs={4}>  <p className="summanry-data">
                    <img src={luggage} height="15px" width="15px" className="mr-2" /> 2</p></Col>
            </Row>
            <Row className="mt-2">
                <Col> <h6 className="payment-span-text float-left">Charges </h6></Col>
            </Row>
            <Row>
                <Col xs={6}>Trip Price</Col>
                <Col xs={6}><span className="summanry-data">{trip.base_price}</span></Col>
            </Row>
            {
                (trip.extra_services && trip.extra_services.length) ? <Row>
                    <Col xs={6}>Addons Price</Col>
                    <Col xs={6}><span className="summanry-data">{trip.addons_price}</span></Col>
                </Row> : null
            }

            {
                (trip.extra_stop_address && trip.extra_stop_address.length) ? <Row>
                    <Col xs={6}>Extra Stops price</Col>
                    <Col xs={6}><span className="summanry-data">{trip.extra_stops_price}</span></Col>
                </Row> : null
            }

            {
                (trip.trip_protection) ? <Row>
                    <Col xs={6}>Trip Protection</Col>
                    <Col xs={6}><span className="summanry-data">{trip.trip_protection_amount}</span></Col>
                </Row> : null
            }

            <Row>
                <Col xs={6}>Gratuity</Col>
                <Col xs={6}><span className="summanry-data">{trip.affiliate_gratuity_price}</span></Col>
            </Row>
            {
                (trip.sales_tax && trip.sales_tax.total_taxes) ? <Row>
                    <Col xs={6}>Sales Tax</Col>
                    <Col xs={6}><span className="summanry-data">${trip.sales_tax.total_taxes}</span></Col>
                </Row> : null
            }

            {
                (trip.promo_code_details && trip.promo_code_details.promo_code_id) ? <Row>
                    <Col xs={6}>Discount</Col>
                    <Col xs={6}><span className="summanry-data">-{trip.promo_code_details.discount_amount}</span></Col>
                </Row> : null
            }

            <Row className="mt-2">
                <Col xs={6}> <h6 className="payment-span-text float-left">Total Cost </h6></Col>
                <Col xs={6}> <h6 className="payment-span-text float-left">{trip.total_fee} </h6></Col>
            </Row>
        </div>
    }
}

export default PdfContent;