import React from 'react';
import { Form, FormControl, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { addOnNames } from '../../constants/app-constants';


interface AddonProps {
    addon: any;
    handleAddonChange: (event: any, addon: any) => any;
    updateMeetandGreetData: (data: any) => any;
    updateChildSeatData: (data: any) => any;
    selectedVehicle: any;
    meetAndGreetData: any;
    childSeatValues: any;
    confirmBookingValidation: any;
    updateConfirmBookingValidation: (data: any) => any;
    showAirLines: boolean;
    carSeatTypes:any;
    setChildSeatToggle: any;
    isChildSeatToggleOn: any;
}

interface AddonState {
    name: string;
    notes: string;
    childSeatCount: number;
    childSeats: any;
    seatTypes:any;
}

const images = {
    //EXTRAS
    ARRIVA_MEET_GREET_UNSELECT: require('../../assets/images/extras/Arrival_Meet_Greet_Unselect.png'),
    ARRIVA_MEET_GREET_SELECT: require('../../assets/images/extras/Arrival_Meet_Greet_Select.png'),

    CHILD_SEAT_UNSELECT: require('../../assets/images/extras/chaild_seat_Unselect.png'),
    CHILD_SEAT_SELECT: require('../../assets/images/extras/chaild_seat_Select.png'),

    TRAVELING_WITH_PET_UNSELECT: require('../../assets/images/extras/travelingWithPet_Unselect.png'),
    TRAVELING_WITH_PET_SELECT: require('../../assets/images/extras/travelingWithPet_Select.png'),

    TRIP_PROTECTION_UNSELECT: require('../../assets/images/extras/Trip_Protection_Unselect.png'),
    TRIP_PROTECTION_SELECT: require('../../assets/images/extras/Trip_Protection_Select.png'),
}

class Addon extends React.Component<AddonProps, any>{
    constructor(props: AddonProps) {
        super(props);
        this.state = {
            name: '',
            notes: '',
            childSeatCount: 1,
            childSeats: [
            ],
            seatTypes:[]
        }
    }

 componentDidMount() {
        let seatTypesRes = this.props.carSeatTypes;
        let seatTypes = seatTypesRes.map((item: { [x: string]: any; id: any; }) => {
            return { id: item.id, type: item['seat_type'] };
          });
          let defaultChildSeat = {id: 0, type: 'Select'};
          seatTypes.unshift(defaultChildSeat);
          seatTypes.sort((a: { id: number; }, b: { id: number; }) => {
            return a.id - b.id;
          });
          
        let stateObj = {
            name: '',
            notes: '',
            childSeatCount: 1,
            childSeats: [
            ]
        }
        if (this.props.meetAndGreetData && this.props.meetAndGreetData != undefined) {
            stateObj.name = this.props.meetAndGreetData.name;
            stateObj.notes = this.props.meetAndGreetData.notes;
        }

        if (this.props.childSeatValues && this.props.childSeatValues.length) {
            stateObj.childSeatCount = this.props.childSeatValues.length;
            stateObj.childSeats = this.props.childSeatValues;
        }

        const { name, notes, childSeatCount, childSeats } = stateObj;

        // console.log(seatTypes,"seatTypes");
        this.setState({
            name, notes, childSeatCount, childSeats, seatTypes
        }, () => {  });
    }

    componentDidUpdate(prevProps: any) {
        if ((prevProps.childSeatValues != this.props.childSeatValues) || (prevProps.meetAndGreetData != this.props.meetAndGreetData)) {
            let stateObj = {
                name: '',
                notes: '',
                childSeatCount: 1,
                childSeats: [
                ]
            }
            if (this.props.meetAndGreetData && this.props.meetAndGreetData != undefined) {
                stateObj.name = this.props.meetAndGreetData.name;
                stateObj.notes = this.props.meetAndGreetData.notes;
            }

            if (this.props.childSeatValues && this.props.childSeatValues.length) {
                stateObj.childSeatCount = this.props.childSeatValues.length;
                stateObj.childSeats = this.props.childSeatValues;
            }
            const { name, notes, childSeatCount, childSeats } = stateObj;
            this.setState({
                name, notes, childSeatCount, childSeats
            }, () => {  });
        }

        const { selectedVehicle } = this.props;
        
        if (prevProps.selectedVehicle !==  selectedVehicle && this.props.childSeatValues && this.props.childSeatValues.length) {
            this.setState({childSeats: [{id: 0, type: 'Select'}], childSeatCount: 1}, async () => {
                await this.props.updateChildSeatData([{id: 0, type: 'Select'}]);
            });
        }
    }

    createMarkup = (myTextFromDatabase: any) => {
        return { __html: myTextFromDatabase };
    }



    updatePassenerInfo = (key: string, value: string) => {
        let confirmBookingValidation = JSON.parse(JSON.stringify(this.props.confirmBookingValidation));
        if (key == 'name') {
            confirmBookingValidation.arrival_meet.passenger_name = (value == '') ? false : true;
            this.props.updateConfirmBookingValidation(confirmBookingValidation);
        }
        this.setState({ [key]: value }, () => {
            this.props.updateMeetandGreetData({ name: this.state.name, notes: this.state.notes });
        });
    }

    renderImage = (addon: any, status = false) => {
        let src = "";
        switch (addon.id) {
            case 1:
                src = (status) ? images.ARRIVA_MEET_GREET_SELECT : images.ARRIVA_MEET_GREET_UNSELECT;
                break;
            case 2:
                src = (status) ? images.TRAVELING_WITH_PET_SELECT : images.TRAVELING_WITH_PET_UNSELECT;
                break;
            case 3:
                src = (status) ? images.CHILD_SEAT_SELECT : images.CHILD_SEAT_UNSELECT;
                break;
            default:
                src = (status) ? images.TRIP_PROTECTION_SELECT : images.TRIP_PROTECTION_UNSELECT;
        }
        return <img className="extra-img" src={src} />
    }

    renderMeetAndGreet = () => {
        return (

            <Row className="ml-3  mt-4 mb-3">
                <div className="form-group col-md-6">
                    <label htmlFor="greet_passenger_name" className="float-left">Passenger Name</label>
                    <FormControl
                        id="greet_passenger_name"
                        placeholder={
                            this.state.name
                                ? this.state.name
                                : "Enter Passenger Name"
                        }
                        onChange={(event: any) =>
                            this.updatePassenerInfo('name', event.target.value)
                        }
                        value={this.state.name}
                        autoComplete="nope"
                    />
                    <p className="text-danger">
                        {
                            (this.props.confirmBookingValidation
                                && !this.props.confirmBookingValidation.arrival_meet.passenger_name
                            ) ?
                                'Enter passenger name' : null
                        }
                    </p>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="greet_notes" className="float-left">Additional Comments</label>
                    <FormControl
                        id="greet_notes"
                        placeholder={
                            this.state.notes
                                ? this.state.notes
                                : "Additional Comments"
                        }
                        onChange={(event: any) =>
                            this.updatePassenerInfo('notes', event.target.value)
                        }
                        value={this.state.notes}
                    />
                </div>
                <div className="col-2"></div>
            </Row>
        )
    }

    removeChildSeat = (index: number) => {
        const count = this.state.childSeatCount - 1;
        let childSeatsValsUpdated = JSON.parse(JSON.stringify(this.state.childSeats));
        childSeatsValsUpdated.splice(index, 1);
        this.setState({ childSeatCount: count, childSeats: childSeatsValsUpdated }, async () => {
            await this.props.updateChildSeatData(childSeatsValsUpdated);
        });
    }

    handleChildSeatChange = (event: any, index: any) => {
        
        let childSeatsValsUpdated = this.state.childSeats;
        let selectedValue = this.state.seatTypes.filter((seat: any) => { return seat.id == event.target.value });
        childSeatsValsUpdated[index] = selectedValue[0];
        this.setState({ childSeats: childSeatsValsUpdated }, async () => {
            await this.props.updateChildSeatData(childSeatsValsUpdated);
        });

        // update childseat data to redux
    }

    addAnotherChildSeat = () => {
        
        const count = this.state.childSeatCount;
        let childSeatsValues = this.state.childSeats;
        if (count < this.props.selectedVehicle.passenger_capacity - 1) {
            childSeatsValues.push(this.state.seatTypes[0]);
            this.setState({ childSeatCount: count + 1, childSeats: childSeatsValues }, async () => {
                await this.props.updateChildSeatData(childSeatsValues);
            });
        } else {
            // this.setState({ childSeatCount: count + 1, childSeats: childSeatsValues }, async () => {
            //     await this.props.updateChildSeatData(childSeatsValues);
            // });
            toast.error('You cannot add more than of ' + (this.props.selectedVehicle.passenger_capacity - 1) + ' child seats.');
            // alert('You cannot add more than of ' + (this.props.selectedVehicle.passenger_capacity - 1) + ' child seats.')
        }

        // update childseat data to redux.
    }

    renderChildSeat = () => {
        

        let childSeat: any = [];
        for (let i = 0; i < this.state.childSeats.length; i++) {
            let row: any = {};
            row = <Row key={'child_' + i}>
                <div className="form-group">
                    <div className="row ml-3 mt-4 mb-3">
                        <div className="col-10">
                            <Form.Control as="select" custom key={'child_select_' + i}
                                onChange={(event: any) => this.handleChildSeatChange(event, i)}
                                value={this.state.childSeats[i].id}
                            >
                                {
                                    this.state.seatTypes.map((seat: any) => {
                                        // selected={this.state.childSeats[i].id == seat.id}
                                        return <option key={i + '_' + seat.id} value={seat.id}>{seat.type}</option>
                                    })

                                }
                            </Form.Control>
                        </div>
                        <div className="col-2 mt-1">
                            {(i > 0) ? <i title='Delete' onClick={() => this.removeChildSeat(i)} className="fa fa-trash pointer"></i> : null}
                        </div>
                    </div>

                </div>





                <div className="col-2"></div>
            </Row >
            childSeat.push(row);
        }
        return childSeat.map((row: any) => {
            return row;
        });
    }

    showAddonForms = (addonName: string, status: number) => {
        switch (addonName) {
            case addOnNames.arrivalMeetAndGreet: return (status) ? this.renderMeetAndGreet() : null;
            case addOnNames.childSeat: {
                if (typeof status === 'boolean' && this.props.isChildSeatToggleOn !== status) {
                    this.props.setChildSeatToggle(status);
                }
                return (status) ? this.renderChildSeat() : null;
            }
            default: return null;
        }
    }

    handleAddonChange = (event: any, addon: any) => {

        if (addon.addon_name == addOnNames.childSeat) {
            if (event.target.checked) {
                let childSeatsValues: any = [];
                childSeatsValues.push(this.state.seatTypes[0]);
                this.setState({ childSeats: childSeatsValues }, () => {
                    this.props.updateChildSeatData(childSeatsValues);
                });

            } else {
                let childSeatsValues: any = [];
                this.setState({ childSeats: childSeatsValues, childSeatCount: 1 }, () => {
                    this.props.updateChildSeatData(childSeatsValues);
                });

            }

        }
        this.props.handleAddonChange(event, addon);
    }

    renderTooltip = (description: any) => (
        <Tooltip id="button-tooltip">
            <div className="addon-meta" dangerouslySetInnerHTML={this.createMarkup(description)} ></div>
        </Tooltip>
    );

    render() {
        const { addon } = this.props;
        // console.log(this.props, 'addon props');
        // console.log('this.props.showAirLines', this.props.showAirLines)
        return (
            (!this.props.showAirLines && addon.addon_name === 'Arrival Meet and Greet')
            ? null
            :
            <div className="addon-block">
                <div className="row">
                    <div className="col-1 p-0  text-center">
                        {this.renderImage(addon, addon.isActive)}
                    </div>
                    <div className="col col-6 pl-0 text-left">
                        <div >
                            <div className="extras"> {addon.addon_name}
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 250 }}
                                    overlay={this.renderTooltip(addon.description)}
                                >
                                    <i className="fa fa-info-circle ml-2"></i>
                                </OverlayTrigger>
                            </div>

                        </div>
                    </div>
                    <div className="col">
                        <div className="custom-control custom-switch  text-right">
                            <input id={addon.id}
                                name={addon.id}
                                onChange={(event) => { this.handleAddonChange(event, addon) }}
                                checked={addon.isActive}
                                value={addon.id}
                                className="custom-control-input"
                                type="checkbox" />
                            <label className="custom-control-label" htmlFor={addon.id}></label>
                        </div>
                    </div>
                </div>
                {this.showAddonForms(addon.addon_name, addon.isActive)}
                {(addon.addon_name == addOnNames.childSeat && addon.isActive) ? <div><a href="javascript:void(0)" className="font-14" onClick={() => this.addAnotherChildSeat()}>+ Add another child seat</a></div> : null}
            </div>
        )
    }
}

export default Addon;