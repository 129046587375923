import { isPossiblePhoneNumber } from 'react-phone-number-input'
export const validateEmail = (email: string) => {
    // let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    let pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    if (!pattern.test(email)) {
        return false;
    } else {
        return true;
    }
}

export const validatePhone = (phone: any) => {
    let phoneNoTrim = phone.replace(/ +/g, "");
    let phoneNo = /^\+?\d{10}[\-\.\s]?|\d{13}$/;

    if (phoneNoTrim.match(phoneNo)) {
        return true;
    }
    else {
        return false;
    }
}

export const validateCountryPhone = (phone: any) => {
    if (phone != undefined) {
        if (isPossiblePhoneNumber(phone)) {
            return true;
        }
        else {
            return false;
        }
    } else {
        return false;
    }

}

