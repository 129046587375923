import React from "react";
import { Switch, Route } from 'react-router-dom';
import UserListContainer from "../containers/user/user-list";
import UserContainer from "../containers/user/user";
import BookARideContainer from "../containers/book-a-ride";
import ForgetPassword from "../components/auth/forgot";
import ExpiredLink from "../components/common/expired";
import ActivateCustomer from "../components/auth/activation";
import DetailsContainer from "../containers/details";
import PaymentFailureContainer from "../components/ride-details/payment-failure";
import PaymentSuccessContainer from "../components/ride-details/payment-success";
import AboutContainer from "../containers/about";
import AffiliateContainer from "../containers/affiliate";
import RideRewardsContainer from "../containers/ride-rewards";
import TermsPolicy from "../components/policy/terms-policy";
import PrivacyPolicy from "../components/policy/privacy-policy";
import Corporate from "../components/corporate/corporate";
import Faqs from "../components/faqs/faqs";
import ReactGA from 'react-ga';
import ProfileContainer from "../containers/profile";
import PaymentSettings from "../containers/payment-settings";
import SafetyBanner from "../components/safety-banner/safety-banner";
import RidePaymentsContainer from "../containers/ride-payments";

// const renderLoader = () => <p>Loading</p>;
// const UserListContainer = lazy(() => import("../containers/user/user-list"));
// const UserContainer = lazy(() => import("../containers/user/user"));
// const BookRide = lazy(() => import("../components/book-a-ride/book-a-ride"));
// const PassengerDetails = lazy(() => import("../components/passenger/passenger"));
// const BookARideContainer = lazy(() => import("../containers/book-a-ride"));
// const ForgetPassword = lazy(() => import("../components/auth/forgot"));
// const ExpiredLink = lazy(() => import("../components/common/expired"));
// const ActivateCustomer = lazy(() => import("../components/auth/activation"));
// const DetailsContainer = lazy(() => import("../containers/details"));
// const PaymentFailureContainer = lazy(() => import("../components/ride-details/payment-failure"));
// const PaymentSuccessContainer = lazy(() => import("../components/ride-details/payment-success"));
// const AboutContainer = lazy(() => import("../containers/about"));
// const AffiliateContainer = lazy(() => import("../containers/affiliate"));
// const RideRewardsContainer = lazy(() => import("../containers/ride-rewards"));
// const TermsPolicy = lazy(() => import("../components/policy/terms-policy"));
// const PrivacyPolicy = lazy(() => import("../components/policy/privacy-policy"));
// const Corporate = lazy(() => import("../components/corporate/corporate"));
// const Faqs = lazy(() => import("../components/faqs/faqs"));
// const BlogContainer = lazy(() => import("../containers/blog"));
// const ProfileContainer = lazy(() => import("../containers/profile"));
// const PaymentSettings = lazy(() => import("../containers/payment-settings"));
// const SafetyBanner = lazy(() => import("../components/safety-banner/safety-banner"));

function AppRouter() {

    const googleAnalyticsID:string = (process.env.REACT_APP_ANALYTICS_ID) ? process.env.REACT_APP_ANALYTICS_ID : '';
    ReactGA.initialize(googleAnalyticsID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (

        <Switch>
            {/* <Suspense fallback={renderLoader()}> */}
            <Route exact path='/' component={BookARideContainer} />
            <Route exact path='/user' component={UserContainer} />
            <Route exact path='/users' component={UserListContainer} />
            <Route exact path='/ride-details' component={DetailsContainer} />
            <Route path='/forget/password/:postId' component={ForgetPassword} />
            <Route path='/expired' component={ExpiredLink} />
            <Route path='/activation/:email' component={ActivateCustomer} />
            <Route path='/ride/completed' component={PaymentSuccessContainer} />
            <Route path='/ride/failure' component={PaymentFailureContainer} />
            <Route path='/about' component={AboutContainer} />
            <Route path='/join/affiliate' component={AffiliateContainer} />
            <Route path='/join/ride-rewards' component={RideRewardsContainer} />
            {/* <Route path='/terms' component={TermsPolicy} /> */}
            <Route path='/privacy-policy' component={PrivacyPolicy} />
            <Route path='/join/corporate' component={Corporate} />
            {/* <Route exact  path='/blogs/:category/:id' component={BlogContainer} /> */}
            {/* <Route exact  path='/blogs/:category' component={BlogContainer} /> */}
            {/* <Route exact  path='/blogs' component={BlogContainer} /> */}
            {/* <Route path="/faqs" component={Faqs}/> */}
            <Route path="/profile" component={ProfileContainer} />
            <Route path="/payment-settings" component={PaymentSettings} />
            <Route path="/travel-guidelines" component={SafetyBanner}/>
            <Route path="/ride-payments" component={RidePaymentsContainer}/>
            {/* </Suspense> */}
        </Switch >

    );
}

export default AppRouter;