import { WP_URLS } from "../constants/app-constants";

export default function redirectToWP (page: string, newLink: boolean = false) {

    const pageName: string = findPageURL(page);

    const WP_URL: string = process.env.REACT_APP_WP_URL || '';
    
    // As all links to be opened in new tab.
    window.open (`${WP_URL}${pageName}`, '_blank');
    // if(newLink) {
    //     window.location.href = "_blank";
    //     window.open (`${WP_URL}${pageName}`, '');
    // }else {
    //     window.location.href = `${WP_URL}${pageName}`;
    // }
}

const findPageURL = (page: string) => {
      if(page) {
        if(page in WP_URLS) {
            // console.log(WP_URLS[page]);
            return WP_URLS[page]
        }else {
            return "";
        }
    }
}