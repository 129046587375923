import { connect } from 'react-redux';
import RideDetails from '../components/ride-details/ride-details';
import { GET_ALL_VEHICLES, UPDATE_BOOKING_STATE } from '../reducer/book-a-ride';
import RideService from '../services/RideService';

type Dispatch = (action: any) => void;

const getVehiclesList = (payload: any) => {
    return async (dispatch: Dispatch) => {

        if (payload !== '') {
            let vData = await RideService.getChargesV5(payload);
            if (vData.data && vData.data.serviceRates != null && vData.data.serviceRates && vData.data.serviceRates.vehicle_types.length) {
                dispatch({
                    type: GET_ALL_VEHICLES, data:  vData.data.serviceRates.vehicle_types
                });
            } else {
                     //comment for region code begins
                // dispatch({
                //     type: GET_ALL_VEHICLES, data: []
                // });
                     //comment for region code ends
                dispatch({
                    type: GET_ALL_VEHICLES, data: { vehicles: [], error: true, vehicleErrorMessage :  vData && vData.error && vData.error.data && vData.error.data.message ? vData.error.data.message : 'This trip can not be quoted at the moment. Please contact us at(833) 438-5899 for immediate assistance.'}
                });
            }
        } else {

            dispatch({
                type: GET_ALL_VEHICLES, data: []
            });
        }
    }
}

const updateBookingState = (data: any) => {
    console.log("actionData4",data)
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_BOOKING_STATE, data: data
        });
    }
}

const mapStateToProps = (state: any) => {
    let supportedCountries='';
    if ( state.configurations.configData){
        let configs = state.configurations.configData;
        configs.forEach( (cnf: { name: string; value: any; }) => {

            if (cnf.name === "SUPPORTED_COUNTRIES"){
                supportedCountries=cnf.value;
            }
        }
        )
    }
    return {
        bookingDetailsReducer: state.bookARide.bookingState,
        vehicleDetailsList: state.bookARide.allVehicles,
        vehicleListError: state.bookARide.vehicleListError,
        supportedCountries: supportedCountries,
        vehicleErrorMessage : state.bookARide.vehicleErrorMessage,
        isUserLogin: state.user.isUserLogin,
        user: state.user.user
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getVehiclesList: (payload: any) => dispatch(getVehiclesList(payload)),
        updateBookingState: (data: any) => dispatch(updateBookingState(data)),
    }
}

const RideDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(RideDetails);

export default RideDetailsContainer;