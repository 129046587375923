import React from 'react';

interface TimeProps {
    id: any,
    reference: any,
    format: string,
    handleChange: (id: any, data: any) => void,
    handleBlur?: (event: any) => void,
    value?: any
}

const HoursInput = (props: any) => {
    //time-form-control form-control custom-input-text-timepicker
    return <select aria-label='hours' value={props.value}
        onChange={(event) => props.handleChange(props.id, event)}
        onBlur={props.handleBlur ? props.handleBlur : undefined}
        className="custom-input-text-timepicker-new" autoComplete="nope">
            {/* <option value="-1"></option> */}
            <option label="12 AM" value="0">12 AM</option>
            <option label="1 AM" value="1">1 AM</option>
            <option label="2 AM" value="2">2 AM</option>
            <option label="3 AM" value="3">3 AM</option>
            <option label="4 AM" value="4">4 AM</option>
            <option label="5 AM" value="5">5 AM</option>
            <option label="6 AM" value="6">6 AM</option>
            <option label="7 AM" value="7">7 AM</option>
            <option label="8 AM" value="8">8 AM</option>
            <option label="9 AM" value="9">9 AM</option>
            <option label="10 AM" value="10">10 AM</option>
            <option label="11 AM" value="11">11 AM</option>
            <option label="12 PM" value="12">12 PM</option>
            <option label="1 PM" value="13">1 PM</option>
            <option label="2 PM" value="14">2 PM</option>
            <option label="3 PM" value="15">3 PM</option>
            <option label="4 PM" value="16">4 PM</option>
            <option label="5 PM" value="17">5 PM</option>
            <option label="6 PM" value="18">6 PM</option>
            <option label="7 PM" value="19">7 PM</option>
            <option label="8 PM" value="20">8 PM</option>
            <option label="9 PM" value="21">9 PM</option>
            <option label="10 PM" value="22">10 PM</option>
            <option label="11 PM" value="23">11 PM</option>
        </select>

}

const MinInput = (props: any) => {
    return <select aria-label="minutes" value={props.value}
        onChange={(event) => props.handleChange(props.id, event)}
        onBlur={props.handleBlur ? props.handleBlur : undefined}
        className="custom-input-text-timepicker-new">
        {/* <option value="-1"></option> */}
        <option label="00" value="0">00</option>
        <option label="05" value="5">05</option>
        <option label="10" value="10">10</option>
        <option label="15" value="15">15</option>
        <option label="20" value="20">20</option>
        <option label="25" value="25">25</option>
        <option label="30" value="30">30</option>
        <option label="35" value="35">35</option>
        <option label="40" value="40">40</option>
        <option label="45" value="45">45</option>
        <option label="50" value="50">50</option>
        <option label="55" value="55">55</option>
    </select>
}

export {
    HoursInput, MinInput
}