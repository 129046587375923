import React, { useContext, Component } from 'react';
import './safety-banner.css';
import safetyRulesBg from '../../assets/images/safety-rules-bg.png';
import maskSvg from '../../assets/images/mask.svg';
import gloveSvg from '../../assets/images/glove.svg';
import contactSvg from '../../assets/images/contact.svg';
import feedBackSvg from '../../assets/images/feedback.svg';
import { Row } from 'react-bootstrap';
import { AccordionContext, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/esm/Accordion';

interface SafetyBannerProps {

}

interface SafetyBannerState {

}

function ContextAwareToggle({ children, eventKey, callback }: any) {
    const currentEventKey = useContext(AccordionContext);

    // const decoratedOnClick = useAccordionToggle(
    //     eventKey,
    //     () => callback && callback(eventKey),
    // );

    const isCurrentEventKey = currentEventKey === eventKey;
    {

        return (
            <span><b>
                {children}
                {
                    (isCurrentEventKey) ? <span className="plus-icon pull-right color-black expand"> -  </span> : <span className="plus-icon pull-right color-black expand"> + </span>
                }
            </b></span>
        );
    }
}




class SafetyBanner extends Component<SafetyBannerProps, SafetyBannerState> {
    constructor(props: SafetyBannerProps) {
        super(props);

        this.state = {

        }
    }



    renderSafetyRules = () => {
        return <div>
            <Accordion>
                <hr />
                <div>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <div className="safety-rules-heading">
                            <ContextAwareToggle eventKey="0">Contactless Payment</ContextAwareToggle>
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <div className="safety-rules-text">
                            We even included gratuity to help limit your points-of-contact between you and your driver.
                            Payment is made at the time of booking and we accept all major credit or debit cards.
                        </div>
                    </Accordion.Collapse>
                </div>
                <hr />
                <Accordion.Toggle as={Card.Header} eventKey="1">
                    <div className="safety-rules-heading">
                        <ContextAwareToggle eventKey="1">Masks & Distance</ContextAwareToggle>
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <div className="safety-rules-text">
                        Travel in a sanitized vehicle driven by the best chauffeurs the black car service industry has to offer.
                        We only ask that you wear a mask at all times and maintain at least 6 feet of distance with your driver.
                        </div>
                </Accordion.Collapse>

                <hr />
                <Accordion.Toggle as={Card.Header} eventKey="2">
                    <div className="safety-rules-heading">
                        <ContextAwareToggle eventKey="2">LUXY™ Trip Protection</ContextAwareToggle>
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                    <div className="safety-rules-text">
                        Worried about a flight delay, diversion, or you might have to cancel last minute?
                        This was made just for you! Add an extra sense of security to your trip for only $8 per ride.
                        Check out the official terms <Link className="pointer anchor-orange" to='/terms'> here</Link>
                    </div>
                </Accordion.Collapse>
                <hr />
                <Accordion.Toggle as={Card.Header} eventKey="3">
                    <div className="safety-rules-heading">
                        <ContextAwareToggle eventKey="3">Ride Safe</ContextAwareToggle>
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                    <div className="safety-rules-text">
                        Your ride is done, but our job isn’t. Let us know how your trip went by marking you ride “SAFE” when you leave a rating from your LUXY™ Ride app.
                        Bad experience? Contact us at <a className="pointer anchor-orange" href="tel:833-GET-LUXY"> (833) GET-LUXY</a> or <a className="pointer anchor-orange" href="mailto:support@luxyride.com">  support@luxyride.com.</a>
                    </div>
                </Accordion.Collapse>
            </Accordion>
        </div>

    }
    render() {
        return (
            <div>
                <div className='safety-top'>


                    <div className="image-safety">
                        {/* <GetLuxy /> */}

                        <Row>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 safety-heading display-div'>
                                <span className='text-center safety-img-text-one'>
                                    Safe Transportation Now and Always
                                </span>
                                <span className='text-center safety-img-text-two'>
                                    YOUR WELL BEING IS OUR #1 PRIORITY
                                </span>
                            </div>
                        </Row>
                        {/* <img className="image-safety"  src={safetyBg} alt='safety' /> */}
                    </div>
                </div>
                <div className="container mx-auto about-content">
                <Row>
                    <div className='col-12 col-sm-12 col-md-7 col-lg-7 pt-5'>
                        <Accordion activeKey="0">
                            <div className="image-description-one">
                                At LUXY™, the health and safety of our passengers and drivers remain our top priority.
                                Vehicles are being wiped and disinfected before and after each ride.
                        </div>
                            <div className="image-description-two image-description-padding">
                                We are cleaning and disinfecting each vehicle multiple times a day, including between all passenger changes. This cleaning process includes, but is not limited to all handles, power switches, door storage areas, windows, cup holders,
                                car seats (including child seats), trunk & seatbelts.
                        </div>
                        </Accordion>
                    </div>
                    <div className='col-12 col-sm-12 col-md-5 col-lg-5 pt-5 text-center'>
                        <img className="image-safety-rules img-fluid" src={safetyRulesBg} alt='safety' />
                    </div>
                </Row>
                
                <div className="safety-text-div">
                    <div className="image-description-one">
                        You are safer with LUXY™, always
                    </div>
                </div>
                <div className="safety-vehicle-div">
                    <div className="image-description-two" style={{ textAlign: "center" }}>
                        Learn more about how we take every precaution to ensure every vehicle meets the
                        </div>
                    <div className="image-description-two" style={{ textAlign: "center" }}>
                        highest standards of quality and cleanliness.
                        </div>
                </div>
                <div className="pt-5">
                    {this.renderSafetyRules()}
                </div>


                <div className="safety-text-div">
                    <div className="image-description-one">
                        Our Door-to-Door Safety Standard
                    </div>
                </div>
                <div className="our-door-div">
                    <div className="image-description-two">
                        Introducing new measures to help keep you safe and healthy.
                        </div>

                </div>
                <Row>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 pt-5'>
                    <div className="our-door-grid-item-one">
                        <div>
                            <img src={maskSvg} alt='mask' />
                        </div>
                        <div className="image-description-two">
                            All passengers and drivers must wear a face cover or mask during the trip.
                    </div>

                    </div>
                    <div className="our-door-grid-item-two">
                        <div>
                            <img src={gloveSvg} alt='hands' />
                        </div>
                        <div className="image-description-two">
                            We are working to provide drivers and passengers with health and safety supplies like face covers, disinfectants, and gloves.
                        </div>
                    </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 pt-5'>
                    <div className="our-door-grid-item-one">
                        <div>
                            <img src={contactSvg} alt='contact' />
                        </div>
                        <div className="image-description-two">
                            We stay up to date with the safety tips and resources provided by the World Health Organization (WHO).
                    </div>

                    </div>
                    <div className="our-door-grid-item-two">
                        <div>
                            <img src={feedBackSvg} alt='feedback' />
                        </div>
                        <div className="image-description-two">
                            You’ll now be able to leave feedback on health issues, such as a driver not wearing a face cover or a mask.
                            This helps us improve and holds everyone accountable.
                        </div>
                    </div>
                    </div>
                </Row>
                </div>
            </div>

        )
    }
}

export default SafetyBanner;