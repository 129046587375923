export const SignIn = 'Sign In';
export const SignOut = 'Sign Out';
export const ChangePassword = 'Change Password';
export const NotificationPreference = 'Notification Preferences'
export const Source = 'web';
export const UserSignIn = 'Customer Login';
export const CorporateSignIn = 'Corporate Login'
export const AffiliateSignIn = 'Affiliate Login'

export const addOnConstants = {
    arrivalMeetAndGreet: "arrivalMeetAndGreet",
    travelingWithPet: "travelingWithPet",
    childSeat: "childSeat",
    clearViewProtection: "clearViewProtection"
};

export const gratuities = {
    fifteenPercentage: '15%',
    eighteenPercentage: '18%',
    twentyPercentage: '20%',
    payLater: 'Pay later',
    payInCash: 'Pay in cash'
}

export const addOnNames = {
    arrivalMeetAndGreet: "Arrival Meet and Greet",
    travelingWithPet: "Traveling with Pet",
    childSeat: "Child Seat",
    clearViewProtection: "Trip Protection"
}

export const common = {
    InvalidDate: 'Invalid Date'
}

export const googleEventAnalytics = {
    BookANextRide: {
        category: "BookANextRide",
        action: "Book A Next Ride",
        label: "User Clicked on Book a Next Ride Button on the Ride completion page"
    },
    GetQuotes: {
        category: "GetQuotes",
        action: "Fetching Vehicle List",
        label: "Clicked on get quotes from book a ride (home page)"
    },

    BookNowWidget: {
        category: "BookNowWidget",
        action: "Redirect to Book A ride page",
        label: "Clicked on Get quotes widget from " + window.location.pathname + "",
    },

    SignUp: {
        category: "SignUp",
        action: "Created User Account",
        label: "User Registered from Create account model",
    },
    SignIn: {
        category: "SignIn",
        action: "User Logged In",
        label: "User clicked on sign in button",
    },
    RideRewards: {
        category: "RideRewards",
        action: "SingUp into ride rewards",
        label: "New user enrolled into ride rewards",
    },
    RideRewardsSignIn: {
        category: "RideRewards",
        action: "SignIn into ride rewards",
        label: "Existing User enrolled into ride rewards",
    },
    guest: {
        category: "ContinueAsGuest",
        action: "Continue as Guest",
        label: "User clicked on continue as guest",
    },
    affiliate: {
        category: "Affiliate",
        action: "Join as Affiliate",
        label: "User clicked on call Luxy from affiliate page"
    },
    corporate: {
        category: "Corporate",
        action: "Join as Corporate",
        label: "User created corporate account"
    },
    iosApp: {
        category: "DownloadAppIphone",
        action: "AppStore",
        label: "User navigated to AppStore link"
    },
    androidApp: {
        category: "DownloadAppAndroid",
        action: "PlayStore",
        label: "User navigated to google Play store link"
    },
    callLuxy: {
        category: "CallLuxy",
        action: "Called Luxy",
        label: "User clicked on call Luxy from footer"
    },
    aboutLuxy:{
        category: "Footer",
        action: "AboutUs Page",
        label: "User clicked on about us from footer"
    },
    faq:{
        category: "Footer",
        action: "FAQs page",
        label: "User clicked on FAQs page from footer"
    },
    privacy:{
        category: "Footer",
        action: "Privacy Policy page",
        label: "User clicked on Privacy Policy page from footer"
    },
    terms:{
        category: "Footer",
        action: "Terms page",
        label: "User clicked on Terms page from footer"
    },
    fb:{
        category: "Footer",
        action: "Facebook",
        label: "User clicked on Facebook from footer"
    },
    insta:{
        category: "Footer",
        action: "Instagram",
        label: "User clicked on Instagram from footer"
    },
    link:{
        category: "Footer",
        action: "LinkedIn",
        label: "User clicked on LinkedIn from footer"
    },
    twit:{
        category: "Footer",
        action: "Twitter",
        label: "User clicked on Twitter from footer"
    },
    confirmBooking:{
        category: "ConfirmBooking",
        action: "Confirming the booking",
        label: "User clicked on confirm booking button in ride detail page"
    },
    confirmBookingSuccess:{
        category: "ConfirmBooking",
        action: "Booking Success",
        label: "Booking successful for the user"
    },
    confirmBookingFailure:{
        category: "ConfirmBooking",
        action: "Booking Failed",
        label: "Booking failed for the user"
    },


}

export const WP_URLS: any = {
    "BLOG": "blog",
    "ABOUT_US": "about-luxy",
    "AFFILIATE": "programs/black-car-affiliate-service",
    "CORPORATE": "programs/book-black-car-for-corporate",
    "RIDE_REWARDS": "programs/ride-rewards",
    "FAQS": "faq",
    "PRIVACY": "privacy-policy",
    "TERMS": "terms-and-conditions-of-use",
    "EVENTS": "programs/book-limo-chauffeur-black-car-service",
    "TRAVEL_AGENT": "programs/join-the-luxy-travel-agent-program",
    "ESG": "programs/esg-compliance-black-car-industry"
}

export const RIDE_REWARDS: any = {
    "USER_REWARDS": "Sign In to apply your reward points.",
}
