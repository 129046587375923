import { connect } from 'react-redux';
import * as NavigationService from '../services/navigation';
import { GET_HEADER_MENUS, UPDATE_NOTIFICATION_DATA } from '../reducer/navigation';
import { IS_GUEST_USER, SIGN_IN_RIDE_REWARDS, SIGN_UP_RIDE_REWARDS, USER_ENROLLED_RIDE_REWARDS, USER_ENROLLED_RIDE_REWARDS_SIGN_IN, USER_SIGN_OUT, USER_DELETE } from '../reducer/user';
import Header from '../components/layout/header';
import { UserService } from '../services/user';

type Dispatch = (action: any) => void;

/**
 * Get all header menus.
 */
const getNavigationData = () => {
    return async (dispatch: Dispatch) => {
        let data = await NavigationService.NavigationService.getHeaderMenus();

        if (data !== undefined) {
            dispatch({
                type: GET_HEADER_MENUS, data: data
            });
        }
    }
}

const getNotificationData = (userId: any) => {
    return async (dispatch: Dispatch) => {
        let data = await UserService.getNotificationData(userId);
        if (data && data.data && data.data.data && data.data.data.length) {

            dispatch({
                type: UPDATE_NOTIFICATION_DATA, data: data.data.data
            });
        } else {
            dispatch({
                type: UPDATE_NOTIFICATION_DATA, data: []
            });
        }
    }
}

const updateNavigationData = (data: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: GET_HEADER_MENUS, data: data
        });
    }
}

const logOutUser = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: USER_SIGN_OUT, data: false
        });
        dispatch({
            type: IS_GUEST_USER, data: false
        });
    }
}

const updateSignIn = () => {
    return async (dispatch: Dispatch) => {
         dispatch({
            type: SIGN_UP_RIDE_REWARDS, data: false
        });
        dispatch({
            type: SIGN_IN_RIDE_REWARDS, data: false
        });
        dispatch({
            type: USER_ENROLLED_RIDE_REWARDS, data: false
        });
        dispatch({
            type: USER_ENROLLED_RIDE_REWARDS_SIGN_IN, data: false
        });
    }
}

const updateUserDeleteAccount = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: USER_DELETE, data: false
        });
    }
}

const mapStateToProps = (state: any) => {
    return {
        navigationData: state.navigation.navigationData,
        isUserLogin: state.user.isUserLogin,
        updateNavigation: state.navigation.updateNavigation,
        notificationData: state.navigation.notificationData,
        isUserDeleted: state.user.isUserDeleted
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getNavigationData: () => dispatch(getNavigationData()),
        updateNavigationData: (data: any) => dispatch(updateNavigationData(data)),
        logOutUser: () => dispatch(logOutUser()),
        getNotificationData: (id: any) => dispatch(getNotificationData(id)),
        updateSignIn: () => dispatch(updateSignIn()),
        updateUserDeleteAccount: () => dispatch(updateUserDeleteAccount())
    };
};

const NavigationContainer = connect(mapStateToProps, mapDispatchToProps)(Header);

export default NavigationContainer;