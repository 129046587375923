import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { parseGooglePlace } from './utils'
import { FormControl } from 'react-bootstrap';

class SearchBox extends Component {
  componentDidMount() {
    const { id, onPlaceChanged } = this.props

    let countries = process.env.REACT_APP_GOOGLE_COMPONENT_RESTRICTION_COUNTRIES.split(',');
    // let countriesRestriction;
    // if(countries.length === 1) {
    //   countriesRestriction = process.env.REACT_APP_GOOGLE_COMPONENT_RESTRICTION_COUNTRIES
    // }else {
    //   countriesRestriction = countries;
    // }
    
    this.searchbox = new window['google'].maps.places.Autocomplete(
      document.getElementById(id),
      { componentRestrictions: { country: countries } }
    )

    this.searchbox.addListener('place_changed', () => {
      let places = [];
      places.push(this.searchbox.getPlace());
      
      onPlaceChanged({ original: places, parsed: places.map(parseGooglePlace) })
    })
  }

  render() {
    const { fields, onPlaceChanged, reference, ...rest } = this.props
    // return <input type='text' {...rest} />
    return <FormControl
      ref={reference}
      {...rest}
    />
  }
}

SearchBox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  onPlaceChanged: PropTypes.func.isRequired,
  style: PropTypes.object
}

SearchBox.defaultProps = {
  onPlaceChanged: place => {
    console.log(
      'This is a placeholder function for the `onPlaceChanged` prop. You should override this.'
    )
    
  }
}

export default SearchBox
