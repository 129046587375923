import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { ECOMMERCE } from '../../Consts';
import { createEcommerceGA4, getPlaceCodeNdState, prepareBeginCheckout, prepareRemoveCartObj, prepareSelectedItemList } from '../../Utilities/helpers';


export default class VehicleList extends React.Component<any, any>{

    constructor(props: any) {
        super(props);
    }

    handleSelectChange = (vehicle: any, index: number) => {
        // this.setState({ selectedIndex: index });
        if (index !== this.props.selectedVehicleIndex) {
            const previousVehicle: any = this.props.vehiclesList[this.props.selectedVehicleIndex];
            let removeVehicle: any = prepareRemoveCartObj(previousVehicle, this.props.selectedVehicleIndex);
            removeVehicle.pickup = getPlaceCodeNdState(this.props.pickup);
            removeVehicle.dropoff = getPlaceCodeNdState(this.props.dropoff);
            createEcommerceGA4(removeVehicle, ECOMMERCE.REMOVE);
            const selectedVehicle: any = prepareSelectedItemList(this.props.vehiclesList[index], index, 'change_list');
            selectedVehicle.list_name = 'change_list';
            selectedVehicle.pickup = getPlaceCodeNdState(this.props.pickup);
            selectedVehicle.dropoff = getPlaceCodeNdState(this.props.dropoff);
            createEcommerceGA4(selectedVehicle, ECOMMERCE.SELECT);
            selectedVehicle.pickup = getPlaceCodeNdState(this.props.pickup);
            selectedVehicle.dropoff = getPlaceCodeNdState(this.props.dropoff);
            createEcommerceGA4(selectedVehicle, ECOMMERCE.ADD);
            const beginCheckOutData: any = prepareBeginCheckout(this.props.vehiclesList[index], index, 'change_list');
            beginCheckOutData.pickup = getPlaceCodeNdState(this.props.pickup);
            beginCheckOutData.dropoff = getPlaceCodeNdState(this.props.dropoff);
            createEcommerceGA4(beginCheckOutData, ECOMMERCE.BEGIN_CHECKOUT);
            this.props.changeListType('change_list');
        }
      
        
        this.props.selectVehicle(vehicle, index);
        // console.log('inx', this.props.selectedVehicleIndex, index)
    }

    render() {
        
        const { selectedVehicleIndex } = this.props;

        return <div className="vehicle-list">
            <div className="row no-gutters">
                <div className="col-12 text-left">
                    <Row>
                        {/* <Col>
                            <h3 className="title">Available Vehicles</h3>
                        </Col> */}
                        {
                            (this.props.isFromChangeVehicle)
                                ?
                                <Col className="text-right">
                                    <i onClick={() => this.props.closeChangeVehicle()} className="fa fa-times pointer" aria-hidden="true"></i>
                                </Col>
                                : null
                        }

                    </Row>
                </div>
            </div>

            {
                (this.props.vehiclesList && this.props.vehiclesList.length) ?
                    this.props.vehiclesList.map((vehicle: any, i: number) => {
                        const bgColor = (selectedVehicleIndex == i) ? '#efefef' : '';
                        return <div onClick={() => this.handleSelectChange(vehicle, i)} key={i} className="vehicle-block"
                            style={{ background: bgColor }}
                        >
                            <Row>
                                <Col xs={12}> 
                                <div className="vehicle-name vehicle-name-bold"> {vehicle.vehicle_type_name}</div>
                                    
                                </Col>
                            </Row> 
                                    <Row>
                                        <Col>
                                            <div className="media">
                                                <div className="media-left">

                                                    {/* `../${vehicle.vehicle_image}` */}
                                                    <img className="media-object" src={`${vehicle.vehicle_image}`} alt={`../${vehicle.vehicle_image}`} />

                                                </div>
                                            </div>
                                        </Col>
                                        
                                        <Col xs={6} className="laggage">
                                            <div className="pull-left">
                                                <span>{vehicle.passenger_capacity}</span> <i className="fa fa-user" aria-hidden="true"></i>
                                            &nbsp;&nbsp;
                                            <span>{vehicle.luggage_capacity}</span> <i className="fa fa-suitcase" aria-hidden="true"></i>
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                        <div className="vehicle-price pull-left font-weight-bold font-13">{vehicle.base_price}</div>
                                        </Col>
                                        {/* <Col xs={1} className="pull-right">
                                            <div className="">
                                                <span>{vehicle.luggage_capacity}</span> <i className="fa fa-suitcase" aria-hidden="true"></i>

                                            </div>
                                        </Col> */}
                                    </Row>
                              
                        </div>
                    }) : null
            }
        </div>
    }
}