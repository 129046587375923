import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { StatusCode } from '../../constants/status-codes';
import { Extras } from './extras';

interface InvoiceProps {
    reservationCharges: any;
    promoCodeDetails: any;
    updateInvoiceAmount: (data: any) => any;
}

interface InvoiceState {
    data: any;
    extras: any;
    showExtras: boolean;
    gratuities: any;
    gratuityAmount: any;
    selectedGratuity: any;
    totalAmount: any;
    totalAmountPromoCode: any;
    discountAmount: any;
    isPromoCodeApplied: boolean;
    extraStopsTotalPrice: any;
    extrasData: Array<any>;
    promoCodeAmount: any;
}

class Invoice extends Component<InvoiceProps, InvoiceState> {
    constructor(props: InvoiceProps) {
        super(props);
        this.state = {
            data: {},
            extras: {},
            showExtras: false,
            gratuities: {},
            gratuityAmount: 0,
            selectedGratuity: process.env.REACT_APP_GRATUITY_AMOUNT,
            totalAmount: 0,
            totalAmountPromoCode: 0,
            discountAmount: 0,
            promoCodeAmount: 0,
            isPromoCodeApplied: false,
            extraStopsTotalPrice: 0,
            extrasData: []
        }
    }

    componentDidMount = () => {
        

    }

    getExtrasDataFromExtras = (data: any) => {
        this.setState({ extrasData: data });
    }

    getAddonsData = () => {
        let extrasDataObj = this.state.data.addons;
        let extraStopsArr = Object.values(extrasDataObj);
        let filterData = extraStopsArr.filter(el => el != 0);
        
        if (filterData.length > 0) {
            this.setState({ showExtras: true });
        } else {
            this.setState({ showExtras: false });
        }
    }

    getGratuities = () => {
        let gratuities: Array<any> = this.state.data.gratuities;
        let gratuityAmountData = [];
        if (gratuities.length) {
            gratuityAmountData = gratuities.filter((gratuity: any) => {
                

                if (this.state.selectedGratuity == gratuity.gratuityText) {
                    return gratuity
                }
            });
        }
        this.setState({ gratuityAmount: (Math.round(gratuityAmountData[0].gratuityAmount * 100) / 100).toFixed(2) }, () => {
            this.returnGrandTotal();
        });
        // localStorage.setItem('gratuityAmountData', JSON.stringify(gratuityAmountData));
        sessionStorage.setItem('gratuityAmountData', JSON.stringify(gratuityAmountData));
        

    }

    componentDidUpdate = async (prevProps: any) => {
        // console.log('Promo ==>', this.props.reservationCharges);
        if (prevProps !== this.props) {
            if (prevProps.reservationCharges !== this.props.reservationCharges) {
                
                this.setState({ data: this.props.reservationCharges }, async () => {
                    await this.getAddonsData();
                    await this.getGratuities();
                    await this.calculatePromoCode();
                });
            }

            if (prevProps.promoCodeDetails !== this.props.promoCodeDetails) {
                // console.log("promoCodeDetails", this.props.promoCodeDetails.data.promo_code_amount.replace(/[^0-9\.]+/g, ""));
                this.calculatePromoCode();
            }
        }
    }

    calculatePromoCode = async () => {
        if (this.props.promoCodeDetails && this.props.promoCodeDetails.data
            && this.props.promoCodeDetails.status == StatusCode.EVERYTHING_IS_OK) {
            this.setState({ promoCodeAmount: this.props.promoCodeDetails.data.promo_code_amount });
            let promoCodeAmount = this.props.promoCodeDetails.data.promo_code_amount.replace(/[^0-9\.]+/g, "");
            
            let discountTotal = this.props.reservationCharges && this.props.reservationCharges.totalDiscount ? 
            parseInt(this.props.reservationCharges.totalDiscount) : 0 ;
            
            let total: any = 0;
            total = this.props.reservationCharges && this.props.reservationCharges.totalCost ?
            this.props.reservationCharges.totalCost : 0
            // this.setState({ totalAmountPromoCode: total, isPromoCodeApplied: true, discountAmount: discountTotal });
            this.setState({ totalAmount: total, isPromoCodeApplied: true, discountAmount: discountTotal }, async () => {
                // Update all state related information in redux state.
                let data = this.state;
                await this.props.updateInvoiceAmount(data);
                
            });


        } else if (this.props.reservationCharges.totalDiscount > 0) {
            this.setState({ isPromoCodeApplied: true });
        } else {
            this.setState({ isPromoCodeApplied: false });
            this.returnGrandTotal();
        }
    }

    returnGrandTotal = async () => {

        let gratuityAmount = this.state.gratuityAmount;
        if (!isNaN(gratuityAmount)) {
            gratuityAmount = parseFloat(gratuityAmount);
        }
        let totalAmount = this.state.data.totalCost;
        
        //begin for charges v2 api
        // let totalAmount = this.state.data.totalCost + gratuityAmount;
        // totalAmount = (Math.round(totalAmount * 100) / 100).toFixed(2);

        // end for charges v2 api

        this.setState({ totalAmount: totalAmount }, async () => {
            // Update all state related information in redux state
            let data = this.state;
            await this.props.updateInvoiceAmount(data);
        });

        return totalAmount;
    }

    calculatePromoCodeAmount = () => {

    }

    render() {
        // console.log(this.state.isPromoCodeApplied, "isPromoCodeApplied");
        
        return (
            <div className="py-1">
                {
                    (this.state.showExtras)
                    &&
                    <div className="px-2 py-0 font-13">
                        <Row className="small-title py-2 mt-3 px-2">
                            Extra Services
                        </Row>

                        <div className="py-0 font-13">
                            <Extras
                                getExtrasDataFromExtras={(data: any) => this.getExtrasDataFromExtras(data)}
                                addOnsData={this.state.data.addons} />
                        </div>
                    </div>
                }
                <div className="luxy-border pt-1">
                    {
                        (this.state.data.extraStopsTotalPrice != 0)
                        &&
                        <div className="px-0 py-2 font-13">
                            <Row>
                                <Col>Extra Stops</Col>
                                <Col className="text-right">{`$${(Math.round(this.state.data.extraStopsTotalPrice * 100) / 100).toFixed(2)}`}</Col>
                            </Row>
                        </div>
                    }

                    <div className="px-0   font-13">
                        <Row>
                            {/* <Col>Gratuity ({this.state.selectedGratuity})</Col> */}
                            <Col>Gratuity</Col>
                            <Col className="text-right">{`$${this.state.gratuityAmount}`}</Col>
                        </Row>
                    </div>
                    <div className="px-0 py-2 font-13">
                        <Row>
                            <Col>Tax</Col>
                            <Col className="text-right">
                                {`$${(Math.round(this.state.data.salesTax * 100) / 100).toFixed(2)}`}
                            </Col>

                        </Row>
                    </div>
                    {
                        (this.state.isPromoCodeApplied) ?
                            <div>
                                <Row>
                                    <Col>Discount</Col>
                                    <Col className="text-right">
                                        - {`$${this.state.data.totalDiscount}`}
                                    </Col>

                                </Row>
                            </div>
                            : null}
                </div>
                <div className="px-0 TotalResult py-2 font-13">
                    <Row>
                        <Col> Grand total</Col>
                        <Col className="text-right">
                            {
                                <b>{`$${this.state.totalAmount.toFixed(2)}`}</b>
                            }

                        </Col>
                    </Row>
                </div>

            </div>
        )
    }
}
export default Invoice;
