
import React from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import Alert from 'react-bootstrap/esm/Alert';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import PhoneInput from 'react-phone-number-input';
import { getLuxyAddress, isValidLuxyAddress } from '../../services/AddressService';
import CommonService from '../../services/CommonService';
import { createEventGA4 } from '../../Utilities/helpers';

import * as Helpers from '../common/helpers';
import GooglePlacesComponent from '../google-places/google-places';
import AgreeText from '../policy/agree-text';

import {toast } from 'react-toastify';

import ConfigurationService from '../../services/ConfigurationService';

interface CFormProps {
    saveAffiliate: (payload: any,w9 :any,insurance:any,directDeposite:any) => any;
    corporateErr: boolean;
    corporateErrMsg: string;
}

interface CFormState {
    corporateErr: boolean;
    firstNameErr: boolean;
    firstName: string;
    lastNameErr: boolean;
    lastName: string;
    termsErr: boolean;
    terms: boolean;
    companyNameErr: boolean;
    companyName: string;
    email: string;
    emailErr: boolean;
    mobile: string;
    mobileErr: boolean;
    website: string;
    websiteErr: boolean;
    businessAddress: string;
    businessAddressErr: boolean;
    post: any;
    form_start: boolean;
    w9:FileState;
    insurance:FileState;
    deposit:FileState;
    configurationsNamesObj: any;
    isPartnerAgreement: boolean,
    partnerErr: boolean,

}

interface FileState {
    file: File | null;
    previewUrl: string | null;
  }

class AffiliateForm extends React.Component<CFormProps, CFormState> {

    constructor(props: CFormProps) {
        super(props);
        this.state = {
            corporateErr: false,
            firstNameErr: false,
            firstName: '',
            lastNameErr: false,
            lastName: '',
            termsErr: false,
            terms: false,
            companyNameErr: false,
            companyName: '',
            email: '',
            emailErr: false,
            mobile: '',
            mobileErr: false,
            website: "",
            websiteErr: false,
            businessAddress: "",
            businessAddressErr: false,
            w9:{ file: null, previewUrl: null },
            insurance:{ file: null, previewUrl: null },
            deposit:{ file: null, previewUrl: null },
            post: {
                checkStatus: [],
                items: [{}],
                itemsforstate: [{}],
                itemsforworkstate: [{}],
                itemsforcity: [{}],
                itemsforworkcity: [{}],
                zipcodelist: [{}],
                workzipcodelist: [{}],
                addonservices: [{}],
                user_type_id: '',
                password: '',
                first_name: '',
                last_name: '',
                email_address: '',
                profile_image: '',
                mobile_number: '',
                airport_name: '',
                work_address: '',
                home_address: '',
                brand_name: '',
                buisness_contact: '',
                website: '',
                payroll_id: '',
                job_position: '',
                dba: '',
                user_id: '',
                airport_id: '',
                state_id: '',
                city_id: '',
                cityName: '',
                zipcode: '',
                work_zipcode: '',
                zipcode_id: '',
                country_id: '',
                work_state_id: 0,
                work_city_id: null,
                legal_business_address: '',
                business_support_email_address: '',
                business_telephone_number: '',
                service_addon_id: ''
            },
            form_start: false,
            isPartnerAgreement: false,
            partnerErr: false,
            configurationsNamesObj: {},
            
        };
    }

    

    componentDidMount() {
        let obj:any = {}
        let arr = [
            'DBW9P',
            'DW9P',
            'DICP',
            'DBDDP',
            'DDDP',
            'DPSA'
        ]
        arr.forEach(async (item: any, i: any) => {
            let response = await ConfigurationService.getConfigurationByName(item);
            if(response){
              obj = {
                  ...obj, [item]: response?.value
              }
              this.setState({ configurationsNamesObj : {...this.state.configurationsNamesObj, ...obj}}, () => {
            })
            }
        })
       
    }


    // handlePdfDownload = async (data: any, item: any) => {
    //     const url = data;
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', `ietm.pdf`);
    //     document.body.appendChild(link);
    //     link.click();
    // }

    validate = async () => {
        (this.state.companyName.trim() === '') ? this.setState({ companyNameErr: true }) : this.setState({ companyNameErr: false });
        (this.state.firstName.trim() === '') ? this.setState({ firstNameErr: true }) : this.setState({ firstNameErr: false });
        (this.state.lastName.trim() === '') ? this.setState({ lastNameErr: true }) : this.setState({ lastNameErr: false });
        (this.state.email === '' || !Helpers.validateEmail(this.state.email)) ? this.setState({ emailErr: true }) : this.setState({ emailErr: false });
        (this.state.mobile === '' || !Helpers.validateCountryPhone(this.state.mobile)) ? this.setState({ mobileErr: true }) : this.setState({ mobileErr: false });
        (!this.state.terms) ? this.setState({ termsErr: true }) : this.setState({ termsErr: false });
        if (!this.state.businessAddress) { this.setState({ businessAddressErr: true }) };
        (!this.state.isPartnerAgreement) ? this.setState({ partnerErr: true }) : this.setState({ partnerErr: false });
    }

    updateEmail = (event: any) => {
        this.setState({ email: event.target.value });
        let isValid = Helpers.validateEmail(event.target.value);
        this.setState({ emailErr: !isValid });

    }

    updateWebsite = (event: any) => {
        let website =event.target.value;
        this.setState({ website: website });
        let isValid = !/\s/.test(website);
        this.setState({ websiteErr: !isValid });
    }

    updateMobile = async (event: any) => {
        // this.setState({ mobile: event.target.value });
        let isValid = await Helpers.validateCountryPhone(event);
        this.setState({ mobile: event, mobileErr: !isValid });

    }

    validateOtherFields = (event: any, type: string) => {
        if (!this.state.form_start) {
            const dataLayerObj: any = {
                'eventName': 'form_start',
                'formName': "Affiliate",
                'event': 'formStart'
            };
            createEventGA4(dataLayerObj);
        }
        this.setState({form_start: true});
       
        if (type === 'company_name') {
            if (event.target.value.trim() === '') {
                this.setState({ companyNameErr: true, companyName: event.target.value });
            } else {
                this.setState({ companyNameErr: false, companyName: event.target.value });
            }
        }

        if (type === 'first_name') {
            if (event.target.value.trim() === '') {
                this.setState({ firstNameErr: true, firstName: event.target.value });
            } else {
                this.setState({ firstNameErr: false, firstName: event.target.value });
            }
        }
        if (type === 'last_name') {
            if (event.target.value.trim() === '') {
                this.setState({ lastNameErr: true, lastName: event.target.value });
            } else {
                this.setState({ lastNameErr: false, lastName: event.target.value });
            }
        }

        if (type === 'terms') {
            let isChecked: boolean = event.target.checked;
            if (!isChecked) {
                this.setState({ termsErr: true, terms: isChecked });
            } else {
                this.setState({ termsErr: false, terms: isChecked });
            }

        }

        if (type === 'isPartner') {
            let isPartnerChecked: boolean = event.target.checked;
            if (!isPartnerChecked) {
                this.setState({ partnerErr: true, isPartnerAgreement: isPartnerChecked });
            } else {
                this.setState({ partnerErr: false, isPartnerAgreement: isPartnerChecked });
            }

        }

        if (type === 'business_address') {
            if (event.target.value.trim() === '') {
                this.setState({ businessAddressErr: true, businessAddress: event.target.value });
            } else {
                this.setState({ businessAddressErr: false, businessAddress: event.target.value });
            }
        }

    }

    createCorporateAccount = async () => {
        const dataLayerObj: any = {
            'eventName': 'form_submit',
            'formName': 'Affiliate',
            'event': 'formSubmit'
        };
        createEventGA4(dataLayerObj);

        await this.validate();
        if (!this.state.firstNameErr &&
            !this.state.lastNameErr &&
            !this.state.emailErr &&
            !this.state.companyNameErr &&
            !this.state.mobileErr &&
            !this.state.businessAddressErr &&
            !this.state.websiteErr &&
            !this.state.termsErr &&
            !this.state.partnerErr
        ) {
            const payload: any = {
                checkStatus: this.state.post.service_addon_id,
                airport_id: this.state.post.airport_id !== '' ? this.state.post.airport_id : null,
                city_id: this.state.post.city_id !== '' ? this.state.post.city_id : null,
                state_id: this.state.post.state_id !== '' ? this.state.post.state_id : null,
                country_id: 3,
                cityName: this.state.post.cityName,
                airport_name: this.state.post.airport_name !== '' ? this.state.post.airport_name : null,
                dba: this.state.companyName !== '' ? this.state.companyName : null,
                payroll_id: this.state.post.payroll_id !== '' ? this.state.post.payroll_id : null,
                job_position: this.state.post.job_position !== '' ? this.state.post.job_position : null,
                work_zipcode: this.state.post.work_zipcode !== '' ? this.state.post.work_zipcode : null,
                business_telephone_number: this.state.mobile !== '' ? this.state.mobile : null,
                profile_image: this.state.post.profile_image !== '' ? this.state.post.profile_image : null,
                zipcode_id: this.state.post.zipcode_id !== '' ? this.state.post.zipcode_id : null,
                website: this.state.website !== '' ? this.state.website : null,
                work_address: this.state.businessAddress !== '' ? this.state.businessAddress : null,
                modetype: '0',
                user_type_id: '1',
                email_address: this.state.email,
                business_support_email_address: this.state.post.business_support_email_address !== '' ? this.state.post.business_support_email_address : null,
                legal_business_address: this.state.post.buisness_contact !== '' ? this.state.post.buisness_contact : null,
                user_id: '0',
                affiliateid: this.state.post.affiliateid !== '' ? this.state.post.affiliateid : null,
                is_w9document: this.state.post.is_w9document !== '' ? this.state.post.is_w9document : null,
                w9document_expiration_date: this.state.post.w9document_expiration_date !== '' ? this.state.post.w9document_expiration_date : null,
                is_insurance_certificate: this.state.post.is_insurance_certificate !== '' ? this.state.post.is_insurance_certificate : null,
                insurance_expiration_date: this.state.post.insurance_expiration_date !== '' ? this.state.post.insurance_expiration_date : null,
                is_direct_deposit_document: this.state.post.is_direct_deposit_document !== '' ? this.state.post.is_direct_deposit_document : null,
                directdeposit_expiration_date: this.state.post.directdeposit_expiration_date !== '' ? this.state.post.directdeposit_expiration_date : null,
                brand_name: this.state.post.brand_name !== '' ? this.state.post.brand_name : null,
                is_lead: true,
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                w9FileName: this.state.w9.file?this.state.w9.file.name:null,
                insuranceFileName: this.state.insurance.file?this.state.insurance.file.name:null,
                directDepositFileName: this.state.deposit.file?this.state.deposit.file.name:null,
                isPartnerAgreement: this.state.isPartnerAgreement,
                isPrivacyPolicy: this.state.terms

            }
            await this.props.saveAffiliate(payload,this.state.w9,this.state.insurance,this.state.deposit);
        }

    }

    handleAddrChange = (event: any) => {
        let value = event;
        this.setState({ businessAddress: value, businessAddressErr: true });
    }

    handleBusinessAddressSelect = async (selection: any) => {
        const address = selection.original[0];
        let business_address = address.name;
        Promise.resolve(getLuxyAddress(address)).then(async (businessAddress: any) => {
            business_address = businessAddress.address.full_address;

            if (!businessAddress.address.postal_code) {

                let postalCode: any = await CommonService.getPOstalCodeDetails(businessAddress.coordinates.latitude, businessAddress.coordinates.longitude);

                if (postalCode) {
                    businessAddress.address.postal_code = postalCode.postal_code;
                }
            }

            isValidLuxyAddress(businessAddress);
            this.setState({ businessAddress: business_address });
            this.setState({ businessAddressErr: false });
        }).catch((err) => {
            this.setState({ businessAddress: business_address });
            this.setState({ businessAddressErr: true });
        });
    }

    validateFile = (fileName:any, type:any, check:any) =>{
        const allowedFiles = ['.pdf'];
        const regex = new RegExp('.*\.(' + allowedFiles.join('|') + ')');
        if (!regex.test(fileName.toLowerCase())) {
          
            toast.error(`Please select pdf only.`, {
              position: toast.POSITION.TOP_RIGHT
            });
          return false;
        } else {
          return true;
        }
  };

    handleFileChange = (event: any, type: any) => {

        if (!this.validateFile(event && event.target && event.target.files[0] && event.target.files[0].name, type, event && event.target && event.target.files[0] && event.target.files[0].type)) {
            return;
          }
        
        let selectedFile = event.target.files[0];
        let bloburl = URL.createObjectURL(selectedFile);
        const reader = new FileReader();
        reader.onloadend = () => {
            if (type === 'w9') {
                this.setState({ w9 : { file: selectedFile, previewUrl: bloburl }});
            }
            if (type === 'insurance') {
                this.setState({ insurance : { file: selectedFile, previewUrl: bloburl }});
            }
            if (type === 'deposit') {
                this.setState({ deposit : { file: selectedFile, previewUrl: bloburl }});
            }
        };
        reader.readAsDataURL(selectedFile);
      };

      deleteFile = (type: any) => {
            if (type === 'w9') {
                this.setState({ w9 : { file: null, previewUrl: null }});
            }
            if (type === 'insurance') {
                this.setState({ insurance : { file: null, previewUrl: null }});
            }
            if (type === 'deposit') {
                this.setState({ deposit : { file: null, previewUrl: null }});
            }
        };

        onInputClick = (event:any) => {
            event.target.value = ''
        }

    render() {
        return (
            <div>
                {
                    (this.props.corporateErr) &&
                    <Alert variant='danger'>
                        <b>Error!</b> {this.props.corporateErrMsg}.
                    </Alert>
                }
                <div className="sign-in">
                    <Row className='affiliateleadColumnContainer'>
                    <Col className='affiliateleadColumn'>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="companyName" className="float-left">COMPANY NAME <span className="importantfields">*</span></label>
                                <FormControl
                                    id="companyName"
                                    onChange={(event: any) =>
                                        this.validateOtherFields(event, 'company_name')
                                    }
                                    value={this.state.companyName}
                                    autoComplete="nope"
                                />

                                <div>
                                    <p className="text-danger">{
                                        (this.state.companyNameErr) ?
                                            'Company Name is required'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="firstName" className="float-left">FIRST NAME <span className="importantfields">*</span></label>
                                <FormControl
                                    id="firstName"
                                    onChange={(event: any) =>
                                        this.validateOtherFields(event, 'first_name')
                                    }
                                    value={this.state.firstName}
                                    autoComplete="nope"
                                />

                                <div>
                                    <p className="text-danger">{
                                        (this.state.firstNameErr) ?
                                            'First Name is required'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="lastName" className="float-left">LAST NAME <span className="importantfields">*</span></label>
                                <FormControl
                                    id="lastName"
                                    //placeholder={"Enter Last name"}
                                    onChange={(event: any) =>
                                        this.validateOtherFields(event, 'last_name')
                                        // this.setState({ lastName: event.target.value })
                                    }
                                    value={this.state.lastName}
                                    autoComplete="nope"
                                />
                                <div>
                                    <p className="text-danger">{
                                        (this.state.lastNameErr) ?
                                            'Last Name is required'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="email" className="float-left">EMAIL <span className="importantfields">*</span></label>
                                <FormControl
                                    id="email"
                                    //placeholder={"Enter Email"}
                                    onChange={(event: any) =>
                                        this.updateEmail(event)
                                        // this.setState({ email: event.target.value })
                                    }
                                    value={this.state.email}
                                    autoComplete="nope"
                                />
                                <div>
                                    <p className="text-danger">{
                                        (!this.state.email && this.state.emailErr) ? 'Email is required' :
                                        (this.state.emailErr) ?
                                            'Enter valid email address'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <div className="form-group">
                                <label htmlFor="mobile" className="">MOBILE PHONE NUMBER <span className="importantfields">*</span></label>
                                {/* <FormControl
                                    id="mobile"
                                    // placeholder={"Enter Mobile Number"}
                                    onChange={(event: any) =>
                                        this.updateMobile(event)
                                        // this.setState({ mobile: event.target.value })
                                    }
                                    maxLength={16}
                                    value={this.state.mobile}
                                />
                                <div>
                                    <p className="text-danger">{
                                        (this.state.mobileErr) ?
                                            'Enter valid contact number'
                                            : null
                                    }</p>
                                </div> */}


                                {/* starts here */}
                                <PhoneInput
                                    defaultCountry='US'
                                    international
                                    onChange={(event: any) =>
                                        this.updateMobile(event)
                                    }
                                    value={this.state.mobile}
                                    autoComplete="nope"
                                />

                                <div>
                                    <p className="text-danger">{
                                        (!this.state.mobile && this.state.mobileErr) ? 'Mobile Number is required' : 
                                        this.state.mobileErr ?
                                            'Enter valid mobile number'
                                            : null
                                    }</p>
                                </div>
                                {/* ends here */}
                            </div>




                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group business-address">
                                <label htmlFor="businessAddress" className="float-left"> BUSINESS ADDRESS <span className="importantfields">*</span></label>
                                <GooglePlacesComponent
                                    id='affliliate_addr'
                                    onPlaceChanged={this.handleBusinessAddressSelect}
                                    value={this.state.businessAddress}
                                    handleChange={(event: any) => this.handleAddrChange(event)}
                                    showFavorites={false}
                                    isGroundOnly={true}
                                />

                                <div>
                                    <p className="text-danger">{
                                        (!this.state.businessAddress && this.state.businessAddressErr) ? 'Business Address is required' :
                                        (this.state.businessAddressErr) ?
                                            'Please select a complete address'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="website" className="float-left"> WEBSITE </label>
                                <FormControl
                                    id="website"
                                    //placeholder={"Enter Email"}
                                    onChange={(event: any) =>
                                        this.updateWebsite(event)
                                        // this.setState({ email: event.target.value })
                                    }
                                    value={this.state.website}
                                    autoComplete="nope"
                                />
                                <div>
                                    <p className="text-danger">{
                                        (this.state.websiteErr) ?
                                            'Space not allowed in website.'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    </Col><Col className='affiliateleadColumn leftBorder'>
                    <Row>
                        <Col>
                            <div className="form-group">
                            Please see samples of each document below. You can download a blank form for w9, and direct deposit. The insurance certificate must have LUXY added as certificate holder, with the vehicle(s) listed under description of operations. LUXY will verify your insurance information with your insurance agent. PDF format is required.
                               {/* Please upload following documents in pdf format only to complete Affiliate onboarding. <br/> Download sample documents from  <Link  to={"#"}  onClick={() =>redirectToWP('AFFILIATE')}>here</Link>. You can also upload these documents later by login to our Affiliate System. */}
                            </div>
                        </Col>
                        <br/>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group" style={{paddingTop:'20px'}}>
                                <div style={{height:'25px'}}> W9 DOCUMENT</div>
                                <br/>
                                <label htmlFor="w9" className="custom-file-upload float-left"><br/>
                                {!this.state.w9.file && <div style={{width: '50px'}}>Upload</div>}
                                {this.state.w9.file && <div style={{width: '50px'}}>Edit</div>}
                                <input type="file" name="w9" id="w9" onChange={(e) => this.handleFileChange(e, 'w9')} onClick={(e) => this.onInputClick(e)} accept="application/pdf"/>
                                </label>
                                {this.state.w9.file && (
                                <div>
                                <div className='fileName'>{this.state.w9.file.name}</div>
                                        <i className="fa fa-trash"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.deleteFile('w9')}
                                    ></i>
                                </div>
                                )}
                                <br/>
                                <a href={this.state.configurationsNamesObj.DW9P} target='_blank' download="W9 Document" style={{fontSize: "12px", textDecoration: 'underline' }}>Sample W9 Document</a>
                                <br/>
                                <a href={this.state.configurationsNamesObj.DBW9P} target='_blank' download style={{fontSize: "12px", textDecoration: 'underline' }}>Download Blank W9</a>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group" style={{paddingTop:'20px'}}>
                                <div style={{height:'25px'}}>INSURANCE CERTIFICATE</div>
                                <br/>
                                <label htmlFor="insurance" className="custom-file-upload float-left"><br/>
                                {!this.state.insurance.file && <div style={{width: '50px'}}>Upload</div>}
                                {this.state.insurance.file && <div style={{width: '50px'}}>Edit</div>}
                                <input type="file" name="insurance" id="insurance" onChange={(e) => this.handleFileChange(e, 'insurance')}  onClick={(e) => this.onInputClick(e)} accept="application/pdf"/>
                                </label>
                                {this.state.insurance.file && (
                                <div>
                                <div className='fileName'>{this.state.insurance.file.name}</div>
                                        <i className="fa fa-trash"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.deleteFile('insurance')}
                                    ></i>
                                </div>
                                )}
                                <a href={this.state.configurationsNamesObj.DICP} target='_blank' download style={{fontSize: "12px", textDecoration: 'underline' }}>Sample Insurance Certificate</a>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group" style={{paddingTop:'20px'}}>
                                <div style={{height:'25px'}}>DIRECT DEPOSIT</div>
                                <br/>
                                <label htmlFor="directDeposit" className="custom-file-upload float-left"><br/>
                                {!this.state.deposit.file && <div style={{width: '50px'}}>Upload</div>}
                                {this.state.deposit.file && <div style={{width: '50px'}}>Edit</div>}
                                <input type="file" name="directDeposit" id="directDeposit" onChange={(e) => this.handleFileChange(e, 'deposit')} onClick={(e) => this.onInputClick(e)} accept="application/pdf"/>
                                </label>
                                {this.state.deposit.file && (
                                <div>
                                <div className='fileName'>{this.state.deposit.file.name}</div>
                                <i className="fa fa-trash"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.deleteFile('deposit')}
                                    ></i>
                                </div>
                                )}
                                <a href={this.state.configurationsNamesObj.DDDP} target='_blank' download style={{fontSize: "12px", textDecoration: 'underline' }}>Sample Direct Deposit</a>
                                <br />
                                <a href={this.state.configurationsNamesObj.DBDDP} target='_blank' download style={{fontSize: "12px", textDecoration: 'underline' }}>Download Blank Direct Deposit</a>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div style={{padding: "20px 0px"}}><p style={{color: "red", fontSize: '12px'}}><b>IMPORTANT NOTICE***We are currently not accepting applications from the following states/cities until further notice: NY, CA, MA, South FL, Washington D.C.</b></p></div>
                    </Row>
                    </Col>
                    
                    </Row>
                    <Row>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox"
                                className="custom-label"
                                // style={{textTransform: 'none'}}
                                label={<AgreeText type="affiliate" isPartner="false"/>}
                                // checked={false}
                                onChange={(event: any) =>
                                    // this.setState({ terms: event.target.checked })
                                    this.validateOtherFields(event, 'terms')
                                }
                            />
                            <div>
                                <p className="text-danger">{
                                    (this.state.termsErr) ?
                                        'You should agree with our terms of service, privacy policy and cookie policy'
                                        : null
                                }</p>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox"
                                className="custom-label"
                                // style={{textTransform: 'none'}}
                                label={<AgreeText type="affiliate" isPartner="true" partnerPDF={this.state.configurationsNamesObj.DPSA}/>}
                                // checked={false}
                                onChange={(event: any) =>
                                    // this.setState({ terms: event.target.checked })
                                    this.validateOtherFields(event, 'isPartner')
                                }
                            />
                            <div>
                                <p className="text-danger">{
                                    (this.state.partnerErr) ?
                                        'You should agree to partner service agreement'
                                        : null
                                }</p>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <br />
                        </Col>
                        <Col>
                            <Button variant="submit float-right"
                                onClick={() => this.createCorporateAccount()}
                            >Create</Button>{' '}
                        </Col>
                    </Row>

                </div>
            </div>
        )
    }
}

export default AffiliateForm;