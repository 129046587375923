import React from 'react';
import { Modal } from "react-bootstrap";
import { createEventGA4 } from '../../Utilities/helpers';

interface ConfirmProps {
    show: boolean;
    message : any;
    isError: boolean;
    isCancelButton: boolean;
    confirmbuttonText : any;
    closeErrorPopUp: () => any;
}

function NoVehicleFoundPopUp(props: ConfirmProps) {
    // const [show, setShow] = useState(false);

    const handleClose = () => {
        props.closeErrorPopUp()
    }

    // const handleShow = () => setShow(true);

    const callGA4Event = () => {
        const obj: any = {
            'eventName': 'contact_us',
            'contactType': 'phone',
            'event': 'contactUs'
        }
        createEventGA4(obj);
    }
    return (
        <>

            <Modal
                show={props.show}
                backdrop="static"
                keyboard={false}
                centered={true}
                className='no-vehicle-found'
            >
                 <Modal.Header className='border-bottom-0'>
                    <Modal.Title>
                        <div>{props.isError ? 'Sorry' : '' }</div>
                    </Modal.Title>
                </Modal.Header> 
                <Modal.Body>
                    {/* <div>This trip can not be quoted at the moment.</div>
                    <div>Please contact us at</div>
                    <div>(833) 438-5899 for immediate assistance.</div> */}
                    <span>{props.message}</span>
                </Modal.Body>
                <div className="footerBtns">
                   {
                    props.isCancelButton ?  <div className='cancelLink'>
                    <a href="javascript:void(0)" onClick={handleClose}>Cancel</a>
                </div> : ''
                   }
                    <div className='callLink'>
                        <a href="tel:833-GET-LUXY" onClick={callGA4Event}>{props.confirmbuttonText}</a>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default NoVehicleFoundPopUp;