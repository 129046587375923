import React, { Component, useRef, useState, useEffect  } from 'react';
import { Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/esm/Modal';
// import AffiliateForm from './affiliateform';
import logo from '../../assets/images/Image 6@2x.png';
import Alert from 'react-bootstrap/esm/Alert';
import { createEventGA4 } from '../../Utilities/helpers';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import './delete-account-modal.css'

interface DeleteAccountProps {
    closeModal: any;
    showDeleteAccountModal: boolean;
    email: any;
    emailErr: any;
    userData: any;
    notifyUser: any;
    verifyOtp: any;
    setEmailToVerify: any;
    notifyUserErrMsg: any;
    showOtpVerification: boolean;
    disableEmailToVerify: boolean;
    verifyOtpMsgSuccess: any;
    showDeleteButton: boolean;
    confirmDelete: any;
    confirmDeleteMsg: any;
    setEmailErr: any;
    otpErrMsg: any;
    clearOtp: boolean;
}

interface DeleteAccountState {
    showModal: boolean;
    isSuccess: boolean;
    otp: any;
    seconds: number;
    shouldTimerStart: any;
    isTimerCompleted: any;
}

const OtpInput= ({  length = 4, onOtpSubmit = (val: any) => { console.log(val)}, clearOtp = false, setShouldTimerStart, setIsTimerCompleted }: any) => {
	const [otp, setOtp] = useState(new Array(length).fill(""));
	const inputRefs: any = useRef([]);

	useEffect(() => {
        setShouldTimerStart(true);
        setIsTimerCompleted(false);
		if (inputRefs.current[0]) {
			inputRefs.current[0].focus();
		}
	}, []);

    useEffect(() => {
        if (clearOtp) {
            setOtp(new Array(length).fill(""));
        }
    }, [clearOtp]);

	const handleChange = (index: number, e: any) => {
		const value = e.target.value;
		if (isNaN(value)) return;
        
		const newOtp = [...otp];
		// allow only one input
		newOtp[index] = value.substring(value.length - 1);
		setOtp(newOtp);

		// submit trigger
		const combinedOtp: any = newOtp.join("");
		// if (combinedOtp.length === length)
		onOtpSubmit(combinedOtp);

		// Move to next input if current field is filled
		if (value && index < length - 1 &&
			inputRefs.current[index + 1]) {
			inputRefs.current[index + 1].focus();
		}
	};

	const handleClick = (index: number) => {
        inputRefs.current[index].type = 'text';
		inputRefs.current[index].setSelectionRange(1, 1);
        inputRefs.current[index].type = 'number';

		// optional
		if (index > 0 && !otp[index - 1]) {
			inputRefs.current[otp.indexOf("")].focus();
		}
	};

	const handleKeyDown = (index: number, e: any) => {
		if (
			e.key === "Backspace" &&
			!otp[index] &&
			index > 0 &&
			inputRefs.current[index - 1]
		) {
			// Move focus to the previous input field on backspace
			inputRefs.current[index - 1].focus();
		}
	};

    const handleFocus = (e: any) => {
        var length = e?.target?.value?.length;
        e.target.type = 'text';
        e.target.setSelectionRange(length, length);
        e.target.type = 'number';
    }

	return (
		<div>
			{otp.map((value, index) => {
				return (
					<input
						key={index}
						type="number"
						ref={(input) => (inputRefs.current[index] = input)}
						value={value}
						onChange={(e) => handleChange(index, e)}
						onClick={() => handleClick(index)}
						onKeyDown={(e) => handleKeyDown(index, e)}
						className="otpInput"
                        style={{width: 40, height: 40, outlineColor: '#EB8122', margin: 5, textAlign: 'center'}}
                        onWheel={(e) => e.currentTarget.blur()}
                        onFocus={(e) => handleFocus(e)}
					/>
				);
			})}
		</div>
	);
};

const Timer = ({ shouldTimerStart, setShouldTimerStart, setIsTimerCompleted  }: any) => {
    const [seconds, setSeconds] = useState(45);
  
    useEffect(() => {
      if (shouldTimerStart) {
        const interval = setInterval(() => {
            setSeconds(seconds => seconds - 1);
        }, 1000);
  
        return () => clearInterval(interval);
      }
    }, [shouldTimerStart]);

    useEffect(() => {
        if (seconds <= 0) {
            setShouldTimerStart(false);
            setIsTimerCompleted(true);
            setSeconds(45);
        }
    }, [seconds]);
  
    return (
        <p className='text-center' style={{ fontSize: 15 }}>Code Expires in 00:{seconds.toString().padStart(2,'0')}</p>
    );
};

class DeleteAccount extends Component<DeleteAccountProps, DeleteAccountState> {
    constructor(props: DeleteAccountProps) {
        super(props);

        this.state = {
            showModal: false,
            isSuccess: false,
            otp: '',
            seconds: 30,
            shouldTimerStart: false,
            isTimerCompleted: true,
        }
    }

    onOtpSubmit = (otp: any) => {
       this.setState({ otp: otp })
    };

    closeModal = () => {
        this.props.closeModal();
        this.setState({ showModal: false });
    }

    setShouldTimeStart  = (startTimer: boolean) => {
        this.setState({ shouldTimerStart :  startTimer})
    }

    setIsTimerCompleted = (isCompleted: boolean) => {
        this.setState({ isTimerCompleted :  isCompleted})
    }

    handleResendButton = () => {
        this.props.notifyUser();
        this.setShouldTimeStart(true);
        this.setIsTimerCompleted(false);
    }

    renderModalPopup = () => {
        return <Modal show={this.props.showDeleteAccountModal} style={{ top: '50px' }} className="">
            <div className="float-right">
                <i className="fa fa-times float-right pointer"
                    onClick={() => this.props.closeModal()}
                    title="close"></i>
            </div>
            <Modal.Body>
                <p>Please enter the email address associated with your account.</p>

                <Row>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="email" className="float-left">Email <span className="importantfields">*</span></label>
                            <FormControl
                                id="email"
                                onChange={(event: any) =>
                                    this.props.setEmailToVerify(event.target.value)
                                }
                                disabled={this.props.disableEmailToVerify}
                                width={20}
                                value={this.props.email}
                                autoComplete="nope"
                                onFocus={() => this.props.setEmailErr()}
                            />
                            <p className="text-danger" style={{ fontSize: 15 }}>{
                                (this.props.emailErr) ?
                                    this.props.emailErr
                                    : null
                            }</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-center'>
                        <Button variant="submit"
                            style={{ display: 'block', width: '100%' }}
                            onClick={() => this.props.notifyUser()}
                            disabled={this.props.disableEmailToVerify}
                        >Continue</Button>{' '}
                        <p className="text-danger" style={{ fontSize: 15 }}>{
                                    (this.props.notifyUserErrMsg ?  this.props.notifyUserErrMsg : null)
                                }</p>
                    </Col>
                </Row>

                { this.props.showOtpVerification ? (
                    <div style={{ marginTop: 45 }}>
                    <h1 className='text-center'>Verify Code</h1>
                    <p className='text-center'>{`Please enter the 5-digit code sent to this mobile number ${this.props.userData ? this.props.userData['mobile_number'] : ''} and email ${this.props.userData ? this.props.userData['email_address'] : ''}. If you did not recieve the code, Click on Resend Code.`}</p>
                    <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Row>
                            <Col>
                                <div>
                                    <OtpInput  
                                        length={5} 
                                        onOtpSubmit={this.onOtpSubmit} 
                                        clearOtp={this.props.clearOtp}
                                        setShouldTimerStart = { this.setShouldTimeStart}
                                        setIsTimerCompleted = { this.setIsTimerCompleted}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <p className='text-danger text-center' style={{ fontSize: 15 }}>{this.props.otpErrMsg.trim() ? this.props.otpErrMsg : ''}</p>
                    {
                     !this.state.isTimerCompleted ? (
                         <Timer 
                             shouldTimerStart = { this.state.shouldTimerStart }
                             setShouldTimerStart = { this.setShouldTimeStart }
                             setIsTimerCompleted = { this.setIsTimerCompleted }
                         />
                     ) : <p>&nbsp;</p>
                    }

                    <Row>
                        <Col className='text-center'>
                            <Button variant="submit" style={{ display: 'block', width: '100%' }} disabled={ this.state.otp.length !== 5 }
                                onClick={() => this.props.verifyOtp(this.state.otp)}
                            >Verify</Button>{' '}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center'>
                            <p>Didn't get the code? <button style={this.state.isTimerCompleted ? { cursor: 'pointer', color: '#EB8122', background:'none', border:'none', outline: 'none' }: { cursor: 'pointer', color: 'grey', background:'none', border:'none', outline: 'none' }} disabled={this.state.isTimerCompleted ? false : true } onClick={() => this.handleResendButton()}>Resend Code</button></p>
                        </Col>
                    </Row>
                </div>
                ) : null
                    
                }

                {
                    this.props.showDeleteButton ? (
                    <div style={{ marginTop: 45  }}>
                        <h1 className='text-center'>Come back soon!</h1>
                        <p className='text-center'>{ this.props.verifyOtpMsgSuccess }</p>
                        <Row style={{ marginTop: 60 }}>
                            <Col className='text-center'>
                                <Button variant="submit" style={{ display: 'block', width: '100%' }}
                                    onClick={ () => this.props.confirmDelete() }
                                >Delete Account</Button>{' '}
                            </Col>
                        </Row>
                        <Row>
                    </Row>
                    </div>
                    ) : null
                }

            </Modal.Body>
            
        </Modal>
    }

    render() {
        return (
            <div>
                {
                    this.renderModalPopup()
                }
            </div>
        )
    }
}

export default DeleteAccount;