import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import PaymentService from '../../services/PaymentService';
import ConfirmPopup from '../modals/confirmation';
import dropin from 'braintree-web-drop-in';
import { toast, ToastContainer } from 'react-toastify';
import { createEventGA4 } from '../../Utilities/helpers';
var instanceval: any = '';
var paymentMethodInstance: any = '';



const images = {
    //EXTRAS
    AMEX: require('../../assets/images/cards/amex.png'),
    DISCOVER: require('../../assets/images/cards/discover.png'),
    VISA: require('../../assets/images/cards/visa.png'),
    MASTER_CARD: require('../../assets/images/cards/mastercard.png'),
    DEFAULT: require('../../assets/images/cards/default.png'),
}

class PaymentSettingsView extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            showConfirmPopup: false,
            cardToken: '',
            showPaymentAddPopup: false,
            showPaymentEditPopup: false,
            selectedCardDetails: null,
            userData: null
        }

    }

    async componentDidMount() {
        await this.props.createCustomerToken();
        await this.loadPaymentMethods();
        // console.log(this.props.paymentMethods);
    }


    loadPaymentMethods = async () => {
        let isLoggedIn = sessionStorage.getItem('isLoggedIn');
        if (isLoggedIn == 'true') {
            let userInfoRaw = sessionStorage.getItem('user');
            if (userInfoRaw != '' && userInfoRaw != undefined && userInfoRaw != null) {
                let curUserInfo: any = JSON.parse(userInfoRaw);
                if (curUserInfo.id) {
                    this.setState({
                        userData: curUserInfo
                    }, () => {
                        this.props.getUserPaymentMethods(curUserInfo.id);
                    })

                }
            }
        }
    }

    renderImage = (cardType: any) => {
        let src = "";
        switch (cardType) {
            case 'AMEX':
                src = images.AMEX;
                break;
            case 'MasterCard':
                src = images.MASTER_CARD;
                break;
            case 'Visa':
                src = images.VISA;
                break;
            case 'Discover':
                src = images.DISCOVER;
                break;
            default:
                src = images.DEFAULT;
        }
        return <img className="pay-card-img" src={src} />
    }

    confirmDeleteSavedCard = async (token: string) => {
        // show alert popup.
        let showConfirmPopup: boolean = this.state.showConfirmPopup;
        if (!showConfirmPopup) {
            showConfirmPopup = true;
            this.setState({ cardToken: token });
        } else {
            showConfirmPopup = false;
        }
        this.setState({ showConfirmPopup: showConfirmPopup }, () => {
        });
    }

    closeConfirmPopup = async () => {
        this.setState({ showConfirmPopup: false });
    }

    deleteSavedCard = async () => {
        let token: string = this.state.cardToken;
        await PaymentService.deleteSavedCard(token);
        await this.props.resetPaymentMethodToken();
        await this.loadPaymentMethods();
        this.setState({ showConfirmPopup: false });
        this.createAddDropCardsEvent('delete')
    }


    //#region create drop in
    CreateDropIn = () => {
        // $('#dropin-container').empty();
        // console.log(this.props);
        dropin.create({
            authorization: this.props.clientToken,
            container: '#dropin-container',
            card: {
                cardholderName: {
                    required: true
                }
            }
        }, (createErr: any, instance: any) => {
            instanceval = instance;
        })
    }


    CreateDropInForEdit(cardToken: any, cardholderName: any, cardExptDate: any, cardLast4: any) {
        const points = "•••• •••• •••• ";
        // $('#cardDetailsEditForm').empty();
        dropin.create({
            authorization: this.props.clientToken,
            container: '#cardDetailsEditForm',
            card: {
                overrides: {
                    fields: {
                        number: {
                            placeholder: `${points} ${cardLast4}`,
                            showLastFour: true
                        },
                        expirationDate: {
                            placeholder: `${cardExptDate}`
                        },
                        cardholderName: {
                            placeholder: `${cardholderName}`
                        }
                    }
                },
                cardholderName: {
                    required: true,
                }
            }
        }, function (createErr, instance) {
            paymentMethodInstance = instance;
        })
    }

    showNewPayment = () => {
        this.setState({
            showPaymentAddPopup: true
        }, () => {
            this.CreateDropIn();
        })

    }

    closePaymentModal = () => {
        this.setState({
            showPaymentAddPopup: false,
            showPaymentEditPopup: false
        });
    }

    //#region create nonce for braintree
    createNonce = async () => {
        const payment_token = this.props.paymentMethodToken;

        // Check for add new card.

        if (this.props.paymentMethods.length === 0 || payment_token == "") {

            if (payment_token == '') {
                return new Promise((resolve, reject) => {
                    if (instanceval != undefined && instanceval != '') {
                        instanceval.requestPaymentMethod((err: any, payload: any) => {

                            if (payload != undefined) {
                                resolve(payload.nonce);
                            } else {
                                // toast.error("Please select a payment method !");
                                resolve(0);
                            }
                        });
                    }
                });
            } else {
                return new Promise(async (resolve, reject) => {
                    await this.props.getPaymentNonce(payment_token);
                    resolve(this.props.paymentNonceData);
                });
            }

        }
        else {
            return new Promise(async (resolve, reject) => {
                await this.props.getPaymentNonce(payment_token);
                resolve(this.props.paymentNonceData);
            });
        }
    }

    createEditNonce = async () => {
        return new Promise((resolve, reject) => {
            if (paymentMethodInstance != undefined && paymentMethodInstance != '') {
                paymentMethodInstance.requestPaymentMethod((err: any, payload: any) => {

                    if (payload != undefined) {
                        resolve(payload.nonce);
                    } else {
                        // toast.error("Please select a payment method !");
                        this.resetEditDropin();
                        resolve(0);
                    }
                });
            }
        });
    }
    //#endregion

    resetEditDropin = async () => {
        paymentMethodInstance.clearSelectedPaymentMethod();
    }
    resetDropin = async () => {
        instanceval.clearSelectedPaymentMethod();
    }

    savePaymentMethod = async () => {
        let nonceData = await this.createNonce();

        let brainTreeCustomerId = this.props.brainTreeCustomerId;
        if(!brainTreeCustomerId) {
            // Check for brainTree customer id.
            let checkBrainTreeCustomer: any = await PaymentService.checkForBraintreeCustomerID(this.state.userData.id);
            if(checkBrainTreeCustomer && checkBrainTreeCustomer.data 
                && checkBrainTreeCustomer.data.data && checkBrainTreeCustomer.data.data.customer_id) {
                    brainTreeCustomerId = checkBrainTreeCustomer.data.data.customer_id;
                }else {
                    let createBrainTreeCustomer: any = await PaymentService.createBrainTreeCustomer();

                    brainTreeCustomerId = createBrainTreeCustomer.data.data.customer_id;
                }
        }

        await this.props.createPaymentMethod(brainTreeCustomerId, nonceData);
        // console.log(this.props.paymentCreatedData, 'paymentCreatedData');
        if (!this.props.paymentCreatedData.success) {
            await toast.error('Invalid Card, Please enter valid card details to continue.');
            await this.resetDropin();
            await this.props.resetPaymentMethodToken();
            return false;
        }
        await this.props.savePaymentMethodDetails(this.props.paymentCreatedData.paymentMethod,
            this.state.userData.id,
            brainTreeCustomerId
        );
        this.setState({
            showPaymentAddPopup: false
        }, async () => {
            await this.resetDropin();
            await this.props.resetPaymentMethodToken();
            toast.success("Card details added successfully");
            this.loadPaymentMethods();
            this.createAddDropCardsEvent('add');
        });
    }

    createAddDropCardsEvent = (preference: string) => {
        const obj: any = {
            'eventName': 'account_card',
            'preference': preference,
            'event': 'accountCards'
        };
        createEventGA4(obj);
    }

    updatePaymentMethod = async () => {
        let nonceData = await this.createEditNonce();
        const data = {
            "nonce": nonceData,
            "cardToken": this.state.selectedCardDetails.token,
            "customerId": this.state.userData.id
        };
        await this.props.updatePaymentMethodDetails(data);
        //  this.setState({
        // showPaymentEditPopup: false,
        //  }, async () => {
        //  console.log(this.props.updatePaymentMethodData, 'this.props.updatePaymentMethodData');
        if (this.props.updatePaymentMethodData.data && this.props.updatePaymentMethodData.data.status && this.props.updatePaymentMethodData.data.status == 200) {
            toast.success("Card details updated successfully");
            this.setState({ showPaymentEditPopup: false, selectedCardDetails: null });
            await this.props.resetPaymentMethodToken();
            this.loadPaymentMethods()
        } else {
            toast.error("Invalid Card, Please enter valid card details to update.");
        }

        // })

    }

    editCard = async (details: any) => {
        // await this.props.selectedPaymentMethod(details.token);
        this.setState({
            showPaymentEditPopup: true,
            selectedCardDetails: details
        }, () => {
            // cardholderName: any, cardExptDate: any, cardLast4: any
            let expiry_date = details.expiration_month + '/' + details.expiration_year;
            this.CreateDropInForEdit(details.token, details.name_on_card, expiry_date, details.card_last4);
        })

    }

    renderPaymentAdd = () => {
        return <Modal size="lg" show={this.state.showPaymentAddPopup} style={{ top: '0px' }} scrollable={true} >
            <div className="float-right">
                <i className="fa fa-times float-right pointer"
                    onClick={() => { this.closePaymentModal() }}
                    title="close"></i>
            </div>
            <Modal.Header>
                Add Card
        </Modal.Header>
            <Modal.Body style={{ overflowY: 'scroll' }}>
                <Row style={{ display: 'inline-flex' }}>
                    <div className="col-sm-12" >
                        <div id="dropin-container">

                        </div>
                    </div>
                </Row>
                <Row>
                    <div>
                        <button style={{ width: '150px' }}
                            onClick={() => { this.savePaymentMethod() }}
                            className="btn btn-block primary custom-button">Save</button>
                    </div>
                </Row>
            </Modal.Body>
        </Modal>
    }

    renderPaymentEdit = () => {
        return <Modal size="lg" show={this.state.showPaymentEditPopup} style={{ top: '0px' }} scrollable={true} >
            <div className="float-right">
                <i className="fa fa-times float-right pointer"
                    onClick={() => { this.closePaymentModal() }}
                    title="close"></i>
            </div>
            <Modal.Header>
                Update Card Details
        </Modal.Header>
            <Modal.Body style={{ overflowY: 'scroll' }}>
                <Row style={{ display: 'inline-flex' }}>
                    <div className="col-sm-12" >
                        <div id="cardDetailsEditForm">

                        </div>
                    </div>
                </Row>
                <Row>
                    <div>
                        <button style={{ width: '150px' }}
                            onClick={() => { this.updatePaymentMethod() }}
                            className="btn btn-block primary custom-button">Save</button>
                    </div>
                </Row>
            </Modal.Body>
        </Modal>
    }

    render() {
        const paymentMethods = this.props.paymentMethods;
        return <div>
            {/* <GetLuxy /> */}
            <ToastContainer />
            <div className='about-top'>
                <div className='get-luxy-widget'>
                    <h1 className='text-center'>Payment Settings</h1>
                </div>
            </div>
            <div className='about-content'>
                <div className="">  <div className="container-fluid container-sm" >

                    <div className="container mt-2">
                        <div className="card p-0 w-100">
                            <div className="card-body p-md-3 py-1 p-0">
                                <div>
                                    <div className="mt-0">
                                        <div className="row">
                                            <div className="col-xl-6"><h4> <span className="payment-span-text">My Cards</span> </h4></div>
                                            <div className="col-xl-6 float-right">
                                                <button style={{ width: '150px' }}
                                                    onClick={() => { this.showNewPayment() }}
                                                    className="btn btn-block primary custom-button float-right">+ Add Card</button>
                                            </div>

                                        </div>
                                        <div className="row mt-2">
                                            {/* <Row className="p-2">
                        <Col xs={4} sm={4} md={4} lg={4}>
                            <Select
                                options={paymentOptions}
                                placeholder="Select Payment"
                                onChange={(event: any) => (event)}
                            />
                        </Col>
                    </Row> */}
                                            {
                                                (paymentMethods && paymentMethods.length) ?

                                                    <>
                                                        {paymentMethods.map((details: any) => {
                                                            // checked={selectedradio}
                                                            //this.SetPaymentMethodToken.bind(this, card_token)
                                                            return <Row key={'pay_' + details.token} className="float-left p-2">

                                                                <div className="col-4 p-0 col-sm-4" >
                                                                    <div className="skill-card">
                                                                        <div>
                                                                            {/* <i className="far  fa-credit-card" style={{ fontSize: '40px' }}></i> */}
                                                                            {/*  this.confirmDeleteSavedCard(details.token) */}
                                                                            <Row>
                                                                                <Col>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className="float-left">
                                                                                        <i className="fa fa-pencil-square-o  pointer" title="Edit"
                                                                                            onClick={() => { this.editCard(details) }} aria-hidden="true">
                                                                                        </i>
                                                                                    </div>
                                                                                    <div className="float-right">
                                                                                        | &nbsp;<i className='fa fa-trash pointer' title="Delete"
                                                                                            onClick={() => this.confirmDeleteSavedCard(details.token)} >
                                                                                        </i>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>

                                                                        </div>
                                                                        {/*  this.SetPaymentMethodToken(details.token) */}
                                                                        <section className="skill-card__body" onClick={() => { }}>
                                                                            <h2 className="skill-card__title">
                                                                                <span style={{ fontSize: '16px', textTransform: 'capitalize' }}>{details.name_on_card}</span></h2><span> {this.renderImage(details.card_type)}</span>&nbsp;&nbsp;<span className="skill-card__duration">xxxx-xxxx-xxxx-{details.card_last4}</span>
                                                                            <ul className="skill-card__duration" style={{ padding: '0px', margin: '0px' }}>
                                                                                <li> <span>{details.expiration_month + '/' + details.expiration_year.slice(2, 4)}</span></li>
                                                                            </ul>

                                                                        </section>
                                                                    </div>


                                                                </div>


                                                            </Row>
                                                        })}
                                                    </> : <Row> <p> No payment methods available. </p> </Row>


                                            }


                                        </div>



                                        {
                                            // this.closeConfirmPopup
                                            // this.deleteSavedCard
                                            (this.state.showConfirmPopup)
                                                ? <ConfirmPopup
                                                    show={this.state.showConfirmPopup}
                                                    closeConfirmPopup={this.closeConfirmPopup}
                                                    title={'Remove'}
                                                    bodyText={'Would you like to remove this card from the list?'}
                                                    deleteMethod={this.deleteSavedCard}
                                                /> : null
                                        }
                                    </div>
                                    {
                                        this.renderPaymentAdd()

                                    }
                                    {
                                        this.renderPaymentEdit()
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    }
}

export default PaymentSettingsView;
