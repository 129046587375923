import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

interface PaymentFailureProps {
    location: { state: any };
    history: { push: any };
}

interface PaymentFailureState {

}

class PaymentFailure extends React.Component<PaymentFailureProps, PaymentFailureState>{

    constructor(props: PaymentFailureProps) {
        super(props);
    }

    componentDidMount() {
        // localStorage.removeItem('bookingDetails');
        sessionStorage.removeItem('bookingDetails');
        sessionStorage.removeItem('valid_pickup');
        sessionStorage.removeItem('gratuityAmountData');
        // localStorage.removeItem('distance_in_miles');
        // localStorage.removeItem('total_hours');
        sessionStorage.removeItem('distance_in_miles');
        sessionStorage.removeItem('total_hours');
        sessionStorage.removeItem('passengerData');
        sessionStorage.removeItem('ispickairportfield');
        // sessionStorage.removeItem('drop_timeZoneId');
        sessionStorage.removeItem('valid_dropoff');
        sessionStorage.removeItem('isdropairportfield');
        
    }


    handleAddonChange = (event: any, addon: any) => {

    }

    render() {

        return (
            <div>

                <div className="container final_step" style={{ marginTop: '150px', minHeight: '300px' }}>

                    <div className="wpb_wrapper ">
                        <h1 style={{ color: '#eb692e', textAlign: 'center' }} className="vc_custom_heading">We were unable to process your ride.</h1>
                        {this.props.location.state.isPaymentError?  <h2 style={{ color: '#eb692e', textAlign: 'center' }}> <b>Payment Error: </b>{this.props.location.state.errorMsg}</h2>: null}
                        <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                                <p style={{ textAlign: 'center' }}>An error occurred.</p> <p style={{ textAlign: 'center' }}>Please contact our 24/7 client care team at 833.GET.LUXY (833-438-5899) for immediate assistance.</p>
                            </div>
                        </div>
                        <Row className="text-center align-center">
                            <Col>
                                <a onClick={() => this.props.history.push('/')} className="pointer">Back To HOME</a>
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        createdTransactionData: state.paymentInfo.createdTransactionData
    };
};

const PaymentFailureContainer = withRouter(connect(mapStateToProps)(PaymentFailure));

export default PaymentFailureContainer;