import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RIDE_REWARDS } from '../../constants/app-constants';


interface RewardsToolBarProps {
    showRideRewards:(event: any) => void;
    showRideReward: boolean;
    disableRideRewards: boolean;
}

interface RewardsToolBarState {
}

class RewardsToolBar extends Component<RewardsToolBarProps, RewardsToolBarState> {
    constructor(props: RewardsToolBarProps) {
        super(props);
        this.state = {
        }
    }

    componentDidMount = async () => {
    }

    componentDidUpdate = (prevProps: any) => {
    }

    createMarkup = (myTextFromDatabase: any) => {
        return { __html: myTextFromDatabase };
    }
    renderTooltip = () => {
        return <Tooltip id="button-tooltip">
            <div className="addon-meta" dangerouslySetInnerHTML={this.createMarkup(RIDE_REWARDS.USER_REWARDS)} ></div>
        </Tooltip>
    }

    render() {

        return (

            <div className="addon-block">
                <div className="row">
                    <div className="col-1 p-0  text-center">
                        <i className="fa fa-gift" style={{ color: '#909090', fontSize: '24px' }} aria-hidden="true"></i>
                    </div>
                    <div className="col pl-0 text-left">
                        <div >
                            <div className="extras">
                                Ride Rewards
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 250 }}
                                    overlay={this.renderTooltip()}
                                >
                                    <i className="fa fa-info-circle ml-2"></i>
                                </OverlayTrigger>
                            </div>

                        </div>
                    </div>
                    <div className="col">
                        <div className="custom-control custom-switch ">
                            <input
                                id={`rewards_${1}`}
                                onChange={(event) => { this.props.showRideRewards(event) }}
                                checked={this.props.showRideReward}
                                disabled={this.props.disableRideRewards}
                                className="custom-control-input"
                                type="checkbox" />
                            <label className="custom-control-label" htmlFor={`rewards_${1}`}>

                            </label>
                        </div>
                    </div>
                </div>
            </div>

        )

    }
}

type Dispatch = (action: any) => void;
const mapStateToProps = (state: any) => {
    return {
        user: state.user.user,
        userRewards: state.rideRewards.userRewards
    };
};

const applyRewardPoints = (points: number, userId: number) => {

}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        applyRewardPoints: (rewardPoints: number, userId: number) => dispatch(applyRewardPoints(rewardPoints, userId))
    };
};

const RewardsToolBarContainer = connect(mapStateToProps, mapDispatchToProps)(RewardsToolBar);

export default RewardsToolBarContainer;