import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import redirect from '../../assets/images/redirect.png';
import './expired.css';

interface ExpiredLinkProps extends RouteComponentProps<any>{

}

interface ExpiredLinkState {

}

class ExpiredLink extends Component<ExpiredLinkProps, ExpiredLinkState> {
    constructor(props: ExpiredLinkProps) {
        super(props);
        this.state = {
        }
        this.RedirectToLogin = this.RedirectToLogin.bind(this);
    }
    RedirectToLogin(){
        this.props.history.push('/');
    }
    render() {
        return (
            <div className="ex-link-wrap">
                <div className="container">
                    <div className="ex-link-inner">
                        <div className="row">
                            <div className="col-lg-12 mb-5">
                                <img src={redirect} className="img-fluid img-redirect" alt="" />
                            </div>
                            <div className="col-lg-12">
                                <h2 className="text-center">Sorry, this link has been expired!</h2>
                                <p className="mb-5">The link you are looking for may be expired, or the page may have been removed.<br /> Click the below button to redirect the Homepage.</p>
                                <button onClick={this.RedirectToLogin} type="button" className="btn-orange-secondary">Redirect</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ExpiredLink;