import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as AuthService from '../../services/auth';

class UserList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount = async () => {
        if (this.props.allUsers === undefined)
            this.props.getAllUsers();
    }

    deleteUser = async (userId: number) => {
        let allUsers: Array<any> = Object.assign([], this.props.allUsers);

        const userIndex = allUsers.findIndex((el: any) => el.id === userId);
        allUsers.splice(userIndex, 1);
        await this.props.updateUsers(allUsers);

    }

    testRefreshToken = async () => {
        await AuthService.AuthService.getPosts();
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <div>
                        <Link to={"/user"}>Add User</Link>
                        <button onClick={() => this.testRefreshToken()}> Test Refresh token</button>
                    </div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (this.props.allUsers)
                                &&
                                this.props.allUsers.map((user: any) => {
                                    return <tr key={user.id}>
                                        <td>{user.id}</td>
                                        <td>{user.firstName}</td>
                                        <td>{user.lastName}</td>
                                        <td>
                                            <Link title="Edit" to={{ pathname: "user", state: { userId: user.id } }}>
                                                <i className="fa fa-edit"></i>
                                            </Link>
                                            <i className="fa fa-trash"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.deleteUser(user.id)}
                                            ></i>
                                        </td>
                                    </tr>
                                })

                            }
                        </tbody>
                    </Table>
                </Row>
            </Container >
        )
    }
}

export default UserList;