import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import './payment.css';
import moment from 'moment';
import OtherVehicles from '../why-luxy/other-vehicles';
import { UPDATE_MEET_AND_GREET } from '../../reducer/details';
import CommonService from '../../services/CommonService';
import TagManager from "react-gtm-module";
import { USER_SIGN_UP } from '../../reducer/user';
import { createEventGA4 } from '../../Utilities/helpers';
import { LIST_TYPE } from '../../reducer/book-a-ride';
import { REWARD_POINTS_APPLIED } from '../../reducer/ride-rewards';

interface PaymentSuccessProps {
    location: { state: any };
    history: { push: any };
    updateMeetandGreetData: (data: any) => any;
    updateIsSignUp: () => any;
    changeListType: () => any;
}

interface PaymentSuccessState {

}

class PaymentSuccess extends React.Component<PaymentSuccessProps, PaymentSuccessState>{

    constructor(props: PaymentSuccessProps) {
        super(props);
    }

    componentDidMount() {
        this.props.updateMeetandGreetData({});
        this.props.changeListType();
        // localStorage.removeItem('bookingDetails');
        sessionStorage.removeItem('bookingDetails');
        sessionStorage.removeItem('valid_pickup');
        sessionStorage.removeItem('gratuityAmountData');
        // localStorage.removeItem('distance_in_miles');
        // localStorage.removeItem('total_hours');
        sessionStorage.removeItem('distance_in_miles');
        sessionStorage.removeItem('total_hours');
        sessionStorage.removeItem('passengerData');
        sessionStorage.removeItem('ispickairportfield');
        // sessionStorage.removeItem('drop_timeZoneId');
        sessionStorage.removeItem('valid_dropoff');
        sessionStorage.removeItem('isdropairportfield');

        // Commenting below fn call not to fire the old purchase event.
        // As we have implemented new purchase event in GA4.
        // if (localStorage.getItem('GTMRecorded') !== 'true') {
        // this.sendRequest(this.props.location.state.reservationData);
        // }

    }

    componentWillUnmount = () => {
        // localStorage.removeItem('GTMRecorded');
        this.props.updateIsSignUp();
    }

    async sendRequest(state: any) {
        try {
            const measurementType = 'purchase';
            const { order_id } = state;
            const orderDetails: any = await CommonService.getOrderDetails(order_id, measurementType);

            if (orderDetails && orderDetails.data && orderDetails.data.data && orderDetails.data.data.length) {
                for (const tripDetail of orderDetails.data.data) {

                    const dataLayerArgs = {
                        dataLayer: {
                            ecommerce: {
                                [measurementType]: tripDetail
                            },
                            event: "onTransactionSuccess"
                        }
                    };
                    TagManager.dataLayer(dataLayerArgs);
                    // localStorage.setItem('GTMRecorded', 'true');
                    sessionStorage.setItem('GTMRecorded', 'true');
                }
            }

        } catch (error) {
            throw error;
        }
    }

    redirectHome = () => {
        // ReactGA.event(googleEventAnalytics.BookANextRide);
        const dataLayerObj: any = {
            'eventName': 'book_next_ride',
            'event': 'bookNextRide'
        };
        createEventGA4(dataLayerObj);
        this.props.history.push('/');
    }

    render() {
        let { reservationData, flightDetails } = this.props.location.state;


        return (
            <>
                <div className="RideDetailsBG text-center">
                    <div className="container py-5 ">
                        <div className="card w-100 p-2 pt-4">
                            <div className="d-flex payment-success-pane">
                                <div>
                                    <div className="success-img">
                                    </div>
                                </div>


                            </div>
                            <div className="success-text">
                                <h4>We appreciate your business</h4>
                            </div>
                            <Row className="p-2">
                                <Col>
                                    <span> <h4 className="payment-span-text">Booking Confirmed</h4> </span>
                                </Col>
                            </Row>
                            <div className="success-table text-left">

                                <Row className="px-3 py-2 text-left">
                                    <Col xl={6}>
                                        <Row>
                                            <Col xl={12}><small><b>Trip Id :</b></small></Col>
                                            <Col xl={12}>
                                                {/* <b> <span className="payment-span-text">{reservationData.trip_id}</span></b> */}
                                                <b> <span className="payment-span-text">{reservationData.tripConfirmationNumber}</span></b>

                                            </Col>
                                        </Row>


                                    </Col>
                                    <Col xl={6}>
                                        <Row>
                                            <Col xl={12}><small><b>Date/Time :</b></small></Col>
                                            <Col xl={12}>
                                                {/* <b> <span className="payment-span-text">
                                                    {moment.tz(reservationData.pickup_date, reservationData.pickup.timezone).format('MM-DD-YYYY')}
                                &nbsp;&nbsp;
                                {moment.tz(reservationData.pickup_date, reservationData.pickup.timezone).format('hh:mm A')}
                                                </span></b> */}
                                                <b> <span className="payment-span-text">
                                                    {moment(reservationData.datetime).format('MM-DD-YYYY')}
                                                    &nbsp;&nbsp;
                                                    {moment(reservationData.datetime).format('hh:mm A')}
                                                </span></b>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="px-3 py-2 text-left">
                                    <Col xl={6}>
                                        <Row>
                                            <Col xl={12}><small><b>Pick-Up :</b></small></Col>
                                            <Col xl={12}>
                                                <b> <span className="payment-span-text">
                                                    {reservationData.pickup}
                                                </span></b>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xl={6}>
                                        <Row>
                                            <Col xl={12}> <small><b>Drop-Off :</b></small></Col>
                                            <Col xl={12}>
                                                <b> <span className="payment-span-text">
                                                    {reservationData.dropoff}
                                                </span></b>
                                            </Col>
                                        </Row>  </Col>
                                </Row>

                                {
                                    (flightDetails.airline_name != '') ? <Row className="px-3 py-2 text-left">
                                        <Col xl={6}>
                                            <Row>
                                                <Col xl={12}><small><b>Flight Number :</b></small></Col>
                                                <Col xl={12}>
                                                    <b> <span className="payment-span-text">{flightDetails.flight_number}</span></b>
                                                </Col>
                                            </Row>
                                        </Col><Col xl={6}>
                                            <Row>
                                                <Col xl={12}> <small><b>Flight Details :</b></small></Col>
                                                <Col xl={12}>
                                                    <b> <span className="payment-span-text">{flightDetails.airline_name}</span></b>
                                                </Col>
                                            </Row>  </Col> </Row> : null
                                }



                            </div>
                            <div className="success-text1">
                                <p>An email has been sent to you.</p>

                            </div>
                            <div className='pb-2'>
                                <Button variant="submit"
                                    style={{ color: '#fff' }}
                                    onClick={this.redirectHome}
                                >Book Next Ride
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>

                <OtherVehicles />
            </>
        )
    }
}

const updateMeetandGreetData = (data: any) => {


    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_MEET_AND_GREET, data: data
        });
    }
}

const updateIsSignUp = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: USER_SIGN_UP, data: false
        });
    }
}
/**
 * Set reward points to zero.
 * @returns 
 */
const updateRewardPoints = () => {
    return async (dispatch: Dispatch) => {
        const pointsData = {
            points: 0,
            user_id: 0,
            is_applied: false
        }
        dispatch({
            type: REWARD_POINTS_APPLIED, data: pointsData
        });
    }
}
const changeListType = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: LIST_TYPE, data: undefined
        });
    }
}
const mapStateToProps = (state: any) => {
    return {
        createdTransactionData: state.paymentInfo.createdTransactionData
    };
};

type Dispatch = (action: any) => void;

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateMeetandGreetData: (data: any) => dispatch(updateMeetandGreetData({
            name: '',
            notes: ''
        })),
        updateIsSignUp: () => dispatch(updateIsSignUp()),
        changeListType: () => dispatch(changeListType()),
        updateRewardPoints: () => dispatch(updateRewardPoints())
    }
}


const PaymentSuccessContainer = connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);

export default PaymentSuccessContainer;