export const SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS";
export const SET_PAYMENT_METHOD_TOKEN = "SET_PAYMENT_METHOD_TOKEN";
export const SET_CLIENT_TOKEN = "SET_CLIENT_TOKEN";
export const SET_PAYMENT_NONCE = "SET_PAYMENT_NONCE";
export const SET_PAYMENT_DATA = "SET_PAYMENT_DATA";
export const SAVE_PAYMENT_METHOD = "SAVE_PAYMENT_METHOD";
export const CREATE_RESERVATION = "CREATE_RESERVATION";
export const SAVE_INVOICE_DATA = "SAVE_INVOICE_DATA";
export const TRANSACTION_DATA = "TRANSACTION_DATA";
export const CREATE_ORDER = "CREATE_ORDER";

const defaultState = {
    paymentMethods: [],
    brainTreeCustomerId: '',
    paymentMethodToken: '',
    clientToken: '',
    paymentNonceData: '',
    createdPaymentData: {},
    savedPaymentMethodData: {},
    createReservationData: {},
    invoiceAmountData: {},
    createTransactionData: {},
    createOrderData:{},
}

export function paymentReducer(state: any = defaultState, action: { type: string, data?: any }) {
    switch (action.type) {
        case SET_PAYMENT_METHODS: {
            return {
                ...state,
                paymentMethods: action.data.paymentMethods,
                brainTreeCustomerId: action.data.brainTreeCustomerId
            }
        }
        case SET_PAYMENT_METHOD_TOKEN: {
            return {
                ...state,
                paymentMethodToken: action.data
            }
        }
        case SET_CLIENT_TOKEN: {
            return {
                ...state,
                clientToken: action.data
            }
        }
        case SET_PAYMENT_NONCE: {
            return {
                ...state,
                paymentNonceData: action.data
            }
        }
        case SET_PAYMENT_DATA: {
            return {
                ...state,
                createdPaymentData: action.data
            }
        }
        case SAVE_PAYMENT_METHOD: {
            return {
                ...state,
                savedPaymentMethodData: action.data
            }
        }
        case CREATE_RESERVATION: {
            return {
                ...state,
                createReservationData: action.data
            }
        }

        case CREATE_ORDER: {
            return {
                ...state,
                createOrderData: action.data
            }
        }

        case SAVE_INVOICE_DATA: {
            return {
                ...state,
                invoiceAmountData: action.data
            }
        }
        case TRANSACTION_DATA: {
            return {
                ...state,
                transactionData: action.data
            }
        }
        default:
            return state;
    }
}