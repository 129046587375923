export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_PAST_TRIPS = "UPDATE_PAST_TRIPS";
export const UPDATE_UPCOMING_TRIPS = "UPDATE_UPCOMING_TRIPS";
export const UPDATE_INPROGRESS_TRIPS = "UPDATE_INPROGRESS_TRIPS";
export const ADDONS_DATA = "ADDONS_DATA";

const defaultState = {
    userInfo: {},
    pastTripData: [],
    upcomingTripData: [],
    inprogressTripData:[],
    addonsList: []
}

export function profileReducer(state: any = defaultState, action: { type: string, data?: any }) {
    switch (action.type) {
        case UPDATE_USER_INFO: {
            return {
                ...state,
                userInfo: action.data
            }
        }
        case UPDATE_PAST_TRIPS: {
            return {
                ...state,
                pastTripData: action.data
            }
        }
        case UPDATE_UPCOMING_TRIPS: {
            return {
                ...state,
                upcomingTripData: action.data
            }
        }
        case UPDATE_INPROGRESS_TRIPS: {
            return {
                ...state,
                inprogressTripData: action.data
            }
        }

        case ADDONS_DATA: {
            return {
                ...state,
                addonsList: action.data
            }
        }

        
        default:
            return state;
    }
}