import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import FormControl from 'react-bootstrap/esm/FormControl';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { getLuxyAddress, isValidLuxyAddress } from '../../services/AddressService';
import GooglePlacesComponent from '../google-places/google-places';
import * as UserService from '../../services/user';
import { toast } from 'react-toastify';

import Select from 'react-select';
import ConfirmPopup from '../modals/confirmation';
import { createEventGA4 } from '../../Utilities/helpers';



interface AddressBlockProps {
    userData: any;
    getUserInfo: (userId: any) => any;
}

interface AddressBlockState {
    activeSlideIndex: number;
    showEditModal: boolean;
    curEditItem: string;
    favAddressVal: string;
    favAddress: any;
    validAddress: boolean;
    showAddressError: boolean;
    airlinesData: any;
    airportId: number;
    airlineDefault: any;
    showConfirmPopup: boolean;
    curDelItem: string;
}

class AddressBlock extends React.Component<AddressBlockProps, AddressBlockState>{
    constructor(props: any) {
        super(props);
        this.state = {
            activeSlideIndex: 0,
            showEditModal: false,
            curEditItem: 'HOME',
            favAddressVal: '',
            favAddress: '',
            validAddress: false,
            showAddressError: false,
            airportId: 0,
            airlinesData: [],
            airlineDefault: {},
            showConfirmPopup: false,
            curDelItem: ''

        }
    }

    componentDidMount() {
        this.getAirlineData();
    }

    setActiveSlideIndex = (newActiveSlideIndex: number, e: any) => {
        this.setState({
            activeSlideIndex: newActiveSlideIndex,
        });
    };

    closeModal = () => {
        this.setState({ showEditModal: false, curEditItem: 'HOME', showAddressError: false });
    }

    showEditModal = async (addressType: string) => {
        let favAddressVal = '';
        let favAddress = '';
        let airportId = 0;
        let df = { value: 0, label: 'Select' };

        if (addressType == 'HOME') {
            if (this.props.userData.home_address && this.props.userData.home_address != '') {
                favAddressVal = this.props.userData.home_address.address.full_address;
                favAddress = this.props.userData.home_address;
            }

        }

        if (addressType == 'OFFICE') {
            if (this.props.userData.work_address && this.props.userData.work_address != '') {
                favAddressVal = this.props.userData.work_address.address.full_address;
                favAddress = this.props.userData.work_address;
            }

        }
        if (addressType == 'AIRPORT') {
            airportId = this.props.userData.airport_id;
            // let findIndex = this.state.airlinesData.findIndex((item: any) => item.id == this.props.userData.airport_id);
            // console.log(findIndex, this.props.userData.airport_id, 'findIndex');
            // if (findIndex != -1) {
            //     df.value = this.state.airlinesData[findIndex].id;
            //     df.label = `${this.state.airlinesData[findIndex].airport_code}-${this.state.airlinesData[findIndex].airport_name}`;
            // }
            if (this.props.userData.airport_id && this.props.userData.airport_id != 0) {
                df.value = this.props.userData.airport_id;
                df.label = `${this.props.userData.airport_name}`;
            }

        }

        this.setState({
            showEditModal: true,
            curEditItem: addressType,
            favAddressVal: favAddressVal,
            favAddress: favAddress,
            airportId: airportId,
            airlineDefault: df
        });

    }

    getAirlineData = async () => {
        let airlinesData = await UserService.UserService.getAirportsInfo();
        if (airlinesData && airlinesData.data) {
            let airLinesArr: any = [];
            let airLineObj: any = {};
            if (airlinesData.data && airlinesData.data.data && airlinesData.data.data.length) {
                airLinesArr.unshift({ value: 0, label: 'Select' });
                for (let i = 0; i < airlinesData.data.data.length; i++) {
                    airLineObj = {};
                    airLineObj.value = airlinesData.data.data[i].id;
                    airLineObj.label = `${airlinesData.data.data[i].airport_code}-${airlinesData.data.data[i].airport_name}`;
                    airLinesArr.push(airLineObj);
                }
                this.setState({ airlinesData: airLinesArr });
            } else {
                this.setState({ airlinesData: [] });
            }
        } else {
            this.setState({ airlinesData: [] });
        }
    }

    handleAddressSelect = (selection: any) => {
        const address = selection.original[0];
        let favAddressVal = address.name;
        Promise.resolve(getLuxyAddress(address)).then((fullAddress: any) => {
            isValidLuxyAddress(fullAddress);
            favAddressVal = fullAddress.address.full_address
            this.setState({ favAddressVal: favAddressVal, favAddress: fullAddress, validAddress: true, showAddressError: false }, () => {
            });
        }).catch((err) => {
            this.setState({ validAddress: false, favAddress: '', favAddressVal: '', showAddressError: true });
        });
    };

    handleAddressChange = (event: any) => {
        let value = event;
        if (value != '') {
            this.setState({ favAddressVal: value, showAddressError: false });
        } else {
            this.setState({ favAddressVal: '', favAddress: '', showAddressError: true });
        }
    }



    updateAddress = async () => {
        let userData = this.props.userData;
        if (this.state.showAddressError || this.state.favAddressVal == '' && this.state.curEditItem !== "AIRPORT") {
            return false;
        } else {

            let payload = {
                first_name: userData ? userData?.first_name : '',
                last_name: userData ? userData?.last_name : '',
                email_address: userData ? userData?.email_address : '',
                mobile_number: userData ? userData?.mobile_number : '',
                home_address: userData.home_address,
                work_address: userData.work_address,
                state_id: 0,
                zipcode_id: 0,
                city_id: 0,
                work_state_id: 0,
                work_city_id: 0,
                work_zipcode_id: 0,
                work_city_name: '',
                home_city_name: '',
                airport_id: userData.airport_id,
                is_active: true,
                is_email_notification_allowed: true,
                is_sms_notification_allowed: true,
            }

            if (this.state.curEditItem == "HOME") {

                payload.home_address = this.state.favAddress;
            }
            if (this.state.curEditItem == "OFFICE") {
                payload.work_address = this.state.favAddress;
            }
            if (this.state.curEditItem == "AIRPORT") {
                payload.airport_id = this.state.airportId;
            }

            const currentItem: string = this.state.curEditItem.toLocaleLowerCase();
            // console.log(payload, this.state.curEditItem, 'payloadddd')
            let updatedData = await UserService.UserService.updateAddressInfo(payload, userData.user_id);
            if (updatedData.data && updatedData.data.status == 200) {
                toast.success(`Address details updated successfully.`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({ showEditModal: false, curEditItem: 'HOME' }, () => {
                    this.props.getUserInfo(userData.user_id);
                });
                this.createAddressEvent(currentItem);
            } else {
                toast.error(`OOps something went wrong!.`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    createAddressEvent = (type: string) => {
        const obj: any = {
            'eventName': 'account_profile',
            'preference': `${type}_address_change`,
            'event': 'accountProfile'
        }
        createEventGA4(obj);
    }

    deleteFavAddress = async () => {
        let userData = this.props.userData;
        let payload = {
            first_name: userData ? userData?.first_name : '',
            last_name: userData ? userData?.last_name : '',
            email_address: userData ? userData?.email_address : '',
            mobile_number: userData ? userData?.mobile_number : '',
            home_address: userData.home_address,
            work_address: userData.work_address,
            state_id: 0,
            zipcode_id: 0,
            city_id: 0,
            work_state_id: 0,
            work_city_id: 0,
            work_zipcode_id: 0,
            work_city_name: '',
            home_city_name: '',
            airport_id: userData.airport_id,
            is_active: true,
            is_email_notification_allowed: true,
            is_sms_notification_allowed: true,
        }

        const type = this.state.curDelItem;

        if (type == "HOME") {
            payload.home_address = '';
        }

        if (type == "OFFICE") {
            payload.work_address = '';
        }

        if (type == "AIRPORT") {
            payload.airport_id = 0;
        }

        let updatedData = await UserService.UserService.updateAddressInfo(payload, userData.user_id);
        if (updatedData.data && updatedData.data.status == 200) {
            toast.success(`Address details deleted successfully.`, {
                position: toast.POSITION.TOP_RIGHT
            });
            this.setState({ showEditModal: false, curEditItem: 'HOME', curDelItem: '', showConfirmPopup: false }, () => {
                this.props.getUserInfo(userData.user_id);
            });
        } else {
            toast.error(`OOps something went wrong!.`, {
                position: toast.POSITION.TOP_RIGHT
            });
            this.setState({
                curDelItem: '', showConfirmPopup: false
            }, this.props.getUserInfo(userData.user_id));
        }
    }

    selectAirLine = (event: any) => {
        this.setState({
            airlineDefault: event,
            airportId: event.value
        })
    }

    // confirm related
    closeConfirmPopup = () => {
        this.setState({
            showConfirmPopup: false, curDelItem: ''
        })
    }

    showConfirmModal = (type: string) => {
        this.setState({
            showConfirmPopup: true, curDelItem: type
        })
    }
    renderEditModal = () => {
        const favAddress = this.state.favAddressVal;
        return <Modal show={this.state.showEditModal} style={{ top: '0px' }} scrollable={true} >
            <div className="float-right">
                <i className="fa fa-times float-right pointer"
                    onClick={() => this.closeModal()}
                    title="close"></i>
            </div>
            <Modal.Header>
                UPDATE {this.state.curEditItem} ADDRESS
            </Modal.Header>
            <Modal.Body style={{ overflowY: 'scroll' }}>
                {/* <GooglePlaces/> */}
                <div>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="address_type" className="float-left">Address Type</label>
                                <FormControl
                                    type="address_type"
                                    id="address_type"
                                    onChange={(event: any) => { }}
                                    value={this.state.curEditItem}
                                    readOnly={true}
                                />
                            </div>
                        </Col>
                    </Row>

                    {
                        (this.state.curEditItem !== 'AIRPORT') ? <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="fav_address" className="float-left">Location <span className="importantfields">*</span></label>
                                    <GooglePlacesComponent
                                        id='fav_address'
                                        onPlaceChanged={this.handleAddressSelect}
                                        value={favAddress}
                                        handleChange={(event: any) => this.handleAddressChange(event)}
                                        placeHolder={(this.state.favAddress) ? this.state.favAddress : 'Enter location'}
                                    />
                                    {(this.state.showAddressError) ?
                                        <div className="text-danger p-1 float-left font-12">
                                            Invalid Address Location.
                                        </div> : null
                                    }
                                </div>
                            </Col>
                        </Row> : <Row>
                            <Col>
                                <div className="form-group">
                                    <Select className="font-13 z-99"
                                        options={this.state.airlinesData}
                                        placeholder="Select Airline"
                                        onChange={(event: any) => this.selectAirLine(event)}
                                        defaultValue={this.state.airlineDefault}
                                    />
                                    {(this.state.showAddressError) ?
                                        <div className="text-danger p-1 float-left font-12">
                                            Invalid Address Location.
                                        </div> : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    }

                    <Row>
                        <Col>
                            <div className="row no-gutters">
                                <div className="col">
                                    <button
                                        disabled={(this.state.showAddressError || (this.state.curEditItem != 'AIRPORT' && this.state.favAddress == '')) || (this.state.curEditItem == 'AIRPORT' && !this.state.airportId)}
                                        onClick={() => this.updateAddress()} className="btn btn-block primary custom-button">UPDATE
                                        {/* { this.state.showAddressError + ' ' + this.state.favAddress + ' ' + this.state.curEditItem + ' ' + this.state.airportId } */}
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    }


    render() {
        // let userData = this.props.userData;
        let workAddress = this.props.userData.work_address;
        let homeAddress = this.props.userData.home_address;
        let airportAddress = this.props.userData.airport_address
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
                slidesToSlide: 3 // optional, default to 1.
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
                slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1 // optional, default to 1.
            }
        };

        return <div className="address-block-main">
            <span className="payment-span-text">
                <strong>FAVORITE LOCATIONS</strong></span>
            <div className="p-2">

                <Carousel
                    swipeable={false}
                    draggable={false}
                    //  showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    // infinite={true}
                    // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    // deviceType={this.props.deviceType}
                    // dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    <div className="card carousel-address-block">
                        <div className="card-body">
                            <Row>
                                <Col xs={1}><i className="fa fa-home mr-2 icon-yellow" aria-hidden="true"></i></Col>
                                <Col xs={6}>
                                    <div><strong>Home Address</strong></div>
                                    <div className="small-text">
                                        <strong> {(homeAddress && homeAddress.address ? homeAddress.address.full_address : "N/A")}</strong>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <div className="pull-right">
                                        <i className="fa fa-pencil-square-o pointer" onClick={() => { this.showEditModal('HOME') }} aria-hidden="true"></i>
                                        {(homeAddress && homeAddress.address && homeAddress.address.full_address) ? <>
                                            | <i title="Delete" className="fa fa-trash extrastopdelete pointer"
                                                onClick={() => { this.showConfirmModal('HOME') }}></i> </> : null}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="card carousel-address-block mr-4">
                        <div className="card-body">
                            <Row>
                                <Col xs={1}> <i className="fa fa-building icon-yellow" aria-hidden="true"></i></Col>
                                <Col xs={6}>
                                    <div><strong>Office</strong></div>
                                    <div className="small-text">
                                        <strong> {(workAddress && workAddress.address ? workAddress.address.full_address : "N/A")}</strong>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <div className="pull-right">
                                        <i className="fa fa-pencil-square-o pointer" onClick={() => { this.showEditModal('OFFICE') }} aria-hidden="true"></i>
                                        {(workAddress && workAddress.address && workAddress.address.full_address) ? <> | <i title="Delete" className="fa fa-trash extrastopdelete pointer"
                                            onClick={() => { this.showConfirmModal('OFFICE') }}></i> </> : null}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="card carousel-address-block mr-2">
                        <div className="card-body">

                            <Row>
                                <Col xs={1}>  <i className="fa fa-plane icon-yellow" aria-hidden="true"></i> </Col>
                                <Col xs={6}>
                                    <div className="">
                                        <div><strong>Airport</strong></div>
                                        <div className="small-text">
                                            <strong> {(airportAddress && airportAddress.address ? airportAddress.address.full_address : "N/A")}</strong>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <div className="pull-right">
                                        <i className="fa fa-pencil-square-o pointer" onClick={() => { this.showEditModal('AIRPORT') }} aria-hidden="true"></i>
                                        {(airportAddress && airportAddress.address && airportAddress.address.full_address) ? <>
                                            | <i title="Delete" className="fa fa-trash extrastopdelete pointer"
                                                // onClick={() => { this.deleteFavAddress('AIRPORT') }}
                                                onClick={() => { this.showConfirmModal('AIRPORT') }}
                                            ></i> </> : null}
                                    </div>
                                </Col>
                            </Row>



                        </div>
                    </div>
                </Carousel>
            </div>
            {this.renderEditModal()}
            {
                (this.state.showConfirmPopup)
                    ? <ConfirmPopup
                        show={this.state.showConfirmPopup}
                        closeConfirmPopup={this.closeConfirmPopup}
                        title={'Remove'}
                        bodyText={`Would you like to delete the ${this.state.curDelItem} address?`}
                        // deleteMethod={this.deleteSavedCard}
                        deleteMethod={() => { this.deleteFavAddress() }}
                    /> : null
            }
        </div>
    }
}

export default AddressBlock;