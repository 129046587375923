import { connect } from "react-redux";
import Invoice from "../components//ride-details/invoice";
import { SAVE_INVOICE_DATA } from "../reducer/payment-info";

type Dispatch = (action: any) => void;

const updateInvoiceAmount = (data: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: SAVE_INVOICE_DATA, data: data
        });
    }
}

const mapStateToProps = (state: any) => {
    return {
        promoCodeDetails: state.details.promoCodeDetails
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateInvoiceAmount: (data: any) => dispatch(updateInvoiceAmount(data))
    };
};

const InvoiceContainer = connect(mapStateToProps, mapDispatchToProps)(Invoice);

export default InvoiceContainer;
