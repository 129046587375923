import React, { Component } from 'react'
import { parseGooglePlace } from './utils'
import { FormControl} from 'react-bootstrap';
import CommonService from '../../services/CommonService';
import { UserService } from '../../services/user';
import { connect } from 'react-redux';
import { FAV_ADD, FAV_ADD_TYPE } from '../../Consts';
import Child from '../../components/common/favAddress';


class GooglePlaces extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filteredSuggestions: [],
            showSuggestions: false,
            activeSuggestion: -1,
            showFavAddresses: false,
            favoriteAddresses: [],
            supportedCountries:'',
            isGroundOnly:false,
            showInfo:false,
            selectedValue:this.props.value

        }
    }

    componentDidMount = async () => {
        await this.getUserFavoriteAddresses();
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps !== this.props) {

            if (this.props.isUserLogin !== prevProps.isUserLogin) {
                if (this.props.isUserLogin) {
                    await this.getUserFavoriteAddresses();
                    let predictions = [...this.state.filteredSuggestions];

                    predictions = predictions.concat(this.state.favoriteAddresses);

                    this.setState({ filteredSuggestions: predictions });
                    this.suggestionsListComponent();

                }

                if (!this.props.isUserLogin || this.props.isUserLogin === undefined || this.props.isUserLogin === null) {
                    let predictions = [...this.state.filteredSuggestions];

                    predictions = predictions.filter((address) => {
                        return address.type !== FAV_ADD
                    });

                    this.setState({ filteredSuggestions: predictions });
                    this.suggestionsListComponent();
                }
            }
        }

    }

    // 
    getUserFavoriteAddresses = async () => {
        let isLoggedIn = sessionStorage.getItem('isLoggedIn');
        let userData = {};
        let customerData = {};

        if (isLoggedIn === 'true') {
            this.setState({ showFavAddresses: true });
            userData = JSON.parse(sessionStorage.getItem('user'));
            customerData = await UserService.getUserById(userData.id);
            // console.log('customer data', customerData);

            if (customerData && customerData.data)
                await this.formatFavoriteAddresses(customerData.data.data[0]);

        } else {
            this.setState({ showFavAddresses: false });
        }
    }

    formatFavoriteAddresses = (userData) => {

        let favAddresses = [];
        if (userData.airport_address) {
            // console.log('userData', userData.airport_address)
            userData.airport_address['description'] = 'Airport Address';
            userData.airport_address['place_id'] = userData.airport_address.place.place_id;
            userData.airport_address['type'] = FAV_ADD;
            userData.airport_address['type_desc'] = 'airport';
            userData.airport_address['full_address'] = userData.airport_address.address.full_address;
            favAddresses.push(userData.airport_address);
        }

        if (userData.home_address) {
            userData.home_address['description'] = 'Home Address';
            userData.home_address['place_id'] = userData.home_address.place.place_id;
            userData.home_address['type'] = FAV_ADD;
            userData.home_address['type_desc'] = 'home';
            userData.home_address['full_address'] = userData.home_address.address.full_address;
            favAddresses.push(userData.home_address);
        }

        if (userData.work_address) {
            userData.work_address['description'] = 'Work Address';
            userData.work_address['place_id'] = userData.work_address.place.place_id;
            userData.work_address['type'] = FAV_ADD;
            userData.work_address['type_desc'] = 'work';
            userData.work_address['full_address'] = userData.work_address.address.full_address;
            favAddresses.push(userData.work_address);
        }
        this.setState({ favoriteAddresses: favAddresses });
    }

    onKeyDown = (e) => {
        const { activeSuggestion, filteredSuggestions } = this.state;
       
       
        if ((e.keyCode === 13 || e.keyCode === 9 )&& filteredSuggestions[activeSuggestion] != '' && filteredSuggestions[activeSuggestion] != undefined) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: true,
                showInfo:false,
            }, async () => {
                await this.props.onChange(filteredSuggestions[activeSuggestion].description);
                await this.onSelection(filteredSuggestions[activeSuggestion]);
            });
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return false;
            }
            const actSugUpdated = activeSuggestion - 1;
            this.setState({ activeSuggestion: actSugUpdated }, async () => {
                await this.props.onChange(filteredSuggestions[actSugUpdated].description);
            });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {

            if (activeSuggestion + 1 == filteredSuggestions.length) {
                const actSugUpdated = 0;
                this.setState({ activeSuggestion: actSugUpdated }, async () => {
                    if (filteredSuggestions[actSugUpdated]) {
                    await this.props.onChange(filteredSuggestions[actSugUpdated].description);
                    }
                });
                return;
            }
            const actSugUpdated = activeSuggestion + 1;
            this.setState({ activeSuggestion: actSugUpdated }, async () => {
                await this.props.onChange(filteredSuggestions[actSugUpdated].description);
            });
        }
    }

    debounce = (func, delay) => {
        let inDebounce;

        return function () {
            this.props.onChange(arguments[0]);
            const context = this;
            const args = arguments;
            clearTimeout(inDebounce)
            inDebounce = setTimeout(() => func.apply(context, args), delay)
        }
    }

    searchPlaces = this.debounce(async (text, supportedCountries, isGroundOnly) => {
        if (text != '') {
            this.state.selectedValue="";
            let response = await CommonService.searchPlaces(text,supportedCountries,isGroundOnly);
            // customerData = await this.getUserFavoriteAddresses();
            let predictions = response.data.predictions;
            // console.log('favAddresses', this.state.favoriteAddresses)
            // predictions = this.state.favoriteAddresses.concat(predictions);
            if (this.props.isUserLogin && this.props.showFavorites) {
                predictions = predictions.concat(this.state.favoriteAddresses);
            }

            // console.log('12321', predictions)
            // [predictions, ...this.state.favoriteAddresses];

            this.setState({ filteredSuggestions: predictions, showSuggestions: true, showInfo:false });
            // if (customerData.data.length) {
            //     await this.formatFavoriteAddresses(customerData.data[0]);
            // }
        } else {
            this.setState({ filteredSuggestions: [], showSuggestions: true , showInfo:false});
        }
    }, 500);

    onSelection = async (selection) => {

        if (selection != '') {
            let response = await CommonService.getSelectedAddress(selection.place_id);
            if (response.data.result) {
                response.data.result.place_id = selection.place_id;
                let selectObj = response.data.result;
                const places = [];
                places.push(selectObj);
                this.props.onPlaceChanged({ original: places, parsed: places.map(parseGooglePlace) })
                this.setState({
                    showSuggestions: false, activeSuggestion: 0, showInfo:false , selectedValue:selection
                });
            }
        }
    }

    poweredByGoogle = () => {
        return (
            <span className='float-right'>powered by&nbsp;
                <img alt="Google"
                    height="25"
                    width="auto"
                    src="https://maps.gstatic.com/mapfiles/api-3/images/google4_hdpi.png" />
            </span>
        )
    }

    suggestionsListComponent = () => {
        // console.log("suggestions", this.state.filteredSuggestions)
        return (this.state.filteredSuggestions).length ? <ul className="suggestions">
            {this.state.filteredSuggestions.map((suggestion, index) => {
                let className = '';
                if (index === this.state.activeSuggestion) {
                    className = "suggestion-active";
                }
                // console.log('suggestion', suggestion);
                if ((suggestion.type === FAV_ADD) &&
                    (suggestion.type_desc === FAV_ADD_TYPE.AIRPORT
                        || suggestion.type_desc === FAV_ADD_TYPE.HOME
                        || suggestion.type_desc === FAV_ADD_TYPE.WORK)
                ) {
                    return (
                        <Child
                            key={suggestion + index}
                            onSelection={() => this.onSelection(suggestion)}
                            suggestion={suggestion}
                        />
                    );
                }
                return (
                    <li className={className} key={suggestion + index}
                        onClick={() => this.onSelection(suggestion)}
                    >
                        <i className="fa fa-map-marker" aria-hidden="true"></i> {suggestion.description}
                    </li>
                );
            })}
            {this.poweredByGoogle()}
        </ul>
            : (this.props.showFavorites && this.props.isUserLogin && this.state.favoriteAddresses.length) ? <ul className="suggestions">
                {this.state.favoriteAddresses.map((suggestion, index) => {
                    // let className = '';
                    // if (index === this.state.activeSuggestion) {
                    //     className = "suggestion-active";
                    // }
                    if ((suggestion.type === FAV_ADD) &&
                        (suggestion.type_desc === FAV_ADD_TYPE.AIRPORT
                            || suggestion.type_desc === FAV_ADD_TYPE.HOME
                            || suggestion.type_desc === FAV_ADD_TYPE.WORK)
                    ) {
                        return (
                            <Child
                                key={suggestion + index}
                                onSelection={() => this.onSelection(suggestion)}
                                suggestion={suggestion}
                            />
                        );
                    }

                })}
                {this.poweredByGoogle()}
            </ul>
                : <ul></ul>

    }

    onBlur = (value) => {
        setTimeout(() => { 
            let showInfo = (typeof this.state.selectedValue !== 'object' && this.state.selectedValue !== value)?true:false;
            this.setState({ showSuggestions: false, showInfo });
         }, 300)
    }



    render() {
        const { fields, onPlaceChanged, reference, isUserLogin, dispatch, ...rest } = this.props;
        return <>
            <FormControl
                ref={reference}
                {...rest}
                className={this.props.className}
                onChange={(event) => this.searchPlaces(event.target.value, this.props.supportedCountries, this.props.isGroundOnly)}
                onFocus={() => { this.setState({ showSuggestions: true, showInfo: false }) }}
                onBlur={(event) => { this.onBlur(event.target.value) }}
                onKeyDown={(event) => { this.onKeyDown(event) }}
                autocomplete='off'
            />
            { (this.state.showInfo) ? <div className="text-danger font-12 p-1">
                                     Please select address from dropdown.
                            </div> : null}
            { (this.state.showSuggestions) ? this.suggestionsListComponent() : null}
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        isUserLogin: state.user.isUserLogin
    };
};
export default connect(mapStateToProps)(GooglePlaces);