import React from "react";
import Period from '../../assets/images/period.svg';
import Plus from '../../assets/images/plus.svg';

import { timeFormatter, pickUpDateFormatter, getTimezoneDatetime, pickUpDateInputFormatter } from '../../Utilities/DateUtilities';
import moment from 'moment';
import { formatDistance, formatDuration, getLuxyAddress, isValidLuxyAddress } from "../../services/AddressService";
import { isAirPortTrip } from "../../Utilities/LocationUtilities";
import { ROUTE_DETAILS_SERVICE_TYPE,ROUTE_DETAILS_SERVICE_ID,SAME_LOCATION_ERR, USER_TYPE } from "../../Consts";
import DateComponent from "../date-component/date";
import RideService from "../../services/RideService";
import { toast, ToastContainer } from 'react-toastify';
import Exchange from '../../assets/images/exchange.png';
import GooglePlacesComponent from "../google-places/google-places";
import { HoursInput, MinInput } from "../date-component/timePicker";
import clock from '../../assets/images/clock.svg';
import { userFromLocalStorage } from "../../Utilities/helpers";

interface RideDetailsProps {
    bookingDetails: any;
    bookingDetailsReducer?: any;
    vehicleDetailsList?: any;
    vehicleListError?: any;
    getVehiclesList: (payload: any) => any;
    updateBookingState: (data: any) => any;
    getReservationCharges: () => any;
    supportedCountries?: any;
      //region buffer code begins
    vehicleErrorMessage?:any;
    isUserLogin: boolean;
    user: any;
      //region buffer code ends
}

class RideDetails extends React.Component<RideDetailsProps, any> {
    pick_time_ptop: any;
    pick_date_ptop: any;

    //added for pickaddress focus
    pick_address_ref: any;
    drop_address_ref: any;
    constructor(props: RideDetailsProps) {
        super(props);
        this.pick_date_ptop = React.createRef();
        this.pick_address_ref = React.createRef();
        this.drop_address_ref = React.createRef();
        this.setRef = this.setRef.bind(this);
        this.setRefPickAddress = this.setRefPickAddress.bind(this);
        this.setRefDropAddress = this.setRefDropAddress.bind(this);
        this.state = {
            bookingDetails: {},
            isPickupEditable: false,
            isDropoffEditable: false,
            isDateTimeEditable: false,
            extra_stop_count: 1,
            extra_stop_controls: [],
            extra_stops: [],
            extra_stop_error: {},
            extra_stop_values: {},
            extra_buffer_time: 15
        };
    }

    componentDidMount() {
        // let bookingDetailsLocalRaw = localStorage.getItem('bookingDetails');
        let bookingDetailsLocalRaw =sessionStorage.getItem('bookingDetails');

        if (bookingDetailsLocalRaw != '' && bookingDetailsLocalRaw != undefined && bookingDetailsLocalRaw != null) {

            const bookingDetailsLocalParsed: any = JSON.parse(bookingDetailsLocalRaw);
            // console.log('entered', bookingDetailsLocalParsed);
            this.setState({
                bookingDetails: bookingDetailsLocalParsed,
                extra_stop_count: (bookingDetailsLocalParsed.extra_stop_count) ? bookingDetailsLocalParsed.extra_stop_count : 1,
                extra_stop_controls: (bookingDetailsLocalParsed.extra_stop_controls) ? bookingDetailsLocalParsed.extra_stop_controls : [],
                extra_stops: (bookingDetailsLocalParsed.extra_stops) ? bookingDetailsLocalParsed.extra_stops : [],
                extra_stop_error: (bookingDetailsLocalParsed.extra_stop_error) ? bookingDetailsLocalParsed.extra_stop_error : {},
                extra_stop_values: (bookingDetailsLocalParsed.extra_stop_values) ? bookingDetailsLocalParsed.extra_stop_values : {}
            }, () => {
                this.getQuote();
            });
        } else {

            this.setState({ bookingDetails: this.props.bookingDetails });
        }
        window.onbeforeunload = () => {
            sessionStorage.removeItem('promoCodeData');
          };
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.bookingDetails !== this.props.bookingDetails) {

            this.setState({ bookingDetails: this.props.bookingDetails });
        }
        if (this.state.isDateTimeEditable) {
            // this.pick_time_ptop.val(this.state.bookingDetails.time);
        }
        if(this.props.isUserLogin !== prevProps.isUserLogin) {
            this.getQuote()
        }
    }

    setRef(input: any) {
        this.pick_time_ptop = input;
    }

    setRefPickAddress(input: any) {
        this.pick_address_ref = input;
        if (this.pick_address_ref != null && this.pick_address_ref != undefined) {
            this.pick_address_ref.focus();
        }

    }

  

    setRefDropAddress(input: any) {
        this.drop_address_ref = input;
        if (this.drop_address_ref != null && this.drop_address_ref != undefined) {
            this.drop_address_ref.focus();
        }

    }

    toggleEditMode = (type: string) => {
        switch (type) {
            case 'pickup':

                // this.pick_address_ref.focus(); 
                this.setState({ isPickupEditable: true, isDropoffEditable: false, isDateTimeEditable: false });
                break;
            case 'dropoff':
                this.setState({ isDropoffEditable: true, isPickupEditable: false, isDateTimeEditable: false });
                break;
            case 'datetime':
                this.setState({ isDateTimeEditable: true, isPickupEditable: false, isDropoffEditable: false }, () => {
                    // this.pick_date_ptop.current.input.focus();
                    // console.log(this.pick_date_ptop,'blur this.pick_date_ptop');
                });
                break;
            default:
                this.setState({ isDateTimeEditable: false, isPickupEditable: false, isDropoffEditable: false });
                return false;
        }
    }

    formatDate = (date: any, islocal = true) => {
        const d = new Date(date);
        if (d.toString() === 'Invalid Date') {
            return date;
        }
        let month = `${d.getMonth() + 1}`;
        let day = `${d.getDate()}`;
        const year = `${d.getFullYear()}`;
        if (month.length < 2) {
            month = `0${month}`;
        }
        if (day.length < 2) {
            day = `0${day}`;
        }
        if (islocal) {
            return [month, day, year].join('/');
        }
        return [year, month, day].join('-');
    };

    dateTimeFormat = (date: Date | string) => {
        const d = new Date(date);
        const mins =
            d.getMinutes() < 10
                ? '0'.concat(d.getMinutes().toString())
                : d.getMinutes();
        const hour =
            d.getHours() === 0
                ? '12'
                : d.getHours() > 12
                    ? d.getHours() - 12
                    : d.getHours();
        const amOrPm = d.getHours() < 12 ? 'AM' : 'PM';
        return {
            date: this.formatDate(d),
            time: hour + ':' + mins + ' ' + amOrPm,
        };
    };
    setBufferExpirationTime(bufferTime: any = 24) {
        let bufferDate: any = moment().add(bufferTime, 'hours');
        bufferDate = moment(bufferDate).add(this.state.extra_buffer_time, 'minutes');
        let dateObject: any = this.dateTimeFormat(bufferDate);

        this.setState({
            time: dateObject.time
        }, async () => {
            await this.handleDateChangePtoP('pick_date_ptop', new Date(dateObject.date));
            if (this.state.isDateTimeEditable) {
                // this.pick_time_ptop.val(dateObject.time);
            }
        });

    }

    getBufferExpirationTime = async () => {
        let bufferValue = this.state.bookingDetails.buffer_expiration_time_widget;

        if (bufferValue) {
            this.setState({
                buffer_expiration_time_widget: Number(bufferValue),
            }, () => {
                // this.setBufferExpirationTime(bufferValue);
            });
        }
    }

    updateDateAndtime = async () => {
        let bufferValue = this.state.bookingDetails.buffer_expiration_time_widget;
        let bufferDate: any = moment().add(bufferValue, 'hours');
        bufferDate = moment(bufferDate).add(this.state.extra_buffer_time, 'minutes');
        let dateObject: any = this.dateTimeFormat(bufferDate);

        return dateObject;
    }


    isInvalidPickupTime = async (selDate: any, selTime: any) => {
        // const currentTime = new Date().toLocaleString("en-US", { timeZone: this.state.bookingDetails.pickup.timezone });
        const validTime = this.state.bookingDetails.buffer_expiration_time_widget;
        // console.log('selectedDat111e', selDate, selTime);
        const currentTimeZoneDate = new Date().toLocaleString("en-US", { timeZone: this.state.bookingDetails.pickup.timezone });
        const aDate = new Date(selDate.split('-').join('/')).getTime();
        const bDate = new Date(currentTimeZoneDate.split('-').join('/')).getTime();
        const dateDiff = (aDate - bDate) / 1000;
        const hours = dateDiff / 60 / 60;
        // console.log('selectedDate', currentTimeZoneDate, selDate, hours);
        if ((Number(hours) * 60) < (Number(validTime) * 60)) {
            return false;
        }

        return true;
    }

    formatAddress = (address: any) => {
        return {
            placeName: address.address.full_address,
            city: address.address.city,
            state: address.address.state,
            timezone: address.timezone,
            airportCode: address.place.airport_code,
            zipCode: address.address.postal_code
        }
    }


    async getQuote() {
        const bookingDetails: any = this.state.bookingDetails;
        if (bookingDetails.pickup != "" && bookingDetails.dropoff != "") {

            // let isValid = await this.isInvalidPickupTime(this.state.bookingDetails.pick_date_ptop, this.state.bookingDetails.time);
            // if (!isValid) {
            //     toast.error(`quote Please book a ride at least ${Number(this.state.bookingDetails.buffer_expiration_time_widget)} hours from now or call 833-GET.LUXY for immediate assistance.`, {
            //         position: toast.POSITION.TOP_RIGHT
            //     });
            //     return false;
            // }
            const route = {
                total_miles: (bookingDetails.distance_in_miles) ? Number(bookingDetails.distance_in_miles) : Number(sessionStorage.getItem('distance_in_miles')),
                total_hours: (bookingDetails.total_hours) ? Number(bookingDetails.total_hours) : Number(sessionStorage.getItem('total_hours')),
            };

            // added pick up date fix
            let { time } = this.state.bookingDetails;
            const pickDate = pickUpDateFormatter(new Date(this.state.bookingDetails.pick_date_ptop));
            const picktime = timeFormatter(time);
            const dateString = `${pickDate} ${picktime}`;
            const timezoneDatetime = getTimezoneDatetime(dateString, this.state.bookingDetails.pickup.timezone);
            // console.log(this.state.bookingDetails.pick_date_ptop, picktime, timezoneDatetime, 'datestring');
            // ends here
            const userData: any = userFromLocalStorage();
            let userID = 0;
            if (userData && userData.id) {
                userID = userData.id;
            }
            // let data = {
            //     service_type: bookingDetails.trip_type,
            //     pickup_hour: bookingDetails.time,
            //     pickup_date: timezoneDatetime,
            //     // pickup_date: bookingDetails.timezoneDatetime,
            //     pickup: bookingDetails.pickup,
            //     dropoff: bookingDetails.dropoff,
            //     extra_stops: this.state.extra_stops,
            //     route,
            //     is_airport_arrival: localStorage.ispickairportfield,
            //     is_airport_departure: localStorage.isdropairportfield,
            //     is_from_backend: false,
            //     application: 'web',
            //     userCorporateId: userID,
            //     userTypeId: USER_TYPE.CUSTOMER
            // };
            // const payload = {
            //     trips: [{
            //         serviceRateId:0,
            //         serviceType : bookingDetails.trip_type,
            //         pickup: this.formatAddress(bookingDetails.pickup),
            //         dropoff: this.formatAddress(bookingDetails.dropoff),
            //         extraStops: this.state.extra_stops,
            //         route: {
            //             totalMiles: (bookingDetails.distance_in_miles) ? Number(bookingDetails.distance_in_miles) : Number(sessionStorage.getItem('distance_in_miles')),
            //             totalHours: (bookingDetails.total_hours) ? Number(bookingDetails.total_hours) : Number(sessionStorage.getItem('total_hours')),
            //         },
            //         pickupTime:  bookingDetails.time,
            //         pickupDate: timezoneDatetime,
            //         addons: {
            //             "tripProtection": false,
            //             "arrivalMeetAndGreet": {
            //                 "id": 0
            //             },
            //             "travelingWithPet": "",
            //             "childSeat": ""
            //         },
            //         promoCodeId : 0,
            //         application:'web',
            //         rewardPoints:0,
            //         bookingUserId: userID,
            //         userCorporateId: userID,
            //         userTypeId: USER_TYPE.CUSTOMER,
            //         is_from_backend: false,
            //         isCustomPrice: false,
            //         customPrice: 0,
            //         isAffiliateCustomPrice: false,
            //         affiliateCustomPrice: 0,
            //         isEditMode: false,
            //     }]
            // }

            // await this.props.getVehiclesList(payload);

               await this.props.getReservationCharges();

          
            if (this.props.vehicleDetailsList && this.props.vehicleDetailsList.length) {
                const selectedVehicle = this.props.vehicleDetailsList.filter((vehicle: any) => vehicle.vehicle_type_id == this.state.bookingDetails.selectedVehicleId);

                let updatedBookingDetails = this.state.bookingDetails;
                updatedBookingDetails.selectedVehicle = selectedVehicle[0];
                updatedBookingDetails.selectedVehicleId = selectedVehicle[0].vehicle_type_id;

                this.setState({ bookingDetails: updatedBookingDetails, }, async () => {
                    // console.log('bookingDetails',bookingDetails)
                    this.props.updateBookingState({
                        ...this.state.bookingDetails,
                        extra_stops: this.state.extra_stops,
                        extra_stop_count: this.state.extra_stop_count,
                        extra_stop_controls: this.state.extra_stop_controls,
                        extra_stop_error: this.state.extra_stop_error,
                        extra_stop_values: this.state.extra_stop_values
                    });
                  
                });

            } else {
                  //region buffer code begins
                if(this.props.vehicleListError) {
                    toast.error(this.props.vehicleErrorMessage, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                }
                  //region buffer code begins
                let updatedBookingDetails = this.state.bookingDetails;
                updatedBookingDetails.selectedVehicle = null;
                // updatedBookingDetails.selectedVehicleId = 0;
                // updatedBookingDetails.selectedVehicleIndex = -1;
                this.setState({ bookingDetails: updatedBookingDetails, }, async () => {
                    this.props.updateBookingState({
                        ...this.state.bookingDetails,
                        extra_stops: this.state.extra_stops,
                        extra_stop_count: this.state.extra_stop_count,
                        extra_stop_controls: this.state.extra_stop_controls,
                        extra_stop_error: this.state.extra_stop_error,
                        extra_stop_values: this.state.extra_stop_values
                    });
                });
            }
        }
    }


    emptyVehicleDetails = async () => {
        toast.error(SAME_LOCATION_ERR, {
            position: toast.POSITION.TOP_RIGHT
        });
        await this.props.getVehiclesList('');
        let updatedBookingDetails = this.state.bookingDetails;
        updatedBookingDetails.selectedVehicle = null;
        // updatedBookingDetails.selectedVehicleId = 0;
        // updatedBookingDetails.selectedVehicleIndex = -1;
        this.setState({ bookingDetails: updatedBookingDetails, }, async () => {
            this.props.updateBookingState({
                ...this.state.bookingDetails,
                selectedVehicle: null,
                extra_stops: this.state.extra_stops,
                extra_stop_count: this.state.extra_stop_count,
                extra_stop_controls: this.state.extra_stop_controls,
                extra_stop_error: this.state.extra_stop_error,
                extra_stop_values: this.state.extra_stop_values
            });
        });
    }

    //#region pick address
    handlePickAddressSelect = async (selection: any) => {
        const address = selection.original[0];
        let validate = this.validateAddresses(address, 'pickup');

        if (validate) {
            await this.emptyVehicleDetails();
            return false;
        }


        let pick_address = address.name;
        // console.log(selection);
        Promise.resolve(getLuxyAddress(address)).then((pickup: any) => {
            isValidLuxyAddress(pickup);
            pick_address = pickup.address.full_address;
            let bookingDetails = this.state.bookingDetails;
            bookingDetails.pickup = pickup;
            bookingDetails.isMapView = false;
            this.setState({ bookingDetails: bookingDetails }, () => {
                this.setTravelType();
                // this.props.onChange("full_pickup_address", this.state.pickup);
            });
            // console.log(this.state.bookingDetails.pickup, 'pk');
            // localStorage.setItem("valid_pickup", 'true');
            sessionStorage.setItem("valid_pickup", 'true');
            // localStorage.setItem('ispickairportfield', (pickup.place.airport_code) ? 'true' : 'false');
            sessionStorage.setItem('ispickairportfield', (pickup.place.airport_code) ? 'true' : 'false');
            //this.props.onChange(ROUTE_DETAILS_FIELDS.PICK_UP_ZONE, pickup.timezone);
            const places = this.state.bookingDetails.places;
            places[0] = { latitude: pickup.coordinates.latitude, longitude: pickup.coordinates.longitude };
            const isValidLatLong = this.validateSourceAndDestination();
            let updatedBookingDetails = this.state.bookingDetails;
            updatedBookingDetails.pick_address = pick_address;
            updatedBookingDetails.pick_address_lat = pickup.coordinates.latitude;
            updatedBookingDetails.pick_address_lat = pickup.coordinates.longitude;
            updatedBookingDetails.isMapView = true;
            updatedBookingDetails.places = places;
            updatedBookingDetails.isValidLatLong = isValidLatLong;
            updatedBookingDetails.valid_pickup = true;
            updatedBookingDetails.pick_up_zone = pickup.timezone;
            this.setState({
                bookingDetails: updatedBookingDetails,
                isPickupEditable: false
            }, () => {
                this.updateDistanceInfo();
            });
            // console.log(this.state);
            // this.setState({ isPickupEditable: true });
            // CUSTOM SETTINGS
            // this.setTimeByPickupZone();
            // this.updateDistanceInfo();
            // this.handleDateChangePtoP('pick_date_ptop', pickup_date_time_format(moment(new Date()).add(Number(this.state.bookingDetails.buffer_expiration_time_widget), 'hours'), this.state.bookingDetails.pickup.timezone))

        }).catch((err) => {
            // console.error(`failed for ${pick_address}: ${err}`);
            // localStorage.setItem("valid_pickup", 'false');
            let updatedBookingDetails = this.state.bookingDetails;
            updatedBookingDetails.valid_pickup = false;
            updatedBookingDetails.pick_address = address.formatted_address;
            this.setState({ bookingDetails: updatedBookingDetails });
            // this.props.onChange("valid_pickup", localStorage.valid_pickup);
        });
    };

    validateSourceAndDestination() {
        return (this.state.bookingDetails.places[0].latitude != 0 && this.state.bookingDetails.places[0].longitude != 0 && this.state.bookingDetails.places[1].latitude != 0 && this.state.bookingDetails.places[1].longitude != 0)
    }

    setTravelType(pickup = this.state.bookingDetails.pickup, dropoff = this.state.bookingDetails.dropoff) {
        let { trip_type_with_id } = this.state.bookingDetails;
        // if (trip_type_with_id.type !== ROUTE_DETAILS_SERVICE_TYPE.HOURLY) {
        const isAirPort = isAirPortTrip(pickup, dropoff);
        trip_type_with_id.type = isAirPort ? ROUTE_DETAILS_SERVICE_TYPE.AIRPORT : ROUTE_DETAILS_SERVICE_TYPE.GROUND;
        trip_type_with_id.id = isAirPort ? ROUTE_DETAILS_SERVICE_ID.AIRPORT : ROUTE_DETAILS_SERVICE_ID.GROUND;
        const trip_type = isAirPort ? ROUTE_DETAILS_SERVICE_TYPE.AIRPORT : ROUTE_DETAILS_SERVICE_TYPE.GROUND;
        // this.props.onChange(ROUTE_DETAILS_FIELDS.TRIP_TYPE, trip_type_with_id);
        let updatedBookingDetails = this.state.bookingDetails;
        updatedBookingDetails.trip_type_with_id = trip_type_with_id;
        updatedBookingDetails.trip_type = trip_type;
        this.setState({ bookingDetails: updatedBookingDetails });
        // this.setState({ trip_type_with_id: trip_type_with_id, trip_type: trip_type });
        // }
    }

    handleDateChangeRawPtoP = (e: any) => {
        if (e != undefined) {
            e.preventDefault();
        }
    }

    async handleDateChangePtoP(field: any, date: any) {
        let time = this.formatHrsAndMin(this.state.bookingDetails.selHrs, this.state.bookingDetails.selMin);;
        // console.log(field, date);
        const currentDate = pickUpDateInputFormatter(date);
        time = !time ? this.getCurrentTime() : time;
        const picktime = timeFormatter(time);
        const currentDateString = pickUpDateFormatter(currentDate);
        const dateString = `${currentDateString} ${picktime}`;
        // console.log(dateString, 'dt1');
        const timezoneDatetime = getTimezoneDatetime(dateString, this.state.bookingDetails.pickup.timezone);
        // console.log(timezoneDatetime, 'timezoneDatetime');
        // added logic to restrict user to select date before buffer time.
        if (time != null) {
              //comment for region buffer code begins
            // let isValid = await this.isInvalidPickupTime(dateString, time);
            // if (!isValid) {
            //     toast.error(`Please book a ride at least ${Number(this.state.bookingDetails.buffer_expiration_time_widget)} hours from now or call 833-GET.LUXY for immediate assistance.`, {
            //         position: toast.POSITION.TOP_RIGHT
            //     });
            //     // let updatedDateAndTime: any = await this.updateDateAndtime();
            //     let updatedBookingDetails = this.state.bookingDetails;
            //     updatedBookingDetails.pickup_timezone_date = timezoneDatetime;
            //     // updatedBookingDetails[field] = new Date(updatedDateAndTime.date);
            //     updatedBookingDetails[field] = new Date(date)
            //     updatedBookingDetails['time'] = time;

            //     this.setState({
            //         bookingDetails: updatedBookingDetails,
            //         isDateTimeEditable: false
            //     }, async () => {
            //         await this.props.updateBookingState(updatedBookingDetails);
            //         // this.updateDistanceInfo();
            //         this.getQuote();
            //         // this.props.onChange(ROUTE_DETAILS_FIELDS.DATE, dateAndTime);
            //         // this.pick_time_ptop.val(updatedDateAndTime.time);
            //     });
            //     //comment for region begins
            //     // this.getBufferExpirationTime();
            //     //comment for region ends
            //     return false;
            // }
              //comment for  region buffer code ends
        }
        //validation ends here for date.

        let updatedBookingDetails = this.state.bookingDetails;
        updatedBookingDetails.pickup_timezone_date = timezoneDatetime;
        updatedBookingDetails[field] = new Date(date);
        updatedBookingDetails['time'] = time;

        this.setState({
            bookingDetails: updatedBookingDetails,
            isDateTimeEditable: false
        }, async () => {
            await this.props.updateBookingState(updatedBookingDetails);
            this.updateDistanceInfo();
            this.getQuote();
            
            // this.props.onChange(ROUTE_DETAILS_FIELDS.DATE, dateAndTime);
        });
    }

    getCurrentTime() {
        var date = new Date();
        var hours = date.getHours();
        var minutes: any = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var currtime = hours + ':' + minutes + ' ' + ampm;
        return currtime;
    }

    handlePickChange = (event: any) => {
        // console.log(event.target.value, 'event');
        const value = event;
        let updatedBookingDetails = this.state.bookingDetails;
        updatedBookingDetails.pick_address = value;
        this.setState({ bookingDetails: updatedBookingDetails });
    }

    handleExtraStopChange = async (event: any, count: any) => {
        // console.log(event.target.value, 'event');
        const value = event;
        let extra_stop_values = this.state.extra_stop_values;
        extra_stop_values[`extra_stop_${count}`] = value;
        this.setState({ [`extra_stop_${count}`]: value, extra_stop_values: extra_stop_values });
        if (value == '') {
            let extraStops = JSON.parse(JSON.stringify(this.state.extra_stops));
            let extraStopIndex = extraStops.findIndex((item: any) => item.stop_number === count);
            // console.log(extraStops, 'extraStops before')
            if (extraStopIndex != -1) {
                extraStops.splice(extraStopIndex, 1);
                // extraStops.push(removedItem);
            }

            this.setState({ extra_stop_values: extra_stop_values, extra_stops: extraStops }, async () => {
                let updatedBookingDetails = {
                    ...this.state.bookingDetails,
                    extra_stops: extraStops,
                    extra_stop_count: this.state.extra_stop_count,
                }
                await this.props.updateBookingState(updatedBookingDetails);
                await this.updateDistanceInfo();
            })


        }
    }

    handleDropChange = (event: any) => {
        // console.log(event.target.value, 'event');
        const value = event;
        let updatedBookingDetails = this.state.bookingDetails;
        updatedBookingDetails.drop_address = value;
        this.setState({ bookingDetails: updatedBookingDetails });
    }

    // validate same addresses.
    validateAddresses = (address: any, type: string) => {

        let extraStops = [...this.state.extra_stops];

        extraStops = extraStops.map((stop: any) => {
            return stop.place.place_id;
        });

        // console.log('extra_places_ids', extraStops);
        let pickup = this.state.bookingDetails.pickup;
        let dropoff = this.state.bookingDetails.dropoff;

        if (type === 'pickup' && dropoff
            && dropoff.place
            && dropoff.place.place_id) {
            if (address.place_id === dropoff.place.place_id
                || extraStops.indexOf(address.place_id) > -1
            ) {

                return true;
            }
        }

        if (type === 'dropoff' && pickup
            && pickup.place
            && pickup.place.place_id) {
            if (address.place_id === pickup.place.place_id
                || extraStops.indexOf(address.place_id) > -1
            ) {

                return true;
            }
        }

        if (type === 'extras' && (pickup
            && pickup.place
            && pickup.place.place_id
            || dropoff
            && dropoff.place
            && dropoff.place.place_id)
        ) {
            if (((pickup
                && pickup.place
                && pickup.place.place_id) && address.place_id === pickup.place.place_id)
                || (extraStops.indexOf(address.place_id) > -1)
                || ((dropoff
                    && dropoff.place
                    && dropoff.place.place_id) && address.place_id === dropoff.place.place_id)
            ) {

                return true;
            }
        }
    }

    handleBlur = (event: any) => {
        this.toggleEditMode('ALL');
    }

    //#region drop address
    handleDropAddressSelect = (selection: any) => {
        const address = selection.original[0];
        let validate = this.validateAddresses(address, 'dropoff');

        if (validate) {
            this.emptyVehicleDetails();
            return false;
        }
        let drop_address = address.name;
        // console.log(selection);
        // this.setState({ drop_address });
        Promise.resolve(getLuxyAddress(address)).then((dropoff: any) => {
            // console.log(dropoff, 'add2');
            drop_address = dropoff.address.full_address;
            isValidLuxyAddress(dropoff);
            let bookingDetails = this.state.bookingDetails;
            bookingDetails.dropoff = dropoff;
            this.setState({ bookingDetails: bookingDetails }, () => {
                this.setTravelType();
                // this.props.onChange("full_dropoff_address", this.state.dropoff);
            });

            // localStorage.setItem("valid_dropoff", 'true');
            sessionStorage.setItem("valid_dropoff", 'true');
            // // this.props.onChange("valid_dropoff", localStorage.valid_dropoff);

            // localStorage.setItem('isdropairportfield', (dropoff.place.airport_code) ? 'true' : 'false');
            sessionStorage.setItem('isdropairportfield', (dropoff.place.airport_code) ? 'true' : 'false');
            // localStorage.setItem('drop_timeZoneId', dropoff.timezone);
            // sessionStorage.setItem('drop_timeZoneId', dropoff.timezone);


            const places = this.state.bookingDetails.places;
            const placesLenth = this.state.bookingDetails.places.length;
            places[placesLenth - 1] = { latitude: dropoff.coordinates.latitude, longitude: dropoff.coordinates.longitude };
            const isValidLatLong = this.validateSourceAndDestination();

            let updatedBookingDetails = this.state.bookingDetails;
            updatedBookingDetails.drop_address = drop_address;
            updatedBookingDetails.drop_address_lat = dropoff.coordinates.latitude;
            updatedBookingDetails.drop_address_lng = dropoff.coordinates.longitude;
            updatedBookingDetails.isMapView = true;
            updatedBookingDetails.places = places;
            updatedBookingDetails.isValidLatLong = isValidLatLong;
            updatedBookingDetails.valid_dropoff = true;

            this.setState({
                bookingDetails: updatedBookingDetails,
                isDropoffEditable: false
            }, () => {
                this.updateDistanceInfo();
            });
        }).catch((err) => {
            // console.error(`failed for ${drop_address}: ${err}`);
            // localStorage.setItem("valid_dropoff", 'false');
            sessionStorage.setItem("valid_dropoff", 'false');
            let updatedBookingDetails = this.state.bookingDetails;
            updatedBookingDetails.valid_dropoff = false;
            updatedBookingDetails.drop_address = address.formatted_address;
            this.setState({ bookingDetails: updatedBookingDetails });
            // this.props.onChange("valid_dropoff", localStorage.valid_dropoff);
        });
    };

    //
    //#region handle time change
    async handleChangeTime(field: any, time: any) {
        const pickDate = pickUpDateFormatter(new Date(this.state.bookingDetails.pick_date_ptop));
        const pickTime = timeFormatter(time.target.value);
        const dateString = `${pickDate} ${pickTime}`;
        const timezoneDatetime = getTimezoneDatetime(dateString, this.state.bookingDetails.pickup.timezone);
         //comment for region buffer code begins
        // let isValid = await this.isInvalidPickupTime(this.state.bookingDetails.pick_date_ptop, pickTime);
        // if (!isValid) {
        //     toast.error(`Please book a ride at least ${Number(this.state.bookingDetails.buffer_expiration_time_widget)} hours from now or call 833-GET.LUXY for immediate assistance.`, {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        //     let updatedBookingDetails = this.state.bookingDetails;
        //     updatedBookingDetails[field] = time.target.value;
        //     updatedBookingDetails.pickup_timezone_date = timezoneDatetime;
        //     //comment for region begins
        //     // await this.getBufferExpirationTime();
        //     //comment for region ends
        //     this.setState({ bookingDetails: updatedBookingDetails }, async () => {
        //         // this.props.onChange(ROUTE_DETAILS_FIELDS.TIME, dateAndTime);
        //         await this.props.updateBookingState(updatedBookingDetails);
        //         this.getQuote();
        //     });
        //     return false;
        // }

        //comment for region buffer code ends
        // const dateAndTime = {
        //     time: time.target.value,
        //     pickup_timezone_date: timezoneDatetime
        // };

        let updatedBookingDetails = this.state.bookingDetails;
        updatedBookingDetails[field] = time.target.value;
        updatedBookingDetails.pickup_timezone_date = timezoneDatetime;
        this.setState({
            bookingDetails: updatedBookingDetails,
            // isDateTimeEditable: false
        }, async () => {
            // this.props.onChange(ROUTE_DETAILS_FIELDS.TIME, dateAndTime);
            await this.props.updateBookingState(updatedBookingDetails);
            this.getQuote();
        });

    }

    updateDistanceInfo = async () => {

        const places = this.state.bookingDetails.places;
        const travelMode = 'DRIVING';
        let waypoints: any = places.map((p: any) => ({
            location: { lat: p.latitude, lng: p.longitude },
            stopover: true
        }));

        let extraStops = this.state.extra_stops;
        let extraWayPoints: any = [];
        const origin = waypoints.shift();
        const destination = waypoints.pop();
        if (extraStops.length) {
            extraWayPoints = extraStops.map((address: any) => ({
                location: { lat: address.coordinates.latitude, lng: address.coordinates.longitude },
                stopover: true
            }));

            extraWayPoints.unshift(origin);
            extraWayPoints.push(destination);

            waypoints = extraWayPoints;
        }




        let routesData: any = await RideService.findMapRoute(origin.location, destination.location, travelMode, waypoints);
        const { distanceInfo } = routesData;


        let updatedBookingDetails = this.state.bookingDetails;
        updatedBookingDetails.distance_in_miles = distanceInfo.miles;
        updatedBookingDetails.total_hours = distanceInfo.hours;
        updatedBookingDetails.distance_in_meteres = distanceInfo.meters;
        updatedBookingDetails.distance_in_seconds = distanceInfo.seconds;

        this.setState({
            bookingDetails: updatedBookingDetails
        }, () => { this.getQuote(); })
    }

    addExtraStop = () => {
        let count = this.state.extra_stop_count;
        if (count > 4) {
            toast.error(`You can not enter more than 4 extra stops !`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        } else {
            // const con = this.getStopCount(count);
            let divid = "extrastop_" + count;
            let id = "extra_stop_" + count;
            let inputid = "auto_extra_" + count;

            const extrastop = {
                count,
                divid,
                id,
                inputid
            };

            this.state.extra_stop_controls.push(
                extrastop
            );
            this.setState({ extra_stop_count: this.state.extra_stop_count + 1 }, () => {
             this.getQuote();
            });
        }
    }

    handleExtraAddressSelect = (count: any, selection: any) => {
        const response = {
            field: `extra_stop_${count}`,
            isValid: false
        };
        const address = selection.original[0];
        let validate = this.validateAddresses(address, 'extras');

        if (validate) {
            toast.error(SAME_LOCATION_ERR, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        // console.log(address, 'address');
        // get full address for luxy address or string
        Promise.resolve(getLuxyAddress(address)).then((extraStop: any) => {
            // console.log(extraStop, 'extrastop value');
            extraStop.stop_number = count;
            this.handleExtraAddressChange(extraStop);

            isValidLuxyAddress(extraStop);
            response.isValid = true;
            // this.props.onChange('valid_extra_stop', response);
            localStorage.setItem(`valid_extra_stop_${count}`, 'true');
           // console.log(this.state, 'dddddd');
        }).catch((err) => {
            response.isValid = false;
            let extra_stop_error = this.state.extra_stop_error;
            extra_stop_error[response.field] = true;
            this.setState({ extra_stop_error: extra_stop_error }, () => {
                // console.log(this.state, 'UPS');
            });
            localStorage.setItem(`valid_extra_stop_${count}`, 'false');
        });
    }

    handleExtraAddressChange(address: any) {
        let extra_stops = [...this.state.extra_stops];
        const existExtraStop = extra_stops.some(e => e.stop_number === address.stop_number);
        if (existExtraStop) {
            const index = extra_stops.map(e => e.stop_number).indexOf(address.stop_number);
            extra_stops[index] = address;
        } else {
            extra_stops.push(address);
        }
        const stopNumber = address.stop_number;

        let extra_stop_error = this.state.extra_stop_error;
        extra_stop_error[`extra_stop_${stopNumber}`] = false;

        let st_extra_stop_values = this.state.extra_stop_values;
        st_extra_stop_values[`extra_stop_${stopNumber}`] = address.address.full_address;
        this.setState({ extra_stops, extra_stop_error, extra_stop_values: st_extra_stop_values }, async () => {
            let updatedBookingDetails = {
                ...this.state.bookingDetails,
                extra_stops,
                extra_stop_count: this.state.extra_stop_count,
                extra_stop_values: st_extra_stop_values
            }
            // console.log('st_extrastop_values added', st_extra_stop_values);
            // console.log(updatedBookingDetails, 'booking state from ride details 2');

            await this.props.updateBookingState(updatedBookingDetails);
            await this.updateDistanceInfo();
            // this.getQuote();
        });

        // console.log(this.state);
    }

    removeExtraStops(e: any, deleteitem: any) {

        e.preventDefault();
        let divid = "extrastop_" + deleteitem;
        var extra_stop_controls = [...this.state.extra_stop_controls];
        let extra_st: any = [];
        if (extra_stop_controls.length > 0) {
            for (let i = 0; i < extra_stop_controls.length; i++) {
                if (extra_stop_controls[i].divid === divid) {
                    var index = extra_stop_controls.indexOf(extra_stop_controls[i]);
                    if (index !== -1) {
                        // console.log(index, divid, 'index');
                        extra_stop_controls.splice(index, 1);
                        // code to remove extra stops address
                        let extraStops = [...this.state.extra_stops];

                        let extraStopIndex = extraStops.findIndex(item => item.stop_number === deleteitem);
                        // console.log(extraStops[extraStopIndex], deleteitem, 'booking state from ride details 3');

                        // get extrastop values fromm state

                        // update extra stop errors
                        let extrastop_errors: any = {};
                        let extrastop_values: any = {};
                    
                        extrastop_errors[`extra_stop_${extraStopIndex}`] = false;
                           

                        extraStops.forEach((extraStop: any, i: any) => {
                            // extraStop.stop_number = i + 1;
                            extrastop_errors[`extra_stop_${extraStop.stop_number}`] = this.state.extra_stop_error[`extra_stop_${extraStop.stop_number}`];
                            extrastop_values[`extra_stop_${extraStop.stop_number}`] = (this.state.extra_stop_values[`extra_stop_${extraStop.stop_number}`]) ? this.state.extra_stop_values[`extra_stop_${extraStop.stop_number}`] : '';
                        });
                        extraStops.sort((a, b) => (a.stop_number > b.stop_number) ? 1 : -1);

                        // let removedItem = {};
                        // if (extraStops.length) { removedItem = JSON.parse(JSON.stringify(extraStops[extraStops.length - 1])); }
                        if (extraStopIndex != -1) {
                            extraStops.splice(extraStopIndex, 1);
                            // extraStops.push(removedItem);
                        }

                        extraStops = extraStops.map((stop: any) => {
                            if (deleteitem < stop.stop_number) {
                                stop.stop_number = stop.stop_number - 1;
                            }
                            return stop;
                        });

                        let st_extra_stop_values: any = {};
                        if (extraStops.length == extra_stop_controls.length) {
                            // console.log(' extrastop_values act entered', extraStops);
                            extraStops.forEach((extraStop: any, i: any) => {
                                extraStop.stop_number = i + 1;
                                // extrastop_errors[`extra_stop_${extraStop.stop_number}`] = extrastop_errors_updated[extraStop.stop_number];
                                st_extra_stop_values[`extra_stop_${extraStop.stop_number}`] = extraStop.address.full_address;
                            });
                        } else {
                            extraStops.forEach((extraStop: any, i: any) => {
                                st_extra_stop_values[`extra_stop_${extraStop.stop_number}`] = extraStop.address.full_address;

                            });
                        }


                        // console.log(Object.assign({}, st_extra_stop_values), extraStops, 'extrastop_values act');

                        // update extra stop references in state like value;
                        // updating component
                        let newComponentList: any = [];
                        extra_stop_controls.forEach((component: any, i: any) => {
                            const count = i + 1;
                            let divid = `extrastop_${count}`;
                            let id = `extra_stop_${count}`;
                            let inputid = `auto_extra_${count}`;

                            const extrastop_c = {
                                count,
                                divid,
                                id,
                                inputid
                            };
                            newComponentList.push(
                                extrastop_c
                            );


                        });
                        // Object.assign(this.state, extraStopsRefences)
                        const extraStopCount = this.state.extra_stop_count - 1;
                        this.setState({
                            extra_stop_controls: newComponentList,
                            extra_stops: extraStops,
                            extra_stop_count: extraStopCount,
                            extra_stop_error: extrastop_errors,
                            extra_stop_values: st_extra_stop_values
                        }, async () => {
                            let updatedBookingDetails = {
                                ...this.state.bookingDetails,
                                extra_stops: extraStops,
                                extra_stop_count: extraStopCount,
                                extra_stop_values: st_extra_stop_values
                            }

                            await this.props.updateBookingState(updatedBookingDetails);
                            await this.updateDistanceInfo();
                            // this.getQuote();
                        });

                        // this.setState(
                        //     Object.assign(this.state, extrastop_values)
                        // )

                    }
                }
            }
        }
    }

    extraStopDiv = (extrastop: any) => {
        // const extraStopVal = this.state[`extra_stop_${extrastop.count}`];
        // console.log('extras1111', `extra_stop_${extrastop.count}`, this.state.extra_stop_values);
        let extraStopVal = (this.state.extra_stop_values[`extra_stop_${extrastop.count}`]) ? this.state.extra_stop_values[`extra_stop_${extrastop.count}`] : '';
        return <div className="row align-items-center no-gutters" key={extrastop.inputid}>
            <div className="col-auto">
                <img className="period-icon img-fluid" src={Period} />
            </div>
            <div className="col-9"  >
                {/* <AddressComponent
                    id={extrastop.inputid}
                    onPlaceChanged={(address) => { this.handleExtraAddressSelect(extrastop.count, address) }}
                    placeHolder={(extraStopVal && extraStopVal != '') ? extraStopVal : 'Extra Stop' + extrastop.count}
                    value={extraStopVal}
                    handleChange={(event: any) => this.handleExtraStopChange(event, extrastop.count)}
                /> */}

                <GooglePlacesComponent
                    // reference={this.setRefPickAddress}
                    id={extrastop.inputid}
                    onPlaceChanged={(address: any) => { this.handleExtraAddressSelect(extrastop.count, address) }}
                    value={extraStopVal}
                    handleChange={(event: any) => this.handleExtraStopChange(event, extrastop.count)}
                    // handleBlur={(event: any) => this.handleBlur(event)}
                    placeHolder={(extraStopVal && extraStopVal != '') ? extraStopVal : 'Extra Stop' + extrastop.count}
                    showFavorites={true}
                    supportedCountries={this.props.supportedCountries}
                    isGroundOnly={false}
                />


                {(this.state.extra_stop_error && this.state.extra_stop_error[`extra_stop_${extrastop.count}`]) ?
                    <div className="text-danger font-12  p-1 float-left">
                        Please select a complete address.
                            </div> : null
                }
            </div>
            <div className='col-1 text-center'>
                <i title="Delete" className="fa fa-trash extrastopdelete pointer"
                    onClick={e => this.removeExtraStops(e, extrastop.count)}></i>
            </div>
        </div>
    }



    renderDistanceInfo() {
        return (this.props.vehicleDetailsList && this.props.vehicleDetailsList.length) ? <div className="col p-0">
            <div className="mt-2">
                <div className="row no-gutters">
                    <div className="col-6">
                        <span className="distance-head">
                            Distance :
                    </span>
                        <span className="distance-value">
                            &nbsp;{(this.state.bookingDetails.distance_in_miles) ? formatDistance(this.state.bookingDetails.distance_in_miles) : formatDistance(Number(sessionStorage.getItem('distance_in_miles')))} mi
                     </span>
                    </div>
                    <div className="col-6">
                        <span className="distance-head">
                            Duration :
                    </span>
                        <span className="distance-value">
                            &nbsp;{(this.state.bookingDetails.total_hours) ? formatDuration(this.state.bookingDetails.total_hours) : formatDuration(Number(sessionStorage.getItem('total_hours')))} (hrs)
                    </span>
                    </div>
                </div>
            </div>
        </div> : null
    }

    exchangeAddress = () => {
        let isValid = this.validateSourceAndDestination();
        if (isValid) {

            const tempDropoff: any = this.state.bookingDetails.pickup;
            const tempPickup: any = this.state.bookingDetails.dropoff;
            const places: any = this.state.bookingDetails.places;
            places[0] = { latitude: tempPickup.coordinates.latitude, longitude: tempPickup.coordinates.longitude };
            const placesLength = this.state.bookingDetails.places.length;
            places[placesLength - 1] = { latitude: tempDropoff.coordinates.latitude, longitude: tempDropoff.coordinates.longitude };

            // pickup changes
            let updatedBookingDetails = this.state.bookingDetails;
            updatedBookingDetails.pick_address = tempPickup.address.full_address;
            updatedBookingDetails.pick_address_lat = tempPickup.coordinates.latitude;
            updatedBookingDetails.pick_address_lng = tempPickup.coordinates.longitude;
            updatedBookingDetails.isMapView = true;
            updatedBookingDetails.places = places;
            updatedBookingDetails.pickup = tempPickup;
            updatedBookingDetails.dropoff = tempDropoff;
            // updatedBookingDetails.isValidLatLong = isValidLatLong;
            updatedBookingDetails.valid_pickup = true;
            updatedBookingDetails.pick_up_zone = tempPickup.timezone;

            updatedBookingDetails.drop_address = tempDropoff.address.full_address;
            updatedBookingDetails.drop_address_lat = tempDropoff.coordinates.latitude;
            updatedBookingDetails.drop_address_lng = tempDropoff.coordinates.longitude;
            updatedBookingDetails.valid_dropoff = true;

            this.setState({
                bookingDetails: updatedBookingDetails,
                isPickupEditable: false,
                isDropoffEditable: false
            }, () => {
                this.updateDistanceInfo();
                // this.getQuote();
            });

        } else {

        }
    }

    formatHrsAndMin = (hours: any, min: any) => {
        let hh = hours;
        let m = min;
        let h = hh;
        let dd = "AM";
        if (h >= 12) {
            h = hh - 12;
            dd = "PM";
        }
        if (h == 0) {
            h = 12;
        }
        h = h < 10 ? "0" + h : h;
        m = m < 10 ? "0" + m : m;
        let replacement = h + ":" + m + " " + dd;
        return replacement;
    }

    handleChangeHourMin = async (type: string, event: any) => {

        let selHrs = (type == 'selHrs') ? event.target.value : this.state.bookingDetails.selHrs;
        let selMin = (type == 'selMin') ? event.target.value : this.state.bookingDetails.selMin;

        let timeStr = this.formatHrsAndMin(selHrs, selMin);

        this.setState({
            selHrs: selHrs,
            selMin: selMin
        },
            async () => {

                const pickDate = pickUpDateFormatter(new Date(this.state.bookingDetails.pick_date_ptop));
                const pickTime = timeStr;
                const dateString = `${pickDate} ${pickTime}`;
                // console.log("dateString", dateString);
                
                const timezoneDatetime = getTimezoneDatetime(dateString, this.state.bookingDetails.pickup.timezone);
                // console.log('pick_date_ptop', dateString);
                  //comment for region buffer code begins
                // let isValid = await this.isInvalidPickupTime(dateString, pickTime);
                // console.log('isValid', isValid)
                // if (!isValid) {
                //     toast.error(`Please book a ride at least ${Number(this.state.bookingDetails.buffer_expiration_time_widget)} hours from now or call 833-GET.LUXY for immediate assistance.`, {
                //         position: toast.POSITION.TOP_RIGHT
                //     });
                //     let updatedBookingDetails = this.state.bookingDetails;
                //     updatedBookingDetails['time'] = timeStr;
                //     updatedBookingDetails['selHrs'] = selHrs;
                //     updatedBookingDetails['selMin'] = selMin;
                //     updatedBookingDetails.pickup_timezone_date = timezoneDatetime;
                //     //comment for region begins
                //     // await this.getBufferExpirationTime();
                //     //comment for region begins
                //     this.setState({ bookingDetails: updatedBookingDetails }, async () => {
                //         // this.props.onChange(ROUTE_DETAILS_FIELDS.TIME, dateAndTime);
                //         await this.props.updateBookingState(updatedBookingDetails);
                //         this.getQuote();
                //     });
                //     return false;
                // }

                // comment for region buffer code ends 

                let updatedBookingDetails = this.state.bookingDetails;
                updatedBookingDetails['time'] = timeStr;
                updatedBookingDetails['selHrs'] = selHrs;
                updatedBookingDetails['selMin'] = selMin;
                updatedBookingDetails.pickup_timezone_date = timezoneDatetime;
                // console.log(updatedBookingDetails, 'timezoneDatetime22');
                this.setState({
                    bookingDetails: updatedBookingDetails,
                    // isDateTimeEditable: false
                }, async () => {
                    // this.props.onChange(ROUTE_DETAILS_FIELDS.TIME, dateAndTime);
                    await this.props.updateBookingState(updatedBookingDetails);
                    this.getQuote();
                });

            })


    }
    //#endregion

    render() {
        // const selDate = this.state.pick_date_ptop != '' ? new Date(this.state.bookingDetails.pick_date_ptop) : new Date(pickup_date_time_format(moment(new Date()).add(Number(this.state.bookingDetails.buffer_expiration_time_widget), 'hours'), this.state.bookingDetails.pickup.timezone));
        let selDate: any = new Date(this.state.bookingDetails.pick_date_ptop);
        const pickup_address = (this.state.bookingDetails.pick_address) ? this.state.bookingDetails.pick_address : '';
        const dropoff_address = (this.state.bookingDetails.drop_address) ? this.state.bookingDetails.drop_address : '';

        // let selTime = (this.state.bookingDetails.time) ? new Date(this.state.bookingDetails.time) : null;
        let selHrs = this.state.bookingDetails.selHrs;
        let selMin = this.state.bookingDetails.selMin;

        //for loading additional stop
        let additionalistops: any = [];
        if (this.state.extra_stop_controls.length > 0) {
            this.state.extra_stop_controls.forEach((extraStopControl: any) => {
                additionalistops.push(extraStopControl);
            });
            // additionalistops.sort((a: any, b: any) => (a.con > b.con) ? 1 : -1);
        }
        return (
            <div className="card w-100 p-0" style={{ backgroundColor: '#F1F1F1' }}>
                <div className="card-body">
                    <div className="ride-booking-pane ride-booking-pane-ride-detail"  >
                        <div>
                            <ToastContainer />
                        </div>
                        <div className="block-head">
                            <h5 className="">Ride Details</h5>



                        </div>
                        <div className="treeview_details"></div>
                        <div className="row align-items-center no-gutters mt-0">
                            <div className="col-auto">
                                <img className="period-icon  img-fluid" src={Period} />
                            </div>
                            {
                                (this.state.isPickupEditable) ? <div className="col">
                                    {/* <AddressComponent
                                        reference={this.setRefPickAddress}
                                        id='input-pickup-searchbox'
                                        onPlaceChanged={this.handlePickAddressSelect}
                                        placeHolder={(this.state.pick_address) ? this.state.pick_address : 'Enter pick-up location'}
                                        value={pickup_address}
                                        handleChange={(event: any) => this.handlePickChange(event)}
                                        handleBlur={(event: any) => this.handleBlur(event)}
                                    /> */}

                                    <GooglePlacesComponent
                                        reference={this.setRefPickAddress}
                                        id='input-pickup-searchbox'
                                        onPlaceChanged={this.handlePickAddressSelect}
                                        value={pickup_address}
                                        handleChange={(event: any) => this.handlePickChange(event)}
                                        handleBlur={(event: any) => this.handleBlur(event)}
                                        placeHolder={(this.state.pick_address) ? this.state.pick_address : 'Enter pick-up location'}
                                        showFavorites={true}
                                        supportedCountries={this.props.supportedCountries}
                                        isGroundOnly={false}
                                    />
                                </div> :
                                    <div className="col ">
                                        <div className="txtoverflow">
                                            <span className="selected-label" title={this.state.bookingDetails.pick_address}>{this.state.bookingDetails.pick_address}</span>
                                        </div>&nbsp; &nbsp;
                                         <span className="edit-booking">
                                            <i className="fa fa-pencil" onClick={() => this.toggleEditMode('pickup')} aria-hidden="true"></i>
                                        </span>
                                    </div>

                            }
                        </div>
                        <div className="row align-items-center no-gutters mt-0">
                            {(!this.state.bookingDetails.valid_pickup && this.state.bookingDetails.pick_address != '') ?
                                <div className="col text-danger font-12 pl-4 p-1 float-left">
                                    Please select a complete address.
                            </div> : null
                            }
                        </div>
                        {
                            (additionalistops.length == 0) ?
                                <div className="text-center btn-icon pointer">
                                    <img className=" img-fluid" onClick={() => { this.exchangeAddress() }} style={{ height: '15px', width: '15px' }} src={Exchange} />
                                </div> : null
                        }
                        <img className="period-icon  img-fluid pointer" style={{ width: '16px', marginLeft: '-3px', position: 'absolute' }}
                            onClick={() => this.addExtraStop()}
                            src={Plus} />
                        {/* extra stops code */}
                        {additionalistops.map((extrastop: any) => (
                            this.extraStopDiv(extrastop))
                        )}
                        <div className="row align-items-left no-gutters">
                            <div className="col-auto">
                                <img className="period-icon  img-fluid  img-fluid" src={Period} />
                            </div>
                            {
                                (this.state.isDropoffEditable) ?
                                    <div className="col">
                                        {/* <AddressComponent
                                            reference={this.setRefDropAddress}
                                            id='input-dropoff-searchbox'
                                            onPlaceChanged={this.handleDropAddressSelect}
                                            placeHolder={(this.state.drop_address) ? this.state.drop_address : 'Dropoff Address'}
                                            value={dropoff_address}
                                            handleChange={(event: any) => this.handleDropChange(event)}
                                            handleBlur={(event: any) => this.handleBlur(event)}
                                        /> */}

                                        <GooglePlacesComponent
                                            reference={this.setRefDropAddress}
                                            id='input-dropoff-searchbox'
                                            onPlaceChanged={this.handleDropAddressSelect}
                                            value={dropoff_address}
                                            handleChange={(event: any) => this.handleDropChange(event)}
                                            handleBlur={(event: any) => this.handleBlur(event)}
                                            placeHolder={(this.state.drop_address) ? this.state.drop_address : 'Dropoff Address'}
                                            showFavorites={true}
                                            supportedCountries={this.props.supportedCountries}
                                            isGroundOnly={false}
                                        />

                                    </div> :
                                    <div className="col ">
                                        <div className="txtoverflow">
                                            <span className="selected-label align-items-left" title={this.state.bookingDetails.drop_address}>{this.state.bookingDetails.drop_address}</span>
                                        </div>&nbsp; &nbsp;
                                <span className="edit-booking"><i className="fa fa-pencil"
                                            onClick={() => this.toggleEditMode('dropoff')}
                                            aria-hidden="true"></i>
                                        </span>
                                    </div>
                            }
                        </div>

                        <div className="row align-items-center no-gutters mt-0">
                            {(!this.state.bookingDetails.valid_dropoff && this.state.bookingDetails.drop_address != '') ?
                                <div className="col text-danger font-12 pl-4 p-1 float-left">
                                    Please select a complete address.
                            </div> : null
                            }
                        </div>

                        <div className="row align-items-center no-gutters">
                            <div className="col-auto">
                                <img className="period-icon  img-fluid" src={Period} />
                            </div>
                            {
                                (this.state.isDateTimeEditable) ?
                                    <div className="col">
                                        <div className="row no-gutters mt-0">
                                            <div className="col-6">
                                                <DateComponent
                                                    reference={this.pick_date_ptop}
                                                    // handleBlur={(event: any) => this.handleBlur(event)}
                                                    format="MM-dd-yyyy"
                                                    placeHolder="MM-DD-YYYY"
                                                    minDate={new Date()}
                                                    handleRaw={this.handleDateChangeRawPtoP}
                                                    handleChange={this.handleDateChangePtoP.bind(this, 'pick_date_ptop')}
                                                    selectedDate={selDate}
                                                />
                                            </div>
                                            <div className="col-6">
                                                {/* <TimeInput
                                                    reference={this.setRef}
                                                    format={'hh:mm tt'}
                                                    value={selTime}
                                                    handleBlur={(event: any) => this.handleBlur(event)}
                                                    handleChange={this.handleChangeTime.bind(this, ROUTE_DETAILS_FIELDS.TIME)}
                                                /> */}
                                                <img src={clock} /> &nbsp;
                                                <HoursInput
                                                    style={{ width: '70px !important;' }}
                                                    value={selHrs}
                                                    id="selHrs"
                                                    handleChange={this.handleChangeHourMin}
                                                    handleBlur={(event: any) => this.handleBlur(event)}
                                                /> : <MinInput
                                                    value={selMin}
                                                    id="selMin"
                                                    handleBlur={(event: any) => this.handleBlur(event)}
                                                    handleChange={this.handleChangeHourMin}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="col" >
                                        <span className="selected-label align-items-left">
                                            {pickUpDateInputFormatter(selDate).toString()} &nbsp;&nbsp;  {this.state.bookingDetails.time}</span>
                                &nbsp; &nbsp; <span className="edit-booking"><i className="fa fa-pencil"
                                            onClick={() => this.toggleEditMode('datetime')}
                                            aria-hidden="true"></i>
                                        </span>
                                    </div>
                            }
                        </div>
                    </div>
                    {this.renderDistanceInfo()}
                </div>
            </div>
        )
    }
}

export default RideDetails;