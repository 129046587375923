import React, { Component } from 'react';
import { Alert, Button, Col, Form, FormControl, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AuthService } from '../../services/auth';
import { Source } from '../../constants/app-constants';
import { StatusCode } from '../../constants/status-codes';
import { USER_SIGN_UP, USER_ENROLLED_RIDE_REWARDS, SIGN_UP_RIDE_REWARDS, SIGN_UP_DATA } from '../../reducer/user';
import * as Helpers from '../common/helpers';
import { checkPassword } from '../../Utilities/password';
import { passwordFormatString } from '../../Consts';
import { SIGN_IN_RIDE_REWARDS } from '../../reducer/user';
import AuthModal from '../modals/auth-modal';
import AgreeText from '../policy/agree-text';
import PhoneInput from 'react-phone-number-input';
import { createEventGA4 } from '../../Utilities/helpers';
import { Link } from 'react-router-dom';

interface SignUpProps {
    customerSignUp: (payload: any) => any;
    navigateToRideDetail: any;
    signUpRideRewards: any;
    signInRideRewards: (data: boolean) => any;
    isFromGetQuotes: boolean;
    rideRewardsConfigData: any;
    setKey?: (key: any) =>  any;
    uniqueID?: string;
}

interface SignUpState {
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
    password: string;
    terms: boolean;
    firstNameErr: boolean;
    lastNameErr: boolean;
    emailErr: boolean;
    passwordErr: boolean;
    mobileErr: boolean;
    termsErr: boolean;
    signUpErr: boolean;
    onSuccess: boolean;
    saveButton: string;
    signInRideRewards: boolean;
    disableSignUp: boolean;
    form_start: boolean;
    ride_rewards: boolean;
    unique_id: string;
}

class SignUp extends Component<SignUpProps, SignUpState> {
    public passCodeRef: any = React.createRef();
    constructor(props: SignUpProps) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            mobile: '',
            email: '',
            password: '',
            terms: false,
            firstNameErr: false,
            lastNameErr: false,
            emailErr: false,
            passwordErr: false,
            mobileErr: false,
            termsErr: false,
            signUpErr: false,
            onSuccess: false,
            saveButton: 'Sign Up',
            signInRideRewards: false,
            disableSignUp: false,
            form_start: false,
            ride_rewards: false,
            unique_id: ''
        }
    }

    validate = async () => {
        (this.state.firstName.trim() === '') ? this.setState({ firstNameErr: true }) : this.setState({ firstNameErr: false });
        (this.state.lastName.trim() === '') ? this.setState({ lastNameErr: true }) : this.setState({ lastNameErr: false });
        (this.state.email === '' || !Helpers.validateEmail(this.state.email)) ? this.setState({ emailErr: true }) : this.setState({ emailErr: false });
        (this.state.password === '') ? this.setState({ passwordErr: true }) : this.setState({ passwordErr: false });
        (this.state.mobile === '' || !Helpers.validateCountryPhone(this.state.mobile)) ? this.setState({ mobileErr: true }) : this.setState({ mobileErr: false });
        (!this.state.terms) ? this.setState({ termsErr: true }) : this.setState({ termsErr: false });
    }

    componentDidMount = () => {

        if (this.props.signUpRideRewards) {
            this.setState({ saveButton: 'Enroll' });
        }

        if(this.props.uniqueID) {
            this.setState({unique_id: this.props.uniqueID});
        }
    }

    componentDidUpdate = (prevProps: SignUpProps) => {
        if (prevProps !== this.props) {
            if (this.props.signUpRideRewards) {

                this.setState({ saveButton: 'Enroll' });
            }
        }
    }

    signUp = async () => {
        this.setState({ disableSignUp: true });
        await this.validate();
        if (!this.state.firstNameErr && !this.state.lastNameErr
            && !this.state.emailErr && !this.state.passwordErr
            && !this.state.mobileErr) {

            if (!this.state.firstNameErr &&
                !this.state.lastNameErr &&
                !this.state.emailErr &&
                !this.state.passwordErr &&
                !this.state.mobileErr &&
                !this.state.termsErr) {
                let payload: any = {
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    mobile_number: this.state.mobile,
                    email_address: this.state.email,
                    password: this.state.password,
                    source: Source,
                    is_email_notification_allowed: true,
                    is_sms_notification_allowed: true,
                    is_active: false,
                    unique_id: this.state.unique_id
                };
                if (this.props.signUpRideRewards || this.state.ride_rewards) {
                    payload = {
                        ...payload,
                        is_enrolled_into_riderewards: true
                    }
                }

                let response = await this.props.customerSignUp(payload);

                if (response.status === StatusCode.CONFLICT) {
                    this.setState({ signUpErr: true, disableSignUp: false });
                }

                if (response.status === StatusCode.EVERYTHING_IS_OK) {
                    // console.log(response);

                    this.setState({ onSuccess: true });
                    let dataSignUpLayerObj: any = {
                        'eventName': 'signup_success',
                        'signUpArea': 'navigation',
                        'loginStatus': 'false',
                        'userId': (response && response.data && response.data.customer_id) ? response.data.customer_id.toString() : '',
                        'event': 'signupSuccess'
                    };
                    if (this.props.signUpRideRewards) {
                        dataSignUpLayerObj.signUpArea = 'enroll';
                        const dataLayerObj: any = {
                            'eventName': 'form_submit_success',
                            'formName': 'Ride Rewards',
                            'event': 'formSubmitSuccess'
                        };
                        createEventGA4(dataLayerObj);
                        createEventGA4(dataSignUpLayerObj);
                    } else {
                        if (this.props.isFromGetQuotes) {
                            dataSignUpLayerObj = {
                                ...dataSignUpLayerObj,
                                signUpArea: 'get_quote'
                            }
                        }
                        createEventGA4(dataSignUpLayerObj);
                        // ReactGA.event(googleEventAnalytics.SignUp);
                    }
                }
            } else {
                this.setState({ disableSignUp: false });
            }

        } else {
            this.setState({ disableSignUp: false });
        }
    }

    togglePassWord = () => {
        const textType = this.passCodeRef.current.type;
        if (textType === 'password') {
            this.passCodeRef.current.type = 'text';
        } else {
            this.passCodeRef.current.type = 'password';
        }

    }

    updateMobile = async (event: any) => {
        // this.setState({ mobile: event.target.value });
        // let isValid = Helpers.validatePhone(event.target.value);
        let isValid = await Helpers.validateCountryPhone(event);
        this.setState({ mobile: event, mobileErr: !isValid });
        // this.setState({ mobileErr: !isValid });
    }

    updateEmail = (event: any) => {
        this.setState({ email: event.target.value });
        let isValid = Helpers.validateEmail(event.target.value);
        this.setState({ emailErr: !isValid });

    }

    validateOtherFields = (event: any, type: string) => {
        if (!this.state.form_start && this.props.signUpRideRewards) {
            const dataLayerObj: any = {
                'eventName': 'form_start',
                'formName': "Ride Rewards",
                'event': 'formStart'
            };
            createEventGA4(dataLayerObj);
        }
        if (type === 'first_name') {
            if (event.target.value.trim() === '') {
                this.setState({ firstNameErr: true, firstName: event.target.value });
            } else {
                this.setState({ firstNameErr: false, firstName: event.target.value });
            }
        }
        if (type === 'last_name') {
            if (event.target.value.trim() === '') {
                this.setState({ lastNameErr: true, lastName: event.target.value });
            } else {
                this.setState({ lastNameErr: false, lastName: event.target.value });
            }
        }

        if (type === 'password') {
            if (!checkPassword(event.target.value.trim())) {
                this.setState({ passwordErr: true, password: event.target.value });
            } else {
                this.setState({ passwordErr: false, password: event.target.value })
            }
        }

        if (type === 'terms') {
            let isChecked: boolean = event.target.checked;
            if (!isChecked) {
                this.setState({ termsErr: true, terms: isChecked });
            } else {
                this.setState({ termsErr: false, terms: isChecked });
            }

        }

        if (type === 'ride_rewards') {
            let isChecked: boolean = event.target.checked;
            this.setState({ ride_rewards: isChecked });
        }

        if (type === 'unique_id') {
            const alphanumericRegex = /^[a-zA-Z0-9 ]*$/;
            const uniqueID = event.target.value;
            if (!alphanumericRegex.test(uniqueID)) {
                return false;
            }
            this.setState({ unique_id: uniqueID });
        }

    }

    signInRideRewards = () => {
        this.setState({ signInRideRewards: true }, () => {
            this.props.signInRideRewards(true);
        });
    }

    updateNavData = () => { }

    closeModal = () => {
        this.setState({ signInRideRewards: false }, () => {
            this.props.signInRideRewards(false);
        })
    }

    render() {
        return (
            <div className='pt-1'>
                {
                    (this.state.signUpErr) &&
                    <Alert variant='danger'>
                        <span><b>Error!</b> This email already exists! Login <Button
                                style={{border: "none",
                                    background: "none",
                                    color: "#007bff",
                                    fontSize: "15px",
                                    padding: "0px",
                                    paddingBottom: "4px"
                                }}
                                onClick={() => this.props.setKey ? this.props.setKey('sing_in') : ''}> {' '} here
                        </Button></span>
                        {/* <b>Error!</b> This email is already exist. */}
                    </Alert>
                 }


                <div className="sign-in">
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="firstName" className="float-left">FIRST NAME <span className="importantfields">*</span></label>
                                <FormControl
                                    id="firstName"
                                    //placeholder={"Enter First name"}
                                    onChange={(event: any) =>
                                        this.validateOtherFields(event, 'first_name')
                                        // this.setState({ firstName: event.target.value })
                                    }
                                    autoComplete="nope"
                                    value={this.state.firstName}
                                />

                                <div>
                                    <p className="text-danger">{
                                        (this.state.firstNameErr) ?
                                            'First Name is required'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="lastName" className="float-left">LAST NAME <span className="importantfields">*</span></label>
                                <FormControl
                                    id="lastName"
                                    //placeholder={"Enter Last name"}
                                    onChange={(event: any) =>
                                        this.validateOtherFields(event, 'last_name')
                                        // this.setState({ lastName: event.target.value })
                                    }
                                    autoComplete="nope"
                                    value={this.state.lastName}
                                />
                                <div>
                                    <p className="text-danger">{
                                        (this.state.lastNameErr) ?
                                            'Last Name is required'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="email" className="float-left">EMAIL <span className="importantfields">*</span></label>
                                <FormControl
                                    id="email"
                                    //placeholder={"Enter Email"}
                                    onChange={(event: any) =>
                                        this.updateEmail(event)
                                        // this.setState({ email: event.target.value })
                                    }
                                    value={this.state.email}
                                    autoComplete="nope"
                                />
                                <div>
                                    <p className="text-danger">{
                                        (!this.state.email && this.state.emailErr) ? 'Email is required' : (this.state.emailErr) ?
                                            'Enter valid email address'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <div>
                                    <label htmlFor="password" className="float-left">PASSWORD <span className="importantfields">*</span></label>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 250 }}
                                        overlay={<Tooltip id="button-tooltip">
                                            <div className="addon-meta"
                                                dangerouslySetInnerHTML={{ __html: passwordFormatString }}
                                            ></div>
                                        </Tooltip>}
                                    >
                                        <i className="fa fa-info-circle ml-2"></i>
                                    </OverlayTrigger>
                                </div>

                                <InputGroup className="mb-3">
                                    <FormControl
                                        type="password"
                                        id="password"
                                        // placeholder={"Enter Password"}
                                        onChange={(event: any) =>
                                            this.validateOtherFields(event, 'password')
                                            // this.setState({ password: event.target.value })
                                        }
                                        value={this.state.password}
                                        ref={this.passCodeRef}
                                        autoComplete="nope"
                                    />
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1"><i className="fa fa-eye pointer"
                                            onClick={() => this.togglePassWord()}
                                        ></i></InputGroup.Text>
                                    </InputGroup.Prepend>
                                </InputGroup>
                                <div>
                                    <p className="text-danger">{
                                        (!this.state.password && this.state.passwordErr) ? 'Password is required' : (this.state.passwordErr) ?
                                            'Enter valid password'
                                            : null
                                    }</p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* <div className="form-group">
                                <label htmlFor="mobile" className="float-left">MOBILE PHONE NUMBER</label>
                                <FormControl
                                    id="mobile"
                                    // placeholder={"Enter Mobile Number"}
                                    onChange={(event: any) =>
                                        this.updateMobile(event)
                                        // this.setState({ mobile: event.target.value })
                                    }
                                    maxLength={16}
                                    value={this.state.mobile}
                                />

                            </div> */}

                            {/* starts here */}
                            <label htmlFor="mobile" className="">MOBILE PHONE NUMBER <span className="importantfields">*</span></label>
                            <PhoneInput
                                defaultCountry='US'
                                international
                                onChange={(event: any) =>
                                    this.updateMobile(event)
                                }
                                value={this.state.mobile}
                                autoComplete="nope"
                            />

                            <div>
                                <p className="text-danger">{
                                    (!this.state.mobile && this.state.mobileErr) ? 'Mobile Number is required' : (this.state.mobileErr) ?
                                        'Enter valid contact number'
                                        : null
                                }</p>
                            </div>
                            {/* ends here */}

                        </Col>
                    </Row>
                {
                <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="uniqueID" className="float-left">REFERENCE CODE: </label>
                                <FormControl
                                maxLength={6}
                                    id="unique_id"
                                    onChange={(event: any) =>
                                        this.validateOtherFields(event, 'unique_id')
                                    }
                                    autoComplete="nope"
                                    value={this.state.unique_id}
                                />
                            </div>
                        </Col>
                    </Row>
                }
                    <Row>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox"
                                className="custom-label"
                                // style={{textTransform: 'none'}}
                                label={<AgreeText type={this.props.signUpRideRewards ? 'ride_rewards' : ''} />}
                                // checked={false}
                                onChange={(event: any) =>
                                    // this.setState({ terms: event.target.checked })
                                    this.validateOtherFields(event, 'terms')
                                }
                            />
                            <div>
                                <p className="text-danger">{
                                    (this.state.termsErr) ?
                                        'You should agree with our terms of service, privacy policy and cookie policy'
                                        : null
                                }</p>
                            </div>
                        </Form.Group>
                    </Row>
                    {
                        (this.props.rideRewardsConfigData && this.props.rideRewardsConfigData.value === 'true') ?

                            <Row>
                                <Form.Group controlId="formBasicCheckbox1">
                                    <Form.Check type="checkbox"
                                        className="custom-label"
                                        label='Enroll for ride rewards'
                                        onChange={(event: any) =>
                                            this.validateOtherFields(event, 'ride_rewards')
                                        }
                                    />
                                </Form.Group>
                            </Row>
                            : null

                    }
                    <Row>
                        <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                            {
                                (this.props.signUpRideRewards)
                                    ?
                                    <>
                                        <p>Already have an account?  <a className='pointer' onClick={() => this.signInRideRewards()}> SIGN IN </a></p>

                                    </>
                                    : null
                            }
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Button variant="submit float-right"
                                disabled={this.state.disableSignUp}
                                onClick={() => this.signUp()}
                            >
                                {this.state.saveButton}
                            </Button>{' '}
                        </Col>
                    </Row>
                </div>
                {
                    (this.state.signInRideRewards)
                        ?
                        <AuthModal
                            updateNavData={() => this.updateNavData()}
                            closeModal={() => this.closeModal()} />
                        : null
                }

            </div >
        )
    }
}


type Dispatch = (action: any) => void;

/**
 * Post signUp.
 */
const signUp = (payload: any) => {
    return async (dispatch: Dispatch) => {
        let data = await AuthService.signUp(payload);

        if (data.status === StatusCode.EVERYTHING_IS_OK) {
            let type = USER_SIGN_UP;
            if (payload.is_enrolled_into_riderewards) {
                type = USER_ENROLLED_RIDE_REWARDS;
            }
            dispatch({
                type: type, data: true
            });
            dispatch({
                type: SIGN_UP_DATA, data: payload
            });
        }
        return data;
    }
}

const signInRideRewards = (data: boolean) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: SIGN_UP_RIDE_REWARDS, data: false
        });
        dispatch({
            type: SIGN_IN_RIDE_REWARDS, data: data
        });
    }
}

const mapStateToProps = (state: any) => {
    return {
        isUserSingUp: state.user.isUserSingUp,
        navigateToRideDetail: state.bookARide.navigateToRideDetail,
        signUpRideRewards: state.user.signUpRideRewards,
        isFromGetQuotes: state.bookARide.isGetQuotesClicked,
        rideRewardsConfigData: state.configurations.rideRewardsConfigData
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        customerSignUp: (payload: any) => dispatch(signUp(payload)),
        signInRideRewards: (data: boolean) => dispatch(signInRideRewards(data)),
    };
};

const SignUpContainer = connect(mapStateToProps, mapDispatchToProps)(SignUp);

export default SignUpContainer;

