import React, { Component } from 'react';
import LuxyAirport from './luxy-airport';
import './why-luxy.css';
const banner1 = require('../../assets/images/webpimages/1.webp');
const banner2 = require('../../assets/images/webpimages/2.webp');
const banner3 = require('../../assets/images/3.PNG');
const banner4 = require('../../assets/images/webpimages/4.webp');
const banner5 = require('../../assets/images/webpimages/5.webp');
const banner6 = require('../../assets/images/6.PNG');
interface WhyLuxyProps {

}

interface WhyLuxyState {

}

class WhyLuxy extends Component<WhyLuxyProps, WhyLuxyState> {
    constructor(props: WhyLuxyProps) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            <div>
                <div className='d-none d-md-block'>
                    <div className="ground_transportation_BANNER text-center  p-4" >
                        <h1 style={{ textAlign: 'center', color: '#000000' }} className="mt-5">Car Service To The Airport - Book A Limo Ride With Luxy Ground Transportation Services</h1>
                        <div style={{ opacity: '0.8', color: '#000000', borderRadius: '20px', width: '100%', marginTop: 0 }} className="p-5 footerbottom mx-auto">
                        Welcome to your premier nationwide destination for reliable and sustainable transportation services. With a mission to provide affordable and eco-friendly solutions, we specialize in filling empty legs at discounted prices without any sacrifices on service, quality, or safety. 
                        For when you need a ride to airport services or other ride requests, our reach spans across the United States, from New Jersey to Rhode Island, Connecticut, New York, Boston, San Francisco, and beyond. Whether it's an airport transfer, a car service, or general ground transportation, we ensure professionalism, punctuality, 
                        and an environmentally conscious approach to all your transportation needs. Be it an airport transfer, like our LAX sedan service or O'Hare car service, or any non-airport ride, we deliver a seamless and hassle-free travel experience regardless of your destination. Our corporate and family-friendly services are top-rated, 
                        presenting a safer and more personal alternative to rideshare options. Book your ride with us today and experience the difference in nationwide transportation while contributing to a greener planet.
                        </div>
                    </div>
                </div>
                <div className="container d-none d-md-block">
                    <h1 style={{ fontSize: '28px !important'}} className="text-center font-weight-normal">Why LUXY&#8482; Black Car Service</h1>
                    <div className="row mt-4">
                        <div className="col-xl-6 text-center">
                            <img
                                alt="Black Car Service"
                                src={banner1}
                                className="d-inline-block  img-fluid align-top image-main-page-size"
                            />
                        </div>
                        <div className="col-xl-6">
                            <h2 className="mt-xl-5 text-xl-left text-sm-center">Vehicles</h2>
                            <p className="text-xl-left text-sm-center">
                                Upgrade the way you travel! You’re<br /> a V.I.P. and you should ride like<br /> one in a luxury vehicle.
        </p>
                        </div>
                    </div>
                    <hr className="mt-5"></hr>
                    <div className="row mt-4">

                        <div className="col-xl-6 pt-3">
                            <h2 className="mt-xl-5 text-xl-right text-sm-center">Availability</h2>
                            <p className="text-xl-right text-sm-center">
                                Need a ride right now or planning your family<br /> vacation for next year? We’ve got you<br /> covered with 24/7 365 availability.
        </p>
                        </div>
                        <div className="col-xl-6 text-center">
                            <img
                                alt="Black Car Service By LUXY Ride"
                                src={banner2}
                                className="d-inline-block  img-fluid align-top image-main-page-size1"
                            />
                        </div>
                    </div>

                    <hr className="mt-5"></hr>

                    <div className="row mt-4">


                        <div className="col-xl-6 text-center">
                            <img
                                alt="Luxury Black Car Service in USA"
                                src={banner3}
                                className="d-inline-block  img-fluid align-top image-main-page-size2"
                            />
                        </div>
                        <div className="col-xl-6 pt-3">
                            <h2 className="mt-xl-5 text-xl-left text-sm-center">Care Team</h2>
                            <p className="text-xl-left text-sm-center">
                                You asked for world-class customer<br></br> service and we listened! Talk to a live<br></br> agent today and get a free quote.
        </p>
                        </div>
                    </div>
                    <hr className="mt-5"></hr>
                    <div className="row mt-4">

                        <div className="col-xl-6 pt-3">
                            <h2 className="mt-xl-5 text-xl-right text-sm-center">Trusted Network</h2>
                            <p className="text-xl-right text-sm-center">
                                Searching for a reliable limo service can be a <br></br>hassle, but we made it easy by partnering<br></br> with only the best of the best across the US.
        </p>
                        </div>
                        <div className="col-xl-6 text-center">
                            <img
                                alt="Black Car Service to Airport"
                                src={banner4}
                                className="d-inline-block  img-fluid align-top image-main-page-size3"
                            />
                        </div>
                    </div>

                    <hr className="mt-5"></hr>
                    <div className="row mt-4">


                        <div className="col-xl-6 text-center">
                            <img
                                alt="Black Car Service From Airport"
                                src={banner5}
                                className="d-inline-block  img-fluid align-top image-main-page-size4"
                            />
                        </div>
                        <div className="col-xl-6 pt-3">
                            <h2 className="mt-xl-5 text-xl-left text-sm-center">Budget Friendly</h2>
                            <p className="text-xl-left text-sm-center">
                                You don’t have to break the wallet to have<br></br> an extraordinary travel experience. <br></br>No hidden fees and always the best deal.
        </p>
                        </div>
                    </div>
                    <hr className="mt-5"></hr>

                    <div className="row mb-5 mt-4">

                        <div className="col-xl-6 pt-3 px-5 ">
                            <h2 className="mt-xl-5 text-sm-center text-xl-right">Check Our Tech</h2>
                            <p className="text-xl-right text-sm-center">
                                Book simply, receive ride alerts, save <br></br>your payment method and don’t forget to  <br></br>enjoy the ride!
        </p>
                        </div>
                        <div className="col-xl-6  mb-5 text-center">
                            <img
                                alt="Professional Black Car Service to Airport"
                                src={banner6}
                                className="d-inline-block  img-fluid align-top image-main-page-size5"
                            />
                        </div>
                    </div>


                </div>
                <LuxyAirport />
            </div>
        )
    }
}
export default WhyLuxy;