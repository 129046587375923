import React from 'react';
import { Row, Navbar, Nav } from 'react-bootstrap';
// import Logo from '../../assets/images/Image 6@2x.png';
import { HeaderProps } from '../../models/header/props';
import { HeaderState } from '../../models/header/state';
import { withRouter } from 'react-router-dom';
import { AffiliateSignIn, ChangePassword, CorporateSignIn, NotificationPreference, SignOut, UserSignIn } from '../../constants/app-constants';
import AuthModal from '../modals/auth-modal';
import { NavDropdown } from 'react-bootstrap';
import UpdatePassword from '../profile/change-password';
import Preferences from '../profile/preferences';
import redirectToWP from '../../Utilities/common';
import { createEventGA4 } from '../../Utilities/helpers';
const Logo = process.env.REACT_APP_LOGO_URL;


class Header extends React.Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
        // tslint:disable-next-line:no-any
        super(props);
        this.state = {
            showLoginPopup: false,
            showChangePassword: false,
            showPreferences: false,
            menuName: '',
            navData: []
        };
    }

    componentDidMount = async () => {
        await this.props.getNavigationData();
        await this.updateNavData();
        await this.getNotificationData();

    }

    componentDidUpdate = async (prevProps: any) => {
        if (prevProps !== this.props) {
            if (prevProps.updateNavigation !== this.props.updateNavigation) {

                await this.props.getNavigationData();
                await this.updateNavData();
            }
            if (prevProps.isUserDeleted !== this.props.isUserDeleted) {
                if (this.props.isUserDeleted) {
                    this.props.updateUserDeleteAccount();
                    this.singOut();
                }
                
            }
        }
    }

    async getNotificationData() {
        let user = sessionStorage.getItem('user');
        let userId = 0;
        if (user != null && user != undefined) {
            let userObj = JSON.parse(user);
            userId = userObj.id;
        }
        await this.props.getNotificationData(userId);
    }

    singOut = async () => {
        await this.props.logOutUser();
        // localStorage.removeItem('user');
        sessionStorage.removeItem('user');
        await this.updateNavData();
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('refresh_token');
        sessionStorage.removeItem('isLoggedIn');
        sessionStorage.clear();
        this.createLogoutEvent();
        this.props.history.push('/');
    }

    createLogoutEvent = () => {
        const obj: any = {
            'eventName': 'logout',
            'event': 'logout'
        }
        createEventGA4(obj);
    }

    updateNavData = async () => {
        // console.log("nav data", this.props.navigationData);


        if (this.props.navigationData) {

            let navData: any = JSON.parse(JSON.stringify(this.props.navigationData));

            if (this.props.navigationData != undefined && this.props.navigationData != null && this.props.navigationData.data) {


                let findSignInIdx = navData.data.findIndex((el: any) => el.menu_name === 'Sign In');
                let findSignOutIdx = navData.data.findIndex((el: any) => el.menu_name === 'Sign Out' && el.parent_id == 0);

                this.setState({ showLoginPopup: false });

                let user: any = sessionStorage.getItem('user');
                let userSignedIn = false;
                if (user) {
                    user = JSON.parse(user);
                    if (typeof (user) === 'object') {
                        if (Object.entries(user).length > 0 && user.id) {
                            userSignedIn = true;
                        }
                    }
                }

                if (this.props.isUserLogin || userSignedIn) {
                    navData.data[findSignInIdx].is_active = false;
                    navData.data[findSignOutIdx].is_active = true;
                    // added for mock user APi
                    // signoutId = navData.data[findSignOutIdx].id;
                    // navData.data.push(
                    //     { "id": 57, "menu_url": "/profile", "is_parent": true, "parent_id": signoutId, "menu_name": "Profile", "is_active": true, "created_date": "2020-09-25T14:44:03.485Z", "menu_order": 3, "is_header_menu": true, "is_authenticated": false },
                    //     { "id": 55, "menu_url": "/", "is_parent": true, "parent_id": signoutId, "menu_name": "Notification Preferences", "is_active": true, "created_date": "2020-09-25T14:44:03.485Z", "menu_order": 3, "is_header_menu": true, "is_authenticated": false },
                    //     { "id": 56, "menu_url": "/", "is_parent": true, "parent_id": signoutId, "menu_name": "Change Password", "is_active": true, "created_date": "2020-09-25T14:44:03.485Z", "menu_order": 3, "is_header_menu": true, "is_authenticated": false },
                    //     { "id": 58, "menu_url": navData.data[findSignOutIdx].menu_url, "is_parent": true, "parent_id": signoutId, "menu_name": "Sign Out", "is_active": true, "created_date": "2020-09-25T14:44:03.485Z", "menu_order": 3, "is_header_menu": true, "is_authenticated": false }
                    // )

                } else {
                    navData.data[findSignInIdx].is_active = true;
                    navData.data[findSignOutIdx].is_active = false;
                }

                // code related to submenu satrts here

                // added dummy childs to menu

                // navData.data.push({ "id": 55, "menu_url": "#", "is_parent": true, "parent_id": 0, "menu_name": "Join", "is_active": true, "created_date": "2020-09-25T14:44:03.485Z", "menu_order": 3, "is_header_menu": true, "is_authenticated": false },
                //     { "id": 56, "menu_url": "#", "is_parent": true, "parent_id": 55, "menu_name": "Affiliate", "is_active": true, "created_date": "2020-09-25T14:44:03.485Z", "menu_order": 3, "is_header_menu": true, "is_authenticated": false },
                //     { "id": 57, "menu_url": "#", "is_parent": true, "parent_id": 55, "menu_name": "Corporate", "is_active": true, "created_date": "2020-09-25T14:44:03.485Z", "menu_order": 3, "is_header_menu": true, "is_authenticated": false },
                //     { "id": 58, "menu_url": "#", "is_parent": true, "parent_id": 55, "menu_name": "Ride Rewards", "is_active": true, "created_date": "2020-09-25T14:44:03.485Z", "menu_order": 3, "is_header_menu": true, "is_authenticated": false });

                await navData.data.map((item: any, index: any) => {
                    if (item.parent_id != 0) {
                        let parentIndex = navData.data.findIndex((el: any) => el.id === item.parent_id);
                        const itemData = JSON.parse(JSON.stringify(item));
                        if (navData.data[parentIndex].sub_menu && navData.data[parentIndex].sub_menu.length) {
                            const isFound = navData.data[parentIndex].sub_menu.findIndex((el: any) => el.id == itemData.id);
                            if (isFound == -1) {
                                navData.data[parentIndex].sub_menu.push(itemData);
                            }
                            item.is_active = false;
                        } else {
                            navData.data[parentIndex].sub_menu = [];
                            navData.data[parentIndex].sub_menu.push(itemData);
                            item.is_active = false;
                        }
                    }
                });



                //ends here

                await this.props.updateNavigationData(navData);
            }
        }
    }

    redirectTo = (url: string, menuName: string, menuId = 0) => {
        const dataLayerObj: any = {
            'eventName': 'navigation',
            'navName': `${menuName.toLowerCase()}`,
            'navPosition': 'top',
            'event': 'navigation'
        };
        createEventGA4(dataLayerObj);
        switch (menuName) {
            case UserSignIn:
                this.props.updateSignIn();
                this.setState({ showLoginPopup: true });
                break;
            case ChangePassword:
                this.setState({ showChangePassword: true });
                break;
            case NotificationPreference:
                this.getNotificationData();
                this.setState({ showPreferences: true });
                break;
            case 'About Us':
                redirectToWP('ABOUT_US');
                break;
            case 'Affiliate':
                redirectToWP('AFFILIATE');
                break;
            case 'Corporate':
                redirectToWP('CORPORATE');
                break;
            case 'FAQs':
                redirectToWP('FAQS');
                break;
            case 'Ride Rewards':
                redirectToWP('RIDE_REWARDS');
                break;
            case 'Blog':
                redirectToWP('BLOG');
                break;
            case 'Events':
                redirectToWP('EVENTS');
                break;
            case 'Travel Agent':
                redirectToWP('TRAVEL_AGENT');
                break;
            case CorporateSignIn:
                this.redirectToConcierge('LOGIN');
                break;
            case AffiliateSignIn:
                this.redirectToAffiliate('LOGIN');
                break;
            case 'ESG':
                redirectToWP('ESG');
                break;
            case SignOut:
                this.singOut();
                break;
            default:
                this.props.history.push(url);
                break;
        }
        // if (menuName === SignIn) {
        //     this.setState({ showLoginPopup: true });
        // } else if (menuName === SignOut) {
        //     this.singOut();
        // } else if (menuName === ChangePassword) {
        //     this.setState({ showChangePassword: true });
        // } else if (menuName === NotificationPreference) {
        //     this.setState({ showPreferences: true });
        // } else {
        //     this.props.history.push(url);
        // }

        // if (menuName === 'About Us') {
        //    return redirectToWP('ABOUT_US');
        // }

    }

    redirectToConcierge = (page: string) => {
        const CN_URL: string = process.env.REACT_APP_CONCIERGE_URL || '';
        
        // As all links to be opened in new tab.
        window.open (`${CN_URL}`, '_blank');
    }

    redirectToAffiliate = (page: string) => {
        const CN_URL: string = process.env.REACT_APP_AFFILIATE_URL || '';
        
        // As all links to be opened in new tab.
        window.open (`${CN_URL}`, '_blank');
    }

    closeModal = async () => {
        this.setState({ showLoginPopup: false });

        // this.setState({ showLoginPopup: false, isUserLoggedIn: true }, async () => {
        //     console.log("isUserLoggedIn", this.state.isUserLoggedIn);
        //     await this.updateNavData();
        // });
    }

    closeCpModal = async () => {
        this.setState({ showChangePassword: false });
    }

    closePrefModal = async () => {
        this.setState({ showPreferences: false });
    }


    render() {

        let user = sessionStorage.getItem('user');
        let userId = 0;
        let sigoutMenu = '';
        if (user != null && user != undefined) {
            let userObj = JSON.parse(user);
            sigoutMenu = "Welcome " + userObj.first_name;
            userId = userObj.id;
        } else {
            sigoutMenu = "Welcome user";
        }

        return (
            <div>
                <Row lg={12}>
                    <Navbar bg="light" collapseOnSelect expand="lg" variant="light" style={{ width: "100%", height: '100%', color: '#fff', background: '#17121A !important' }}>
                        <Navbar.Brand>
                            <div className="pl-2">
                                <img
                                    onClick={() => this.props.history.push('/')}
                                    alt="Book Luxury Car Service"
                                    src={Logo}
                                    height="48px"
                                    width="184px"
                                    className="d-inline-block align-top pointer"
                                />
                                <div style={{ display: 'inline-flex', marginTop: '24px', marginLeft: '-10px' }}>
                                    {/* <div className="header-ride">RIDE</div> */}
                                    <span className="header-luxy">
                                        <a className="footer-nav-menu" href="tel:+1 833-438-5899"> 
                                        833.438.5899
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className=" ml-auto">
                                {
                                    (this.props.navigationData && this.props.navigationData.data)
                                    &&
                                    this.props.navigationData.data.map((data: any, i: number) => {
                                        if (data.is_active) {
                                            if (data.sub_menu && data.sub_menu.length) {
                                                return <NavDropdown
                                                    key={'d_' + i} className="nav-dropdown" title={(data.menu_name == SignOut) ? sigoutMenu : data.menu_name} id={'basic-nav-dropdown'+i}>

                                                    {
                                                        data.sub_menu.map((smenu: any, index: any) => {
                                                            return <NavDropdown.Item
                                                                key={'s_' + index}
                                                                onClick={() => this.redirectTo(smenu.menu_url, smenu.menu_name, smenu.id)}
                                                            >{smenu.menu_name}</NavDropdown.Item>

                                                        })
                                                    }
                                                </NavDropdown>

                                            } else {
                                                return <Nav.Link className="nav-menu"
                                                    key={i}
                                                    onClick={() => this.redirectTo(data.menu_url, data.menu_name)}
                                                >{data.menu_name}</Nav.Link>
                                            }

                                        }

                                    })
                                }

                            </Nav>

                        </Navbar.Collapse>
                    </Navbar>
                </Row>
                {
                    (this.state.showLoginPopup) ?
                        <AuthModal
                            updateNavData={() => this.updateNavData()}
                            closeModal={() => this.closeModal()} />
                        : null
                }

                {
                    (this.state.showChangePassword) ? <UpdatePassword userId={userId} closeModal={() => this.closeCpModal()} /> : null
                }

                {
                    (this.state.showPreferences) ? <Preferences
                        notificationData={this.props.notificationData}
                        userId={userId} closeModal={() => this.closePrefModal()} /> : null
                }

            </div>
        )
    }
}

export default withRouter(Header);
// export default Header;