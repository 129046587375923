export const GET_HEADER_MENUS = 'GET_HEADER_MENUS';
export const IS_UPDATE_NAVIGATION_DATA = 'IS_UPDATE_NAVIGATION_DATA';
export const UPDATE_NOTIFICATION_DATA = 'UPDATE_NOTIFICATION_DATA'

export function navigationReducer(state: any = {}, action: { type: string, data?: any }) {

    switch (action.type) {
        case GET_HEADER_MENUS:
            return {
                ...state,
                navigationData: action.data
            };
        case IS_UPDATE_NAVIGATION_DATA:
            return {
                ...state,
                updateNavigation: action.data
            }
        case UPDATE_NOTIFICATION_DATA:
            return {
                ...state,
                notificationData: action.data
            }
        default:
            return state;
    }
}