import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import configureStore from './store';
import { BrowserRouter } from 'react-router-dom';
import axiosGlobalConfig from './axios/config';
import './assets/font/stylesheet.css';
import ScrollToTop from './components/layout/scroll-top';

/**
 * Configure the store.
 */
const store = configureStore();

axiosGlobalConfig();
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
    <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
