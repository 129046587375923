import React from 'react';
import { Link } from 'react-router-dom';
import redirectToWP from '../../Utilities/common';

interface AgreeProps {
    type?: string
    isPartner?: string
    partnerPDF?: string
}

interface AgreeState {

}

export class AgreeText extends React.Component<AgreeProps, AgreeState>{
    constructor(props: AgreeProps){
            super(props);
            this.state = {

            }
    }
    redirectTo = (menuName: string) => {
        switch (menuName) {
            case 'PRIVACY':
                redirectToWP('PRIVACY');
                break;
            default:
                return '#';
        }
        return '#';
    }
    render() {
        // target = { "_blank"}
        return (<> { this.props.type ? 
        <>
        { this.props.isPartner == "true" ?
        <span>Agree to partner service agreement. <a href={this.props.partnerPDF} target='_blank' download>Download (Partner Service Agreement)</a></span>:
        <span>By checking here, you are agreeing to our <Link target={"_blank"} to={"/privacy-policy"}>privacy policy.</Link></span>}
        </> : 
        <span>By checking this box, you acknowledge that you have read our updated  <Link target={"_blank"} to={"/terms"}>terms of service</Link>, 
        <Link  to={"#"} onClick={() => this.redirectTo('PRIVACY')}>privacy policy</Link> and cookie policy, and that your continued use of the website is subject
        to those policies.</span>}
        </>)
    }
}

export default AgreeText;