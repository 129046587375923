// import axios from 'axios';
import React, { Component } from 'react';
// import config from 'react-global-configuration';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import resetPasswordService from '../../service/ResetPasswordService';
// import { USER_TYPE } from '../../Consts';
import { checkPassword } from '../../Utilities/password';
// import { isAbsoluteUrl } from '../../Utilities/UrlUtilities';
import { RouteComponentProps } from 'react-router';
import { AuthService } from '../../services/auth';
import './forgot-style.css';
import logo from '../../assets/images/Image 6@2x.png';
import { passwordFormatString } from '../../Consts';

interface ForgotProps extends RouteComponentProps<any> {

}

interface ForgotState {
    fields: any;
    errors: any;
    newpwdtype: string;
    newpwdwording: string;
    confirmpwdtype: string;
    confirmpwdwording: string;
    newpwdicon: string;
    conpwdicon: string;
    post: any;
    postId: any;
}

class ForgetPassword extends Component<ForgotProps, ForgotState> {
    constructor(props: ForgotProps) {
        super(props);
        this.state = {
            ...this.state,
            fields: {},
            errors: {},
            newpwdtype: 'password',
            newpwdwording: 'Show',
            confirmpwdtype: 'password',
            confirmpwdwording: 'Show',
            newpwdicon: 'fa fa-eye',
            conpwdicon: 'fa fa-eye',
            post: { email_address: '', user_id: '', forgot_confirm_pwd: '', new_password: '' },
            postId: this.props.match.params.postId
        };
        this.changeNewPwdState = this.changeNewPwdState.bind(this);
        this.changeConfirmPwdState = this.changeConfirmPwdState.bind(this);
        this.CheckURLExpiration = this.CheckURLExpiration.bind(this);
    }

    componentDidMount() {
        this.CheckURLExpiration(); //Added for checking weather the link is expired or not
    }

    async CheckURLExpiration() {
        var formatted_token = this.props.match.params.postId;
        var from = "forgot_pwd";
        var data = {
            token: formatted_token,
            from: from
        };
        try {
            let verifyExpiration = await AuthService.verifyLinkExpiration(data);
            if (verifyExpiration.data != undefined && verifyExpiration.data.data != undefined) {
                if (verifyExpiration.data.data[0].check_link_expiration == true) {
                    this.props.history.push('/expired');
                }
            }
        } catch (error) {
            toast.error("Can't verify Link.");
        }
    }

    changeNewPwdState() {
        var oldState = this.state.newpwdtype;
        var isTextOrHide = (oldState === 'password');
        var newState = (isTextOrHide) ? 'text' : 'password';
        var newWord = (isTextOrHide) ? 'Hide' : 'Show';
        this.setState({
            newpwdtype: newState,
            newpwdwording: newWord
        })
        if (newState == "text") {
            this.setState({ newpwdicon: 'fa fa-eye-slash' });
        } else {
            this.setState({ newpwdicon: 'fa fa-eye' });
        }
    }

    changeConfirmPwdState() {
        var oldState = this.state.confirmpwdtype;
        var isTextOrHide = (oldState === 'password');
        var newState = (isTextOrHide) ? 'text' : 'password';
        var newWord = (isTextOrHide) ? 'Hide' : 'Show';
        this.setState({
            confirmpwdtype: newState,
            confirmpwdwording: newWord
        })
        if (newState == "text") {

            this.setState({ conpwdicon: 'fa fa-eye-slash' });
        } else {
            this.setState({ conpwdicon: 'fa fa-eye' });
        }
    }

    handleChange(field: any, e: any) {
        const post = Object.assign({}, this.state.post, { [field]: e.target.value });
        this.setState(Object.assign({}, this.state, { post }));
    }

    handleSubmit(e: any) {
        e.preventDefault();
        if (!this.handleValidation()) {
            toast.error("Please check all required fields and validation.", {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        this.state.post.email_address = this.state.postId;
        this.saveorupdate(this.state.post);
    }

    handleValidation() {
        let fields = this.state.post;
        let errors: any = {};
        let formIsValid = true;

        if (!fields["new_password"]) {
            formIsValid = false;
            errors["new_password"] = "New Password is required.";
        }
        else if (checkPassword(fields["new_password"]) == false) {
            formIsValid = false;
            errors["new_password"] = passwordFormatString;
        }

        if (!fields["forgot_confirm_pwd"]) {
            formIsValid = false;
            errors["forgot_confirm_pwd"] = "Confirm Password is required.";
        }
        if (fields["new_password"] != "" && fields["forgot_confirm_pwd"] != "") {
            if (fields["new_password"] != fields["forgot_confirm_pwd"]) {
                formIsValid = false;
                errors["Not_Matching_pwd"] = "New Password and Confirm Password should be same.";
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    getRedirectURL(userTypeId: any) {
        // return userTypeId === USER_TYPE.CUSTOMER
        //     ? config.get("MAIN_SITE")
        //     : '/login';
        return;
    }


    async saveorupdate(data: any) {
        try {
            await AuthService.customerResetPassword(data);

            toast.success("Your password changed successfully");
            setTimeout(() => {
                this.props.history.push('/');
            }, 2000);
        } catch (error) {
            toast.error("Something went wrong changing your password.");
        }
    }

    render() {
        return (
            <div className="wrapper">
                <div className="wrapper loginWrapper">
                    <div className="loginContainer clearfix">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="logoHead mt-4">
                                    <img src={logo} className="logoSize" />
                                </div>
                                <div className="loginBody mb-4 pb-4 resPwd">
                                    <form onSubmit={this.handleSubmit.bind(this)} data-hs-do-not-collect>
                                        <h4 className="topText">Reset Password</h4>
                                        <div className="row">
                                            <div> <ToastContainer /></div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleFormControlInput1">New Password<span className="importantfields">*</span></label>
                                                    <input type={this.state.newpwdtype} value={this.state.post.new_password} onChange={this.handleChange.bind(this, 'new_password')} defaultValue="" className="form-control" />
                                                    {/* <span className="password-trigger c-trigger" onClick={this.changeNewPwdState}><i className={this.state.newpwdicon} aria-hidden="true"></i></span> */}
                                                    <span className="error">{this.state.errors["new_password"]}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleFormControlInput1">Confirm Password<span className="importantfields">*</span></label>
                                                    <input type={this.state.confirmpwdtype} value={this.state.post.forgot_confirm_pwd} onChange={this.handleChange.bind(this, 'forgot_confirm_pwd')} defaultValue="" className="form-control" />
                                                    {/* <span className="password-trigger c-trigger" onClick={this.changeConfirmPwdState}><i className={this.state.conpwdicon} aria-hidden="true"></i></span> */}
                                                    <span className="error">{this.state.errors["forgot_confirm_pwd"]}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="error">{this.state.errors["Not_Matching_pwd"]}</span>
                                        <div className="row">
                                            <div className="col-12 text-center mt-3 mb-2">
                                                <Link to='/'>
                                                    <button type="button" className="btn-outline-orange btn-cancel btn-reg mb-3 w-100">Cancel</button>
                                                </Link>
                                                <button type="submit" className="btn-outline-orange btn-reg mb-3 w-100">Reset</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgetPassword;
