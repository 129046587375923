import React from 'react';
import Tab from 'react-bootstrap/esm/Tab';
import Tabs from 'react-bootstrap/esm/Tabs';
import Accordion from 'react-bootstrap/Accordion'
import Trip from './trip';
import { MESSAGES } from '../../Consts';

interface TripsProps {
    pastTripData: any;
    upcomingTripData: any;
    inprogressTripData: any;
    addonsList: any;
    handleTripRating: (rating: any, tripId: any) => any;
    handlePdfDownload: (data: any) => any;
}

interface TripsState {
    key: string;

}

class Trips extends React.Component<TripsProps, TripsState>{
    constructor(props: TripsProps) {
        super(props);
        this.state = {
            key: 'upcoming',
        }
    }

    componentDidMount() {

    }

    setKey = (key: any) => {
        this.setState({ key: key });
    }
    render() {
        const key = this.state.key;
        let { pastTripData, upcomingTripData, inprogressTripData } = this.props;
        // pastTripData = (pastTripData.length > 10) ? pastTripData.slice(0, 10) : pastTripData;
        // upcomingTripData = (upcomingTripData.length > 10) ? upcomingTripData.slice(0, 10) : upcomingTripData;
        // inprogressTripData = (inprogressTripData.length > 10) ? inprogressTripData.slice(0, 10) : inprogressTripData;
        return <div className="trips-tab m-4">
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => this.setKey(k)}
            >
                <Tab eventKey="upcoming" title="UPCOMING TRIPS" className="modal-title">
                    <div className="mt-0 scroll-tab">
                        <Accordion defaultActiveKey="upcoming_0" className="tab-content-style">
                            {
                                (upcomingTripData && upcomingTripData.length) ?
                                    upcomingTripData.map((trip: any, index: any) => {
                                        return <Trip key={'upcoming_trip_' + index} trip={trip} index={index} type={'upcoming'} addonsList={this.props.addonsList} 
                                        handleTripRating={this.props.handleTripRating}
                                        handlePdfDownload ={this.props.handlePdfDownload} />
                                    })
                                    : <span>{MESSAGES.NO_UPCOMING_TRIPS}</span>
                            }
                        </Accordion>
                    </div>
                </Tab>
                <Tab eventKey="inprogress" title="IN-PROGRESS TRIPS" className="modal-title">
                    <div className="mt-0 tab-content-style scroll-tab">
                        <Accordion defaultActiveKey="inprogress_0">
                            {
                                (inprogressTripData && inprogressTripData.length) ?
                                    inprogressTripData.map((trip: any, index: any) => {
                                        return <Trip key={'inprogress_trip_' + index} trip={trip} index={index} type={'inprogress'} addonsList={this.props.addonsList} handleTripRating={this.props.handleTripRating}  handlePdfDownload ={this.props.handlePdfDownload} />
                                    })
                                    : <span>{MESSAGES.NO_INPROGRESS_TRIPS}</span>
                            }
                        </Accordion>
                    </div>
                </Tab>
                <Tab eventKey="past" title="PAST TRIPS">
                    <div className="mt-0 tab-content-style scroll-tab">
                        <Accordion defaultActiveKey="past_0">
                            {
                                (pastTripData && pastTripData.length) ?
                                    pastTripData.map((trip: any, index: any) => {
                                        return <Trip key={'past_trip_' + index} trip={trip} index={index} type={'past'} addonsList={this.props.addonsList} handleTripRating={this.props.handleTripRating}  handlePdfDownload ={this.props.handlePdfDownload} />
                                    })
                                    : <span>{MESSAGES.NO_PAST_TRIPS}</span>
                            }
                        </Accordion>
                    </div>
                </Tab>

            </Tabs>
        </div>
    }
}

export default Trips;
