import { connect } from "react-redux";
import Profile from "../components/profile/profile";
import { ADDONS_DATA, UPDATE_INPROGRESS_TRIPS, UPDATE_PAST_TRIPS, UPDATE_UPCOMING_TRIPS, UPDATE_USER_INFO } from "../reducer/profile";
import RideService from "../services/RideService";
import * as UserService from '../services/user';
import { USER_DELETE } from '../reducer/user';

type Dispatch = (action: any) => void;

const getUserInfo = (id: any) => {
    return async (dispatch: Dispatch) => {
        let vData = await UserService.UserService.getUserById(id);
        // console.log(vData, 'vData');
        if (vData.data && vData.data.data && vData.data.data.length) {
            dispatch({
                type: UPDATE_USER_INFO, data: vData.data.data[0]
            });
        } else {
            dispatch({
                type: UPDATE_USER_INFO, data: {}
            });
        }
    }
}

const updateUserInfo = () => {
    return async (dispatch: Dispatch) => {
            dispatch({
                type: UPDATE_USER_INFO, data: {}
            });
        }
}

const getPastTripData = (id: any) => {
    return async (dispatch: Dispatch) => {
        let vData = await UserService.UserService.getPastTripData(id);
        if (vData.data && vData.data.data && vData.data.data.length) {
            dispatch({
                type: UPDATE_PAST_TRIPS, data: vData.data.data
            });

        } else {
            dispatch({
                type: UPDATE_PAST_TRIPS, data: []
            });
        }
    }
}

const getUpcomingTripData = (id: any) => {
    return async (dispatch: Dispatch) => {
        let vData = await UserService.UserService.getUpcomingTripData(id);
        if (vData.data && vData.data.data && vData.data.data.length) {
            dispatch({
                type: UPDATE_UPCOMING_TRIPS, data: vData.data.data
            });

        } else {
            dispatch({
                type: UPDATE_UPCOMING_TRIPS, data: []
            });
        }
    }
}

const getInprogressTripData = (id: any) => {
    return async (dispatch: Dispatch) => {
        let vData = await UserService.UserService.getInprogressTripData(id);
        if (vData.data && vData.data.data && vData.data.data.length) {
            dispatch({
                type: UPDATE_INPROGRESS_TRIPS, data: vData.data.data
            });

        } else {
            dispatch({
                type: UPDATE_INPROGRESS_TRIPS, data: []
            });
        }
    }
}

const getAddons = () => {
    return async (dispatch: Dispatch) => {
        let addonsData = await RideService.getAddons();
        // console.log(addonsData, 'chargesData');
        if (addonsData.data.data && addonsData.data.data != null) {
            dispatch({
                type: ADDONS_DATA, data: addonsData.data.data
            });
        } else {
            dispatch({
                type: ADDONS_DATA, data: []
            });
        }
    }
}

const updateUserDeleteAccount = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: USER_DELETE, data: true
        });
    }
}

const mapStateToProps = (state: any) => {
    return {
        isUserLogin: state.user.isUserLogin,
        userInfo: state.profile.userInfo,
        pastTripData: state.profile.pastTripData,
        upcomingTripData: state.profile.upcomingTripData,
        inprogressTripData: state.profile.inprogressTripData,
        addonsList: state.profile.addonsList,
        rideRewardsConfigData: state.configurations.rideRewardsConfigData
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getUserInfo: (id: any) => dispatch(getUserInfo(id)),
        getPastTripData: (id: any) => dispatch(getPastTripData(id)),
        getUpcomingTripData: (id: any) => dispatch(getUpcomingTripData(id)),
        getInprogressTripData: (id: any) => dispatch(getInprogressTripData(id)),
        getAddons:() => dispatch(getAddons()),
        updateUserInfo: () => dispatch(updateUserInfo()),
        updateUserDeleteAccount: () => dispatch(updateUserDeleteAccount()),
    }
}

const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default ProfileContainer;