import React from "react";
import { Row, Col } from "react-bootstrap";
import ChangeVehicleContainer from "../../containers/change-vehicle";
import RideDetailsContainer from "../../containers/ride-details";
import PassengerDetails from "../passenger/passenger";
import InvoiceContainer from "../../containers/invoice";
import Addons from "../ride-details/addons";
import { addOnNames } from '../../constants/app-constants';
import PaymentDetails from "../../containers/payment-info";
import PromoCodeContainer from "../ride-details/promo-code";
import PaymentService from "../../services/PaymentService";
import { StatusCode } from "../../constants/status-codes";
import * as Helpers from '../common/helpers';
import { toast } from "react-toastify";
import { AuthService } from "../../services/auth";
import { DATE_FORMAT } from "../../Consts";
import moment from "moment";
// import { pickUpDateInputFormatter } from "../../Utilities/DateUtilities";
import { getTimezoneDatetime, pickUpDateFormatter, timeFormatter } from "../../Utilities/DateUtilities";
import OtherVehicles from "../why-luxy/other-vehicles";
import { trackPromise } from "react-promise-tracker";
// import {Prompt, RouteComponentProps} from 'react-router-dom';
import * as Constant from '../../Consts';
import ErrorPopUp from "../modals/error";
import { createEcommerceGA4, createEventGA4, createPurchaseEventGA4, getPlaceCodeNdState, prepareBeginCheckout, preparePurchaseEvent, SelectedVehicleObj, userFromLocalStorage, sendToIREEvent} from "../../Utilities/helpers";
import RewardPointsContainer from "../reward-points/reward-points";
import RewardsToolBarContainer from "../reward-points/rewards-tool-bar";
import BookingForContainer from "../../containers/booking-for";
import ConfigurationService from "../../services/ConfigurationService";
const banner6 = require('../../assets/images/webpimages/FC-Platinum-Seal.webp');

interface DetailsProps {
    getCharges: () => any;
    getAddons: () => any;
    updateAddons: (data: any) => any;
    updateSelectedAddons: (data: any) => any;
    updateMeetandGreetData: (data: any) => any;
    updateChildSeatValues: (data: any) => any;
    updateBookingState: (data: any) => any;
    reservationCharges: any;
    bookingDetails: any;
    addonsData: any;
    selectedAddons: any;
    meetAndGreetData: any;
    childSeatValues: any;
    getAirlines: () => any;
    createPaymentMethod: () => any;
    brainTreeCustomerId: any;
    paymentCreatedData: any;
    savedPaymentMethodData: any;
    savePaymentMethodDetails: (brainTreePaymentData: any, userId: number) => any;
    user: any;
    airLines: any;
    createReservation: (createReservation: any) => any;
    createReservationData: any;
    createTransaction: (data: any) => any;
    invoiceAmountData: any;
    sendTripMail: (transactionData: any, trip_id: any) => any;
    updateConfirmBookingValidation: (data: any) => any;
    getUserPaymentMethods: (userId: any) => any;
    clearContinueAsGuest: () => any;
    createOrder:(createOrder:any)=>any;
    createOrderData:any;
      //region buffer code begins
    vehicleErrorMessage?:any;
    vehicleListError?: any;
    carSeatTypes:any;
    promoCodeDetails:any;
    vehiclesList: any;
      //region buffer code begins
}
interface DetailsState {
    bookingDetails: any;
    showChangeVehicle: boolean;
    vehiclesList: any;
    showAirlines: boolean;
    saveForFuturePayments: boolean;
    disableConfirmBooking: boolean;
    showPromoCode: boolean;
    showMag: boolean;
    isMilesIssue:boolean;
    isSelected:boolean;
    showRideRewards: boolean;
    disableRideRewards: boolean;
    carSeatTypes:any;
    isChildSeatToggleOn: boolean;
    isFAQConfirmation: boolean;
    configurationsNamesObj: any;
}

class Details extends React.Component<any, DetailsState> {
    public childRef: any = React.createRef();
    public promoCodeRef: any = React.createRef();
    constructor(props: any) {
        super(props);
        this.state = {
            bookingDetails: {},
            showChangeVehicle: false,
            vehiclesList: {},
            showAirlines: false,
            saveForFuturePayments: false,
            disableConfirmBooking: false,
            showPromoCode: false,
            showMag: false,
            isMilesIssue : false,
            isSelected: false,
            showRideRewards: false,
            disableRideRewards: true,
            carSeatTypes:[],
            isChildSeatToggleOn: false,
            isFAQConfirmation: false,
            configurationsNamesObj:{}
        }
    }

    closePromoCodeToggle = (isFromAlert:any) => {
        this.setState({ showPromoCode: false },()=>{
            if(isFromAlert){
                if (this.props.vehiclesList && this.props.vehiclesList.length) {
                    const selectedVehicle = this.props.vehiclesList.filter((vehicle: any) => vehicle.vehicle_type_id == this.state.bookingDetails.selectedVehicleId);
    
                    let updatedBookingDetails = this.state.bookingDetails;
                    updatedBookingDetails.selectedVehicle = selectedVehicle[0];
                    updatedBookingDetails.selectedVehicleId = selectedVehicle[0].vehicle_type_id;
    
                    this.setState({ bookingDetails: updatedBookingDetails, }, async () => {
                        // console.log('bookingDetails',bookingDetails)
                        this.props.updateBookingState({
                            ...this.state.bookingDetails
                           
                        });
                      
                    });
    
                }
            }
        });
    }
    setChildSeatToggle = (isChildSeatToggleOn: any) =>  {
        this.setState({ isChildSeatToggleOn: isChildSeatToggleOn });
    }

    formatAddress = (address: any) => {
        return {
            placeName: address.address.full_address,
            city: address.address.city,
            state: address.address.state,
            timezone: address.timezone,
            airportCode: address.place.airport_code,
            zipCode: address.address.postal_code
        }
    }

    checkIsValidTripDetails = (pickup:any={},dropoff:any={},pickupTime:any='',pickupDate :any = '',serviceRateId:any=0) =>{

    
     if((typeof pickup === 'object' && Object.keys(pickup).length ===0 )|| 
    (typeof dropoff === 'object' && Object.keys(dropoff).length === 0 )){
        toast.error(`please select route addresses`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return false;
    }else if(!pickupTime || !pickupDate){
        toast.error(`please select date and time`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return false;
    }else if(serviceRateId == 0){
        toast.error(`please select vehicle`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return false;
    }else{
        return true
    }  
    }

    checkMilesIssue = (totalMiles:any=0,totalHours:any=0) =>{
        if(totalMiles ==0 || totalHours == 0){
            this.setState({isMilesIssue:true})
            return false;
        }
        return true
    }

    //get reservation charges
    getReservationCharges = async () => {
        const bookingDetails = (this.state.bookingDetails) ? this.state.bookingDetails : this.props.bookingDetails;
        // console.log('bookingDetails state', this.state.bookingDetails);
        // console.log('bookingDetails props', this.props.bookingDetails);
        const user = userFromLocalStorage();

        // extrastops
        let extraStops = [];
        if (bookingDetails && bookingDetails.pickup != "" && bookingDetails.dropoff != "") {
            if (bookingDetails.extra_stops && bookingDetails.extra_stops.length) {
                for (let i = 0; i < bookingDetails.extra_stops.length; i++) {
                    let address = {};
                    address = this.formatAddress(bookingDetails.extra_stops[i]);
                    extraStops.push(address);
                }

            }

            let addons = this.prepareAddonPayload(this.props.selectedAddons);
            // addons
            // {
            //     arrivalMeetAndGreet: {},
            //     travelingWithPet: {},
            //     childSeat: {},
            //     tripProtection: false
            // }


            if (bookingDetails.selectedVehicle && bookingDetails.selectedVehicle.service_rate_id) {
                let promoCodeLcDataRaw = sessionStorage.getItem('promoCodeData');
                let promo_code_id = 0;

                if(promoCodeLcDataRaw !='' &&  promoCodeLcDataRaw!= undefined && promoCodeLcDataRaw !=null){
                    const promoCodeLcDataParsed: any = JSON.parse(promoCodeLcDataRaw);
                    // console.log("promoCodeLcData",promoCodeLcDataParsed.data.promo_code_id)
                    if(promoCodeLcDataParsed.data && promoCodeLcDataParsed.data.promo_code_id){
                       promo_code_id = parseInt(promoCodeLcDataParsed.data.promo_code_id)
                    }
                }
                let { time } = this.state.bookingDetails;
                const pickDate = pickUpDateFormatter(new Date(this.state.bookingDetails.pick_date_ptop));
                const picktime = timeFormatter(time);
                const dateString = `${pickDate} ${picktime}`;
                const timezoneDatetime = getTimezoneDatetime(dateString, this.state.bookingDetails.pickup.timezone);
                const userData: any = userFromLocalStorage();
                let userID = 0;
                if (userData && userData.id) {
                    userID = userData.id;
                }
                const payload = {
                    trips: [{
                        serviceRateId: bookingDetails.selectedVehicle.service_rate_id,
                        serviceType : bookingDetails.trip_type_with_id && bookingDetails.trip_type_with_id.type,
                        pickup: this.formatAddress(bookingDetails.pickup),
                        dropoff: this.formatAddress(bookingDetails.dropoff),
                        extraStops: extraStops,
                        route: {
                            totalMiles: (bookingDetails.distance_in_miles) ? Number(bookingDetails.distance_in_miles) : Number(sessionStorage.getItem('distance_in_miles')),
                            totalHours: (bookingDetails.total_hours) ? Number(bookingDetails.total_hours) : Number(sessionStorage.getItem('total_hours')),
                        },
                        pickupTime: bookingDetails.time,
                        pickupDate: timezoneDatetime,
                        addons: addons,
                        promoCodeId : promo_code_id,
                        application:'web',
                        rewardPoints: this.props.rewardPointsData.points,
                        bookingUserId: user.id,
                        userCorporateId: userID,
                        userTypeId: Constant.USER_TYPE.CUSTOMER,
                        is_from_backend: false,
                        isCustomPrice: false,
                        customPrice: 0,
                        isAffiliateCustomPrice: false,
                        affiliateCustomPrice: 0,
                        isEditMode: false,
                    }]
                }

                let trip : any = payload &&  payload.trips && payload.trips.length ?  payload.trips[0] : {route:{}}
                let isMilesIssue = this.checkMilesIssue(trip.route.totalMiles,trip.route.totalHours);
                if(!isMilesIssue) return false;
                let isValid: any  = this.checkIsValidTripDetails(
                    trip.pickup,trip.dropoff,trip.pickupTime,trip.pickupDate,trip.serviceRateId)
                // console.log("isValid===",isValid)
                if(!isValid) return false;
                await this.props.getCharges(payload);

                // no need to call api every time we will just pass promocode id to charges/v2 api
                // if(this.state.showPromoCode)
                // {
                //     let promoCodeLocal: any = localStorage.getItem('promoCodeData');
                //     let promoCodeObj: any = JSON.parse(promoCodeLocal);

                //     if(promoCodeObj && promoCodeObj.status && promoCodeObj.status === StatusCode.EVERYTHING_IS_OK) 
                //     {
                //         await this.promoCodeRef.applyPromoCode();
                //     }
                    
                // }

            }

        }

    }

    updateAddonsInfo = async (addonsData: any, selectedAddons: any) => {
        await this.props.updateAddons(addonsData);
        await this.props.updateSelectedAddons(selectedAddons);
        await this.getReservationCharges();
    }

    isAirportTrip = () => {
    if (this.props.bookingDetails &&
        this.props.bookingDetails.pickup
        && this.props.bookingDetails.pickup.place
        && this.props.bookingDetails.pickup.place.place_type === 'airport') {
        return true;
    } else {
        return false;
    }
    }

    prepareAddonPayload = (selectedAddons: any) => {
        let payload: any = {};

        // console.log(this.state.showAirlines, 'showAirlines')
        selectedAddons && selectedAddons.length && selectedAddons.map((addon: any) => {
            switch (addon.addon_name) {
                case addOnNames.arrivalMeetAndGreet:
                    payload['arrivalMeetAndGreet'] = this.isAirportTrip() ?{
                        id: addon.id,
                        name: this.props.meetAndGreetData.name,
                        notes: this.props.meetAndGreetData.notes
                    } : {id: 0}
                    break;
                case addOnNames.travelingWithPet:
                    payload['travelingWithPet'] = {
                        id: addon.id,
                    }
                    break;
                case addOnNames.childSeat:
                    const { childSeatValues } = this.props;
                    let filteredChildSeats = childSeatValues.filter((childSeat: any) => childSeat.id !== 0);
                    payload['childSeat'] = {
                        id: addon.id,
                        value: filteredChildSeats
                    }
                    break;
                default:
                    payload['tripProtection'] = true;
            }
            return payload;
        })

        return payload;
    }

    updateMeetandGreetData = async (data: any) => {
        await this.props.updateMeetandGreetData(data);
        // await this.getReservationCharges();
    }

    updateChildSeatData = async (data: any) => {
        await this.props.updateChildSeatValues(data);
        await this.getReservationCharges();
    }

    componentWillUpdate(nxtProps: any, nxtState: any) {
        // console.log('nxtState', nxtState)
    }
    async componentDidMount() {

        if(this.props.isUserLogin) {
            this.setState({ disableRideRewards: false});
            this.props.getUserRewards();
        }
        // let bookingDetailsLocalRaw = localStorage.getItem('bookingDetails');
        let bookingDetailsLocalRaw =sessionStorage.getItem('bookingDetails');
        let response = await ConfigurationService.getConfigurationByName("FAQC");
        if(response){
            this.setState({ configurationsNamesObj : response}, () => {
        })
        }
        // fetch airlines only once
        await this.props.getAirlines();

        // fetch car seat types
        await this.props.getCarSeatTypes();

        // if(promoCodeLcDataRaw !='' &&  promoCodeLcDataRaw!= undefined && promoCodeLcDataRaw !=null){
        //     const promoCodeLcDataParsed: any = JSON.parse(promoCodeLcDataRaw);
        //     console.log("promoCodeLcData",promoCodeLcDataParsed.data.promo_code_id)
        //     if(promoCodeLcDataParsed.data.promo_code_id){
        //         this.setState({
        //             showPromoCode : true
        //         })
        //     }
        // }

        // const beginCheckOutData: any = prepareBeginCheckout(vehicle, index);
        // createEcommerceGA4(selectedVehicle, ECOMMERCE.SELECT);
        if (bookingDetailsLocalRaw != '' && bookingDetailsLocalRaw != undefined && bookingDetailsLocalRaw != null) {
            const bookingDetailsLocalParsed: any = JSON.parse(bookingDetailsLocalRaw);


            let showAirlines = this.state.showAirlines;
            if (bookingDetailsLocalParsed.trip_type_with_id
                && bookingDetailsLocalParsed.trip_type_with_id.type == 'airport') {

                showAirlines = true;
            }

            this.setState({
                bookingDetails: bookingDetailsLocalParsed,
                vehiclesList: this.props.location.state.vehiclesList,
                showAirlines: showAirlines
            }, async () => {


                await this.props.updateBookingState(bookingDetailsLocalParsed);
                await this.props.getAddons();
                let selectedaddons:any = [];
                let updatedAddons = this.props.addonsData.map((addonData: any) => {
                    if (addonData.id == 4) {
                        addonData.isActive = true;
                        if (this.props.selectedAddons && this.props.selectedAddons.length) {
                            selectedaddons = this.props.selectedAddons.map((obj: any) => {
                                // console.log(obj)
                                if (obj && obj.id == 4) {
                                    addonData.isActive = true;
                                } else {
                                    addonData.isActive = false;
                                }
                                return addonData;
                            });
                        } else {
                            addonData.isActive = true;
                            selectedaddons.push(addonData);
                        }

                    }
                    return addonData;
                });
                await this.props.updateAddons(updatedAddons);
                await this.props.updateSelectedAddons(selectedaddons);
                if (bookingDetailsLocalParsed.addonsData && bookingDetailsLocalParsed.addonsData.length) {
                    await this.updateAddonsInfo(bookingDetailsLocalParsed.addonsData, bookingDetailsLocalParsed.selectedAddons);
                }

                if (bookingDetailsLocalParsed.meetAndGreetData && bookingDetailsLocalParsed.meetAndGreetData.name && bookingDetailsLocalParsed.meetAndGreetData.notes) {
                    await this.updateMeetandGreetData(bookingDetailsLocalParsed.meetAndGreetData);
                }

                if (bookingDetailsLocalParsed.childSeatValues && bookingDetailsLocalParsed.childSeatValues.length) {
                    await this.updateChildSeatData(bookingDetailsLocalParsed.childSeatValues);
                }
                await this.getReservationCharges();
                // console.log("booking0000", this.props.bookingDetails);
                this.GA4BeginCheckout(this.props.bookingDetails.selectedVehicle, this.props.bookingDetails.selectedVehicleIndex);
            });
        } else {
            if (this.props.location && this.props.location.state && this.props.location.state != undefined) {

                if (this.props.location.state.bookingDetails) {
                    let showAirlines = this.state.showAirlines;
                    if (this.props.location.state.bookingDetails.trip_type_with_id
                        && this.props.location.state.bookingDetails.trip_type_with_id.type == 'airport') {

                        showAirlines = true;
                    }
                    this.setState({
                        bookingDetails: this.props.location.state.bookingDetails,
                        vehiclesList: this.props.location.state.vehiclesList,
                        showAirlines: showAirlines
                    }, async () => {
                        await this.getReservationCharges();
                        await this.props.getAddons();
                        // console.log("booking00", this.props.bookingDetails);
                        this.GA4BeginCheckout(this.props.bookingDetails, this.props.bookingDetails.selectedVehicle);
                    });
                }
            } else {
                this.setState({
                    bookingDetails: this.props.bookingDetails,
                    vehiclesList: this.props.vehiclesList
                }, async () => {
                    await this.getReservationCharges();
                    await this.props.getAddons();
                    // console.log("booking11", this.props.bookingDetails);
                    // console.log("begin checkout", this.props.bookingDetails);
                    this.GA4BeginCheckout(this.props.bookingDetails, this.props.bookingDetails.selectedVehicle);
                });
            }

        }
        
    }

    componentDidUpdate(prevProps: any, prevState: any) {

        if (prevProps.isVehicleChange !== this.props.isVehicleChange) {
            console.log('this.props.vehiclesList444',this.props.vehiclesList)
            this.setState({
                vehiclesList: this.props.vehiclesList
            }, async () => {
                await this.getReservationCharges();
            });
        }



        if (prevProps.bookingDetails !== this.props.bookingDetails) {
            let isAirPort = false;
            let showMeetGreet = false;
            if (this.props.bookingDetails && this.props.bookingDetails.trip_type === 'airport') {
                isAirPort = true;
            } else {
                isAirPort = false;
            }

            if (this.props.bookingDetails &&
                this.props.bookingDetails.pickup
                && this.props.bookingDetails.pickup.place
                && this.props.bookingDetails.pickup.place.place_type === 'airport' && this.props.bookingDetails.pickup.place.airport_code) {
                showMeetGreet = this.props.bookingDetails.pickup.is_meet_and_greet == false ? false : true;
            } else {
                showMeetGreet = false;
            }

            this.setState({
                bookingDetails: this.props.bookingDetails,
                vehiclesList: this.props.vehiclesList,
                showAirlines: isAirPort,
                showMag: showMeetGreet
            }, async () => {
                await this.getReservationCharges();
                // await this.props.getAddons();
            });
        }

        if(prevProps !== this.props) {
            if (prevProps.isUserLogin !== this.props.isUserLogin && this.props.isUserLogin) {
                // console.log('isUserLoginUpdate', this.props.isUserLogin)
                this.setState({ disableRideRewards: false });
                this.props.getUserRewards();
            }
        }

    }

    componentWillUnmount = async () => {
        // Set to default state for selected payment method.
        await this.props.selectedPaymentMethod('');
        await this.props.clearContinueAsGuest();
        this.props.changeListType();
    }

    GA4BeginCheckout = (data: any, selectedIndex: number) => {
        console.log("GA4BeginCheckout",data)
        const SelectedVehicle: any = SelectedVehicleObj(data);
        const from: string = (this.props.listType !== 'change_list') ? 'home_list' : 'change_list';
        const beginCheckOutData: any = prepareBeginCheckout(SelectedVehicle, selectedIndex, from);
        beginCheckOutData.pickup = getPlaceCodeNdState(this.props.bookingDetails.pickup);
        beginCheckOutData.dropoff = getPlaceCodeNdState(this.props.bookingDetails.dropoff);
        createEcommerceGA4(beginCheckOutData, Constant.ECOMMERCE.BEGIN_CHECKOUT);
    }

    saveCardForFuturePayments = (event: any) => {
        // console.log('event', event.target.checked);
        this.setState({ saveForFuturePayments: event.target.checked }, () => {

        });
    }

    isSavedCard = (val: any) => {
        // console.log('event', event.target.checked);
        this.setState({ isSelected: val }, () => {
        });
    }

    validateBookingData = async () => {
        // Check for passenger details.
        // let passengerDetailsLS: any = localStorage.getItem('passengerData');
        let passengerDetailsLS: any = sessionStorage.getItem('passengerData');
        let primaryPassengerData = JSON.parse(passengerDetailsLS);

        let confirmBookingValidation = {
            primary_passenger: {
                pp_firstName: true,
                pp_lastName: true,
                pp_email: true,
                pp_mobile: true
            },
            secondary_passenger: {
                sp_firstName: true,
                sp_lastName: true,
                sp_email: true,
                sp_mobile: true
            },
            arrival_meet: {
                passenger_name: true
            }
        }

        if (!Helpers.validateEmail(primaryPassengerData.pp_email)) {
            confirmBookingValidation.primary_passenger.pp_email = false;
        }

        if (!primaryPassengerData.pp_firstName || primaryPassengerData.pp_firstName.trim() === '') {
            confirmBookingValidation.primary_passenger.pp_firstName = false;
        }

        if (!primaryPassengerData.pp_lastName || primaryPassengerData.pp_lastName.trim() === '') {
            confirmBookingValidation.primary_passenger.pp_lastName = false;
        }

        if (!Helpers.validateCountryPhone(primaryPassengerData.pp_contact)) {
            confirmBookingValidation.primary_passenger.pp_mobile = false;
        }

        // let isShowSecondaryPassenger: any = localStorage.getItem('showSecondaryPassenger');
        let isShowSecondaryPassenger: any = sessionStorage.getItem('showSecondaryPassenger');

        if (isShowSecondaryPassenger == 'true') {
            if (!Helpers.validateEmail(primaryPassengerData.sp_email)) {
                confirmBookingValidation.secondary_passenger.sp_email = false;
            }

            if (!primaryPassengerData.sp_firstName || primaryPassengerData.sp_firstName.trim() === '') {
                confirmBookingValidation.secondary_passenger.sp_firstName = false;
            }

            if (!primaryPassengerData.sp_lastName || primaryPassengerData.sp_lastName.trim() === '') {
                confirmBookingValidation.secondary_passenger.sp_lastName = false;
            }

            if (!Helpers.validateCountryPhone(primaryPassengerData.sp_contact)) {
                confirmBookingValidation.secondary_passenger.sp_mobile = false;
            }
        }
        // let bookingDetailsDataLS: any = localStorage.getItem("bookingDetails");
         let bookingDetailsDataLS: any = sessionStorage.getItem("bookingDetails");
        let bookingDetailsDataParsed = JSON.parse(bookingDetailsDataLS);
        let checkForMeetGreet: Array<any> = [];
        if (bookingDetailsDataParsed && bookingDetailsDataParsed.addonsData) {
            checkForMeetGreet = bookingDetailsDataParsed.addonsData.filter((addon: any) => {
                if (addon.addon_name === 'Arrival Meet and Greet') {
                    return addon;
                }
            });
        }
        let meetName = '';
        if (bookingDetailsDataParsed.meetAndGreetData) {
            meetName = bookingDetailsDataParsed.meetAndGreetData.name;
        }
        if (checkForMeetGreet.length && checkForMeetGreet[0].isActive) {
            if (meetName == '') {
                confirmBookingValidation.arrival_meet.passenger_name = false;
            }
        }

        await this.props.updateConfirmBookingValidation(confirmBookingValidation);

    }

    getCurrentTime() {
        var date = new Date();
        var hours = date.getHours();
        var minutes: any = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var currtime = hours + ':' + minutes + ' ' + ampm;
        return currtime;
    }

    isInvalidPickupTime = async () => {
        const bookingDetails = this.state.bookingDetails;
        const pickupTimeZone = bookingDetails.pickup.timezone;
        const validTime = bookingDetails.buffer_expiration_time_widget;
        let selectedDate = bookingDetails.pickup_timezone_date;

        const currentTimeZoneDate = moment(new Date().toLocaleString("en-US", { timeZone: pickupTimeZone })).format(DATE_FORMAT.DATE_AND_TIME_WITH_COMMA);
        const time = bookingDetails.time;
        
        if (selectedDate.includes('T')) {            
            selectedDate = selectedDate.split('T')[0];
            selectedDate = moment(`${selectedDate} ${time}`).format('MM-DD-YYYY, h:mm a');
        }

        /*safari handling*/
        if (selectedDate=== "Invalid date" && bookingDetails.pickup_timezone_date.includes('T')) {            
            selectedDate = bookingDetails.pickup_timezone_date.split('T')[0];
            selectedDate =moment(selectedDate, DATE_FORMAT.DATE_YYYY_MM_DD).format(DATE_FORMAT.DATE_MM_DD_YYYY);
            // console.log(selectedDate,time);
            selectedDate = `${selectedDate} ${time}`;
        }
        
        const aDate = new Date(selectedDate.split("-").join("/")).getTime();
        const bDate = new Date(currentTimeZoneDate.split("-").join("/")).getTime();
        const dateDiff = (aDate - bDate) / 1000;
        const hours = dateDiff / 60 / 60;

        if (Number(hours) > 0 && (Number(hours) * 60 > Number(validTime) * 60)) {
            return true;
        }
        return false;
    }


    bookARide = async () => {
          //comment region buffer code begins
        // let isValidDateTime = await this.isInvalidPickupTime();
        // if (!isValidDateTime) {
        //     toast.error(`Please book a ride at least ${Number(this.state.bookingDetails.buffer_expiration_time_widget)} hours from now or call 833-GET.LUXY for immediate assistance.`, {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        //     return false;
        // }

          //comment region buffer code ends
  //region buffer code begins
        if(this.props.vehicleListError){
            toast.error(this.props.vehicleErrorMessage,{position: toast.POSITION.TOP_RIGHT});
            return false;
        }
          //region buffer code ends
        // for validating extra stops
        const bd = (this.state.bookingDetails) ? this.state.bookingDetails : this.props.bookingDetails;
        if (bd.extra_stop_controls && bd.extra_stop_controls.length) {
            let controls = bd.extra_stop_controls;
            let values = bd.extra_stop_values;
            for (let i = 0; i < controls.length; i++) {

                let extraStops = JSON.parse(JSON.stringify(bd.extra_stops));
                let extraStopIndex = extraStops.findIndex((item: any) => item.stop_number === i + 1);

                if (values['extra_stop_' + (i + 1)] == '' || values['extra_stop_' + (i + 1)] == undefined || extraStopIndex == -1) {
                    toast.error(`Extrastop value(s) are invalid. Please verify.`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return false;
                }
            }
        }
        // return false;

        // added for event racking
        // ReactGA.event(googleEventAnalytics.confirmBooking);

        this.setState({ disableConfirmBooking: true });
        // let validate the booking data.
        await this.validateBookingData();

        let validateData = this.props.confirmBookingValidation;

        if (this.state.bookingDetails.selectedVehicle == null) {
            toast.error("Please verify the vehicle information");
            this.setState({ disableConfirmBooking: false });
            return false;
        }

        if (!validateData.arrival_meet.passenger_name
            || !validateData.primary_passenger.pp_email
            || !validateData.primary_passenger.pp_firstName
            || !validateData.primary_passenger.pp_lastName
            || !validateData.primary_passenger.pp_mobile
            || !validateData.secondary_passenger.sp_email
            || !validateData.secondary_passenger.sp_firstName
            || !validateData.secondary_passenger.sp_lastName
            || !validateData.secondary_passenger.sp_mobile) {
            toast.error("Please verify and enter the required information");
            this.setState({ disableConfirmBooking: false });
            return false;
        }


        // Check for the Airline validation.
        if (this.state.showAirlines) {
            // const bookingDetails = (this.state.bookingDetails) ? this.state.bookingDetails : this.props.bookingDetails;
            // let airLineData: any = localStorage.getItem('airLineInfo');
            // let flightObj = JSON.parse(airLineData);

            // if (flightObj
            //     && flightObj.flight && flightObj.flight.label === 'Select' && flightObj.flight_number !== '') {
            //     toast.error("Please select Airline");
            //     this.setState({ disableConfirmBooking: false });
            //     return false;
            // }

            // if (flightObj
            //     && flightObj.flight && flightObj.flight.label !== 'Select') {

            //     // if (flightObj.flight_number === '') {
            //         // toast.error("Enter flight number");
            //         // this.setState({ disableConfirmBooking: false });
            //         // return false;
            //     // }

            //     if (flightObj.flight_number &&
            //         flightObj.flight_number !== '') {

            //         const userTimeZone = bookingDetails.pickup.timezone;
            //         const dateString: any = `${moment(bookingDetails.pick_date_ptop).format('YYYY-MM-DD')} ${timeFormatter(bookingDetails.time)}`;
            //         let dateStr = getTimezoneDatetime(dateString, userTimeZone);
            //         const airLineInfo = {
            //             flight_Date: dateStr,
            //             drop_off_place_type: bookingDetails.pickup.place.place_type,
            //             flight_number: flightObj.flight_number,
            //             airline_name: flightObj.flight.label
            //         };
            //         let airlineValidation = await AirportService.verifyFlight(airLineInfo);
            //         if (airlineValidation && airlineValidation.error) {

            //             toast.error("Invalid flight information");
            //             this.setState({ disableConfirmBooking: false });
            //             return false;
            //         }
            //     }

            // }
        }

        // Get payment nonce data.
        let nonceData = await this.childRef.createNonce();
        if (nonceData === 0) {
            this.setState({ disableConfirmBooking: false });
            return false;
        }

        // Check for user is guest.
        // let isGuestUser = localStorage.getItem('isGuestUser');
        let isGuestUser = sessionStorage.getItem('isGuestUser');
        if (isGuestUser == 'true') {
            // let passengerDetailsLS: any = localStorage.getItem('passengerData');
            let passengerDetailsLS: any = sessionStorage.getItem('passengerData');
            let primaryPassengerData = JSON.parse(passengerDetailsLS);
            let getCustomerByEmail: any = await AuthService.getUserByEmail(primaryPassengerData.pp_email);

            if (getCustomerByEmail && getCustomerByEmail.data && getCustomerByEmail.data.status === StatusCode.EVERYTHING_IS_OK) {
                let userDetails = getCustomerByEmail.data.data[0];
                await this.setUserToLocalStorage(userDetails, 'existing_user');
                await this.props.getUserPaymentMethods(userDetails.id);
            } else {
                // let passengerDetailsLS: any = localStorage.getItem('passengerData');
                let passengerDetailsLS: any = sessionStorage.getItem('passengerData');
                let primaryPassengerData = JSON.parse(passengerDetailsLS);

                let payload = {
                    email_address: primaryPassengerData.pp_email,
                    first_name: primaryPassengerData.pp_firstName,
                    is_active: false,
                    is_email_notification_allowed: true,
                    is_sms_notification_allowed: true,
                    last_name: primaryPassengerData.pp_lastName,
                    mobile_number: primaryPassengerData.pp_contact,
                    password: ""
                }
                let userSignUp = await AuthService.signUp(payload);
                if (userSignUp && userSignUp.data && userSignUp.status === StatusCode.EVERYTHING_IS_OK) {
                    await this.setUserToLocalStorage(userSignUp.data, 'new_user');
                    await this.props.getUserPaymentMethods(userSignUp.data.user_id);
                }
            }
        }

        // let userFromLS: any = localStorage.getItem('user');
        let userFromLS: any = sessionStorage.getItem('user');
        let user = JSON.parse(userFromLS);

        await this.props.createPaymentMethod(this.props.brainTreeCustomerId, nonceData);


        if (!this.props.paymentCreatedData.success) {
            // await this.childRef.CreateDropIn();
            // this.props.history.push('/ride/failure', { transactionData: 'Payment Method Error' });
            this.setState({ disableConfirmBooking: false });
            toast.error(Constant.MESSAGES.CARD_VALIDATION_FAILED);
            await this.childRef.resetDropin();
            return false;
        }
        // check for if user click on the save card for future payments.
        if (this.state.saveForFuturePayments) {
            nonceData = await this.childRef.createNonce();
            await this.props.createPaymentMethod(this.props.brainTreeCustomerId, nonceData);
            await this.props.savePaymentMethodDetails(this.props.paymentCreatedData.paymentMethod, user.id, this.props.brainTreeCustomerId);

        }
        nonceData = await this.childRef.createNonce();
        let reservationPayload = await this.createReservationPayload();

        await this.props.createReservation(reservationPayload);

        if (Object.keys(this.props.createReservationData).length == 0) {
            this.props.history.push('/ride/failure', {});
            return false;
        }
        let brainTreeCustomerData = await PaymentService.checkForBraintreeCustomerID(user.id);

        //TODO: Check for data is correct.
        let brainTreeCustomerId = brainTreeCustomerData.data.data.customer_id;
        // this.props.brainTreeCustomerId,
        let data = {
            createReservationData: this.props.createReservationData,
            brainTreeCustomerId: brainTreeCustomerId,
            amount: this.props.invoiceAmountData,
            paymentNonce: nonceData,
            userId: user.id
        }
        // console.log("amount123", this.props.invoiceAmountData);
        await this.props.createTransaction(data);
        // console.log("transactionData123", this.props.transactionData);

        if (this.props.transactionData.data && this.props.transactionData.data.status === StatusCode.EVERYTHING_IS_OK) {
            await this.props.sendTripMail(this.props.transactionData.data, data.createReservationData.id);
            // added for event racking
            // ReactGA.event(googleEventAnalytics.confirmBookingSuccess);
            this.props.history.push('/ride/completed',
                {
                    transactionData: this.props.transactionData.data.data,
                    reservationData: this.props.createReservationData,
                    flightDetails: {
                        airline_name: reservationPayload.airline_name,
                        flight_number: reservationPayload.flight_number
                    }
                });
        }

        // Show transaction error.
        // console.log('12345678', this.props.transactionData)
        if (this.props.transactionData.error && this.props.transactionData.error.status === StatusCode.BAD_REQUEST) {

            toast.error(this.props.transactionData.error.data.message);
            this.setState({ disableConfirmBooking: false });
            return false;
        }
        if (this.props.transactionData.error && this.props.transactionData.error.status === StatusCode.CONFLICT) {
            // added for event racking
            // ReactGA.event(googleEventAnalytics.confirmBookingFailure);
            this.props.history.push('/ride/failure', {});
        }

    }
    triggerGA4ErrorEvent = (data: any) => {
        const obj: any = {
            'eventName': 'error',
            'errorSection': data.area,
            'errorMessage': data.errMessage,
            'errorFields': data.errFields,
            'event': 'error'
        }
        createEventGA4(obj);
    }

    bookARideV2 = async () => {
        let eventObj: any = {
            area: 'confirm_booking_error',
            errMessage: '',
            errFields: ''
        }

          //comment region buffer code begins
        // let isValidDateTime = await this.isInvalidPickupTime();
        // if (!isValidDateTime) {
        //     toast.error(`Please book a ride at least ${Number(this.state.bookingDetails.buffer_expiration_time_widget)} hours from now or call 833-GET.LUXY for immediate assistance.`, {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        //     return false;
        // }

          //region buffer code ends

            //region buffer code begins

        if(this.props.vehicleListError){
            toast.error(this.props.vehicleErrorMessage,{position: toast.POSITION.TOP_RIGHT});
            return false
        }
          //region buffer code ends
        // for validating extra stops
        const bd = (this.state.bookingDetails) ? this.state.bookingDetails : this.props.bookingDetails;
        if (bd.extra_stop_controls && bd.extra_stop_controls.length) {
            let controls = bd.extra_stop_controls;
            let values = bd.extra_stop_values;
            for (let i = 0; i < controls.length; i++) {

                let extraStops = JSON.parse(JSON.stringify(bd.extra_stops));
                let extraStopIndex = extraStops.findIndex((item: any) => item.stop_number === i+1);

                if (values['extra_stop_' + (i + 1)] == '' || values['extra_stop_' + (i + 1)] == undefined || extraStopIndex == -1 || bd.extra_stop_error['extra_stop_' + (i + 1)]) {
                    toast.error(`Extrastop value(s) are invalid. Please verify.`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    eventObj = {
                        ...eventObj,
                        errMessage: 'Extra stop value(s) are invalid.',
                        errFields: 'extra_stops'
                    }
                    this.triggerGA4ErrorEvent(eventObj);
                    return false;
                }
            }
        }
        //invalid pickup or drop off
        if (!bd.valid_dropoff) {
            toast.error(`Dropoff address is invalid. Please verify.`, {
                position: toast.POSITION.TOP_RIGHT
            });
            eventObj = {
                ...eventObj,
                errMessage: 'Drop off address is invalid.',
                errFields: 'drop_off_address'
            }
            this.triggerGA4ErrorEvent(eventObj);
            return false;
        }
        if (!bd.valid_pickup) {
            toast.error(`Pickup address is invalid. Please verify.`, {
                position: toast.POSITION.TOP_RIGHT
            });
            eventObj = {
                ...eventObj,
                errMessage: 'Pickup address is invalid.',
                errFields: 'pickup_address'
            }
            this.triggerGA4ErrorEvent(eventObj);
            return false;
        }
        // return false;

        // added for event racking
        // ReactGA.event(googleEventAnalytics.confirmBooking);
        
        // validate child seat
        let childSeatValues = this.props.childSeatValues;
        if (childSeatValues && childSeatValues.length && this.state.isChildSeatToggleOn) {
            let isEmptyChildSeat = false;
            childSeatValues.forEach((childSeat: any) => {
                if (childSeat.id === 0 ) {
                    isEmptyChildSeat = true;
                }
            });
            if (isEmptyChildSeat) {
                toast.error('Please select child seat options',{position: toast.POSITION.TOP_RIGHT});
                return false;
            }
        }

        this.setState({ disableConfirmBooking: true });
        // let validate the booking data.
        await this.validateBookingData();

        let validateData = this.props.confirmBookingValidation;

        if (this.state.bookingDetails.selectedVehicle == null) {
            toast.error("Please verify the vehicle information");
            this.setState({ disableConfirmBooking: false });
            eventObj = {
                ...eventObj,
                errMessage: 'verify the vehicle information',
                errFields: 'vehicle_selection'
            }
            this.triggerGA4ErrorEvent(eventObj);
            return false;
        }

        if (!validateData.arrival_meet.passenger_name
            || !validateData.primary_passenger.pp_email
            || !validateData.primary_passenger.pp_firstName
            || !validateData.primary_passenger.pp_lastName
            || !validateData.primary_passenger.pp_mobile
            || !validateData.secondary_passenger.sp_email
            || !validateData.secondary_passenger.sp_firstName
            || !validateData.secondary_passenger.sp_lastName
            || !validateData.secondary_passenger.sp_mobile) {
            toast.error("Please verify and enter the required information");
            this.setState({ disableConfirmBooking: false });
            eventObj = {
                ...eventObj,
                errMessage: 'verify and enter the required information',
                errFields: 'input_fields'
            }
            this.triggerGA4ErrorEvent(eventObj);
            return false;
        }

        if(!this.state.isFAQConfirmation){
            toast.error(`Please agree to terms & conditions`, {
                position: toast.POSITION.TOP_RIGHT
            });
            this.setState({ disableConfirmBooking: false });
            return false;
        }

        // Check for user is guest.
        // let isGuestUser = localStorage.getItem('isGuestUser');
        let isGuestUser = sessionStorage.getItem('isGuestUser');
        if (isGuestUser == 'true') {
            // let passengerDetailsLS: any = localStorage.getItem('passengerData');
            let passengerDetailsLS: any = sessionStorage.getItem('passengerData');
            let primaryPassengerData = JSON.parse(passengerDetailsLS);
            let getCustomerByEmail: any = await AuthService.getUserByEmail(primaryPassengerData.pp_email);

            if (getCustomerByEmail && getCustomerByEmail.data && getCustomerByEmail.data.status === StatusCode.EVERYTHING_IS_OK) {
                let userDetails = getCustomerByEmail.data.data[0];
                await this.setUserToLocalStorage(userDetails, 'existing_user');
                await this.props.getUserPaymentMethods(userDetails.id);
            } else {
                // let passengerDetailsLS: any = localStorage.getItem('passengerData');
                let passengerDetailsLS: any = sessionStorage.getItem('passengerData');
                let primaryPassengerData = JSON.parse(passengerDetailsLS);

                let payload = {
                    email_address: primaryPassengerData.pp_email,
                    first_name: primaryPassengerData.pp_firstName,
                    is_active: false,
                    is_email_notification_allowed: true,
                    is_sms_notification_allowed: true,
                    last_name: primaryPassengerData.pp_lastName,
                    mobile_number: primaryPassengerData.pp_contact,
                    password: ""
                }
                let userSignUp = await AuthService.signUp(payload);
                if (userSignUp && userSignUp.data && userSignUp.status === StatusCode.EVERYTHING_IS_OK) {
                    await this.setUserToLocalStorage(userSignUp.data, 'new_user');
                    await this.props.getUserPaymentMethods(userSignUp.data.user_id);
                }
            }
        }

        // let userFromLS: any = localStorage.getItem('user');
        let userFromLS: any = sessionStorage.getItem('user');
        let user = JSON.parse(userFromLS);

         // Get payment nonce data.
         let nonceData = await this.childRef.createNonce();
         if (nonceData === 0) {
             this.setState({ disableConfirmBooking: false });
             return false;
         }

        await this.props.createPaymentMethod(this.props.brainTreeCustomerId, nonceData);


        if (!this.props.paymentCreatedData.success) {
            // await this.childRef.CreateDropIn();
            // this.props.history.push('/ride/failure', { transactionData: 'Payment Method Error' });
            this.setState({ disableConfirmBooking: false });
            toast.error(Constant.MESSAGES.CARD_VALIDATION_FAILED);
            await this.childRef.resetDropin();
            eventObj = {
                ...eventObj,
                errMessage: Constant.MESSAGES.CARD_VALIDATION_FAILED,
                errFields: 'payment'
            }
            this.triggerGA4ErrorEvent(eventObj);
            return false;
        }

        
     


     

        let brainTreeCustomerData = await PaymentService.checkForBraintreeCustomerID(user.id);

      
        if(brainTreeCustomerData){
           
            if(brainTreeCustomerData && brainTreeCustomerData.data &&
                brainTreeCustomerData.data.data && brainTreeCustomerData.data.data.customer_id
                ){
                     //  check for if user click on the save card for future payments.
                     if (this.state.saveForFuturePayments) {
                           nonceData = await this.childRef.createNonce();
                            await this.props.createPaymentMethod(this.props.brainTreeCustomerId, nonceData);
                            await this.props.savePaymentMethodDetails(this.props.paymentCreatedData.paymentMethod, user.id, this.props.brainTreeCustomerId);
                
                    }
                   nonceData = await this.childRef.createNonce();
                   let brainTreeCustomerId = brainTreeCustomerData.data.data.customer_id;
                   let orderPayload = await this.createOrderPayload(brainTreeCustomerId,user.id,nonceData);
                    
                   if( typeof orderPayload !== 'object'){
                    this.setState({ disableConfirmBooking: false });
                       return false
                   }
               
                  await this.props.createOrder(orderPayload );
                  
                   if(! this.props.createOrderData.order_id) {
                    // localStorage.removeItem('total_hours');
                    // localStorage.removeItem('distance_in_miles')
                    // ReactGA.event(googleEventAnalytics.confirmBookingFailure);
                       if (this.props.createOrderData.error && this.props.createOrderData.alert) {
                           toast.error(this.props.createOrderData.error.message, {
                               position: toast.POSITION.TOP_RIGHT
                           });
                           this.setState({ disableConfirmBooking: false });
                           return false;
                       }
                       eventObj = {
                           ...eventObj,
                           errMessage: 'booking failed for the user',
                           errFields: 'booking'
                       }
                    let isPaymentError = false;
                     if (this.props.createOrderData.error && this.props.createOrderData.error.isPaymentError){
                        isPaymentError=true;
                     }
                     if (this.props.createOrderData.message) {
                         eventObj.errMessage = this.props.createOrderData.message;
                         this.triggerGA4ErrorEvent(eventObj);
                        this.props.history.push('/ride/failure', {errorMsg: this.props.createOrderData.message, isPaymentError});
                    } else {
                         this.triggerGA4ErrorEvent(eventObj);
                        this.props.history.push('/ride/failure', {});
                     }
                    return false;
                     }
                     else{
                    //  console.log("Its comingv9", this.props.createOrderData.trips[0].tripConfirmationNumber)     
                    // ReactGA.event(googleEventAnalytics.confirmBookingSuccess);
                    orderPayload.orderId = this.props.createOrderData.trips[0].tripConfirmationNumber;
                    this.createEventInGA(orderPayload);
                    if(localStorage.getItem('irclickid')){
                        this.createIREImpact(orderPayload);
                    }
                    this.props.history.push('/ride/completed',{
                    transactionData: '',
                    reservationData: {
                        order_id : this.props.createOrderData.order_id,
                        ...this.props.createOrderData.trips[0]
                    } ,
                    flightDetails: {
                        airline_name: orderPayload.trips[0].flight.airlineName ? orderPayload.trips[0].flight.airlineName : '' ,
                        flight_number: orderPayload.trips[0].flight.flightNumber ? orderPayload.trips[0].flight.flightNumber : ''
                     }
                    });
                    localStorage.removeItem('irclickid')
                }
                }else{
                    toast.error(`The customer id does not exist or could not get card nonce`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.setState({ disableConfirmBooking: false });
                    eventObj = {
                        ...eventObj,
                        errMessage: 'The customer id does not exist or could not get card nonce',
                        errFields: 'payment'
                    }
                    this.triggerGA4ErrorEvent(eventObj);
                    return false;
                   
                }
        }else{
            toast.error(`The customer id does not exist or could not get card nonce`, {
                position: toast.POSITION.TOP_RIGHT
            });
            this.setState({ disableConfirmBooking: false });
            eventObj = {
                ...eventObj,
                errMessage: 'The customer id does not exist or could not get card nonce',
                errFields: 'payment'
            }
            this.triggerGA4ErrorEvent(eventObj);
            return false;
        }

       

        //TODO: Check for data is correct.
      
        // this.props.brainTreeCustomerId,
       
        // console.log("amount123", this.props.invoiceAmountData);
        
        // console.log("transactionData123", this.props.transactionData);

        // if (this.props.transactionData.data && this.props.transactionData.data.status === StatusCode.EVERYTHING_IS_OK) {
        //     await this.props.sendTripMail(this.props.transactionData.data, data.createReservationData.id);
        //     // added for event racking
        //     ReactGA.event(googleEventAnalytics.confirmBookingSuccess);
        //     this.props.history.push('/ride/completed',
        //         {
        //             transactionData: this.props.transactionData.data.data,
        //             reservationData: this.props.createReservationData,
        //             flightDetails: {
        //                 airline_name: reservationPayload.airline_name,
        //                 flight_number: reservationPayload.flight_number
        //             }
        //         });
        // }

        // Show transaction error.
        // console.log('12345678', this.props.transactionData)
        // if(this.props.transactionData.error && this.props.transactionData.error.status === StatusCode.BAD_REQUEST) {

        //     toast.error(this.props.transactionData.error.data.message);
        //     this.setState({ disableConfirmBooking: false });
        //     return false;
        // }
        // if (this.props.transactionData.error && this.props.transactionData.error.status === StatusCode.CONFLICT) {
        //     // added for event racking
        //     ReactGA.event(googleEventAnalytics.confirmBookingFailure);
        //     this.props.history.push('/ride/failure', {});
        // }

    }

    createEventInGA(orderPayload: any) {
        
        const { trips: [{ addons, flight, secondary_passenger, promoCodeId }] } = orderPayload;
        // const passengerType: any = (localStorage.getItem('iamNotPassenger') && localStorage.getItem('iamNotPassenger') == 'true') ? 'secondary' : 'primary';
        const passengerType: any = (sessionStorage.getItem('iamNotPassenger') && sessionStorage.getItem('iamNotPassenger') == 'true') ? 'secondary' : 'primary';
        const GA4Obj = {
            'eventName': 'confirm_booking_details',
            'passengerType': passengerType,
            'secondaryPassenger': (secondary_passenger && secondary_passenger.email) ? 'true' : 'false',
            'airlineDetails': (flight && flight.airlineName) ? `${flight.airlineName} | ${flight.flightNumber}`: '',
            'tripProtection': addons.tripProtection ? 'true': 'false',
            'arrivalMeet': (addons.arrivalMeetAndGreet && addons.arrivalMeetAndGreet.id) ? 'true' : 'false',
            'childSeat': (addons.childSeat && addons.childSeat.length) ? 'true' : 'false',
            'travelPet': (addons && addons.travelingWithPet && addons.travelingWithPet.id) ? 'true' : 'false',
            'promoCode': (promoCodeId) ? 'true' : 'false',
            'event': 'confirmBookingDetails',
            'pickupName': getPlaceCodeNdState(this.props.bookingDetails.pickup),
            'dropName': getPlaceCodeNdState(this.props.bookingDetails.dropoff)
        }
        createEventGA4(GA4Obj);

        // Prepare purchase event.
        const orderForPurchase: any = this.preparePurchaseObj(orderPayload);
        const purchaseData: any = preparePurchaseEvent(orderForPurchase);
        purchaseData.pickupName = getPlaceCodeNdState(this.props.bookingDetails.pickup);
        purchaseData.dropName = getPlaceCodeNdState(this.props.bookingDetails.dropoff);
        createPurchaseEventGA4(purchaseData);

    }

    preparePurchaseObj = (orderPayload: any) => {
        
        const { addonsTotalPrice, baseRate, gratuityPriceTotal,
            salesTax, totalCost, totalDiscount, affiliatePayoutTotal,
            affiliateFeeTotal, tripProtectionTotalPrice, addons
        } = this.props.reservationCharges;
        const { trips: [{ flight, secondary_passenger, promoCodeId }], orderId } = orderPayload;
        const { trips: [{ tripId }] } = this.props.createOrderData;
        const { selectedVehicle: {vehicle_type_id, vehicle_type_name }, selectedVehicleIndex } = this.props.bookingDetails;
        return {
            'secondaryPassenger': (secondary_passenger && secondary_passenger.email) ? 'enabled' : 'disabled',
            'airlineDetails': (flight && flight.airlineName) ? `${flight.airlineName} | ${flight.flightNumber}` : '',
            'tripProtection': (addons.clearViewProtection) || 0,
            'arrivalMeet': addons.arrivalMeetAndGreet || 0,
            'childSeat': addons.childSeat || 0,
            'promoCode': (promoCodeId) ? 'enabled' : 'disabled',
            'travelPet': addons.travelingWithPet || 0,
            'tripId': tripId.toString(),
            baseRate, gratuityPriceTotal, addonsTotalPrice, salesTax, totalCost, totalDiscount, 
            affiliatePayoutTotal, affiliateFeeTotal, tripProtectionTotalPrice, vehicle_type_id,
            selectedVehicleIndex, vehicle_type_name, promoCodeId,
            listType: (this.props.listType !== 'change_list') ? 'home_list' : 'change_list',
            orderId
        }
    }

    createIREImpact(orderPayload: any) {
        // console.log(orderPayload, 'orderPayload')
        // const { trips: [{ addons, flight, secondary_passenger, promoCodeId }] } = orderPayload;
        // const passengerType: any = (localStorage.getItem('iamNotPassenger') && localStorage.getItem('iamNotPassenger') == 'true') ? 'secondary' : 'primary';
        
        const orderForIREImpact: any = this.prepareIREImpactObj(orderPayload);
        // console.log(orderForIREImpact, 'orderForIREImpact')
        sendToIREEvent(orderForIREImpact);
        // console.log(IREData);
       
    }

    prepareIREImpactObj = (orderPayload: any) => {
        
        const { totalCost, totalDiscount } = this.props.reservationCharges;
        const { trips: [{ promoCodeId }], orderId, customerId } = orderPayload;
        // const { trips: [{ tripId }] } = this.props.createOrderData;
        // const { selectedVehicle: {vehicle_type_id, vehicle_type_name }, selectedVehicleIndex } = this.props.bookingDetails;
        // console.log(this.props.reservationCharges, orderPayload, this.props.createOrderData, this.props.bookingDetails)
        return {
            orderId: orderId,
            customerId: customerId,
            customerEmail: '',
            customerStatus: "New",
            currencyCode: "USD",
            orderPromoCode: promoCodeId ? promoCodeId : '',
            orderDiscount: totalDiscount ? totalDiscount : '0',
                items: [
            {
                    subTotal: totalCost,
                    category: "Luxy Ride",
                    sku: orderId,
                    quantity: 1,
                    name: "Ride Confirmed"
                },
                ]
        }

    }

    setUserToLocalStorage = async (userData: any, type: string) => {
        let userDetails = userData;
        let customerData: any = {
            id: (type === 'new_user') ? userDetails.user_id : userDetails.id,
            first_name: userDetails.first_name,
            last_name: userDetails.last_name,
            email_address: userDetails.email_address,
            user_type_id: userDetails.user_type_id,
            mobile_number: userDetails.mobile_number
        }

        // localStorage.setItem('user', JSON.stringify(customerData));
        sessionStorage.setItem('user', JSON.stringify(customerData));
    }

    createReservationPayload = async () => {

        const bookingDetails = (this.state.bookingDetails) ? this.state.bookingDetails : this.props.bookingDetails;

        let pickupPlaceType = '';
        let pickupAirportCode = '';
        let isAirportDeparture = false;
        let dropOffPlaceType = '';
        let dropOffAirportCode = '';
        let isAirportArrival = false;

        let airLineInfo: any = {
            name: '',
            flight_number: ''
        };

        let passengerInfo: any = {
            name: '',
            email_address: '',
            mobile_number: '',
            notes: '',
            sp_name: '',
            sp_email_address: '',
            sp_mobile_number: '',

        }

        let promoCodeId = '0';

        // let passengerData: any = localStorage.getItem('passengerData');
        let passengerData: any = sessionStorage.getItem('passengerData');
        // let airLineData: any = localStorage.getItem('airLineInfo');
        let airLineData: any = sessionStorage.getItem('airLineInfo');
        let promoCodeData: any = 
         sessionStorage.getItem('promoCodeData');

        if (promoCodeData != '' && promoCodeData != null && promoCodeData != undefined) {
            promoCodeData = JSON.parse(promoCodeData);
            promoCodeId = promoCodeData.data.promo_code_id.toString();
        }

        if (airLineData != '' && airLineData != null && airLineData != undefined) {
            airLineData = JSON.parse(airLineData);
            airLineInfo.name = airLineData.flight && airLineData.flight.label ? airLineData.flight.label : "";
            airLineInfo.flight_number = airLineData.flight_number;
        }

        if (passengerData != '' && passengerData != null && passengerData != undefined) {
            passengerData = JSON.parse(passengerData);
            passengerInfo.name = passengerData.pp_firstName + ' ' + passengerData.pp_lastName;
            passengerInfo.email_address = passengerData.pp_email;
            passengerInfo.mobile_number = passengerData.pp_contact;
            passengerInfo.notes = passengerData.passenger_notes; // TODO: need to update.

            if (passengerData.sp_email != '' && passengerData.sp_email != null && passengerData.sp_email != undefined) {
                passengerInfo.sp_name = passengerData.sp_firstName + ' ' + passengerData.sp_lastName;
                passengerInfo.sp_email_address = passengerData.sp_email;
                passengerInfo.sp_mobile_number = passengerData.sp_contact;
            }


        }

        let gratuityObj: any = {
            id: '0',
            amount: '0'
        }

        // let gratuityData: any = localStorage.getItem('gratuityAmountData');

        let gratuityData: any = sessionStorage.getItem('gratuityAmountData');

        if (gratuityData != '' && gratuityData != null && gratuityData != undefined) {
            gratuityData = JSON.parse(gratuityData);
            gratuityObj.id = gratuityData[0].id.toString();
            gratuityObj.amount = gratuityData[0].gratuityAmount.toString(); // TODO: update with precision 2 if error occurs.
        }

        if (bookingDetails.pickup.place.place_type == 'airport') {
            pickupPlaceType = 'airport';
            pickupAirportCode = bookingDetails.pickup.place.airport_code;
            isAirportArrival = true;
        } else {
            pickupPlaceType = bookingDetails.pickup.place.place_type;
        }

        if (bookingDetails.dropoff.place.place_type == 'airport') {
            dropOffPlaceType = 'airport';
            dropOffAirportCode = bookingDetails.dropoff.place.airport_code;
            isAirportDeparture = true;
        } else {
            dropOffPlaceType = bookingDetails.dropoff.place.place_type;
        }

        let extraStops: any = (bookingDetails.extra_stops && bookingDetails.extra_stops.length) ? bookingDetails.extra_stops : [];
        let addonsInfo = this.prepareAddonsForReservation(this.props.selectedAddons); // includes 4 addons
        // let userFromLS: any = localStorage.getItem('user');
        let userFromLS: any = sessionStorage.getItem('user');
        let user = JSON.parse(userFromLS);

        const userTimeZone = bookingDetails.pickup.timezone;
        const dateString: any = `${moment(bookingDetails.pick_date_ptop).format('YYYY-MM-DD')} ${timeFormatter(bookingDetails.time)}`;
        let dateconst = getTimezoneDatetime(dateString, userTimeZone);

        //return false;

        let payload = {
            customer_id: user.id,
            service_rate_id: bookingDetails.selectedVehicle.service_rate_id,
            service_type: bookingDetails.trip_type_with_id.type,
            vehicle_type_id: bookingDetails.selectedVehicle.vehicle_type_id,
            // pickup_date: bookingDetails.pickup_timezone_date, // TODO: need to check
            pickup_date: dateconst,
            pickupDate: bookingDetails.pick_date_ptop, // TODO: need to check
            pickup_hour: bookingDetails.time,
            pickup: bookingDetails.pickup,
            dropoff: bookingDetails.dropoff,
            route: {
                total_miles: (bookingDetails.distance_in_miles) ? bookingDetails.distance_in_miles : sessionStorage.getItem('distance_in_miles'),
                total_hours: (bookingDetails.total_hours) ? bookingDetails.total_hours : sessionStorage.getItem('total_hours'),
            },
            is_airport_departure: isAirportDeparture,
            is_airport_arrival: isAirportArrival,
            airline_name: airLineInfo.name,
            flight_number: airLineInfo.flight_number,
            primary_passenger: {
                name: passengerInfo.name,
                mobile_number: passengerInfo.mobile_number,
                email_address: passengerInfo.email_address,
                notes: passengerInfo.notes
            },
            secondary_passenger: {
                name: passengerInfo.sp_name,
                mobile_number: passengerInfo.sp_mobile_number,
                email_address: passengerInfo.sp_email_address
            },
            extra_stops: extraStops,
            ...addonsInfo,
            gratuity: gratuityObj,
            promo_code_id: promoCodeId,
            order_source: 'customer_web'
        };
        return payload;
    }


    createOrderPayload = async (brainTreeCustomerId:any,userId:any,nonceData:any) => {

        
        // gratutity ID

        // let gratuityData: any = localStorage.getItem('gratuityAmountData');


        let gratuityData: any = sessionStorage.getItem('gratuityAmountData');

        let gratuityId : any = 2;

        if (gratuityData != '' && gratuityData != null && gratuityData != undefined) {
            gratuityData = JSON.parse(gratuityData);
            gratuityId = gratuityData[0].id
         
        }

        //PromoCode Id

        let promoCodeId : any = 0;

        let promoCodeData: any = 
        sessionStorage.getItem('promoCodeData'); 

        if (promoCodeData != '' && promoCodeData != null && promoCodeData != undefined) {
            promoCodeData = JSON.parse(promoCodeData);
            promoCodeId = promoCodeData.data.promo_code_id
        }


        let rateQuoteId = 0;

      
        if(this.props.reservationCharges && this.props.reservationCharges.requestQuoteId){
            rateQuoteId= this.props.reservationCharges.requestQuoteId
        }

        let body:any = {
            nonce : nonceData,
            customerId: userId,
            createdBy:userId,
            braintreeCustomerId:brainTreeCustomerId,
            gratuityId : gratuityId,
            promoCodeId : promoCodeId,
            "orderSource": "customer_web",
            rateQuoteId
            
        }

       

        const bookingDetails = (this.state.bookingDetails) ? this.state.bookingDetails : this.props.bookingDetails;
        
      
        let pickupPlaceType = '';
        let pickupAirportCode = '';
        let isAirportDeparture = false;
        let dropOffPlaceType = '';
        let dropOffAirportCode = '';
        let isAirportArrival = false;

        let airLineInfo: any = {
            airlineName: '',
            flightNumber: ''
        };

        let passengerInfo: any = {
            name: '',
            email: '',
            mobileNumber: '',
            notes: '',
            sp_name: '',
            sp_email_address: '',
            sp_mobile_number: '',

        }


        // let passengerData: any = localStorage.getItem('passengerData');

        let passengerData: any = sessionStorage.getItem('passengerData');
        // let airLineData: any = localStorage.getItem('airLineInfo');
        let airLineData: any = sessionStorage.getItem('airLineInfo');
        

        if (airLineData != '' && airLineData != null && airLineData != undefined) {
            airLineData = JSON.parse(airLineData);
            airLineInfo.airlineName = airLineData.flight && airLineData.flight.label ? airLineData.flight.label : "";
            airLineInfo.flightNumber = airLineData.flight_number;
        }

        if (passengerData != '' && passengerData != null && passengerData != undefined) {
            passengerData = JSON.parse(passengerData);
            passengerInfo.name = passengerData.pp_firstName + ' ' + passengerData.pp_lastName;
            passengerInfo.email = passengerData.pp_email;
            passengerInfo.mobileNumber = passengerData.pp_contact;
            passengerInfo.notes = passengerData.passenger_notes; // TODO: need to update.

            if (passengerData.sp_email != '' && passengerData.sp_email != null && passengerData.sp_email != undefined) {
                passengerInfo.sp_name = passengerData.sp_firstName + ' ' + passengerData.sp_lastName;
                passengerInfo.sp_email_address = passengerData.sp_email;
                passengerInfo.sp_mobile_number = passengerData.sp_contact;
            }


        }

    

       

        if (bookingDetails.pickup.place.place_type == 'airport') {
            pickupPlaceType = 'airport';
            pickupAirportCode = bookingDetails.pickup.place.airport_code;
            isAirportArrival = true;
        } else {
            pickupPlaceType = bookingDetails.pickup.place.place_type;
        }

        if (bookingDetails.dropoff.place.place_type == 'airport') {
            dropOffPlaceType = 'airport';
            dropOffAirportCode = bookingDetails.dropoff.place.airport_code;
            isAirportDeparture = true;
        } else {
            dropOffPlaceType = bookingDetails.dropoff.place.place_type;
        }

        let extraStops: any = (bookingDetails.extra_stops && bookingDetails.extra_stops.length) ? bookingDetails.extra_stops : [];
        let addonsInfo = this.prepareAddonsForReservation(this.props.selectedAddons); // includes 4 addons
        // let userFromLS: any = localStorage.getItem('user');
        let userFromLS: any = sessionStorage.getItem('user');
        let user = JSON.parse(userFromLS);

        const userTimeZone = bookingDetails.pickup.timezone;
        const dateFormat: any = `${moment(bookingDetails.pick_date_ptop).format('DD/MM/YYYY')}`;
        const dateString: any = `${moment(bookingDetails.pick_date_ptop).format('YYYY-MM-DD')} ${timeFormatter(bookingDetails.time)}`;
        let dateconst = getTimezoneDatetime(dateString, userTimeZone);

        //return false;


        let addons : any = {
            arrivalMeetAndGreet : addonsInfo.meet_greet_service,
            travelingWithPet : addonsInfo.pet_service ,
            tripProtection : addonsInfo.trip_protection ? addonsInfo.trip_protection : false,
            childSeat : addonsInfo.additional_car_seat

        }

        if(airLineInfo && airLineInfo.flightNumber){
            airLineInfo = airLineInfo
        }else{
            airLineInfo = {}
        }
        const userData: any = userFromLocalStorage();
        let userID = 0;
        if (userData && userData.id) {
            userID = userData.id;
        }
        let tripData : any = {
            serviceRateId: bookingDetails.selectedVehicle.service_rate_id,
            serviceTypeId: bookingDetails.trip_type_with_id.id,
            vehicleTypeId: bookingDetails.selectedVehicle.vehicle_type_id,
            flight: airLineInfo,
            passenger: {
                name: passengerInfo.name,
                mobileNumber: passengerInfo.mobileNumber,
                email: passengerInfo.email,
                notes: passengerInfo.notes
            },
            addons: addons,
            pickup:bookingDetails.pickup,
            dropoff:bookingDetails.dropoff,
            extraStops:extraStops,
            route: {
                totalMiles: (bookingDetails.distance_in_miles) ? bookingDetails.distance_in_miles : sessionStorage.getItem('distance_in_miles'),
                totalHours: (bookingDetails.total_hours) ? bookingDetails.total_hours : sessionStorage.getItem('total_hours'),
            },
            pickupTime:bookingDetails.time,
            pickupDate:dateFormat,
            pickupDatetime:dateconst,
            secondary_passenger: {
                name: passengerInfo.sp_name,
                mobileNumber: passengerInfo.sp_mobile_number,
                email: passengerInfo.sp_email_address
            },
            promoCodeId : promoCodeId,
            application:'web',
            rewardPoints: this.props.rewardPointsData.points,
            bookingUserId: user.id,
            userCorporateId: userID,
            userTypeId: Constant.USER_TYPE.CUSTOMER,
            isEditMode: false,
            isManualFarmOut: false,
            orderSource: "customer_web",
            internal_notes: "",
            rateQuoteId,
            booking_for: this.props.booking_for
        }
        if(localStorage.getItem('irclickid')){
            tripData.external_source_info = {
                'irclickid' : localStorage.getItem('irclickid')
            }
        }
        let isMilesIssue = this.checkMilesIssue(tripData.route.totalMiles,tripData.route.totalHours,);
        if(!isMilesIssue) return false;
        let isValid: any  = this.checkIsValidTripDetails(
            tripData.pickup,tripData.dropoff,tripData.pickupTime,tripData.pickupDate,tripData.serviceRateId)
    
        if(!isValid) return false;
        body.trips=[{...tripData}]
        return body
    }

    prepareAddonsForReservation = (selectedAddons: any) => {
        let payload: any = {
            meet_greet_service: {
                id: 0
            },
            pet_service: {
                id: 0
            },
            additional_car_seat: []
        };

        selectedAddons && selectedAddons.length && selectedAddons.map((addon: any) => {
            switch (addon.addon_name) {
                case addOnNames.arrivalMeetAndGreet:
                    payload['meet_greet_service'] = this.isAirportTrip() ? {
                        id: addon.id,
                        name: this.props.meetAndGreetData.name,
                        notes: this.props.meetAndGreetData.notes
                    } : {id:0}
                    break;
                case addOnNames.travelingWithPet:
                    payload['pet_service'] = {
                        id: addon.id,
                    }
                    break;
                case addOnNames.childSeat:
                    payload['additional_car_seat'] = {
                        id: addon.id,
                        value: this.props.childSeatValues
                    }
                    break;
                default:
                    payload['trip_protection'] = true;
            }
            return payload;
        })

        return payload;
    }

    updateConfirmBookingValidation = (data: any) => {
        this.props.updateConfirmBookingValidation(data);
    }

    showPromoCode = (event: any) => {

        this.setState({ showPromoCode: event.target.checked},async()=>{
            // to call charges api when promocode does not apply
            console.log('promoCodeRef',this.promoCodeRef)
            if(!this.state.showPromoCode){
                sessionStorage.removeItem('promoCodeData');
                await this.getReservationCharges();
                if(this.promoCodeRef && this.promoCodeRef.current!=null){
                await this.promoCodeRef.removePromoCode({});
                }
            }
            if(this.state.showPromoCode) {
                this.setState({showRideRewards: false})
            }
        });
    }

    showRideRewards = (event: any) => {
        this.setState({ showRideRewards: event.target.checked }, async () => {
            if (this.state.showRideRewards) {
                this.setState({ showPromoCode: false })
            }
        });
    }

    closeMilesPopUp = ()=>{
        this.setState({isMilesIssue:false})
    }

    setTermsAndConditions = (val : any)=>{
        this.setState({isFAQConfirmation:val})
    }

    render() {
        let isGuestUser = sessionStorage.getItem('isGuestUser');

        // console.log("this.state.showPromoCode",this.state.showPromoCode)

        return (<div> <div className="RideDetailsBG">  <div className="container py-5 luxygreenBussinessPy-1"> <div className="rideDetails luxyGreenBussiness" style={{
        }}>
        <div className="ride-booking-pane luxyGreenBussiness-1" >
                <a href="https://luxyride.com/programs/esg-compliance-black-car-industry/" target="_blank">
                <img
                        alt="Black Car Service From Airport"
                        src={banner6}
                        className="d-inline-block  img-fluid"
                    />
                </a>
            </div>
            </div>
            <Row noGutters={true}>
            <Col md={4} xl={4} xs={12} className="p-0">

                <div style={{ paddingBottom: '10px' }}>
                    <RideDetailsContainer
                        bookingDetails={this.state.bookingDetails}
                        getReservationCharges={this.getReservationCharges} />
                </div>
                {
                    (this.state.bookingDetails.selectedVehicle != null) ? <>
                        <div className="card p-0 w-100">
                            <div className="card-body p-0">
                                <ChangeVehicleContainer
                                    // vehicleDetailsList={this.state.vehiclesList}
                                    vehicleDetailsList={this.props.vehiclesList}
                                    // bookingDetails={this.state.bookingDetails} 
                                    // vehicleDetailsList={this.state.vehiclesList}
                                    bookingDetails={this.state.bookingDetails} 
                                    isChildSeatToggleOn={this.state.isChildSeatToggleOn}
                                    updateChildSeatData={this.updateChildSeatData}
                                    childSeatValues={this.props.childSeatValues}
                                />

                                {
                                    (!this.props.isVehicleChange || this.props.isVehicleChange === undefined)
                                        ?
                                        <InvoiceContainer reservationCharges={this.props.reservationCharges} />

                                        : null
                                }
                            </div>
                        </div>
                    </> : <div className="card p-0 w-100">
                        <div className="card-body p-0">
                            <div className="text-danger p-1 text-center">No vehicles found in this route.</div></div></div>
                }



            </Col>
            <Col md={8} xl={8} xs={12} className="px-md-2 mt-3 mt-md-0 mt-sm-0 py-sm-2 px-sm-0 py-md-0" >

                <div className="card p-0 w-100">
                    <div className="card-body p-md-3 py-3 p-0">
                        <div>
                            <PassengerDetails showAirLines={this.state.showAirlines} closePromoCodeToggle={this.closePromoCodeToggle} />
                        </div>
                        <div className="mt-1 p-3 col-md-12">
                            <Addons addonsData={this.props.addonsData}
                                updateAddonData={this.updateAddonsInfo}
                                updateMeetandGreetData={this.updateMeetandGreetData}
                                updateChildSeatData={this.updateChildSeatData}
                                selectedVehicle={this.state.bookingDetails.selectedVehicle}
                                meetAndGreetData={this.props.meetAndGreetData}
                                childSeatValues={this.props.childSeatValues}
                                confirmBookingValidation={this.props.confirmBookingValidation}
                                updateConfirmBookingValidation={this.updateConfirmBookingValidation}
                                showAirLines={this.state.showMag}
                                carSeatTypes={this.props.carSeatTypes}
                                setChildSeatToggle={this.setChildSeatToggle}
                                isChildSeatToggleOn={this.state.isChildSeatToggleOn}
                            />

                            <div>

                                <div className="addon-block">
                                    <div className="row">
                                        <div className="col-1 p-0  text-center">
                                            <i className="fa fa-gift" style={{ color: '#909090', fontSize: '24px' }} aria-hidden="true"></i>
                                        </div>
                                        <div className="col pl-0 text-left">
                                            <div >
                                                <div className="extras">
                                                    Promo Code
                            </div>

                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="custom-control custom-switch ">
                                                <input
                                                    id={`promo_${1}`}
                                                    onChange={(event) => { this.showPromoCode(event) }}
                                                    checked={this.state.showPromoCode}
                                                    // value={1}
                                                    className="custom-control-input"
                                                    type="checkbox" />
                                                <label className="custom-control-label" htmlFor={`promo_${1}`}>

                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="addon-block">
                                    <div className="row">
                                        <div className="col-1 p-0  text-center">
                                            <i className="fas fa-business-time" style={{ color: '#909090', fontSize: '20px', paddingTop: '5px' }} aria-hidden="true"></i>
                                        </div>
                                        <div className="col pl-0 text-left">
                                            <div>
                                                <div className="extras">
                                                <BookingForContainer />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (this.state.showPromoCode) ?

                                        <PromoCodeContainer
                                        onRef={(ref: any) => (this.promoCodeRef = ref)}
                                        getReservationCharges={this.getReservationCharges}
                                        closePromoCodeToggle={this.closePromoCodeToggle}
                                        />
                                        : null
                                }
                                {
                                    (this.props.rideRewardsConfigData && this.props.rideRewardsConfigData.value === 'true') ?
                                        <>
                                            <RewardsToolBarContainer
                                                showRideRewards={(event: any) => this.showRideRewards(event)}
                                                showRideReward={this.state.showRideRewards}
                                                disableRideRewards={this.state.disableRideRewards}
                                            />
                                            {
                                                (this.state.showRideRewards) ?
                                                    <RewardPointsContainer
                                                        getReservationCharges={this.getReservationCharges}
                                                    />
                                                    : null

                                            }
                                        </>
                                        : null
                                }

                            </div>
                        </div>

                        <div>
                            <PaymentDetails
                                onRef={(ref: any) => (this.childRef = ref)}
                                isSaved={this.isSavedCard}
                                setTermsAndConditions={this.setTermsAndConditions}
                                
                            />
                        </div>
                        <Row>
                            <div className="col-md-8 col-12">
                                {
                                    (!this.state.isSelected && (isGuestUser == undefined || isGuestUser == null))
                                        ? <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1"
                                                onClick={(ev: any) => this.saveCardForFuturePayments(ev)}
                                            />
                                            <label className="form-check-label" htmlFor="exampleCheck1">Save card for future use</label>
                                        </div>
                                        : null
                                }

                            </div>
                            <div className="col-md-4 col-12">

                                <button
                                    style={{ width: '100%' }}
                                    disabled={this.state.disableConfirmBooking}
                                    onClick={() => trackPromise(this.bookARideV2())}
                                    className="btn btn-block primary custom-button">CONFIRM BOOKING</button>
                            </div>
                            {
                                this.state.isMilesIssue ? 
                                <ErrorPopUp
                                show={this.state.isMilesIssue}
                                closeErrorPopUp={this.closeMilesPopUp}
                                title={'Oops...'}
                                bodyText={'Something went wrong. Please try again to receive a quote or give us a call at (833) 438-5899 for immediate assistance.'}
                                // deleteMethod={this.deletePromoCode}
                            /> : null
                            }
                        </Row>
                    </div>
                </div>
            </Col>
        </Row></div>

        </div>  <div><OtherVehicles /></div> </div>)
    }
}
export default Details;