export const UPDATE_PAYMENT_METHODS = "UPDATE_PAYMENT_METHODS";
export const UPDATE_CLIENT_TOKEN = "UPDATE_CLIENT_TOKEN";
export const UPDATE_PAYMENT_METHOD_TOKEN = "UPDATE_PAYMENT_METHOD_TOKEN";
export const UPDATE_PAYMENT_DATA = "UPDATE_PAYMENT_DATA";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_DATA = "UPDATE_PAYMENT_METHOD_DATA"; 

const defaultState = {
    paymentMethods: [],
    brainTreeCustomerId: '',
    clientToken: '',
    paymentMethodToken: ''
}

export function paymentSettingsReducer(state: any = defaultState, action: { type: string, data?: any }) {
    switch (action.type) {
        case UPDATE_PAYMENT_METHODS: {
            return {
                ...state,
                paymentMethods: action.data.paymentMethods,
                brainTreeCustomerId: action.data.brainTreeCustomerId
            }
        }
        case UPDATE_CLIENT_TOKEN: {
            return {
                ...state,
                clientToken: action.data
            }
        }
        case UPDATE_PAYMENT_METHOD_TOKEN: {
            return {
                ...state,
                paymentMethodToken: action.data
            }
        }
        case UPDATE_PAYMENT_DATA: {
            return {
                ...state,
                createdPaymentData: action.data
            }
        }
        case UPDATE_PAYMENT_METHOD: {
            return {
                ...state,
                savedPaymentMethodData: action.data
            }
        }
        case UPDATE_PAYMENT_METHOD_DATA:{
            return {
                ...state,
                updatePaymentMethodData: action.data
            }
        }
        default:
            return state;
    }
}