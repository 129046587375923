export const SET_BLOG_PAGE_INFO = 'SET_CATEGORY_PAGE';
export const SET_BLOGS_DATA = 'SET_BLOGS_DATA';
export const SET_BLOG_DETAIL = 'SET_BLOG_DETAIL';

const defaultState = {
    isCategoryPage: true,
    categoryId: 0,
    blogId: 0,
    allBlogs: [],
    blogDetail: null
}

export function blogReducer(state: any = defaultState, action: { type: string, data?: any }) {
    switch (action.type) {
        case SET_BLOG_PAGE_INFO: {
            return {
                ...state,
                isCategoryPage: action.data.isCategoryPage,
                categoryId: action.data.categoryId,
                blogId: action.data.blogId
            };
        }
        case SET_BLOGS_DATA: {
            return {
                ...state,
                allBlogs: action.data
            };
        }
        case SET_BLOG_DETAIL: {
            return {
                ...state,
                blogDetail: action.data
            };
        }
        default:
            return state;
    }
}