import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DateProps {
    reference: any,
    format: string,
    placeHolder: string,
    minDate: any,
    handleRaw: (event: any) => void,
    handleChange: (data: any) => void,
    handleBlur?: (event: any) => void;
    selectedDate: any
}
const DateComponent = (props: DateProps) => {
    return <DatePicker className="form-control custom-input-text-datepicker"
    ref={props.reference}
    dateFormat={props.format}
    placeholderText={props.placeHolder}
    minDate={props.minDate}
    onChangeRaw={props.handleRaw}
    onChange={props.handleChange}
    // value={this.state.pick_date_ptop}
    selected={props.selectedDate}
    onBlur={props.handleBlur ? props.handleBlur : undefined}
    autoComplete="nope"
>
</DatePicker>
}

export default DateComponent;