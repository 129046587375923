import React, { Component } from 'react';
import { FormControl, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthService } from '../../services/auth';
import ConfirmPopup from '../modals/confirmation';
import ConfirmationPopup from '../modals/confirm-popup';
import { userFromLocalStorage } from '../../Utilities/helpers';
import { REWARD_POINTS, REWARD_POINTS_APPLIED } from '../../reducer/ride-rewards';

interface RewardPointsProps {
    user: any;
    userRewards: any;
    getReservationCharges: () => any;
    updateUserAppliedPoints: (points: number) => any;
    rewardPointsData: any;
    applyRewardPoints: (points: number, user_id: number, is_applied: boolean) => any;
    reservationCharges: any;
}

interface RewardPointsState {
    rewardPointsData: any;
    applyRideRewards: boolean;
    rideRewardSuccess: boolean;
    points: string;
    isDeleteRewards: boolean;
    rewardsApplied: boolean;
}

class RewardPoints extends Component<RewardPointsProps, RewardPointsState> {
    constructor(props: RewardPointsProps) {
        super(props);
        this.state = {
            rewardPointsData: {},
            applyRideRewards: false,
            rideRewardSuccess: false,
            points: '',
            isDeleteRewards: false,
            rewardsApplied: false
        }
    }

    componentDidMount = async () => {
    }

    componentDidUpdate = (prevProps: any) => {

        if (prevProps !== this.props) {
            if (this.props.reservationCharges && this.props.reservationCharges.totalDiscount
                && this.props.rewardPointsData.is_applied) {
                // console.log('charges123')
                this.setState({ rewardsApplied: true });
            }
        }
    }

    componentWillUnmount(): void {
        this.deleteRideRewards();
    }

    useRewardPoints = async () => {
        const points = Number(this.state.points);
        if (!points) {
            toast.error('Enter reward points');
            return false;
        }
        const user: any = userFromLocalStorage();
        // await this.props.updateUserAppliedPoints(points);
        await this.props.applyRewardPoints(points, user.id, true);
        await this.props.getReservationCharges();
    }

    setPoints = (e: any) => {
        const validation: any = new RegExp(/^[0-9]+$/);
        if (validation.test(Number(e.target.value))) {
            this.setState({ points: e.target.value }, () => {
            });
        }
    }

    removeRideRewards = () => {
        this.setState({ isDeleteRewards: true });
    }

    deleteRideRewards = async () => {

        // await this.props.updateUserAppliedPoints(0);
        const user: any = userFromLocalStorage();
        await this.props.applyRewardPoints(0, user.id, false);
        await this.props.getReservationCharges();
        this.setState({ points: '', isDeleteRewards: false, rewardsApplied: false });

    }

    showRewardsSection = () => {
        const { points, enrolled } = this.props.userRewards;

        if (this.state.rideRewardSuccess) {
            return <>
                You have successfully enrolled into ride rewards.
            </>
        }
        if (points && enrolled) {
            return <><div className="row">
                <div className="col-md-8 pl-4 col-7 pr-0 " >

                    <div className="form-group mb-0">

                        <FormControl
                            id="promoCode"
                            placeholder="Apply reward points"
                            style={{ borderRadius: '5px 0px 0px 5px ', padding: '20px', border: '1px solid #eb8122' }}
                            onChange={(event: any) =>
                                this.setPoints(event)
                            }
                            autoComplete={'off'}
                            value={this.state.points}
                            disabled={this.state.rewardsApplied}
                        />
                    </div>
                </div>
                <div className="col-md-4 col-5 pl-0" >
                    <div>
                        <button type="button"
                            className={(this.state.rewardsApplied) ? "btn btn-small applyed-button"
                                : "btn btn-small customized-button"} style={{ padding: '10px 25px' }}
                            onClick={this.useRewardPoints}
                            disabled={this.state.rewardsApplied}
                        >
                            <i className="fa fa-gift"></i>  &nbsp;
                            {
                                (!this.state.rewardsApplied) ?
                                    'Apply' : 'Applied'
                            }
                        </button>
                        {
                            (!this.state.rewardsApplied) ?
                                null : <i style={{ fontSize: '24px' }} className='font-13 text-danger fa fa-trash pointer'
                                    title='Delete'
                                    onClick={() => this.removeRideRewards()}
                                ></i>
                        }
                    </div>
                </div>
            </div>
                <p className='pl-4'> {
                    (this.props.userRewards && this.props.userRewards.data) ?
                        this.props.userRewards.data
                        :
                        ''
                }</p></>
        } else if (enrolled && !points) {
            return <>
                <p className='pl-4'> {
                    (this.props.userRewards && this.props.userRewards.data) ?
                        <>
                            {this.props.userRewards.data}
                        </>
                        :
                        ''
                }</p>
            </>;
        } else if (!enrolled && points) {
            return <>
                <p className='pl-4'> {
                    (this.props.userRewards && this.props.userRewards.data) ?
                        <>
                            {this.props.userRewards.data}
                            <a href='/' onClick={(e: any) => this.applyRideReward(e)}> Click </a>
                            to apply.
                        </>
                        :
                        ''
                }</p>
            </>;
        }
        else {
            return <>
                <p className='pl-4'> {
                    (this.props.userRewards && this.props.userRewards.data) ?
                        <>
                            {this.props.userRewards.data}
                            <a href='/' onClick={(e: any) => this.applyRideReward(e)}> Click </a>
                            to apply.
                        </>
                        :
                        ''
                }</p>
            </>
        }

    }

    applyRideReward = (e: any) => {
        e.preventDefault();
        this.setState({ applyRideRewards: true });
    }

    applyUserRideRewards = async () => {
        const user: any = userFromLocalStorage();

        const errorMsg: string = `Unable to process at the moment, Please try again.`;
        if (user) {
            const data: any = await AuthService.signUpForRideReward(user.email_address);
            if (data) {
                this.setState({ applyRideRewards: false, rideRewardSuccess: true }, () => {
                    toast.success(`You have successfully enrolled into ride rewards.`);
                });
            } else {
                this.setState({ applyRideRewards: false, rideRewardSuccess: false }, () => {
                    toast.error(errorMsg);
                });
            }
        } else {
            this.setState({ applyRideRewards: false, rideRewardSuccess: false }, () => {
                toast.error(errorMsg);
            });
        }
    }

    render() {

        return (
            <>
                <Row className=" mt-0 border-styling">
                    <div className="col-md-12 course-info">
                        {/* <div>
                            <ToastContainer />
                        </div> */}
                        {this.showRewardsSection()}

                    </div>
                </Row>
                {
                    (this.state.applyRideRewards) ?
                        <ConfirmationPopup
                            show={true}
                            closeConfirmPopup={() => this.setState({ applyRideRewards: false })}
                            title='Ride Rewards'
                            bodyText='Apply for ride rewards to start earning points'
                            callbackMethod={() => { this.applyUserRideRewards() }}
                            variant="success"
                        /> : null
                }

                {
                    (this.state.isDeleteRewards)
                        ? <ConfirmPopup
                            show={this.state.isDeleteRewards}
                            closeConfirmPopup={() => this.setState({ isDeleteRewards: false })}
                            title={'Remove Reward Points'}
                            bodyText={'Would you like to remove Reward Points?'}
                            deleteMethod={this.deleteRideRewards}
                        /> : null
                }

            </>
        )

    }
}

type Dispatch = (action: any) => void;

const applyRewardPoints = (points: number, user_id: number, is_applied: boolean) => {
    return async (dispatch: Dispatch) => {
        const pointsData = {
            points,
            user_id,
            is_applied
        }
        dispatch({
            type: REWARD_POINTS_APPLIED, data: pointsData
        });
    }
}

const updateUserAppliedPoints = (points: number) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: REWARD_POINTS, data: points
        });
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user.user,
        userRewards: state.rideRewards.userRewards,
        rewardPointsData: state.rideRewards.rewardPointsData,
        reservationCharges: state.details.reservationCharges
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        applyRewardPoints: (rewardPoints: number, userId: number, is_applied: boolean) => dispatch(applyRewardPoints(rewardPoints, userId, is_applied)),
        updateUserAppliedPoints: (points: number) => dispatch(updateUserAppliedPoints(points)),
    };
};

const RewardPointsContainer = connect(mapStateToProps, mapDispatchToProps)(RewardPoints);

export default RewardPointsContainer;