import React from 'react';
import { Button, Modal } from "react-bootstrap";
import luggageImage from "../../assets/images/luggage-image.png";
import passengerImage from "../../assets/images/passenger-image.png";
import './book-a-ride.css';

interface ConfirmationProps {
    show: boolean;
    closeConfirmPopup: () => any;
    title: string;
    // bodyText: string;
    callbackMethod: () => any;
    variant: string;
    selectedVehicle: any;
    selectedVehicleIndex: any;
}

function VehicleConfirmationPopup(props: ConfirmationProps) {

    const handleClose = () => {
        props.closeConfirmPopup()
    }

    return (
        <>

            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ fontSize: "large" }}>Please note that our {props.selectedVehicle &&  props.selectedVehicle.vehicle_type_name} can accommodate a maximum of:</p>
                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <div>
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <img style={{ width: 30, height: 30 }} src={passengerImage} alt='passenger' /> - 
                                <p>{ props.selectedVehicle && props.selectedVehicle.passenger_capacity } passengers </p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <img style={{ width: 30, height: 30 }} src={luggageImage} alt='luggage' /> -
                                <p>{ props.selectedVehicle && props.selectedVehicle.luggage_capacity } check size luggage </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-top-0'>
                    <button className="btn primary cancel-button" onClick={handleClose}>
                        Cancel
                    </button>
                    <button
                        // className="custom-button"
                        className="btn primary custom-button"
                        onClick={() => props.callbackMethod()}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default VehicleConfirmationPopup;
