
import React from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import Alert from 'react-bootstrap/esm/Alert';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import PhoneInput from 'react-phone-number-input';
import { getLuxyAddress, isValidLuxyAddress } from '../../services/AddressService';
import CommonService from '../../services/CommonService';
import { createEventGA4 } from '../../Utilities/helpers';
import GooglePlacesComponent from '../google-places/google-places';

import * as Helpers from '../common/helpers';
import AgreeText from '../policy/agree-text';

import {toast } from 'react-toastify';

import ConfigurationService from '../../services/ConfigurationService';

interface CFormProps {
    saveTravelAgent: (payload: any,w9 :any,directDeposite:any) => any;
    corporateErr: boolean;
    corporateErrMsg: string;
    refferedBy : any;
}

interface CFormState {
    corporateErr: boolean;
    firstNameErr: boolean;
    firstName: string;
    lastNameErr: boolean;
    lastName: string;
    termsErr: boolean;
    terms: boolean;
    companyNameErr: boolean;
    companyName: string;
    email: string;
    emailErrMsg: string;
    mobile: string;
    mobileErrMsg: string;
    website: string;
    websiteErr: boolean;
    businessAddress: string;
    businessAddressObj: string;
    businessAddressErr: boolean;
    post: any;
    form_start: boolean;
    w9:FileState;
    w9Err: boolean;
    deposit:FileState;
    depositErr: boolean;
    configurationsNamesObj: any
    pinOption: boolean;

}

interface FileState {
    file: File | null;
    previewUrl: string | null;
  }

class TravelAgentForm extends React.Component<CFormProps, CFormState> {

    constructor(props: CFormProps) {
        super(props);
        this.state = {
            corporateErr: false,
            firstNameErr: false,
            firstName: '',
            lastNameErr: false,
            lastName: '',
            termsErr: false,
            terms: false,
            companyNameErr: false,
            companyName: '',
            email: '',
            emailErrMsg: '',
            mobile: '',
            mobileErrMsg: "",
            website: "",
            websiteErr: false,
            businessAddress: "",
            businessAddressObj:"",
            businessAddressErr: false,
            w9:{ file: null, previewUrl: null },
            w9Err: false,
            deposit:{ file: null, previewUrl: null },
            depositErr: false,
            post: {
                checkStatus: [],
                items: [{}],
                itemsforstate: [{}],
                itemsforworkstate: [{}],
                itemsforcity: [{}],
                itemsforworkcity: [{}],
                zipcodelist: [{}],
                workzipcodelist: [{}],
                addonservices: [{}],
                user_type_id: '',
                password: '',
                first_name: '',
                last_name: '',
                email_address: '',
                profile_image: '',
                mobile_number: '',
                airport_name: '',
                work_address: '',
                home_address: '',
                brand_name: '',
                buisness_contact: '',
                website: '',
                payroll_id: '',
                job_position: '',
                dba: '',
                user_id: '',
                airport_id: '',
                state_id: '',
                city_id: '',
                cityName: '',
                zipcode: '',
                work_zipcode: '',
                zipcode_id: '',
                country_id: '',
                work_state_id: 0,
                work_city_id: null,
                legal_business_address: '',
                business_support_email_address: '',
                business_telephone_number: '',
                service_addon_id: ''
            },
            form_start: false,
            configurationsNamesObj: {},
            pinOption: true
        };
    }

    

    componentDidMount() {
        let obj:any = {}
        let arr = [
            'DBW9P',
            'DW9P',
            'DICP',
            'DBDDP',
            'DDDP'
        ]
        arr.forEach(async (item: any, i: any) => {
            let response = await ConfigurationService.getConfigurationByName(item);
            if(response){
              obj = {
                  ...obj, [item]: response?.value
              }
              this.setState({ configurationsNamesObj : {...this.state.configurationsNamesObj, ...obj}}, () => {
            })
            }
        })
       
    }


    // handlePdfDownload = async (data: any, item: any) => {
    //     const url = data;
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', `ietm.pdf`);
    //     document.body.appendChild(link);
    //     link.click();
    // }

    validate = async () => {
        (this.state.companyName.trim() === '') ? this.setState({ companyNameErr: true }) : this.setState({ companyNameErr: false });
        (this.state.firstName === '') ? this.setState({ firstNameErr: true }) : this.setState({ firstNameErr: false });
        (this.state.lastName === '') ? this.setState({ lastNameErr: true }) : this.setState({ lastNameErr: false });
        if (this.state.email) {
            (!Helpers.validateEmail(this.state.email)) ? this.setState({ emailErrMsg: 'Invalid Email' }) : this.setState({ emailErrMsg: '' });
        } else {
            this.setState({ emailErrMsg: 'Email is required'});
        }

        if (this.state.mobile) {
            !Helpers.validateCountryPhone(this.state.mobile) ? this.setState({ mobileErrMsg: 'Invalid Mobile Number'}) : this.setState({ mobileErrMsg: '' })
        } else {
            this.setState({ mobileErrMsg:  "Mobile Number is required" });
        }

        (!this.state.terms) ? this.setState({ termsErr: true }) : this.setState({ termsErr: false });
        (!this.state.w9.file) ? this.setState({ w9Err: true }) : this.setState({ w9Err: false });
        (!this.state.deposit.file) ? this.setState({ depositErr: true }) : this.setState({ depositErr: false });
    }

    updateEmail = (event: any) => {
        this.setState({ email: event.target.value });
        this.setState({ emailErrMsg: ''})
    }

    updateWebsite = (event: any) => {
        let website =event.target.value;
        this.setState({ website: website });
        let isValid = !/\s/.test(website);
        this.setState({ websiteErr: !isValid });
    }

    updateMobile = async (event: any) => {
        this.setState({ mobile: event });
        this.setState({ mobileErrMsg: ''})
    }

    validateOtherFields = (event: any, type: string) => {
        if (!this.state.form_start) {
            const dataLayerObj: any = {
                'eventName': 'form_start',
                'formName': "TravelAgent",
                'event': 'formStart'
            };
            createEventGA4(dataLayerObj);
        }
        this.setState({form_start: true});
       
        if (type === 'company_name') {
            this.setState({ companyNameErr: false, companyName: event.target.value });
        }

        if (type === 'first_name') {
            this.setState({ firstNameErr: false, firstName: event.target.value.trim() });
        }
        if (type === 'last_name') {
            this.setState({ lastNameErr: false, lastName: event.target.value.trim() });
        }

        if (type === 'terms') {
            let isChecked: boolean = event.target.checked;
            this.setState({ termsErr: false, terms: isChecked });
        }

        /*if (type === 'business_address') {
            if (event.target.value.trim() === '') {
                this.setState({ businessAddressErr: false, businessAddress: event.target.value });
            } else {
                this.setState({ businessAddressErr: false, businessAddress: event.target.value });
            }
        }*/

    }

    createCorporateAccount = async () => {
        const dataLayerObj: any = {
            'eventName': 'form_submit',
            'formName': 'TravelAgent',
            'event': 'formSubmit'
        };
        createEventGA4(dataLayerObj);

        await this.validate();
        if (!this.state.firstNameErr &&
            !this.state.lastNameErr &&
            !this.state.emailErrMsg &&
            !this.state.companyNameErr &&
            !this.state.mobileErrMsg &&
            !this.state.businessAddressErr &&
            !this.state.websiteErr &&
            !this.state.termsErr &&
            !this.state.w9Err &&
            !this.state.depositErr
        ) {
            const payload: any = {
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                mobile_number: this.state.mobile,
                email_address: this.state.email,
                company_name: this.state.companyName.trim(),
                work_address: this.state.businessAddressObj,
                create_concierge: true, 
                created_by: 1,
                w9FileName: this.state.w9.file?this.state.w9.file.name:null,
                directDepositFileName: this.state.deposit.file?this.state.deposit.file.name:null,
                is_travel_agent :true,
                website: this.state.website !== '' ? this.state.website : null,
                refferedBy : this.props.refferedBy,
                pin_opt_in: this.state.pinOption
            }
            await this.props.saveTravelAgent(payload,this.state.w9,this.state.deposit);
        }

    }

    handleAddrChange = (event: any) => {
        let value = event;
        if (value.trim() !== '') {
            this.setState({ businessAddress: value, businessAddressErr: true });
        } else {
            this.setState({ businessAddress: value, businessAddressErr: false });
        }
    }

    handleBusinessAddressSelect = async (selection: any) => {
        const address = selection.original[0];
        let business_address = address.name;
        Promise.resolve(getLuxyAddress(address)).then(async (businessAddress: any) => {
            business_address = businessAddress.address.full_address;
            let business_address_obj = businessAddress;

            if (!businessAddress.address.postal_code) {

                let postalCode: any = await CommonService.getPOstalCodeDetails(businessAddress.coordinates.latitude, businessAddress.coordinates.longitude);

                if (postalCode) {
                    businessAddress.address.postal_code = postalCode.postal_code;
                }
            }

            isValidLuxyAddress(businessAddress);
            this.setState({ businessAddress: business_address });
            this.setState({ businessAddressObj: business_address_obj });
            this.setState({ businessAddressErr: false });
        }).catch((err) => {
            this.setState({ businessAddress: business_address });
            this.setState({ businessAddressErr: true });
        });
    }

    validateFile = (fileName:any, type:any, check:any) =>{
        const allowedFiles = ['.pdf'];
        const regex = new RegExp('.*\.(' + allowedFiles.join('|') + ')');
        if (!regex.test(fileName.toLowerCase())) {
          
            toast.error(`Please select pdf only.`, {
              position: toast.POSITION.TOP_RIGHT
            });
          return false;
        } else {
          return true;
        }
  };

    handleFileChange = (event: any, type: any) => {

        if (!this.validateFile(event && event.target && event.target.files[0] && event.target.files[0].name, type, event && event.target && event.target.files[0] && event.target.files[0].type)) {
            return;
          }
        
        let selectedFile = event.target.files[0];
        let bloburl = URL.createObjectURL(selectedFile);
        const reader = new FileReader();
        reader.onloadend = () => {
            if (type === 'w9') {
                this.setState({ w9Err: false })
                this.setState({ w9 : { file: selectedFile, previewUrl: bloburl }});
            }

            if (type === 'deposit') {
                this.setState({ deposit : { file: selectedFile, previewUrl: bloburl }});
                this.setState({ depositErr: false })
            }
        };
        reader.readAsDataURL(selectedFile);
      };

      deleteFile = (type: any) => {
            if (type === 'w9') {
                this.setState({ w9 : { file: null, previewUrl: null }});
            }
            if (type === 'deposit') {
                this.setState({ deposit : { file: null, previewUrl: null }});
            }
        };

        onInputClick = (event:any) => {
            event.target.value = ''
        }

    render() {
        return (
            <div>
                {
                    (this.props.corporateErr) &&
                    <Alert variant='danger'>
                        <b>Error!</b> {this.props.corporateErrMsg}.
                    </Alert>
                }
                <div className="sign-in">
                    <Row className='affiliateleadColumnContainer'>
                    <Col className='affiliateleadColumn'>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="companyName" className="float-left">COMPANY NAME <span className="importantfields">*</span></label>
                                <FormControl
                                    id="companyName"
                                    onChange={(event: any) =>
                                        this.validateOtherFields(event, 'company_name')
                                    }
                                    value={this.state.companyName}
                                    autoComplete="nope"
                                />

                                <div>
                                    <p className="text-danger">{
                                        (this.state.companyNameErr) ?
                                            'Company Name is required'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="firstName" className="float-left">FIRST NAME <span className="importantfields">*</span></label>
                                <FormControl
                                    id="firstName"
                                    onChange={(event: any) =>
                                        this.validateOtherFields(event, 'first_name')
                                    }
                                    value={this.state.firstName}
                                    autoComplete="nope"
                                />

                                <div>
                                    <p className="text-danger">{
                                        (this.state.firstNameErr) ?
                                            'First Name is required'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="lastName" className="float-left">LAST NAME <span className="importantfields">*</span></label>
                                <FormControl
                                    id="lastName"
                                    //placeholder={"Enter Last name"}
                                    onChange={(event: any) =>
                                        this.validateOtherFields(event, 'last_name')
                                        // this.setState({ lastName: event.target.value })
                                    }
                                    value={this.state.lastName}
                                    autoComplete="nope"
                                />
                                <div>
                                    <p className="text-danger">{
                                        (this.state.lastNameErr) ?
                                            'Last Name is required'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="email" className="float-left">EMAIL <span className="importantfields">*</span></label>
                                <FormControl
                                    id="email"
                                    //placeholder={"Enter Email"}
                                    onChange={(event: any) =>
                                        this.updateEmail(event)
                                        // this.setState({ email: event.target.value })
                                    }
                                    value={this.state.email}
                                    autoComplete="nope"
                                />
                                <div>
                                    <p className="text-danger">
                                        {
                                            this.state.emailErrMsg
                                        }
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <div className="form-group">
                                <label htmlFor="mobile" className="">MOBILE PHONE NUMBER <span className="importantfields">*</span></label>
                                {/* <FormControl
                                    id="mobile"
                                    // placeholder={"Enter Mobile Number"}
                                    onChange={(event: any) =>
                                        this.updateMobile(event)
                                        // this.setState({ mobile: event.target.value })
                                    }
                                    maxLength={16}
                                    value={this.state.mobile}
                                />
                                <div>
                                    <p className="text-danger">{
                                        (this.state.mobileErr) ?
                                            'Enter valid contact number'
                                            : null
                                    }</p>
                                </div> */}


                                {/* starts here */}
                                <PhoneInput
                                    defaultCountry='US'
                                    international
                                    onChange={(event: any) =>
                                        this.updateMobile(event)
                                    }
                                    value={this.state.mobile}
                                    autoComplete="nope"
                                />

                                <div>
                                    <p className="text-danger">{
                                        this.state.mobileErrMsg
                                    }</p>
                                </div>
                                {/* ends here */}
                            </div>




                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group business-address">
                                <label htmlFor="businessAddress" className="float-left"> BUSINESS ADDRESS</label>
                                <GooglePlacesComponent
                                    id='affliliate_addr'
                                    onPlaceChanged={this.handleBusinessAddressSelect}
                                    value={this.state.businessAddress}
                                    handleChange={(event: any) => this.handleAddrChange(event)}
                                    showFavorites={false}
                                    isGroundOnly={true}
                                />

                                <div>
                                    {/* <p className="text-danger">{
                                        (this.state.businessAddressErr) ?
                                            'Please select a complete address.'
                                            : null
                                    }</p> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="website" className="float-left"> WEBSITE </label>
                                <FormControl
                                    id="website"
                                    //placeholder={"Enter Email"}
                                    onChange={(event: any) =>
                                        this.updateWebsite(event)
                                        // this.setState({ email: event.target.value })
                                    }
                                    value={this.state.website}
                                    autoComplete="nope"
                                />
                                <div>
                                    <p className="text-danger">{
                                        (this.state.websiteErr) ?
                                            'Space not allowed in website.'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    </Col><Col className='affiliateleadColumn leftBorder'>
                    <Row>
                        <Col>
                            <div className="form-group">
                            Please see samples of each document below. You can download a blank form for w9, and direct deposit. PDF format is required.
                            </div>
                        </Col>
                        <br/>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group" style={{paddingTop:'20px'}}>
                                <div style={{height:'25px'}}> W9 DOCUMENT <span className="importantfields">*</span></div>
                                <br/>
                                <label htmlFor="w9" className="custom-file-upload float-left"><br/>
                                {!this.state.w9.file && <div style={{width: '50px'}}>Upload</div>}
                                {this.state.w9.file && <div style={{width: '50px'}}>Edit</div>}
                                <input type="file" name="w9" id="w9" onChange={(e) => this.handleFileChange(e, 'w9')} onClick={(e) => this.onInputClick(e)} accept="application/pdf"/>
                                </label>
                                {this.state.w9.file && (
                                <div>
                                <div className='fileName'>{this.state.w9.file.name}</div>
                                        <i className="fa fa-trash"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.deleteFile('w9')}
                                    ></i>
                                </div>
                                )}
                                <div style={{float: 'left'}}>
                                    <p className="text-danger">{
                                        (this.state.w9Err) ?
                                            'W9 Document is required'
                                            : null
                                    }</p>
                                </div>
                                <div style={{float: 'left'}}>
                                <a href={this.state.configurationsNamesObj.DW9P} target='_blank' download="W9 Document" style={{fontSize: "12px", textDecoration: 'underline' }}>Sample W9 Document</a>
                                <br/>
                                <a href={this.state.configurationsNamesObj.DBW9P} target='_blank' download style={{fontSize: "12px", textDecoration: 'underline' }}>Download Blank W9</a>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group" style={{paddingTop:'20px'}}>
                                <div style={{height:'25px'}}>DIRECT DEPOSIT DOCUMENT<span className="importantfields">*</span></div>
                                <br/>
                                <label htmlFor="directDeposit" className="custom-file-upload float-left"><br/>
                                {!this.state.deposit.file && <div style={{width: '50px'}}>Upload</div>}
                                {this.state.deposit.file && <div style={{width: '50px'}}>Edit</div>}
                                <input type="file" name="directDeposit" id="directDeposit" onChange={(e) => this.handleFileChange(e, 'deposit')} onClick={(e) => this.onInputClick(e)} accept="application/pdf"/>
                                </label>
                                {this.state.deposit.file && (
                                <div>
                                <div className='fileName'>{this.state.deposit.file.name}</div>
                                <i className="fa fa-trash"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.deleteFile('deposit')}
                                    ></i>
                                </div>
                                )}
                                <div style={{float: 'left'}}>
                                    <p className="text-danger">{
                                        (this.state.depositErr) ?
                                            'Direct Deposit Document is required'
                                            : null
                                    }</p>
                                </div>
                                <div style={{float: 'left'}}>
                                <a href={this.state.configurationsNamesObj.DDDP} target='_blank' download style={{fontSize: "12px", textDecoration: 'underline' }}>Sample Direct Deposit</a>
                                <br />
                                <a href={this.state.configurationsNamesObj.DBDDP} target='_blank' download style={{fontSize: "12px", textDecoration: 'underline' }}>Download Blank Direct Deposit</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group controlId="formBasicCheckbox" style={{paddingTop: 12}}>
                            <Form.Check type="checkbox"
                                className="custom-label"
                                label={'Opt in PIN Verification for security'}
                                onChange={(event) => {
                                    this.setState({ pinOption: !this.state.pinOption });
                                  }}
                                checked={this.state.pinOption}
                            />
                            <div>
                                <p className="summary-mini-text">
                                It is advised for Travel Arrangers to employ Luxy's Pin Verification Security Measure. However, the Travel Arranger retains the option to decline Luxy's Pin Verification by deselecting the checkbox. In the event that the Travel Arranger exercises this option, it acknowledges and assumes all liabilities, expenses, fraudulent activities, charges, or potential conflicts arising from the decision to opt out of Luxy's Pin Verification.
                                </p>
                            </div>
                        </Form.Group>
                    </Row>
                    </Col>
                    
                    </Row>
                    <Row>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox"
                                className="custom-label"
                                // style={{textTransform: 'none'}}
                                label={<AgreeText type="affiliate" />}
                                // checked={false}
                                onChange={(event: any) =>
                                    // this.setState({ terms: event.target.checked })
                                    this.validateOtherFields(event, 'terms')
                                }
                            />
                            <div>
                                <p className="text-danger">{
                                    (this.state.termsErr) ?
                                        'You should agree with our terms of service, privacy policy and cookie policy'
                                        : null
                                }</p>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                            <br />
                        </Col>
                        <Col>
                            <Button variant="submit float-right"
                                onClick={() => this.createCorporateAccount()}
                            >Create</Button>{' '}
                        </Col>
                    </Row>

                </div>
            </div>
        )
    }
}

export default TravelAgentForm;