export const GET_ALL_VEHICLES = "GET_ALL_VEHICLES";
export const UPDATE_BOOKING_STATE = "UPDATE_BOOKING_STATE";
export const NAVIGATE_TO_RIDE_DETAIL = 'NAVIGATE_TO_RIDE_DETAIL';
export const IS_GET_QUOTES_CLICKED = 'IS_GET_QUOTES_CLICKED';
export const LIST_TYPE = 'LIST_TYPE';


const defaultState = {
    allVehicles: [],
    vehicleListError: false,
      //region buffer code begins
    bookingState: {},
    vehicleErrorMessage : ''
      //region buffer code ends
}

export function bookARideReducer(state: any = defaultState, action: { type: string, data?: any }) {
    switch (action.type) {
        case GET_ALL_VEHICLES:
            if (action.data.length) {
                return {
                    ...state,
                    allVehicles: action.data,
                    vehicleListError: false,
                    vehicleErrorMessage : ''
                };
            } else {
                return {
                    ...state,
                    allVehicles: action.data.vehicles,
                    vehicleListError: action.data.error,
                    vehicleErrorMessage : action.data.vehicleErrorMessage ? action.data.vehicleErrorMessage : ''
                };
            }
        case UPDATE_BOOKING_STATE:
            // localStorage.setItem('bookingDetails', JSON.stringify(action.data));
            const clonedObject = JSON.parse(JSON.stringify(action.data));
            const keyToRemove = 'directions';
            delete clonedObject[keyToRemove];
            sessionStorage.setItem('bookingDetails',JSON.stringify(clonedObject))
            //    sessionStorage.setItem('bookingDetails',JSON.stringify(action.data))
            return {
                ...state,
                bookingState: action.data
            }
        case NAVIGATE_TO_RIDE_DETAIL: {
            return {
                ...state,
                navigateToRideDetail: NAVIGATE_TO_RIDE_DETAIL
            }
        }
        case IS_GET_QUOTES_CLICKED: {
            return {
                ...state,
                isGetQuotesClicked: action.data
            }
        }
        case LIST_TYPE: {
            return {
                ...state,
                listType: action.data
            }
        }
        default:
            return state;
    }
}
