import React from 'react';
import { Button, Modal } from "react-bootstrap";

interface ConfirmationProps {
    show: boolean;
    closeConfirmPopup: () => any;
    title: string;
    bodyText: string;
    callbackMethod: () => any;
    variant: string
}

function ConfirmationPopup(props: ConfirmationProps) {

    const handleClose = () => {
        props.closeConfirmPopup()
    }

    return (
        <>

            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.bodyText}
                </Modal.Body>
                <Modal.Footer className='border-top-0'>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant={props.variant}
                        onClick={() => props.callbackMethod()}
                    >Confirm</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConfirmationPopup;