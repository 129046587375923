import React from 'react';
import { RouteComponentProps, withRouter } from "react-router";
// import Logo from '../../assets/images/Image 6@2x.png';
import fb from '../../assets/images/fb.png';
import insta from '../../assets/images/insta.png';
import link from '../../assets/images/link.png';
import twit from '../../assets/images/twit.png';
import { Link } from 'react-router-dom';
import redirectToWP from '../../Utilities/common';
import { createEventGA4 } from '../../Utilities/helpers';
const Logo = process.env.REACT_APP_LOGO_URL;
const GreenInitiatives = require('../../assets/images/webpimages/Green Initiatives.webp');
interface FooterProps extends RouteComponentProps<any> {

}

interface FooterState {
  playStore: string,
  appStore: string
}

class Footer extends React.Component<FooterProps, FooterState> {

  constructor(props: FooterProps) {
    super(props);
    this.state = {
      playStore: 'https://play.google.com/store/apps/details?id=com.luxycustomer',
      appStore: 'https://apps.apple.com/us/app/Luxy%20Ride/id1473341696?ls=1'
    };
  }

  redirectTo = (event: any, menuName: string) => {
    const dataLayerObj: any = {
      'eventName': 'navigation',
      'navName': `${menuName.toLowerCase()}`,
      'navPosition': 'bottom',
      'event': 'navigation'
    };
    createEventGA4(dataLayerObj);
    event.stopPropagation();
    switch (menuName) {
      case 'ABOUT_US':
        redirectToWP('ABOUT_US');
        break;
      case 'FAQS':
        redirectToWP('FAQS');
        break;
      case 'PRIVACY':
        redirectToWP('PRIVACY');
        break;
      case 'TERMS':
        redirectToWP('TERMS');
        break;
      default:
        this.props.history.push('/');
        break;
    }
    return '#';
  }

  appIconClick = (appType: string) => {
    let appLink: string;
    appLink = (appType === 'App Store') ? this.state.appStore : this.state.playStore;
    const dataLayerObj: any = {
      'eventName': 'app_download_cta',
      'storeName': appType,
      'storeUrl': appLink,
      'event': 'appDownload'
    }
    createEventGA4(dataLayerObj);
  }

  socialIconClick = (socialName: string, URI: any) => {

    const dataLayerObj: any = {
      'eventName': 'social',
      'socialName': socialName,
      'socialUrl': URI,
      'event': 'social'
    }
    createEventGA4(dataLayerObj);
  }

  createGA4Events = (data: any) => {
    const obj: any = {
      'eventName': 'contact_us',
      'contactType': 'phone',
      'event': 'contactUs'
    }
    createEventGA4(obj);
  }

  render() {
    return (

      <div className="bg-dark p-4">
        <div className="row">
          <div className="col-xl-6 text-footer-center col-12">
            <img
              onClick={() => this.props.history.push('/')}
              alt=""
              src={Logo}
              height="48px"
              width="184px"
              className="d-inline-block align-top pointer"
            />
            <div className="footer-luxy text-footer-center">DOWNLOAD THE APP:</div>
            <div className="row mt-2">
              <div className="col-12  p-0">
                <div className="flex social-btns">
                  <a className="app-btn blu flex vert"
                    onClick={() => { this.appIconClick('App Store') }}
                    href={this.state.appStore}>
                    <i className="fab fa-apple"></i>
                    <p>Available on the <br /> <span className="big-txt">App Store</span></p>
                  </a>

                  <a className="app-btn blu flex vert"
                    onClick={() => { this.appIconClick('Play Store') }}
                    href={this.state.playStore}
                  >
                    <i className="fab fa-google-play"></i>
                    <p>Get it on <br /> <span className="big-txt">Google Play</span></p>
                  </a>


                </div>


              </div>
            </div>
          </div>
          <div className="col-xl-6 col-12">

            <div className="row">
            <div className="col-xl-4 col-12 text-footer-center text-right greenInitiatives">
              <a href={process.env.REACT_APP_LUXY_GREEN_INITIATIVES ? process.env.REACT_APP_LUXY_GREEN_INITIATIVES : '#' } target="_blank">
                <img
                alt="Green Initiatives"
                src={GreenInitiatives}
                height="120px"
                width="120px"
                className="d-inline-block align-top pointer"
              />
              </a>
              </div>
              <div className="col-xl-8 col-12 text-footer-center text-right">

              <div className="col-12  text-footer-center text-right">
                <div className="footer-right">
                <a className="footer-nav-menu"
                    href={process.env.REACT_APP_LUXY_DUTY_OF_CARE ? process.env.REACT_APP_LUXY_DUTY_OF_CARE : '#'} target='_balnk'>Duty of Care</a> |
                  <a className="footer-nav-menu"
                    // onClick={() => { ReactGA.event(googleEventAnalytics.callLuxy); }}
                    onClick={() => this.createGA4Events('+1 833-438-5899')}
                    href="tel:+1 833-438-5899"> 833.438.5899 </a> |

                  <Link className="footer-nav-menu"
                    // onClick={() => {ReactGA.event( googleEventAnalytics.aboutLuxy);}}
                    to='#'
                    onClick={(event) => this.redirectTo(event, 'ABOUT_US')}> ABOUT LUXY&#8482; </Link> |  <Link className="footer-nav-menu"
                      //  onClick={() => {ReactGA.event( googleEventAnalytics.faq);}}
                      to='#'
                      onClick={(event) => this.redirectTo(event, 'FAQS')}> FAQs</Link> <br />
                  <Link className="footer-nav-menu"
                    // onClick={() => {ReactGA.event( googleEventAnalytics.privacy);}}
                    to='#'
                    onClick={(event) => this.redirectTo(event, 'PRIVACY')}
                  // onClick={() => this.redirectTo(smenu.menu_url, smenu.menu_name, smenu.id)}
                  > Privacy </Link>| <Link className="footer-nav-menu"
                    // onClick={() => {ReactGA.event( googleEventAnalytics.terms);}}
                    to='#'
                    onClick={(event) => this.redirectTo(event, 'TERMS')}
                  >Terms & Conditions </Link>
                </div>
              </div>
              <div className="col-12 text-footer-center  text-right">
                <a href={process.env.REACT_APP_LUXY_FB}
                  onClick={() => this.socialIconClick('FaceBook', process.env.REACT_APP_LUXY_FB)}
                  target="_blank">
                  <img
                    alt="luxy-fb"
                    src={fb}
                    width="30px"
                    height="30px"
                    className="d-inline-block align-top pointer"
                  />&nbsp;&nbsp;&nbsp;&nbsp;</a>

                <a href={process.env.REACT_APP_LUXY_INSTA}
                  onClick={() => this.socialIconClick('Instagram', process.env.REACT_APP_LUXY_INSTA)}
                  target="_blank"><img
                    alt="luxy-insta"
                    src={insta}
                    width="30px"
                    height="30px"
                    className="d-inline-block align-top pointer"
                  />&nbsp;&nbsp;&nbsp;&nbsp;</a>
                <a href={process.env.REACT_APP_LUXY_LINKEDIN}
                  onClick={() => this.socialIconClick('LinkedIn', process.env.REACT_APP_LUXY_LINKEDIN)}
                  target="_blank"><img
                    alt="luxy-linkedIn"
                    src={link}
                    width="30px"
                    height="30px"
                    className="d-inline-block align-top pointer"
                  />&nbsp;&nbsp;&nbsp;&nbsp;</a>
                <a href={process.env.REACT_APP_LUXY_TWITTER}
                  onClick={() => this.socialIconClick('Twitter', process.env.REACT_APP_LUXY_TWITTER)}
                  target="_blank"><img
                    alt="luxy-twitter"
                    src={twit}
                    width="30px"
                    height="25px"
                    className="d-inline-block align-top pointer"
                  /></a>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Footer);