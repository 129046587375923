import React from 'react';
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface ConfirmProps {
    show: boolean;
    closeConfirmPopup: () => any;
    title: string;
    bodyText: string;
    deleteMethod: () => any;
}

function ConfirmPopup(props: ConfirmProps) {
    // const [show, setShow] = useState(false);

    const handleClose = () => {
        props.closeConfirmPopup()
    }
    // const handleShow = () => setShow(true);

    return (
        <>

            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                 <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title>
                        {props.title}
                    </Modal.Title>
                </Modal.Header> 
                <Modal.Body>
                    {props.bodyText}
                </Modal.Body>
                <Modal.Footer className='border-top-0'>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
            </Button>
                    <Button variant="danger"
                    onClick={() => props.deleteMethod()}
                    >Continue</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConfirmPopup;