import React, { Component } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { AuthService } from '../../services/auth';
import { USER_ACTIVATION } from '../../reducer/user';
import logo from '../../assets/images/Image 6@2x.png';

interface ActivateUserProps {
    activationUserData: any;
    updateUserActivation: () => void;
    closeModal: () => any;
}

interface ActivateUserState {
    user: {
        email_address: string;
        mobile_number: any;
        password: string;
        user_id: number;
        user_type: string;
        encrypted_token: string;
    },
    terms: boolean;
    termsErr: boolean;
    mobileErr: boolean;
    passwordErr: boolean;
}

class ActivateUser extends Component<ActivateUserProps, ActivateUserState> {
    public passCodeRef: any = React.createRef();
    constructor(props: ActivateUserProps) {
        super(props);
        this.state = {
            user: {
                email_address: '',
                mobile_number: '',
                password: '',
                user_id: 0,
                user_type: '',
                encrypted_token: ''
            },
            terms: false,
            termsErr: false,
            mobileErr: false,
            passwordErr: false
        }
    }

    componentDidMount = () => {

        if (this.props.activationUserData.user_id !== 0) {
            this.props.activationUserData['password'] = '';

            this.setState({ ...this.state, user: this.props.activationUserData }, () => {

            });
        }
    }

    updateState = (ev: any, key: string) => {
        const userState = { ...this.state.user };
        if (key === 'password') {
            userState.password = ev.target.value;
            this.setState({ user: userState }, () => {
                // console.log("user state", this.state.user)
            });
        }
    }

    validate = () => {
        (this.state.user.password === '') ? this.setState({ passwordErr: true }) : this.setState({ passwordErr: false });
    }

    activateUser = async () => {
        await this.validate();
        let userState = this.state;

        if (!this.state.passwordErr) {
            const payload = {
                encrypted_token: userState.user.encrypted_token,
                password: userState.user.password
            }

            await AuthService.updateUserActivation(payload);
            this.props.updateUserActivation();

            this.props.closeModal();

        }

    }

    togglePassWord = () => {
        const textType = this.passCodeRef.current.type;
        if (textType === 'password') {
            this.passCodeRef.current.type = 'text';
        } else {
            this.passCodeRef.current.type = 'password';
        }

    }

    render() {
        const userObj = this.state.user;
        return (
            <>
                <div>
                    <img src={logo} width="180" />
                </div>

                <div>
                    <h3>Confirm Your Account</h3>
                </div>

                <div>
                    <p className="info">Username: <b>{userObj.email_address}</b></p>
                </div>

                <div>
                    <InputGroup className="mb-3">

                        <FormControl
                            type="password"
                            placeholder="Password"
                            aria-label="Password"
                            aria-describedby="basic-addon1"
                            onChange={(ev: any) => this.updateState(ev, 'password')}
                            ref={this.passCodeRef}
                            autoComplete='nope'
                        />
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1"><i className="fa fa-eye pointer"
                                onClick={() => this.togglePassWord()}
                            ></i></InputGroup.Text>
                        </InputGroup.Prepend>
                    </InputGroup>


                    <div>
                        <p className="text-danger">{
                            (this.state.passwordErr) ?
                                'Password field is required!'
                                : null
                        }</p>
                    </div>
                </div>

                <div className="float-left">
                    <Button
                        className="btn-txt"
                        onClick={() => this.activateUser()}
                        variant="submit float-right">Confirm Luxy Account</Button>{' '}
                </div>

            </>
        )
    }
}


type Dispatch = (action: any) => void;

const updateUserActivation = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: USER_ACTIVATION, data: false
        });
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

        updateUserActivation: () => dispatch(updateUserActivation())
    };
};

const mapStateToProps = (state: any) => {
    return {
        activationUserData: state.user.userActivationData
    };
};


const ActivateUserContainer = connect(mapStateToProps, mapDispatchToProps)(ActivateUser);

export default ActivateUserContainer;