// import config from "react-global-configuration";
import { formatNumber } from "./Utilities/NumberUtilities";

export default {
    REGION_ID: 0,
    MANUAL_FARM_OUT_HOUR: 24
};

export const MONEY = {
    NO_AMOUNT: formatNumber(0)
}

export const ADDONS_ID = {
    ARRIVAL_MEET_GREET: '1',
    TRAVELLING_WITH_PET: '2',
    ADDITIONAL_CAR_SEAT: '3'
};

export const CONTACT_US = {
    PHONE_NUMBER: '(833) 438-5899',
    EMAIL: 'info@luxyride.com',
    GET_LUXY_NUMBER: '833.GET.LUXY'
};

export const USER_TYPE = {
    CUSTOMER: 1,
    ADMIN: 2,
    CSR: 3,
    MANAGER: 4,
    AFFILIATE: 5,
    DRIVER: 6
};

export const RIDE_STATUS = {
    DEFAULT: 0,
    QUOTED: 7,
    AVAILABLE: 8,
    UPCOMING: 9,
    IN_PROGRESS: 11,
    COMPLETED: 12,
    CANCELLED: 13,
    PENDING_AVAILABILITY: 16,
}

export const HOURS_TO_AVOID_REMOVING_CLAIMED_RIDES = 10;


export const BUFFER_EXPIRATION_TIME = {
    BOOKING_WIDGET: 0,
    DISPATCHER: 0,
    CUSTOM: 0
};

export const APP_STORE_URL = {
    DRIVER: {
        playStore: "http://play.google.com/store/apps/details?id=com.luxydrivers",
        appStore: "https://apps.apple.com/us/app/Luxy%20Driver/id1474386165?ls=1"
    },
    CUSTOMER: {
        playStore: "http://play.google.com/store/apps/details?id=com.luxycustomer",
        appStore: "https://apps.apple.com/us/app/Luxy%20Ride/id1473341696?ls=1"
    }
}

export const SEAT = new Map()
    .set('Rear-Facing Car Seat (Ages 0-3)', 'baby_car_seat_price')
    .set('Forward-Facing Car Seat (Ages 1-7)', 'toddler_car_seat_price')
    .set('Booster Seat (Ages 4-12)', 'booster_car_seat_price');

export const GOOGLE_ANALYTICS_MEASUREMENTS = {
    REFUND: 'refund',
    PURCHASE: 'purchase',
    BEGIN_CHECKOUT: 'begin_checkout'
}

export const CITY = ["locality", "sublocality_level_1", "neighborhood", "administrative_area_level_3"];

export const CHILD_SEATS_ID = {
    "Rear-Facing Car Seat (Ages 0-3)": "1",
    "Forward-Facing Car Seat (Ages 1-7)": "2",
    "Booster Seat (Ages 4-12)": "3"
}

export const HTTP_STATUS_CODES = {
    CONFLICT: 409,
    NOT_FOUND: 404,
    EVERYTHING_IS_OK: 200
}

export const HTML_ASCII = {
    SPACE: 32,
    APOSTROPHE: 39,
    COMMA: 44,
    HYPHEN: 45,
    PERIOD: 46, //Point
    UPPERCASE_A: 65,
    UPPERCASE_Z: 90,
    LOWERCASE_A: 97,
    LOWERCASE_Z: 122,
}

export const HEADERS = {
    "Content-Type": "application/json",
    // Authorization: config.get("authenticationtoken")
};

export const AGREEMENTS_USER_TYPES = {
    CUSTOMER_AND_DRIVER: 1,
    AFFILIATE: 2
}

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\`\~\!\@\#\$\%\^\&\*\(\)\-\_\=\+\[\]\{\}\|\;\'\:\"\,\.\/\<\>\?\\\s])[A-Za-z\d\`\~\!\@\#\$\%\^\&\*\(\)\-\_\=\+\[\]\{\}\|\;\'\:\"\,\.\/\<\>\?\\\s]{8,}$/
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const GRATUITY = {
    PAY_LATER: 4,
}

export const SALES_TAX = {
    EARLY_PICK_UP : 'Early Pick Up',
}

export const STATUS_FOR_REFUND = [
    'settled',
    'settling',
];


export const STATUS_FOR_VOID = [
    'authorized',
    'submitted_for_settlement',
];

export const MAX_TRIPS_PER_ORDER = 8;

export const MESSAGES = {
    TRANSACTION_CANNOT_BE_REFUNDED: 'The transaction cannot be refunded at this time. Please contact 833.GET.LUXY for immediate assistance.',
    SEARCH_CUSTOMER_SAME_AS_AFFILIATE: 'Please first search for a customer in the customer details section.',
    INVALID_FLIGHT_INFO: 'Invalid flight information',
    OOPS_SOMETHING_WENT_WRONG: 'Oops, something went wrong while processing your request.',
    INVALID_PAYMENT_METHOD: 'The transaction cannot be completed. Invalid selected payment method.',
    OOPS_SOMETHING_WENT_WRONG_CUSTOMERS:'Oops, something went wrong consulting customers',
    OOPS_SOMETHING_WENT_WRONG_MOBILE: 'Oops, something went wrong with the mobile number',
    INVALID_CUSTOMER_FIELDS: 'Please check all customer details required fields and validation.',
    BOOKING_SUCCESSFUL: 'Reservation has been saved successfully !',
    CUSTOMER_ACTIVATED_SUCCESSFULLY: 'Customer activated successfully',
    WANT_TO_ACTIVATE_CUSTOMER: 'Are you sure you want to activate this customer?',
    WANT_TO_CREATE_CUSTOMER: 'The entered email is not registered yet. \n Check if the following info is valid and press YES:',
    CUSTOMER_CREATED_SUCCESSFULLY: 'Customer created',
    MAX_TRIPS_PER_ORDER: `You cannot add more than ${MAX_TRIPS_PER_ORDER} trips!`,
    WANT_TO_REMOVE_TRIP: 'Would you like to remove this trip from the list?',
    CARD_VALIDATION_FAILED: 'Invalid Card, Please enter valid card details to continue.',
    NO_INPROGRESS_TRIPS: 'There are no In progress trips available currently.',
    NO_PAST_TRIPS: 'There are no past trips available.',
    NO_UPCOMING_TRIPS: 'There are no upcoming trips available.'
}

export const TITLE = {
    ACTIVATE_CUSTOMER: 'Activate Customer',
    CREATE_CUSTOMER: 'Create Customer',
    REMOVE_TRIP: 'Remove trip',
}

export const SERVICE_TYPE_ID = {
    AIRPORT: 1,
    GROUND: 2,
    HOURLY: 3,
    SPECIAL_EVENT: 4
};

export const DEFAULT_SUB_STATUS = {value: '0#0', label: "All"};


export const EXCLUDE_COLUMNS = ['drop_off_address', 'pick_up_address', 'trip_created_date'];

export const AFFILIATE_PROCESSING_FEE_PERCENTAGE = 0.035;

export const SERVICE_FIELDS_WITHOUT_PRICE_CALCULATION = {
    flight_number: 'flight_number',
    internal_notes: 'internal_notes',
    passenger_notes: 'passenger_notes',
    passenger_details: 'passenger_details',
    is_same_as_customer: 'is_same_as_customer',
};

export const SERVICE_FIELDS_WITH_PRICE_CALCULATION = {
    customer_addons_price: 'customer_addons_price',
    extra_services_price: 'extra_services_price',
    additional_car_seat: 'additional_car_seat',
    travelling_with_pet: 'travelling_with_pet'
};

export const SERVICE_DETAILS_FIELDS = {
    GRATUITY_FIELD: "GratuityPercentageOrId",
    ARRIVAL_MEET_GREET_FIELD: "arrival_meet_greet",
    AIRLINE_CODE_FIELD: "airline_code",
    TRIP_PROTECTION_SELECTED: 'trip_protection_selected',
    ESPECIAL_SEAT_ADDON_ID: "special_seat_addon_id",
    IS_CAR_SEAT: "is_car_seat",
};

export const SERVICE_DETAILS_PASSENGER_FIELDS = {
    FIRST_NAME: "f_name",
    LAST_NAME: "l_name",
    EMAIL: "email"
};


export const ROUTE_DETAILS_FIELDS = {
    TRIP_TYPE: "trip_type",
    EARLY_PICK_UP_AMOUNT: "earlyPickUpAmount",
    VEHICLE_PRICE_OR_ID: "vehiclePriceOrId",
    PHONE_CUSTOMER_BOOKING_FEE:"phoneCustomerBookingFee",
    TIME: "time",
    PICK_UP_ZONE: "pick_up_zone",
    SELECTED_VEHICLE:"selected_vehicle",
    EXTRA_STOPS: "extra_stops",
    PICK_UP_TIMEZONE_DATE: "pickup_timezone_date",
    DATE: "Date",
    CUSTOMER_EXTRA_STOPS_PRICE: "customer_extra_stops_price",
    REGION_AMOUNT: "region-amount"
};

export const ROUTE_DETAILS_SERVICE_TYPE = {
    GROUND: "ground",
    HOURLY: "hourly",
    AIRPORT: "airport",
};

export const ROUTE_DETAILS_SERVICE_INDEX = {
    GROUND: 0,
    HOURLY: 1,
};

export const ROUTE_DETAILS_SERVICE_ID = {
    AIRPORT: 1,
    GROUND: 2,
    HOURLY: 3,
};

export const DATE_FORMAT = {
    TIME_AM_PM: "h:mm A",
    TIME_HH_MM: "HH:mm",
    DATE_MM_DD_YYYY: "MM-DD-YYYY",
    DATE_YYYY_MM_DD: "YYYY-MM-DD",
    DATE_TIME: "MM-DD-YYYY h:mm A",
    DATE_AND_TIME_WITH_COMMA: 'MM-DD-YYYY, h:mm a'
};

export const CUSTOMER_DETAILS_FIELDS = {
    CUSTOMER_DETAILS: "customerDetails",
    SEARCH_PASSENGER: "searchForPassenger",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    EMAIL: "email",
    MOBILE: "mobile",
    IS_ACTIVE: "isActive",
}

export const CUSTOMER_DETAILS_FIELDS_REQUIRED = {
    FIRST_NAME: "First name is required.",
    LAST_NAME: "Last name is required.",
    EMAIL: "Email is required.",
    MOBILE: "Mobile is required.",
    IS_ACTIVE: "isActive",
}

export const USER_STATUS = {
    ACTIVE: 1,
    INACTIVE: 2,
}

export const TOAST_ID = {
    ERROR_CUSTOMER_DETAILS: 1,
    SUCCESSFUL_CUSTOMER_DETAILS: 2,
    BOOKING_SUCCESSFUL: 3,
    BOOKING_ERROR: 4,
}

export const TOAST_TYPE = {
    SUCCESS: 1,
    ERROR: 2,
}

export const DEFAULT_TRIP = {
        id: null,
        date: "",
        dropoff: "",
        pickup: "",
        serviceType: "",
        time: "",
        tripTotal: "",
        phoneBookingFee: "",
        customerGratuityPrice: "",
        customerTripPrice: "",
        extrasPrice: "",
    }

export const APP_USER_TYPE = {
    CUSTOMER: 'CUSTOMER',
    ADMIN: 'ADMIN',
    CSR: 'CSR',
    MANAGER: 'MANAGER',
    AFFILIATE: 'AFFILIATE',
    DRIVER: 'DRIVER',
    BOOKING_AGENT: 'BOOKING_AGENT',
    DISPATCHER: 'DISPATCHER'
}

export const passwordFormatString = 'Secured passwords are at least 8 characters long and include uppercase, numbers, and symbols.';

export const FAV_ADD = 'FAVORITE_ADDRESS';

export const FAV_ADD_TYPE = {
    AIRPORT: 'airport',
    HOME: 'home',
    WORK: 'work'
}

export const ECOMMERCE = {
    VIEW: 'view_item_list',
    SELECT: 'select_item',
    ADD: 'add_to_cart',
    REMOVE: 'remove_from_cart',
    BEGIN_CHECKOUT: 'begin_checkout',
    PURCHASE: 'purchase'
}

export const SAME_LOCATION_ERR = 'Please select different locations';