export const GET_CONFIG = 'GET_CONFIG';
export const RIDE_REWARDS = 'RIDE_REWARDS';

export function configurationReducer(state: any = {}, action: { type: string, data?: any }) {
    switch (action.type) {
        case GET_CONFIG:
            return {
                ...state,
                configData: action.data
            };
        case RIDE_REWARDS:
            return {
                ...state,
                rideRewardsConfigData: action.data
            };
        default:
            return state;
    }
}