import React from 'react';
import aboutBg from '../../assets/images/about-bg.png';
import { Row } from 'react-bootstrap';
import queryString from 'query-string';
import dropin from 'braintree-web-drop-in';
import { ToastContainer, toast } from 'react-toastify';
import PaymentService from '../../services/PaymentService';
import { StatusCode } from '../../constants/status-codes';
import { trackPromise } from "react-promise-tracker";
var instanceval: any = '';


class RidePayments extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            trip_id: '',
            confirmation: '',
            email: '',
            b_c_id: '',
            trip_total: '',
            user_id: 0

        }
    }

    componentDidMount() {
        this.props.createCustomerToken("").then(() => {
            this.CreateDropIn();
        });
        let querystringArr: any = queryString.parse(this.props.location.search);
        // console.log(querystringArr);
        if (querystringArr) {
            const { email, confirmation, trip_id, b_c_id, trip_total, user_id } = querystringArr;
            this.setState({ email, confirmation, trip_id, b_c_id, trip_total, user_id });
            const reqObj = {
                trip_id,
                payment_nonce: "",
                braintree_customer_id: b_c_id,
                user_id: user_id
    
            }
            PaymentService.getVendorPayments(reqObj).then(data => {
    
            if(data && data.error && data.error.data && data.error.data.status === StatusCode.BAD_REQUEST) {
                toast.error(data.error.data.message);
                // this.resetDropin();
            }
        })
    
        }
        
    }
    // componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    //     console.log(this.props, '2222');
    // }

    CreateDropIn = () => {
        dropin.create({
            authorization: this.props.clientToken,
            container: '#dropin-container',
            card: {
                cardholderName: {
                    required: true
                }
            }
        }, (createErr: any, instance: any) => {
            instanceval = instance;
        })
    }

    createNonce = async () => {
        const payment_token = this.props.paymentMethodToken;
        // Check for add new card.

        if (payment_token == "") {

            if (payment_token == '') {
                return new Promise((resolve, reject) => {
                    if (instanceval != undefined && instanceval != '') {
                        instanceval.requestPaymentMethod((err: any, payload: any) => {

                            if (payload != undefined) {
                                resolve(payload.nonce);
                            } else {
                                toast.error("Please select a payment method !");
                                resolve(0);
                            }
                        });
                    }
                });
            } else {
                return new Promise(async (resolve, reject) => {
                    await this.props.getPaymentNonce(payment_token);
                    resolve(this.props.paymentNonceData);
                });
            }

        }
        else {
            return new Promise(async (resolve, reject) => {
                await this.props.getPaymentNonce(payment_token);
                resolve(this.props.paymentNonceData);
            });
        }
    }

    confirmPayment = async () => {
        const tokencc_data = await this.createNonce();
        if(!tokencc_data) {
            return;
        }
        await this.props.createPaymentMethod(this.state.b_c_id, tokencc_data);
        const nonceData = await this.createNonce();

        const reqObj = {
            trip_id: this.state.trip_id,
            payment_nonce: nonceData,
            braintree_customer_id: this.state.b_c_id,
            user_id: this.state.user_id

        }
        const data = await PaymentService.processVendorPayments(reqObj);

        if(data && data.error && data.error.data && data.error.data.status === StatusCode.BAD_REQUEST) {
            toast.error(data.error.data.message);
            this.resetDropin();
        }
        if(data && data.status === StatusCode.EVERYTHING_IS_OK) {
            toast.success(data.message);
            this.resetDropin();
        }
    }

    resetDropin = async () => {
        instanceval.clearSelectedPaymentMethod();
    }

    render() {
        return (
            <>
                <div>
                    <div className='about-top'>
                    <div>
                <ToastContainer />
            </div>

                        <div className='get-luxy-widget'>

                            <Row>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 about-heading'>
                                    <h1 className='text-center'>
                                        LUXY Ride - Trip Payments
                                    </h1>
                                </div>
                            </Row>
                            <img style={{ width: '100%', height: '135px' }} src={aboutBg} alt='about' />
                        </div>
                    </div>

                    <div className='container mx-auto about-content'>
                        <Row>

                            <p className='about-p1'>
                                LUXY Ride aims to make your journey better. We provide a simple booking experience comparable to common ride-hailing apps, except it raises the bar. Unlike the major ride-hailing apps, which rely on part-time drivers and personal cars, every LUXY ride is chauffeured by a licensed professional in a new, safety-inspected luxury sedan or SUV, ensuring a reliable, comfortable, and safe journey to and from airports across the country. Travelers can get quotes and book rides on the LUXY website, the free LUXY Ride mobile app for iPhone/iPad and Android, or by calling one of LUXY's live customer service representatives available 24 hours a day, seven days a week.
                            </p>

                        </Row>
                        <hr />
                        <Row>
                            <b> TRIP ID: {this.state.confirmation}</b>
                        </Row>

                        <Row>
                            <b> AMOUNT: ${this.state.trip_total}</b>
                        </Row>

                        <Row style={{ display: 'inline-flex' }}>
                        <div className="col-sm-12" >
                            <div id="dropin-container">

                            </div>
                        </div>
                    </Row>

                        <div className="offset-md-9 col-md-3 col-12 float-right">

                            <button
                                style={{ width: '100%' }}
                                // disabled={this.state.disableConfirmBooking}
                                onClick={() => trackPromise(this.confirmPayment())}
                                className="btn btn-block primary custom-button">CONFIRM PAYMENT</button>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}
export default RidePayments;