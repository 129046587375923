import React, { Component } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { StatusCode } from '../../constants/status-codes';
import CommonService from '../../services/CommonService';
import TravelAgentForm from './travel-agent-form';
import logo from '../../assets/images/Image 6@2x.png';
import Alert from 'react-bootstrap/esm/Alert';
import { createEventGA4 } from '../../Utilities/helpers';


interface TravelAgentProps {
    closeModal: any;
    refferedBy : any;
}

interface TravelAgentState {
    showModal: boolean;
    corporateErr: boolean;
    corporateErrMsg: string;
    isSuccess: boolean;
}

// function ContextAwareToggle({ children, eventKey, callback }: any) {
//     const currentEventKey = useContext(AccordionContext);

//     const decoratedOnClick = useAccordionToggle(
//         eventKey,
//         () => callback && callback(eventKey),
//     );

//     const isCurrentEventKey = currentEventKey === eventKey;
//     {

//         return (
//             <span><b>
//                 {
//                     (isCurrentEventKey) ? <span className="plus-icon"> -  </span> : <span className="plus-icon"> + </span>
//                 }
//                 {children}
//             </b></span>
//         );
//     }
// }



class TravelAgent extends Component<TravelAgentProps, TravelAgentState> {
    constructor(props: TravelAgentProps) {
        super(props);

        this.state = {
            showModal: false,
            corporateErr: false,
            corporateErrMsg: '',
            isSuccess: false
        }
    }

    handleJoinCorporate = () => {
        this.setState({
            showModal: true,
            corporateErr: false,
            corporateErrMsg: '',
            isSuccess: false
        });
    }

    closeModal = () => {
        this.props.closeModal();
        this.setState({ showModal: false });
    }

    uploaFileToS3 = (url:any,file: File) =>{
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', url);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    // console.log("uploaded"  + file.name);
                } 
            }
        };
        xhr.setRequestHeader('Content-Type', 'multipart/form-data');
        xhr.send(file);
    }

    handleSaveTravelAgent = async (payload: any, w9 :any, directDeposite:any) => {
        let response: any = await CommonService.saveCompany(payload);
        try {
            if (response.data && response.data.data[0] && response.data.data[0].w9UploadUrl) {
                this.uploaFileToS3(response.data.data[0].w9UploadUrl, w9.file);
            }
            if (response.data && response.data.data[0] && response.data.data[0].directDepositUploadUrl) {
                this.uploaFileToS3(response.data.data[0].directDepositUploadUrl, directDeposite.file);
            }
        } catch (error) {
            // console.log(error);
        }

        if (response.data.status === StatusCode.EVERYTHING_IS_OK) {
            this.setState({
                showModal: true,
                corporateErr: false,
                corporateErrMsg: '',
                isSuccess: true
            });
        } else {
            this.setState({
                showModal: true,
                corporateErr: true,
                corporateErrMsg: response.data.status === StatusCode.ALREADY_EXIST ?
                    'This Company or email address already exists in our system' : 'Something went wrong please try again!',
                isSuccess: false
            });
        }
    }

    renderModalPopup = () => {
        // console.log('isSuccess', this.state.isSuccess);
        if (this.state.isSuccess) {
            const dataLayerObj: any = {
                'eventName': 'form_submit_success',
                'formName': 'TravelAgent',
                'event': 'formSubmitSuccess'
            };
            createEventGA4(dataLayerObj);
        }
        return <Modal show={true} style={{ top: '50px' }} className="affiliate-registration-modal">
            <div className="float-right">
                <i className="fa fa-times float-right pointer"
                    onClick={() => this.closeModal()}
                    title="close"></i>
            </div>
            <Modal.Body>
                <div>
                    <div className="modal-img">
                        <img src={logo}
                            width="180"></img>
                    </div>
                </div>
                {
                    (!this.state.isSuccess) ? <TravelAgentForm saveTravelAgent={this.handleSaveTravelAgent}
                        corporateErr={this.state.corporateErr}
                        corporateErrMsg={this.state.corporateErrMsg} 
                        refferedBy = {this.props.refferedBy}
                        /> : <div>
                        <div className="sign-in">
                            <Alert variant='success'>
                                <b>Success!</b> Thank you signing up for our LUXY™ Travel Agent program.
                            </Alert>
                        </div>

                    </div>
                }

            </Modal.Body>
        </Modal>
    }

    render() {
        return (
            <div>
                {
                    this.renderModalPopup()
                }
            </div>
        )
    }
}

export default TravelAgent;