import { connect } from "react-redux";
import { UPDATE_BOOKING_FOR } from "../reducer/details";
import BookingFor from "../components/book-a-ride/booking-for";

type Dispatch = (action: any) => void;


const updateBookingFor = (data: string) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_BOOKING_FOR, data: data
        });
    }
}


const mapStateToProps = (state: any) => {
    return {
        booking_for: state.details.booking_for,
        travel_option_addon: state.details.travel_option_addon
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateBookingFor: (data: string) => dispatch(updateBookingFor(data))

    };
};

const BookingForContainer = connect(mapStateToProps, mapDispatchToProps)(BookingFor);

export default BookingForContainer;