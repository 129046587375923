import React from 'react';
import Addon from './addon';

interface AddonsProps {
    addonsData: any;
    selectedVehicle: any;
    meetAndGreetData: any;
    childSeatValues: any
    updateAddonData: (data: any, selectedAddons: any) => any;
    updateMeetandGreetData: (data: any) => any;
    updateChildSeatData: (data: any) => any;
    confirmBookingValidation: any;
    updateConfirmBookingValidation: (data: any) => any;
    showAirLines: boolean;
    carSeatTypes:any;
    setChildSeatToggle: any;
    isChildSeatToggleOn: boolean;
}

class Addons extends React.Component<AddonsProps, any>{

    constructor(props: AddonsProps) {
        super(props);
    }

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.addonsData != this.props.addonsData) {
            
        }
    }

    handleAddonChange = (event: any, addon: any) => {
        //updateAddonData
        const { addonsData } = this.props;
        let updatedAddons = addonsData.map((addonData: any) => {
            if (addonData.id == addon.id) {
                if (event.target.checked) {
                    addonData.isActive = true;
                } else {
                    addonData.isActive = false;
                }
            }
            return addonData;
        });
        const selectedAddons = updatedAddons.filter((addon_in: any) => { return (addon_in.isActive == true) });
        this.props.updateAddonData(updatedAddons, selectedAddons);
        // console.log( selectedAddons, updatedAddons, event.target.checked, addon, this.props.addonsData);
        // console.log(selectedAddons); 
    }

    updateConfirmBookingValidation = (data: any) => {
        this.props.updateConfirmBookingValidation(data);
    }
    render() {
        

        return (
            <div className="mt-2">
                <h5>
                    Extras
                </h5>
                <div>
                    {
                        (this.props.addonsData && this.props.addonsData.length) ?
                            this.props.addonsData.map((addon: any, i: number) => {
                            // console.log("12312", addon)
                                return <Addon
                                    confirmBookingValidation={this.props.confirmBookingValidation}
                                    addon={addon}
                                    key={i}
                                    handleAddonChange={this.handleAddonChange}
                                    updateMeetandGreetData={this.props.updateMeetandGreetData}
                                    updateChildSeatData={this.props.updateChildSeatData}
                                    selectedVehicle={this.props.selectedVehicle}
                                    meetAndGreetData={this.props.meetAndGreetData}
                                    childSeatValues={this.props.childSeatValues}
                                    updateConfirmBookingValidation={this.updateConfirmBookingValidation}
                                    showAirLines={this.props.showAirLines}
                                    carSeatTypes={this.props.carSeatTypes}
                                    setChildSeatToggle={this.props.setChildSeatToggle}
                                    isChildSeatToggleOn={this.props.isChildSeatToggleOn}
                                />
                            }) : null
                    }
                </div>
            </div>
        )
    }

}

export default Addons;