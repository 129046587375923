import React from 'react';
import { InputGroup, FormControl, Container, Row, Button } from 'react-bootstrap';
import * as AuthService from '../../services/auth';
class User extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            id: 0,
            firstName: "",
            lastName: ""
        }
    }

    componentDidMount() {
        
        if (this.props.location.state && this.props.location.state.userId) {
            let userIndex = this.props.allUsers.findIndex((el: any) => el.id === this.props.location.state.userId);
            this.setState({
                id: this.props.allUsers[userIndex].id,
                firstName: this.props.allUsers[userIndex].firstName,
                lastName: this.props.allUsers[userIndex].lastName
            });
        }
    }

    saveUserDetails = async () => {
        let isUpdated = false;
        let user = {
            id: (!this.state.id) ? this.props.allUsers.length + 1 : this.state.id,
            firstName: this.state.firstName,
            lastName: this.state.lastName
        }
        if (this.state.id) {
            isUpdated = !isUpdated;
        } else {

        }

        await AuthService.AuthService.getAccessToken();
        

        await this.props.saveUser(user, this.props.allUsers, isUpdated);
        this.props.history.push("/users");
    }

    render() {
        return (
            <>
                <Container>
                    <br />
                    <br />

                    <Row >
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>

                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Username"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                onChange={(ev: any) => this.setState({ firstName: ev.target.value })}
                                value={this.state.firstName}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>

                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="LastName"
                                aria-label="LastName"
                                aria-describedby="basic-addon1"
                                onChange={(ev: any) => this.setState({ lastName: ev.target.value })}
                                value={this.state.lastName}
                            />
                        </InputGroup>
                        <div >
                            <Button className=""
                                onClick={() => this.saveUserDetails()}
                                variant="primary"
                                >Save</Button>{' '}
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}
export default User;