import { connect } from "react-redux";
import ChangeVehicle from "../components/ride-details/change-vehicle";
import { LIST_TYPE, UPDATE_BOOKING_STATE } from "../reducer/book-a-ride";
import { IS_VEHICLE_CHANGE, UPDATE_CHILD_SEAT_VALUES } from "../reducer/details";

type Dispatch = (action: any) => void;

// const getVehiclesList = (payload: any) => {
//     return async (dispatch: Dispatch) => {
//         let vData = await RideService.getVehiclePrice(payload);
//         if (vData.data && vData.data.data != null && vData.data.data.vehicle_types.length) {
//             dispatch({
//                 type: GET_ALL_VEHICLES, data: vData.data.data.vehicle_types
//             });
//         } else {
//                  //comment for region code begins
//             // dispatch({
//             //     type: GET_ALL_VEHICLES, data: []
//             // });
//                  //comment for region code ends
//             dispatch({
//                 type: GET_ALL_VEHICLES, data: { vehicles: [], error: true, vehicleErrorMessage : vData && vData.data && vData.data.message ? vData.data.message : 'This trip can not be quoted at the moment. Please contact us at(833) 438-5899 for immediate assistance.'}
//             });
//         }
//     }
// }

const isChangeVehicle = (data: boolean) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: IS_VEHICLE_CHANGE, data: data
        });
    }
}

const updateBookingState = (data: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_BOOKING_STATE, data: data
        });
    }
}

const updateChildSeatValues = (data: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_CHILD_SEAT_VALUES, data: data
        });
    }
}
const changeListType = (data: string) => {
      return async (dispatch: Dispatch) => {
        dispatch({
            type: LIST_TYPE, data: data
        });
    }
}

const mapStateToProps = (state: any) => {
    return {
        bookingDetailsReducer: state.bookARide.bookingState,
        childSeatValuesReducer: state.details.childSeatValues
        // vehicleDetailsList: state.bookARide.allVehicles
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        isChangeVehicle: (data: boolean) => dispatch(isChangeVehicle(data)),
        updateBookingState: (data: any) => dispatch(updateBookingState(data)),
        updateChildSeatValues: (data: any) => dispatch(updateChildSeatValues(data)),
        changeListType: (data: string) => dispatch(changeListType(data))
    };
};

const ChangeVehicleContainer = connect(mapStateToProps, mapDispatchToProps)(ChangeVehicle);

export default ChangeVehicleContainer;