import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { addOnConstants } from '../../constants/app-constants';

export function Extras(props: any) {

    // var resultArray = Object.keys(props.addOnsData).map(function(personNamedIndex){
    //     let person = props.addOnsData[personNamedIndex];
    //     // do something with person
    //     return person;
    // });
    let addOnsData = props.addOnsData;

    let data = Object.keys(props.addOnsData);

    let addOnsDataObj: any = {};
    let addOnsDataArr: Array<any> = [];

    for (let i = 0; i < data.length; i++) {
        addOnsDataObj = {};
        if (data[i] == addOnConstants.arrivalMeetAndGreet && addOnsData[data[i]] != 0) {
            
            addOnsDataObj['key'] = addOnConstants.arrivalMeetAndGreet;
            addOnsDataObj['name'] = 'Arrival Meet and greet';
            addOnsDataObj['price'] = (Math.round(addOnsData[data[i]] * 100) / 100).toFixed(2);
            addOnsDataArr.push(addOnsDataObj);
        }
        if (data[i] == addOnConstants.childSeat && addOnsData[data[i]] != 0) {
            addOnsDataObj['key'] = addOnConstants.childSeat;
            addOnsDataObj['name'] = 'Child Seat';
            addOnsDataObj['price'] = (Math.round(addOnsData[data[i]] * 100) / 100).toFixed(2);
            addOnsDataArr.push(addOnsDataObj);
        }
        if (data[i] == addOnConstants.clearViewProtection && addOnsData[data[i]] != 0) {
            addOnsDataObj['key'] = addOnConstants.clearViewProtection;
            addOnsDataObj['name'] = 'Trip Protection';
            addOnsDataObj['price'] = (Math.round(addOnsData[data[i]] * 100) / 100).toFixed(2);
            addOnsDataArr.push(addOnsDataObj);
        }
        if (data[i] == addOnConstants.travelingWithPet && addOnsData[data[i]] != 0) {
            addOnsDataObj['key'] = addOnConstants.travelingWithPet;
            addOnsDataObj['name'] = 'Traveling with Pet';
            addOnsDataObj['price'] = (Math.round(addOnsData[data[i]] * 100) / 100).toFixed(2);
            addOnsDataArr.push(addOnsDataObj);
        }

    }
   
    return (
        <div>
            {
                (addOnsDataArr.length > 0) ?
                    addOnsDataArr.map((addOn: any, i: number) => {
                        return <Row key={i} >
                            <Col className="px-2 py-1" xs={9}>{addOn.name}</Col>
                            <Col className="px-2 py-1 text-right" xs={3}>{`$${addOn.price}`}</Col>
                        </Row>
                    })
                    : null

            }

        </div>
    )
}