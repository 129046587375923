import httpService from "./http-service";
class AirportService {

  getByCode = async (place_name: string, coordinates: any, types: any) => {
    // alert(process.env.REACT_APP_API_URL);
    if (coordinates.latitude && coordinates.longitude) {
      let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/airport_code/`, method: 'get' }, {
        lat: parseFloat(coordinates.latitude),
        lng: parseFloat(coordinates.longitude),
        place_name: place_name,
        types: types
      });
      if (res.data && res.data.data) {
        return {
          airport_code: res.data.data.airport_code,
          airport_name: res.data.data.airport_name,
          classification: res.data.data.classification,
          is_meet_and_greet: res.data.data.is_meet_and_greet == undefined ? true : res.data.data.is_meet_and_greet
        }
      }
    }
  }

  verifyFlight = async (data: any) => {
    const URL = `${process.env.REACT_APP_API_URL}/verify-flight`;
    try {
      const response = await await httpService<any>({ url: `${URL}`, method: 'get'}, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // getAll = async () => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_API_URL}/airport`, { headers: getHeaders() });

  //     const { data: { data: airports  } = {} } = response || {};

  //     return airports;
  //   } catch (error) {
  //     throw error;
  //   }
  // };
}

export default new AirportService();
