import React, { Component } from 'react';
import redirectToWP from '../../Utilities/common';
import { createEventGA4 } from '../../Utilities/helpers';
import './why-luxy.css';
const banner7 = require('../../assets/images/7.PNG');
const banner8 = require('../../assets/images/8.PNG');
const banner9 = require('../../assets/images/9.PNG');
interface OtherVehiclesProps {

}

interface OtherVehiclesState {

}

class OtherVehicles extends Component<OtherVehiclesProps, OtherVehiclesState> {
    constructor(props: OtherVehiclesProps) {
        super(props);

        this.state = {

        }
    }

    redirectTo = (menuName: string) => {

        const dataLayerObj: any = {
            'eventName': 'get_quote_cta',
            'getQuoteArea': 'Limo Quote',
            'event': 'getQuoteCta'
        };
        createEventGA4(dataLayerObj);
        switch (menuName) {
            case 'Events':
                redirectToWP('EVENTS', true);
                break;
            default:
                return false;
        }
        return '#';
    }

    render() {
        return (
            <>
            <h4 style={{fontSize: '2rem'}} className='text-center mt-5'>Looking for More?</h4>
            <div className="pt-4"> <div className="container mt-5">
                
                <div className="row mt-4">
                    <div className="col-xl-6 text-center">
                        <img
                            alt=""
                            src={banner9}
                            className="d-inline-block align-top img-fluid"
                        />
                    </div>
                    <div className="col-xl-6">
                        <h5 className="mt-xl-5 text-xl-left text-sm-center">Premium Vans - XL</h5>
                        <p className="text-xl-left text-sm-center">
                            LUXY&#8482; fully equipped mid and mini <br></br>coaches offer WiFi, USB ports, and  <br></br>deluxe entertainment systems to carry <br></br> up to 13 passengers.
                        </p>
                    </div>
                </div>
                <hr className="mt-5"></hr>
                <div className="row mt-4">

                    <div className="col-xl-6 pt-3">
                        <h5 className="mt-xl-5 text-xl-right text-sm-center">Premium Vans</h5>
                        <p className="text-xl-right text-sm-center">
                            Luxy&#8482; offers luxury vans and sprinters to<br></br> transport up to 10 or 12 passengers from<br></br>    point A to point B.
                        </p>
                    </div>
                    <div className="col-xl-6 text-center">
                        <img
                            alt=""
                            src={banner8}
                            className="d-inline-block align-top img-fluid"
                        />
                    </div>
                </div>

                <hr className="mt-5"></hr>

                <div className="row mt-4 pb-5">


                    <div className="col-xl-6 text-center">
                        <img
                            alt=""
                            src={banner7}
                            className="d-inline-block align-top img-fluid"
                        />
                    </div>
                    <div className="col-xl-6 pt-3">
                        <h5 className="mt-xl-5 text-xl-left text-sm-center">Stretch Limousine</h5>
                        <p className="text-xl-left text-sm-center">
                            Limousine is a symbol of luxury and pride.<br></br> These limos are the most exclusive and luxurious<br></br> vehicle out on the road.

                        </p>
                    </div>

                </div>
                <div className='mt-2 center'>
                    <div className="col text-center">
                        <button
                        hidden={true}
                            style={{ width: '20%' }}
                                onClick={() => this.redirectTo('Events')}
                            className="btn btn-default custom-button">GET QUOTE</button>
                    </div>
                </div>


            </div>
                <div className="footer_img_BANNER text-center mt-5 p-4" >
                    {/* <h1 style={{textAlign:'center', color:'#fff'}} className="mt-5">LUXY&#8482; IS THE BETTER WAY TO THE AIRPORT</h1> */}
                    <div style={{ background: '#000', opacity: '0.8', color: '#fff', borderRadius: '20px' }} className="p-5 mx-auto footerbottom">
                        Travelers can rely on our expanding network of premium cars and professional drivers to deliver them in style and comfort, on time and often at lower rates. Every LUXY ride is supported by our cutting-edge technology and 24/7 live customer care team, ensuring a stress-free ride, every time. We’re on a mission to make the journey better.
                    </div>
                    </div></div></>
        )
    }
}
export default OtherVehicles;