
/**
 * @function validate if one of the locations is and airport.
 * @param
 *      pickup: Location object.
 *      dropoff: Location object.
 * @return true if one of addresses provided is an airport
 */
export const isAirPortTrip = (pickup, dropoff) => {
  return (pickup && pickup.place &&pickup.place.airport_code) || (dropoff && dropoff.place && dropoff.place.airport_code);
}

/**
 * @function find city for google location address component.
 * @param address components array.
 * @param list of options to match a city.
 * @return city object.
 */
export const findCity = (components, options) => {

    let city;
    for (let option of options) {
        city = components.find(c => c.types.includes(option));
        if (city) {
            break;
        }
    }
    return city;
}
