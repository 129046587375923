import httpService from "./http-service";
import * as EndPoints from '../constants/end-points';
var API_URL = process.env.REACT_APP_API_URL;

export class RideRewardService {

    static getUserRewardPoints = async (userID: number) => {
        try {
            const url = `${API_URL}${EndPoints.RideRewards.GET_REWARDS}/${userID}`;
            let data = await httpService<any>({ url: url, method: 'get' }, null);
            return data.data;
        } catch (err) {
            return {}
        }
    }

}