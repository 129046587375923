import httpService from "./http-service";

import { metersToMiles, secondsToHours } from "../Utilities/UnitConversionsUtilities";


const google = window.google;
class RideService {

    getVehiclePrice = async (payload: any) => {
        // payload.pickup_date = moment.tz().(payload.pickup_date).format('L');
        // ex format '11/20/2020';
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/vehicle-price/`, method: 'post' }, payload);
        return res;
    }

    getCharges = async (payload: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/charges/`, method: 'post' }, payload);
        return res;
    }

    
    getChargesV2 = async (payload: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/charges/v3`, method: 'post' }, payload);
        return res;
    }

    getChargesV5 = async (payload: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/charges/v5`, method: 'post' }, payload);
        return res;
    }

    
    getAddons = async () => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/booking/addon`, method: 'get' }, {});
        return res;
    }

    applyPromoCode = async (promoCode: string, bookingDetails: any, userId: number) => {
        
        let payload = {
            promo_code: promoCode,
            user_id: userId,
            service_rate_id: bookingDetails.selectedVehicle.service_rate_id,
            service_type: bookingDetails.selectedVehicle.service_type,
            vehicle_type_id: bookingDetails.selectedVehicle.vehicle_type_id,
            pickup: bookingDetails.pickup,
            dropoff: bookingDetails.dropoff,
            pickup_hour: bookingDetails.time,
            base_price: bookingDetails.selectedVehicle.base_price ? bookingDetails.selectedVehicle.base_price.replace('$', '') : ''
        }

        try {
            let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/booking/PromoCodeDetails`, method: 'post' },
                payload);

            // Construct response for invalid promo code.
            let errorData = {
                status: 400
            }

            if (res.error) {
                return errorData;
            }

            return res;
        } catch (Exception) {
            return {
                status: 400
            };
        }

    }

    findMapRoute = async (origin: any, destination: any, travelMode: any, waypoints: any) => {
        
        return new Promise((resolve, reject) => {
            if (this.areThereValidCoordinates(origin, destination)) {
                
                const directionsService = new google.maps.DirectionsService();
                // const directionsService = new google.maps.DirectionsService();
                directionsService.route(
                    {
                        origin: origin,
                        destination: destination,
                        travelMode: travelMode,
                        waypoints: waypoints,
                        unitSystem: google.maps.UnitSystem.IMPERIAL
                    },
                    (result: any, status: any) => {
                        if (status === 'OK' && result.routes.length) {

                            let meters = 0;
                            let seconds = 0;

                            for (let i = 0; i < result.routes[0].legs.length; i++) {
                                meters = meters + Number(result.routes[0].legs[i].distance.value);
                                seconds = seconds +  Number(result.routes[0].legs[i].duration.value);
                            }

                            

                            let miles = metersToMiles(meters);
                            let hours = secondsToHours(seconds);



                            // localStorage.setItem("distance_in_miles", '' + miles);
                            // localStorage.setItem("total_hours", '' + hours);

                             sessionStorage.setItem("distance_in_miles", '' + miles);
                            sessionStorage.setItem("total_hours", '' + hours);

                           

                            resolve({
                                directions: result,
                                distanceInfo: {
                                    meters: meters,
                                    seconds: seconds,
                                    miles: miles,
                                    hours: hours
                                },
                                error: null
                            });
                        } else {
                            // localStorage.setItem("distance_in_miles", '' + 0);
                            // localStorage.setItem("total_hours", '' + 0);

                            sessionStorage.setItem("distance_in_miles", '' + 0);
                            sessionStorage.setItem("total_hours", '' + 0);
                            resolve({
                                error: (result && result.status) ? result.status : 'Error',
                                directions: null,
                                distanceInfo: {
                                    meters: 0,
                                    seconds: 0,
                                    miles: 0,
                                    hours: 0
                                }
                            })
                        }
                    }
                );

            } else {
                // localStorage.setItem("distance_in_miles", '' + 0);
                // localStorage.setItem("total_hours", '' + 0);

                sessionStorage.setItem("distance_in_miles", '' + 0);
                sessionStorage.setItem("total_hours", '' + 0);
                resolve({
                    error: 'Error',
                    directions: null,
                    distanceInfo: {
                        meters: 0,
                        seconds: 0,
                        miles: 0,
                        hours: 0
                    }
                })
            }
        });
    }

    areThereValidCoordinates = (origin: any, destination: any) => {
        const pickupCoordinates = origin;
        const dropOffCoordinates = destination;

        

        return (
            pickupCoordinates && pickupCoordinates.lat != 0 && pickupCoordinates.lng != 0 &&
            dropOffCoordinates && dropOffCoordinates.lat != 0 && dropOffCoordinates.lng != 0
        );
    }



    getAirlinesData = async () => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/airlines/`, method: 'get' }, null);
        return res;
    }
}

export default new RideService();