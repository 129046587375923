import React, { Component } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { StatusCode } from '../../constants/status-codes';
import CommonService from '../../services/CommonService';
import AffiliateForm from './affiliateform';
import logo from '../../assets/images/Image 6@2x.png';
import Alert from 'react-bootstrap/esm/Alert';
import './affiliate.css';
import { createEventGA4 } from '../../Utilities/helpers';

interface AffiliateProps {
    closeModal: any;
}

interface AffiliateState {
    showModal: boolean;
    corporateErr: boolean;
    corporateErrMsg: string;
    isSuccess: boolean;
}

class Affiliate extends Component<AffiliateProps, AffiliateState> {
    constructor(props: AffiliateProps) {
        super(props);

        this.state = {
            showModal: false,
            corporateErr: false,
            corporateErrMsg: '',
            isSuccess: false
        }
    }

    handleJoinCorporate = () => {
        this.setState({
            showModal: true,
            corporateErr: false,
            corporateErrMsg: '',
            isSuccess: false
        });
    }

    closeModal = () => {
        this.props.closeModal();
        this.setState({ showModal: false });
    }

    uploaFileToS3 = (url:any,file: File) =>{
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', url);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    // console.log("uploaded"  + file.name);
                } 
            }
        };
        xhr.setRequestHeader('Content-Type', 'multipart/form-data');
        xhr.send(file);
    }

    handleSaveAffiliate = async (payload: any, w9 :any, insurance:any, directDeposite:any) => {
        let response: any = await CommonService.saveAffiliate(payload);
        try {
            if (response.data.data.w9UploadUrl) {
                this.uploaFileToS3(response.data.data.w9UploadUrl, w9.file);
            }
            if (response.data.data.directDepositUploadUrl) {
                this.uploaFileToS3(response.data.data.directDepositUploadUrl, directDeposite.file);
            }
            if (response.data.data.insuranceUploadUrl) {
                this.uploaFileToS3(response.data.data.insuranceUploadUrl, insurance.file);
            }
        } catch (error) {
            // console.log(error);
        }

        if (response.data.status === StatusCode.EVERYTHING_IS_OK) {
            this.setState({
                showModal: true,
                corporateErr: false,
                corporateErrMsg: '',
                isSuccess: true
            });
            // ReactGA.event(googleEventAnalytics.affiliate);
        } else {
            this.setState({
                showModal: true,
                corporateErr: true,
                corporateErrMsg: response.data.status === StatusCode.ALREADY_EXIST ?
                    'This email address already exists in our system' : 'Something went wrong please try again!',
                isSuccess: false
            });
        }
    }

    renderModalPopup = () => {
        // console.log('isSuccess', this.state.isSuccess);
        if (this.state.isSuccess) {
            const dataLayerObj: any = {
                'eventName': 'form_submit_success',
                'formName': 'Affiliate',
                'event': 'formSubmitSuccess'
            };
            createEventGA4(dataLayerObj);
        }
        return <Modal show={true} style={{ top: '50px' }} className="affiliate-registration-modal">
            <div className="float-right">
                <i className="fa fa-times float-right pointer"
                    onClick={() => this.closeModal()}
                    title="close"></i>
            </div>
            <Modal.Body>
                <div>
                    <div className="modal-img">
                        <img src={logo}
                            width="180"></img>
                    </div>
                </div>
                {
                    (!this.state.isSuccess) ? <AffiliateForm saveAffiliate={this.handleSaveAffiliate}
                        corporateErr={this.state.corporateErr}
                        corporateErrMsg={this.state.corporateErrMsg} /> : <div>
                        <div className="sign-in">
                            <Alert variant='success'>
                                <b>Success!</b> Thank you signing up for our LUXY™ Affiliate program. Check your email and click the link to activate your account.
                            </Alert>
                        </div>

                    </div>
                }

            </Modal.Body>
        </Modal>
    }

    render() {
        return (
            <div>
                {
                    this.renderModalPopup()
                }
                {/* <div className=''>
                        <GetLuxy />
                    <div className="affiliate_img_BANNER text-center   p-4" >
                        <h1 style={{ textAlign: 'center', color: '#fff', marginTop: '115px' }} >JOIN AS AN AFFILIATE</h1>
                        <div style={{ color: '#fff' }} className="footerbottom mx-auto">
                            LUXY™ is your trusted partner for ride offers, live dispatching, and 24/7 customer service. Join the largest growing network of black car service providers, <b> it's free</b>- seriously.
                    </div>
                    </div>

                    <div className="row my-5 pt-2">
                        <div className="col-xl-6 text-center">
                            <img
                                alt=""
                                src={banner1}
                                className="d-inline-block img-fluid align-top"
                            />
                        </div>
                        <div className="col-xl-6 p-2">
                            <Accordion>
                                <div>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        <h6 className="pointer">
                                            <ContextAwareToggle eventKey="0">No monthly fees</ContextAwareToggle>
                                        </h6>

                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className='font-14 pt-0'> No recurring or registration fees.</Card.Body>
                                    </Accordion.Collapse>
                                </div>
                                <div>
                                    <Accordion.Toggle as={Card.Header} eventKey="1">
                                        <h6 className="pointer">
                                            <ContextAwareToggle eventKey="1">Weekly Payments</ContextAwareToggle>
                                        </h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body className='font-14 pt-0'>  Receive payment via direct deposit every Friday.</Card.Body>
                                    </Accordion.Collapse>
                                </div>

                                <div>
                                    <Accordion.Toggle as={Card.Header} eventKey="2">
                                        <h6 className="pointer">
                                            <ContextAwareToggle eventKey="2">Manage your ride dashboard</ContextAwareToggle>
                                        </h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body className='font-14 pt-0'>   Claim, confirm, and assign rides all right from your dashboard.</Card.Body>
                                    </Accordion.Collapse>
                                </div>
                                <div>
                                    <Accordion.Toggle as={Card.Header} eventKey="3">
                                        <h6 className="pointer">
                                            <ContextAwareToggle eventKey="3">Chauffeur training programs</ContextAwareToggle>
                                        </h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body className='font-14 pt-0'>   We'll take the time to train your team on the official LUXY™ Driver app, so you don't have to.</Card.Body>
                                    </Accordion.Collapse>
                                </div>
                                <div>
                                    <Accordion.Toggle as={Card.Header} eventKey="4">
                                        <h6 className="pointer">
                                            <ContextAwareToggle eventKey="4">24/7 Affiliate Care Team</ContextAwareToggle>
                                        </h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body className='font-14 pt-0'> Questions or concerns? Give us a call at (833) 438-5899 or email us at affiliate@luxyride.com.</Card.Body>
                                    </Accordion.Collapse>
                                </div>
                            </Accordion>
                        </div>
                    </div>

                    <div className='corporate-3 mt-4'>
                        <Row className='row-1'>
                            <h4>Interested?</h4>
                            <p>
                                Start claiming rides today.
                            </p>
                            {/* <div>
                            Call <a href="tel:833-GET-LUXY" onClick={() => { ReactGA.event( googleEventAnalytics.affiliate); }} >833.GET.LUXY</a>  
                            </div> */}
                {/*  <div>
                            <Button variant="submit"
                                onClick={() => this.handleJoinCorporate()}
                            >JOIN US</Button>
                            {
                            (this.state.showModal) ? this.renderModalPopup() : null
                            }
                        </div>


                        </Row>
                    </div>


                </div> */}
            </div>
        )
    }
}

export default Affiliate;