import { connect } from 'react-redux';
import About from '../components/about-us/about';

type Dispatch = (action: any) => void;

const mapStateToProps = (state: any) => {
    return {
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
    };
};

const AboutContainer = connect(mapStateToProps, mapDispatchToProps)(About);

export default AboutContainer;