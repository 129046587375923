import React, { Component } from 'react';
import './ride-rewards.css';
import AuthModal from '../modals/auth-modal';
import { AuthService } from '../../services/auth';
import { toast } from 'react-toastify';
import { StatusCode } from '../../constants/status-codes';
interface RideRewardsProps {
    closeModal: any;
    signUpForRideRewards: (data: boolean) => any;
}

interface RideRewardsState {
    showRideRewards: boolean;
}

class RideRewards extends Component<RideRewardsProps, RideRewardsState> {
    constructor(props: RideRewardsProps) {
        super(props);

        this.state = {
            showRideRewards: false
        }
    }

    componentDidMount = async () => {
        this.rideRewards();
    }

    rideRewards = async () => {

        let isUserLoggedIn: any = sessionStorage.getItem('isLoggedIn');

        if (isUserLoggedIn == 'true') {
            let userFromLS: any = sessionStorage.getItem('user');
            let user = JSON.parse(userFromLS);

            let email = '';
            if (user) {
                email = user.email_address
            }
            let data: any = await AuthService.signUpForRideReward(email);

            if (data && data.data && data.data.user) {
                toast.success('Your are successfully enrolled to rewards program');
            } else if (data && data.data && data.data.status === StatusCode.BAD_REQUEST) {
                toast.error(data.data.message);
            } else {
                toast.error('Something went wrong, Please try again');
            }

        } else {
            this.setState({
                showRideRewards: true
            }, () => {
                this.props.signUpForRideRewards(true);
            });
        }
    }

    updateNavData = () => {

    }

    closeModal = () => {
        this.props.closeModal();
        // this.setState({ showRideRewards: false }, () => {
        //     this.props.signUpForRideRewards(false);
        // });
    }

    render() {
        return (
            <div className=''>
                {/* <GetLuxy />
                <ToastContainer />
                <div className='ride-wrapper container'>
                    <div className='row'>
                        <div className='col-12 col-sm-6 mt-5 col-md-6 col-lg-6'>
                            <h1 className='font-white'>LUXY&#8482; Introduces Ride Rewards.</h1>
                            <Row> */}
                                {/* <div className='col-2 col-sm-2 col-md-2 col-sm-2 col-lg-2'>

                                    </div> */}
                                {/* <div className='col-12 mt-3 col-sm-10 pl-0 col-md-10 col-sm-10 col-lg-10'>
                                    <h2 className='font-white mb-0'> <i className="fas fa-car"></i>&nbsp;
                                        Ride. Earn. Repeat.
                                    </h2>
                                </div>
                            </Row>
                            <Row> */}
                                {/* <div className='col-2 col-sm-2 col-md-2 col-sm-2 col-lg-2'>

                                    </div> */}
                                {/* <div className='text-left col-12 pl-0 col-sm-10 col-md-10 col-sm-10 col-lg-10 '>
                                    <p className='font-white'>
                                        Once enrolled, all that’s left is to book rides and earn – it’s that simple.
                                    </p>
                                </div>
                            </Row>
                            <hr style={{ borderTop: '1px solid #fff' }} />
                            <Row>
                                {/* <div className='col-2 col-sm-2 col-md-2 col-sm-2 col-lg-2'>

                                    </div> */}
                                {/* <div className='col-12 col-sm-10 pl-0 col-md-10 col-sm-10 col-lg-10'>
                                    <h2 className='font-white  mb-0'><i className="fas fa-gift"></i> &nbsp;
                                        Your Reward
                                    </h2>
                                </div>
                            </Row>
                            <Row> */}
                                {/* <div className='col-2 col-sm-2 col-md-2 col-sm-2 col-lg-2'>

                                    </div> */}
                                {/* <div className='text-left col-12 col-sm-10 pl-0 col-md-10 col-sm-10 col-lg-10 '>
                                    <p className='font-white'>
                                        $50 LUXY&#8482; Promo Code
                                    </p>
                                </div>
                            </Row>

                            <Row>

                            </Row>
                        </div>
                        <div className='sign-up-ride-rewards col-12 col-sm-6 col-md-6 col-lg-6 p-5'>
                            <h1 className='font-white'>Enroll Today.</h1>
                            <p className='font-white'>A loyalty program that allows frequent travelers to receive a $50 promo code for every four (4) completed rides. Please note that cancelled rides will not be credited towards the program.</p>
                            <Button
                                onClick={() => this.rideRewards()}
                                style={{ fontSize: '16px' }}
                                variant="submit"
                                className='font-white'
                            >Enroll</Button>{' '}
                            <hr style={{ borderTop: '1px solid' }} />
                            <h1 className='font-white'>Questions?</h1>
                            <p className='font-white'>
                                For more information on how you can start earning rewards, check out our <Link style={{textDecoration: 'underline', color:'white'}} className=""
                                    to='/faqs'> FAQ's</Link> .
                            </p>
                        </div>
                    </div>
                </div> */}
                {
                    // this.state.showRideRewards
                        // ?
                        <AuthModal
                            updateNavData={() => this.updateNavData()}
                            closeModal={() => this.closeModal()} />
                        // : null
                }
            </div>
        )
    }
}

export default RideRewards;