import React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/esm/Modal';
import { toast } from 'react-toastify';
import { checkPassword } from '../../Utilities/password';
import { UserService } from '../../services/user';
import { createEventGA4 } from '../../Utilities/helpers';

interface CpProps {
    closeModal: () => any;
    userId: any;
}

interface CpState {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    errors: any;
}

class UpdatePassword extends React.Component<CpProps, CpState> {

    constructor(props: CpProps) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            errors: {}
        }
    }

    handleFieldChange = (e: any) => {
        const key = e.target.name;
        const value = e.target.value;
        let stateCopy: any = Object.assign({}, this.state);
        stateCopy[key] = value;
        this.setState(stateCopy);
    }

    handleValidation() {
        let fields = this.state;
        let errors: any = {};
        let formIsValid = true;

        if (!fields["oldPassword"]) {
            formIsValid = false;
            errors["oldPassword"] = "Old Password is  required.";
        }
        if (!fields["newPassword"]) {
            formIsValid = false;
            errors["newPassword"] = "New Password is  required.";
        }
        if (!fields["confirmPassword"]) {
            formIsValid = false;
            errors["confirmPassword"] = "Confirm password is  required.";
        }
        if (checkPassword(fields["newPassword"]) == false) {
            formIsValid = false;
            errors["newPassword"] = "Secured passwords are at least 8 characters long and include uppercase, numbers, and symbols.";
        } else if (fields["newPassword"] != fields["confirmPassword"]) {
            formIsValid = false;
            errors["confirmPassword"] = "New password and confirm password must be same.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit() {
        if (!this.handleValidation()) {
            toast.error("Please check all required fields and validation.");
            return false;
        }
        this.changePassword({ user_id: this.props.userId, old_pwd: this.state.oldPassword, new_pwd: this.state.newPassword });
    }

    changePassword = async (payload: any) => {
        let response = await UserService.changePassword(payload);
        if (response.error && response.error.data) {
            toast.error(response.error.data.message);
            return false;
        } else {
            const obj: any = {
                'eventName': 'account_preference',
                'password': 'change password',
                'event': 'accountPreference'
            }
            createEventGA4(obj);
            toast.success('Password updated successfully.');
            this.props.closeModal();

        }

    }

    render() {
        return <Modal show={true} style={{ top: '50px' }} >
            <div className="float-right">
                <i className="fa fa-times float-right pointer"
                    onClick={() => this.props.closeModal()}
                    title="close"></i>
            </div>
            <Modal.Header>

                <h4 className="payment-span-text"> CHANGE PASSWORD </h4>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="address_type" className="float-left">Old Password <span className="importantfields">*</span></label>
                                <FormControl
                                    type="password"
                                    name="oldPassword"
                                    id="old_password"
                                    onChange={(event: any) => { this.handleFieldChange(event) }}
                                    value={this.state.oldPassword}
                                />
                                <span className="error">{this.state.errors["oldPassword"]}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="address_type" className="float-left">New Password <span className="importantfields">*</span></label>
                                <FormControl
                                    type="password"
                                    id="new_password"
                                    name="newPassword"
                                    onChange={(event: any) => { this.handleFieldChange(event) }}
                                    value={this.state.newPassword}

                                />
                                <span className="error">{this.state.errors["newPassword"]}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="address_type" className="float-left">Confirm Password <span className="importantfields">*</span></label>
                                <FormControl
                                    type="password"
                                    id="confirm_password"
                                    name="confirmPassword"
                                    onChange={(event: any) => { this.handleFieldChange(event) }}
                                    value={this.state.confirmPassword}

                                />
                                <span className="error">{this.state.errors["confirmPassword"]}</span>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="row no-gutters">
                                <div className="col">
                                    <button
                                        // disabled={this.state.showAddressError}
                                        onClick={() => { this.handleSubmit() }} className="btn btn-block primary custom-button">SUBMIT</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    }

}

export default UpdatePassword;