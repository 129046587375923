import React from "react";
import { FormControl, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { UPDATE_AIRLINE_INFO, UPDATE_PASSENGER_INFO } from '../../reducer/user';
import Select from 'react-select';
import * as Helpers from '../common/helpers';
import { UPDATE_VALIDATION_DATA } from "../../reducer/details";
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'



interface PassengerProps {
    updatePassengerInfo: (passengerData: any) => any;
    user: any;
    isUserLogin: boolean;
    showAirLines: boolean;
    airLines: any;
    updateAirLineData: (airlineData: any) => any;
    airLineReducerData: any;
    confirmBookingValidation: any;
    updateConfirmBookingValidation: (data: any) => any;
    promoCodeApplied: boolean;
    closePromoCodeToggle: (isFromAlert:any) => any;
}

interface PassengerState {
    showSecondaryPassenger: boolean;
    passenger: any;
    notAPassenger: boolean;
    airLinesData: Array<any>;
    airLineInfo: any;
    airlineDefault: any;
    confirmValidationData: any;
    disablePrimaryEmail: boolean;
}
var nope = 'nope';
class PassengerDetails extends React.Component<PassengerProps, PassengerState> {
    constructor(props: PassengerProps) {
        super(props);

        this.state = {
            showSecondaryPassenger: false,
            passenger: {
                pp_firstName: '',
                pp_lastName: '',
                pp_contact: '',
                pp_email: '',
                sp_firstName: '',
                sp_lastName: '',
                sp_contact: '',
                sp_email: '',
                passenger_notes: '',
            },
            notAPassenger: false,
            airLinesData: [],
            airLineInfo: {
                flight: {},
                flight_number: ''
            },
            airlineDefault: null,
            confirmValidationData: {
                primary_passenger: {
                    pp_firstName: true,
                    pp_lastName: true,
                    pp_email: true,
                    pp_mobile: true
                },
                secondary_passenger: {
                    sp_firstName: true,
                    sp_lastName: true,
                    sp_email: true,
                    sp_mobile: true
                },
                arrival_Meet: {
                    passenger_name: true
                }
            },
            disablePrimaryEmail: false
        };
    }

    componentDidMount() {
        // let iamNotPassenger = localStorage.getItem('iamNotPassenger');
        let iamNotPassenger = sessionStorage.getItem('iamNotPassenger');

        let showSecondaryPassengerLS = sessionStorage.getItem('showSecondaryPassenger');
        if (showSecondaryPassengerLS == 'true') {
            this.setState({ showSecondaryPassenger: true });
        }
        if (iamNotPassenger == 'true') {
            this.setState({ notAPassenger: true }, () => {
                this.createPassengerData();
            });
        } else {
            this.createPassengerData();
        }

        // let airLineInfoFromLS: any = localStorage.getItem('airLineInfo');
        let airLineInfoFromLS: any = sessionStorage.getItem('airLineInfo');


        if (airLineInfoFromLS != null) {
            let airLineData = JSON.parse(airLineInfoFromLS);
            let airLineState = this.state.airLineInfo;
            airLineState.flight = airLineData.flight;
            airLineState.flight_number = airLineData.flight_number;
            this.setState({ airLineInfo: airLineState, airlineDefault: airLineState.flight ? airLineState.flight : null }, () => {
                this.props.updateAirLineData(this.state.airLineInfo);
            });
        }

    }

    componentDidUpdate = (prevProps: any) => {
        if (prevProps != this.props) {
            if (this.props.isUserLogin && !this.state.notAPassenger
                &&
                this.props.confirmBookingValidation === prevProps.confirmBookingValidation) {
                this.createPassengerData();
            }

            let airLinesArr: any = [];
            let airLineObj: any = {};
            // console.log(this.props.airLines );

            if (this.props.airLines && this.props.airLines.data && this.props.airLines.data.length) {
                
                // airLinesArr.unshift({ value: 0, label: 'Select' });
                for (let i = 0; i < this.props.airLines.data.length; i++) {
                    airLineObj = {};
                    airLineObj.value = this.props.airLines.data[i].id;
                    airLineObj.label = `${this.props.airLines.data[i].fs}-${this.props.airLines.data[i].name}`;
                    airLinesArr.push(airLineObj);
                }
                this.setState({ airLinesData: airLinesArr });
            }

            if (this.props.confirmBookingValidation !== prevProps.confirmBookingValidation) {
                this.setState({ confirmValidationData: this.props.confirmBookingValidation });
            }

            // if (this.props.promoCodeApplied !== prevProps.promoCodeApplied) {

            //     if (this.props.promoCodeApplied) {
            //         this.setState({ disablePrimaryEmail: true });
            //     } else {
            //         this.setState({ disablePrimaryEmail: false });
            //     }

            //     if(this.props.isUserLogin && this.props.promoCodeApplied) {
            //         this.setState({ disablePrimaryEmail: true });
            //     }
            // }
        }
    }

    createPassengerData = () => {
        // create new passenger object.
        let userFromLocalStorage: any = sessionStorage.getItem('user');
        let userParsed = JSON.parse(userFromLocalStorage);
        // let passengerLocalStorage: any = localStorage.getItem('passengerData');
        let passengerLocalStorage: any = sessionStorage.getItem('passengerData');


        let passengerLocalStorageParsed = JSON.parse(passengerLocalStorage);

        if (passengerLocalStorageParsed == null) {
            passengerLocalStorageParsed = {};
        }
        let passenger: any = {
            pp_firstName: (userParsed !== null && userParsed.first_name) ? userParsed.first_name :
                (passengerLocalStorageParsed.pp_firstName) ? passengerLocalStorageParsed.pp_firstName : '',
            pp_lastName: (userParsed !== null && userParsed.last_name) ? userParsed.last_name :
                (passengerLocalStorageParsed.pp_lastName) ? passengerLocalStorageParsed.pp_lastName : '',

            pp_contact: (userParsed !== null && userParsed.mobile_number) ? userParsed.mobile_number :
                (passengerLocalStorageParsed.pp_contact) ? formatPhoneNumberIntl(passengerLocalStorageParsed.pp_contact) : '',

            pp_email: (userParsed !== null && userParsed.email_address) ? userParsed.email_address :
                (passengerLocalStorageParsed.pp_email) ? passengerLocalStorageParsed.pp_email : '',
            sp_firstName: (passengerLocalStorageParsed.sp_firstName) ? passengerLocalStorageParsed.sp_firstName : '',
            sp_lastName: (passengerLocalStorageParsed.sp_lastName) ? passengerLocalStorageParsed.sp_lastName : '',
            sp_contact: (passengerLocalStorageParsed.sp_contact) ? passengerLocalStorageParsed.sp_contact : '',
            sp_email: (passengerLocalStorageParsed.sp_email) ? passengerLocalStorageParsed.sp_email : '',
            passenger_notes: (passengerLocalStorageParsed.passenger_notes) ? passengerLocalStorageParsed.passenger_notes : '',
        }


        if (this.state.notAPassenger) {
            passenger = {
                pp_firstName: (passengerLocalStorageParsed.pp_firstName) ? passengerLocalStorageParsed.pp_firstName : '',
                pp_lastName: (passengerLocalStorageParsed.pp_lastName) ? passengerLocalStorageParsed.pp_lastName : '',
                pp_contact: (passengerLocalStorageParsed.pp_contact) ? passengerLocalStorageParsed.pp_contact : '',
                pp_email: (passengerLocalStorageParsed.pp_email) ? passengerLocalStorageParsed.pp_email : '',
                sp_firstName: (passengerLocalStorageParsed.sp_firstName) ? passengerLocalStorageParsed.sp_firstName : '',
                sp_lastName: (passengerLocalStorageParsed.sp_lastName) ? passengerLocalStorageParsed.sp_lastName : '',
                sp_contact: (passengerLocalStorageParsed.sp_contact) ? passengerLocalStorageParsed.sp_contact : '',
                sp_email: (passengerLocalStorageParsed.sp_email) ? passengerLocalStorageParsed.sp_email : '',
                passenger_notes: (passengerLocalStorageParsed.passenger_notes) ? passengerLocalStorageParsed.passenger_notes : '',
            }
        }
        this.setState({ passenger: passenger }, () => {

            this.props.updatePassengerInfo(passenger);
        });
    }

    showSecondaryPassenger = () => {
        this.setState({ showSecondaryPassenger: !this.state.showSecondaryPassenger }, () => {
            let showSecondaryPassengerStr = (this.state.showSecondaryPassenger) ? 'true' : 'false';
            // localStorage.setItem('showSecondaryPassenger', showSecondaryPassengerStr);
            sessionStorage.setItem('showSecondaryPassenger', showSecondaryPassengerStr);
        });

        let passengerData = this.state.passenger;
        passengerData.sp_firstName = '';
        passengerData.sp_lastName = '';
        passengerData.sp_email = '';
        passengerData.sp_contact = '';
        this.setState({ passenger: passengerData });
        this.props.updatePassengerInfo(passengerData);

         // added new
         let confirmBookingValidation: any = JSON.parse(JSON.stringify(this.props.confirmBookingValidation));
         confirmBookingValidation.secondary_passenger.sp_firstName = true;
         confirmBookingValidation.secondary_passenger.sp_lastName = true;
         confirmBookingValidation.secondary_passenger.sp_mobile = true;
         confirmBookingValidation.secondary_passenger.sp_email = true;
         this.props.updateConfirmBookingValidation(confirmBookingValidation);
    }

    /**
     * Update passenger info on change on every input.
     * @param event: any
     * @param type: string
     * @return void
     */
    updatePassengerInfo = (event: any, type: string) => {

        let updatedPassengerData = this.state.passenger && typeof this.state.passenger == 'object' ? JSON.parse(JSON.stringify(this.state.passenger)) : this.state.passenger;

        switch (type) {
            case 'pp_firstName':
                updatedPassengerData.pp_firstName = event.target.value;
                this.validateOthers('pp_firstName', event.target.value);
                break;
            case 'pp_lastName':
                updatedPassengerData.pp_lastName = event.target.value;
                this.validateOthers('pp_lastName', event.target.value);
                break;
            case 'pp_contact':

                updatedPassengerData.pp_contact = formatPhoneNumberIntl(event);
                this.validateOthers('pp_contact', event);
                break;
            case 'pp_email':
                updatedPassengerData.pp_email = event.target.value;
                this.validateEmail(event.target.value, 'pp_email');
                break;
            case 'sp_firstName':
                updatedPassengerData.sp_firstName = event.target.value;
                this.validateOthers('sp_firstName', event.target.value);
                break;
            case 'sp_lastName':
                updatedPassengerData.sp_lastName = event.target.value;
                this.validateOthers('sp_lastName', event.target.value);
                break;
            case 'sp_contact':
                // updatedPassengerData.sp_contact = event.target.value;
                updatedPassengerData.sp_contact = formatPhoneNumberIntl(event);
                this.validateOthers('sp_contact', event);
                break;
            case 'sp_email':
                updatedPassengerData.sp_email = event.target.value;
                this.validateEmail(event.target.value, 'sp_email');
                break;
            case 'passenger_notes':
                updatedPassengerData.passenger_notes = event.target.value;
                break;
            default:
                break;
        }
        this.setState({ passenger: updatedPassengerData });
        this.props.updatePassengerInfo(updatedPassengerData);
    }

    checkForNotAPassenger = () => {

        let isChecked = this.state.notAPassenger;

        if (!isChecked) {
            this.setState({ notAPassenger: true }, () => {
                // localStorage.setItem('iamNotPassenger', 'true');
                sessionStorage.setItem('iamNotPassenger', 'true');
                this.setState({ disablePrimaryEmail: false });
                // Empty Primary Passenger info.
                let passengerData = this.state.passenger;
                passengerData.pp_firstName = '';
                passengerData.pp_lastName = '';
                passengerData.pp_email = '';
                passengerData.pp_contact = '';
                this.setState({ passenger: passengerData });
                this.props.updatePassengerInfo(passengerData);
            });
        } else {

            this.setState({ notAPassenger: false }, () => {
                // localStorage.setItem('iamNotPassenger', 'false');
                sessionStorage.setItem('iamNotPassenger', 'false');
                // let userFromLocalStorage: any = localStorage.getItem('user');
                let userFromLocalStorage: any = sessionStorage.getItem('user');
                let userParsed = JSON.parse(userFromLocalStorage);
                let passengerData = this.state.passenger;
                if (this.props.isUserLogin && this.props.promoCodeApplied) {
                    this.setState({ disablePrimaryEmail: true });
                } else {
                    this.setState({ disablePrimaryEmail: false });
                }
                passengerData.pp_firstName = (userParsed !== null && userParsed.first_name) ? userParsed.first_name : '';
                passengerData.pp_lastName = (userParsed !== null && userParsed.last_name) ? userParsed.last_name : '';
                passengerData.pp_email = (userParsed !== null && userParsed.email_address) ? userParsed.email_address : '';
                passengerData.pp_contact = (userParsed !== null && userParsed.mobile_number) ? userParsed.mobile_number : '';
                this.setState({ passenger: passengerData });
                this.props.updatePassengerInfo(passengerData);

                // added new
                let confirmBookingValidation: any = JSON.parse(JSON.stringify(this.props.confirmBookingValidation));
                confirmBookingValidation.primary_passenger.pp_firstName = true;
                confirmBookingValidation.primary_passenger.pp_lastName = true;
                confirmBookingValidation.primary_passenger.pp_mobile = true;
                confirmBookingValidation.primary_passenger.pp_email = true;
                this.props.updateConfirmBookingValidation(confirmBookingValidation);
            });
        }

    }

    selectAirLine = (event: any) => {

        let flight = event;
        let flightState = this.state.airLineInfo;
        flightState.flight = flight;
        this.setState({ airLineInfo: flightState }, async () => {

            await this.props.updateAirLineData(this.state.airLineInfo);
        });
    }

    updateAirLineInfo = async (event: any) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {

            let flightState = this.state.airLineInfo;
            flightState.flight_number = event.target.value;
            this.setState({ airLineInfo: flightState }, async () => {

                await this.props.updateAirLineData(this.state.airLineInfo);
            });
        }
    }

    validateEmail = async (email: string, type: string) => {

        let isValid = Helpers.validateEmail(email);
        let confirmBookingValidation: any = JSON.parse(JSON.stringify(this.props.confirmBookingValidation));
        
        if (type === 'pp_email') {
            confirmBookingValidation.primary_passenger.pp_email = isValid;
        } else {
            confirmBookingValidation.secondary_passenger.sp_email = isValid;
        }
        if(isValid && !this.props.isUserLogin && !this.state.notAPassenger){
            let intialEmail = this.state.passenger && this.state.passenger.pp_email ? this.state.passenger.pp_email : '';
            if(intialEmail!=email){
                this.props.closePromoCodeToggle(false)
            }
        }

       

        await this.props.updateConfirmBookingValidation(confirmBookingValidation);
    }

    validateOthers = async (type: string, data: string) => {
        let confirmBookingValidation: any = JSON.parse(JSON.stringify(this.props.confirmBookingValidation));
        if (type === 'pp_firstName') {
            confirmBookingValidation.primary_passenger.pp_firstName = (!data || data.trim() === '') ? false : true;
        }
        if (type === 'pp_lastName') {
            confirmBookingValidation.primary_passenger.pp_lastName = (!data || data.trim() === '') ? false : true;
        }
        if (type === 'sp_firstName') {
            confirmBookingValidation.secondary_passenger.sp_firstName = (!data || data.trim() === '') ? false : true;
        }
        if (type === 'sp_lastName') {
            confirmBookingValidation.secondary_passenger.sp_lastName = (!data || data.trim() === '') ? false : true;
        }
        if (type === 'sp_contact') {
            confirmBookingValidation.secondary_passenger.sp_mobile = (Helpers.validateCountryPhone(data)) ? true : false;
        }
        if (type === 'pp_contact') {
            confirmBookingValidation.primary_passenger.pp_mobile = (Helpers.validateCountryPhone(data)) ? true : false;
        }

        await this.props.updateConfirmBookingValidation(confirmBookingValidation);
    }

    render() {
        return (
            <div>
                <div className="block-head">
                    <Row>
                        <div className="col-12 col-sm-9 col-md-8 col-lg-7">
                            <h5>Primary Passenger Information</h5>
                        </div>
                        {
                           this.props.isUserLogin ?  <div className="col-12 col-sm-3 col-md-6 col-lg-5 custom-switch1 text-right custom-control custom-switch">
                           <label className="pr-5"> I'm not the passenger</label>
                           <input type="checkbox" className="custom-control-input" id="customSwitch1"
                               title="I'm not a passenger"
                               checked={this.state.notAPassenger}
                               onChange={() => this.checkForNotAPassenger()}
                           />
                           <label className="custom-control-label" htmlFor="customSwitch1">

                           </label>
                       </div> : ''
                        }
                        
                    </Row>
                </div>
                <Row>
                    <div className="form-group col-md-6">
                        <label htmlFor="firstName" className="float-left">First Name <span className="importantfields">*</span></label>
                        <FormControl
                            id="pp-firstName"
                            placeholder={
                                this.state.passenger.pp_firstName
                                    ? this.state.passenger.pp_firstName
                                    : "Enter First Name"
                            }
                            autoComplete={nope}
                            onChange={(event: any) =>
                                this.updatePassengerInfo(event, 'pp_firstName')
                            }
                            value={this.state.passenger.pp_firstName}
                        />
                        <p className="text-danger">
                            {
                                (!this.state.confirmValidationData.primary_passenger.pp_firstName) ?
                                    'First Name is required' : null
                            }
                        </p>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="lastName" className="float-left">Last Name <span className="importantfields">*</span></label>
                        <FormControl
                            id="pp-lastName"
                            placeholder={
                                this.state.passenger.pp_lastName
                                    ? this.state.passenger.pp_lastName
                                    : "Enter Last Name"
                            }
                            autoComplete={nope}
                            onChange={(event: any) =>
                                this.updatePassengerInfo(event, 'pp_lastName')
                            }
                            value={this.state.passenger.pp_lastName}
                        />
                        <p className="text-danger">
                            {
                                (!this.state.confirmValidationData.primary_passenger.pp_lastName) ?
                                    'Last Name is required' : null
                            }
                        </p>
                    </div>
                </Row>
                <Row>
                    <div className=" col-md-6">
                        <div className="form-group input-group" style={{ display: 'grid' }}>
                            <label htmlFor="contactNumber" style={{ height: '22px' }} className="float-left">Contact Number <span className="importantfields">*</span></label>
                            {/* <PhoneInput
                            country={'us'}
                            value={this.state.passenger.pp_contact}
                            onChange={(event: any) =>
                                this.updatePassengerInfo(event, 'pp_contact')
                            }
                        /> */}

                            <PhoneInput
                                defaultCountry='US'
                                international
                                placeholder={
                                    this.state.passenger.pp_contact
                                        ? this.state.passenger.pp_contact
                                        : "Enter Contact Number"
                                }
                                onChange={(event: any) =>
                                    this.updatePassengerInfo(event, 'pp_contact')
                                }
                                value={this.state.passenger.pp_contact || ''}
                                autoComplete={nope}
                            />

                            {/* <FormControl
                            id="pp-contact-number"
                            maxLength={16}
                            placeholder={
                                this.state.passenger.pp_contact
                                    ? this.state.passenger.pp_contact
                                    : "Enter Contact Number"
                            }
                            autoComplete={nope}
                            onChange={(event: any) =>
                                this.updatePassengerInfo(event, 'pp_contact')
                            }
                            value={this.state.passenger.pp_contact}
                        /> */}
                            <p className="text-danger">{
                                (!this.state.passenger.pp_contact && !this.state.confirmValidationData.primary_passenger.pp_mobile) ? 'Contact Number is required' : (!this.state.confirmValidationData.primary_passenger.pp_mobile) ?
                                    'Enter valid contact number' : null
                            }</p>
                            <p style={{fontSize: "12px", margin: 0}}>Drivers will need to receive a safety pin from the passenger, please provide an accurate cell phone number.</p>
                        </div>
                    </div>
                    <div className=" col-md-6">
                        <div className="form-group">
                            <label htmlFor="email" className="float-left">Email <span className="importantfields">*</span></label>
                            <FormControl
                                id="pp-email"
                                placeholder={
                                    this.state.passenger.pp_email
                                        ? this.state.passenger.pp_email
                                        : "Enter Email Address"
                                }
                                autoComplete={nope}
                                onChange={(event: any) =>
                                    this.updatePassengerInfo(event, 'pp_email')
                                }
                                // disabled={this.state.disablePrimaryEmail}
                                disabled={(this.props.isUserLogin && !this.state.notAPassenger && this.props.promoCodeApplied) ?true :false}
                                value={this.state.passenger.pp_email}
                                style={(this.props.isUserLogin && !this.state.notAPassenger && this.props.promoCodeApplied) ? { cursor: 'not-allowed' } : {}}
                            />
                            <p className="text-danger">
                                {
                                    (!this.state.passenger.pp_email && !this.state.confirmValidationData.primary_passenger.pp_email) ? 'Email is required' : (!this.state.confirmValidationData.primary_passenger.pp_email) ?
                                        'Enter valid email address' : null
                                }
                            </p>
                        </div>
                    </div>
                </Row>
                {
                    (!this.state.showSecondaryPassenger)
                        ?
                        <>
                            <a className="passenger luxy-btn pointer font-14" style={{ marginLeft: '14px' }} onClick={() => this.showSecondaryPassenger()}>+ Add Secondary Passenger</a>
                        </>
                        :
                        <a className="passenger luxy-btn  pointer  font-14" style={{ marginLeft: '14px' }} onClick={() => this.showSecondaryPassenger()}>- Delete Secondary Passenger</a>
                }

                <>
                    {

                        (this.state.showSecondaryPassenger)

                        &&
                        <>
                            <Row className="mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="firstName" className="float-left">First Name</label>
                                    <FormControl
                                        id="sp-firstName"
                                        placeholder={
                                            this.state.passenger.sp_firstName
                                                ? this.state.passenger.sp_firstName
                                                : "Enter First Name"
                                        }
                                        onChange={(event: any) =>
                                            this.updatePassengerInfo(event, 'sp_firstName')
                                        }
                                        autoComplete={nope}
                                        value={this.state.passenger.sp_firstName}
                                    />
                                    <p className="text-danger">
                                        {
                                            (!this.state.confirmValidationData.secondary_passenger.sp_firstName) ?
                                                'First Name is required' : null
                                        }
                                    </p>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="lastName" className="float-left">Last Name</label>
                                    <FormControl
                                        id="sp-lastName"
                                        placeholder={
                                            this.state.passenger.sp_lastName
                                                ? this.state.passenger.sp_lastName
                                                : "Enter Last Name"
                                        }
                                        autoComplete={nope}
                                        onChange={(event: any) =>
                                            this.updatePassengerInfo(event, 'sp_lastName')
                                        }
                                        value={this.state.passenger.sp_lastName}
                                    />
                                    <p className="text-danger">
                                        {
                                            (!this.state.confirmValidationData.secondary_passenger.sp_lastName) ?
                                                'Last Name is required' : null
                                        }
                                    </p>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-6">
                                    <div className="form-group input-group " style={{ display: 'grid' }}>
                                        <label htmlFor="contactNumber" style={{ height: '22px' }} className="float-left">Contact Number</label>
                                        {/* <FormControl
                                        id="sp-contact-number"
                                        maxLength={16}
                                        placeholder={
                                            this.state.passenger.sp_contact
                                                ? this.state.passenger.sp_contact
                                                : "Enter Contact Number"
                                        }
                                        autoComplete={nope}
                                        onChange={(event: any) =>
                                            this.updatePassengerInfo(event, 'sp_contact')
                                        }
                                        value={this.state.passenger.sp_contact}
                                    /> */}

                                        <PhoneInput
                                            defaultCountry='US'
                                            international
                                            placeholder={
                                                this.state.passenger.sp_contact
                                                    ? this.state.passenger.sp_contact
                                                    : "Enter Contact Number"
                                            }
                                            onChange={(event: any) =>
                                                this.updatePassengerInfo(event, 'sp_contact')
                                            }
                                            value={this.state.passenger.sp_contact}
                                            autoComplete={nope}
                                        />
                                        <p className="text-danger">
                                            {
                                                (!this.state.passenger.sp_contact && !this.state.confirmValidationData.secondary_passenger.sp_mobile) ? 'Contact Number is required' : !this.state.confirmValidationData.secondary_passenger.sp_mobile ?
                                                    'Enter valid contact number' : null
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="email" className="float-left">Email</label>
                                        <FormControl
                                            id="sp-email"
                                            placeholder={
                                                this.state.passenger.sp_email
                                                    ? this.state.passenger.sp_email
                                                    : "Enter Email Address"
                                            }
                                            autoComplete={nope}
                                            onChange={(event: any) =>
                                                this.updatePassengerInfo(event, 'sp_email')
                                            }
                                            value={this.state.passenger.sp_email}
                                        />
                                        <p className="text-danger">
                                            {
                                                (!this.state.passenger.sp_email && !this.state.confirmValidationData.secondary_passenger.sp_email) ? 'Email is required' : !this.state.confirmValidationData.secondary_passenger.sp_email ?
                                                    'Enter valid email address' : null
                                            }
                                        </p>
                                    </div>
                                </div>
                            </Row>
                        </>

                    }
                </>

                <Row className="mt-3">
                    {
                        (this.props.showAirLines) ?
                            <>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <label style={{ margin: '0 0 3px' }}>Airline</label>
                                    <Select className="font-13 z-99"
                                        options={this.state.airLinesData}
                                        placeholder="Select Airline"
                                        onChange={(event: any) => this.selectAirLine(event)}
                                        defaultValue={this.state.airlineDefault}
                                    />
                                </Col>
                                <div className="form-group col-md-6">
                                    <label htmlFor="flight_number" className="float-left">Flight Number</label>
                                    <FormControl
                                        id="flight_number"
                                        placeholder="Enter Flight Number"
                                        autoComplete={nope}
                                        maxLength={4}
                                        onChange={(event: any) =>
                                            this.updateAirLineInfo(event)
                                        }
                                        value={this.state.airLineInfo.flight_number}
                                    />
                                </div>
                            </>
                            : null

                    }
                </Row>

                <Row className="mt-3">
                    <div className="form-group col-md-12">
                        <label htmlFor="firstName" className="float-left">Passenger Notes</label>
                        <textarea className="form-control" id="passenger_notes" rows={3}
                            onChange={(event: any) =>
                                this.updatePassengerInfo(event, 'passenger_notes')
                            }
                            value={this.state.passenger.passenger_notes}
                        ></textarea>
                    </div>
                </Row>
            </div>

        );
    }
}

type Dispatch = (action: any) => void;
const updatePassengerInfo = (passengerData: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_PASSENGER_INFO, data: passengerData
        });
    }
}

const updateAirLineData = (airLineData: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_AIRLINE_INFO, data: airLineData
        });
    }
}

const updateConfirmBookingValidation = (data: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_VALIDATION_DATA,
            data: data
        });
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user.user,
        isUserLogin: state.user.isUserLogin,
        airLines: state.details.airLines,
        airLineReducerData: state.user.airLineData,
        confirmBookingValidation: state.details.confirmBookingValidation,
        promoCodeApplied: state.details.promoCodeApplied
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updatePassengerInfo: (passengerData: any) => dispatch(updatePassengerInfo(passengerData)),
        updateAirLineData: (airLineData: any) => dispatch(updateAirLineData(airLineData)),
        updateConfirmBookingValidation: (data: any) => dispatch(updateConfirmBookingValidation(data))
    };
};

const PassengerDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(PassengerDetails);

export default PassengerDetailsContainer;
