import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
const loader = require('../../assets/images/loader.gif');

export const LoadingSpinerComponent = (props: any) => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        <>
            {
                (promiseInProgress === true) ?
                        <div className="loaderMain">
                            <img src={loader} alt="Loding..." />
                        </div>
                    :
                    null
            }
        </>

    )
};
