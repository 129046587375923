import React from 'react';
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

interface AlertPopup {
    show: boolean;
    closeAlertPopup: () => any;
    title: string;
    bodyText: string;
    // deleteMethod: () => any;
}

function AlertPopup(props: AlertPopup) {
    // const [show, setShow] = useState(false);
    const handleClose = () => {
        props.closeAlertPopup()
    }
    // const handleShow = () => setShow(true);

    return (
        <>

            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                 <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title>
                        {props.title}
                    </Modal.Title>
                </Modal.Header> 
                <Modal.Body>
                    {props.bodyText}
                </Modal.Body>
                <Modal.Footer className='border-top-0'>
                    {/* <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button> */}
                    <Button variant="danger"
                    onClick={() => props.closeAlertPopup()}
                    >Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AlertPopup;