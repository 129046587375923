import moment from 'moment';
import Geocode from 'react-geocode';
import instance from '../axios/external';
import airportService from './AirportService';

const GOOGLE_API_KEY: any = process.env.REACT_APP_GOOGLE_API;

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.setLanguage('en');
Geocode.setRegion('us');

if (process.env.REACT_APP_GEO_CODE_DEBUG_ENABLE === 'true') { // to enable geocode debug
    Geocode.enableDebug();
}

const address_template = {
    postal_code: ['postal_code'],
    postal_code_suffix: ['postal_code_suffix'],
    city: ['locality', 'sublocality_level_1', 'neighborhood', 'administrative_area_level_3'],
    county: ['administrative_area_level_2'],
    state: ['administrative_area_level_1'],
    country: ['country']
};

const long_names = [...address_template.city];

const parseAddressComponents = (address_components: any, template: any = address_template) => {
    let address: any = {};
    let address_parts: any = address_components.reduce(function (map: any, ac: any) {
        // use the long_name for any city type
        map[ac.types[0]] = long_names.some(t => t === ac.types[0]) ? ac.long_name : ac.short_name;
        return map;
    }, {});
    let field: any;
    let matches: any;
    for ([field, matches] of Object.entries(template)) {
        matches.forEach((match: any) => {
            if (address_parts[match] && !address[field]) {
                address[field] = address_parts[match];
            }
        });
    }

    return address;
};

const getTimezone = async (location: any) => {
    const loc = location.lat + ',' + location.lng;
    const targetDate = new Date();
    const timestamp = targetDate.getTime() / 1000 + targetDate.getTimezoneOffset() * 60;
    return new Promise(function (resolve, reject) {
        let apicall = `https://maps.googleapis.com/maps/api/timezone/json?location=${loc}&timestamp=${timestamp}&key=${GOOGLE_API_KEY}`;
        instance.get(apicall).then(res => {
            resolve(res.data.timeZoneId);
        });
    });
};


const getPlaceName = (gmapsAddress: any) => {
    let { address_components } = gmapsAddress;
    let name_parts = parseAddressComponents(address_components, {
        premise: ['premise'],
        airport: ['airport'],
        street_number: ['street_number'],
        route: ['route']
    });
    let street_number = name_parts.street_number ? name_parts.street_number : '';
    let name = gmapsAddress.name || name_parts.airport || name_parts.premise || `${street_number} ${name_parts.route}`;

    return name.trim();
};

const getStreetAddress = (gmapsAddress: any) => {
    let { address_components } = gmapsAddress;
    let parts = parseAddressComponents(address_components, {
        premise: ['premise'],
        airport: ['airport'],
        street_number: ['street_number'],
        route: ['route']
    });
    let address = '';
    address += parts.airport ? parts.airport : '';
    address += parts.premise ? ` ${parts.premise}` : '';
    address += parts.street_number ? ` ${parts.street_number}` : '';
    address += parts.route ? ` ${parts.route}` : '';

    return address.trim();
};

const getStreetNumber = (gmapsAddress: any) => {
    let { address_components } = gmapsAddress;
    let parts = parseAddressComponents(address_components, {
        street_number: ['street_number']
    });
   return parts.street_number;
};

const parseGMapsAddress = (gmapsAddress: any) => {
    // google provides lat/lng as function or value
    let lat =
        'function' === typeof gmapsAddress.geometry.location.lat
            ? gmapsAddress.geometry.location.lat()
            : gmapsAddress.geometry.location.lat;
    let lng =
        'function' === typeof gmapsAddress.geometry.location.lng
            ? gmapsAddress.geometry.location.lng()
            : gmapsAddress.geometry.location.lng;
    let location = {
        lat: lat,
        lng: lng
    };
    let timezone_promise = getTimezone(location);

    const coordinates = {
        latitude: lat,
        longitude: lng
    };
    let place_name = getPlaceName(gmapsAddress);
    let airport_details_promise = airportService.getByCode(place_name, coordinates, gmapsAddress.types);
    return Promise.all([timezone_promise, airport_details_promise]).then(values => {
        let timezone = values[0];
        let airport: any = values[1];
        let luxyAddress:any = {
            place: {
                place_id: gmapsAddress.place_id,
                name: place_name,
                types: gmapsAddress.types,
                place_type: airport && airport.airport_code ? 'airport' : gmapsAddress.types[0],
                ...(airport && {
                    airport_code: airport.airport_code,
                    classification: airport.classification,
                    airport_name: airport.airport_name
                })
            },
            address: {
                ...parseAddressComponents(gmapsAddress.address_components),
                address: getStreetAddress(gmapsAddress),
                full_address: (airport && airport.airport_code) ? place_name + ' ' + gmapsAddress.formatted_address : gmapsAddress.formatted_address,
                streetNumber: getStreetNumber(gmapsAddress)
            },
            coordinates: coordinates,
            timezone: timezone,
            // is_meet_and_greet: airport ? (airport.is_meet_and_greet == undefined) ? true : airport.is_meet_and_greet : ''
        };
        if(airport && luxyAddress){
            luxyAddress.is_meet_and_greet = (airport.is_meet_and_greet == undefined) ? true : airport.is_meet_and_greet
        }
        return luxyAddress;
    });
};

export const getLuxyAddress = async (address: any) => {

    if (address.address_components && address.types && address.formatted_address && address.geometry) {
        return parseGMapsAddress(address);
    }

    try {
        const result = await Geocode.fromAddress(address);

        if (result && result.results) {
            const luxyAddress = parseGMapsAddress(result.results[0]);
            return luxyAddress;
        }
    } catch (error) {
        throw Error(`failed during geocode ${address}`);
    }
};

export const getFormattedAddress = async (address: any) => {
    try {
        const luxyAddressFormat = await getLuxyAddress(address);
        return luxyAddressFormat;
    } catch (error) {
        return null;
    }
}

export const isValidLuxyAddress = (address: any) => {
    const { address: addressDetails = {} } = address || {};
    
    if (!address || !addressDetails.postal_code) {
        throw Error("invalid address");
    }

    if (!address.timezone) {
        throw Error(`could not detect timezone`);
    }

    if (!address.place.types || address.place.types.length === 0) {
        throw Error(`no place types found`);
    }

    if (process.env.REACT_APP_ADDRESS_VALIDATION_FLAG && process.env.REACT_APP_ADDRESS_VALIDATION_FLAG.toLowerCase() === 'true') {
        if (!address.address.streetNumber 
            && !address.place.types.includes("establishment")
            && !address.place.types.includes("premise")) {
            throw Error(`no house number`);
        }
    }
   

    return true;
};

export const getAddressByCoordinates = async (lat: any, long: any) => {
    return new Promise(function (resolve, reject) {
        // var apicall = `https://maps.googleapis.com/maps/api/timezone/json?location=${loc}&timestamp=${timestamp}&key=${GOOGLE_API_KEY}`;
        var apicall = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${GOOGLE_API_KEY}`
        instance.get(apicall).then(res => {
            
            resolve(res.data.results);
        });
    });
};

export const formatDistance = (distance: any, decimal_places = 2) => {
    return (isNaN(distance)) ? 0 : parseFloat(distance).toFixed(decimal_places);
}

export const formatDuration = (time: any, unitOfTime: any = 'hours') => {
    const duration = moment.duration(time, unitOfTime);
    const hours = Math.floor(duration.asHours());
    let mins: any = Math.floor(duration.asMinutes()) - hours * 60;
    mins = String(mins).padStart(2, '0');
    const formattedTime = `${hours}:${mins}`;

    return (isNaN(time)) ? 0
        : formattedTime;
}
