import { connect } from 'react-redux';
import RidePayments from '../components/vendor-payments/ride-payments';
import PaymentService from '../services/PaymentService';
import { SET_CLIENT_TOKEN, SET_PAYMENT_DATA, SET_PAYMENT_METHOD_TOKEN, SET_PAYMENT_NONCE } from '../reducer/payment-info';


type Dispatch = (action: any) => void;

const createPaymentMethod = (brainTreeCustomerId: any, paymentNonce: any) => {
    return async (dispatch: Dispatch) => {
        let createPaymentMethodData = await PaymentService.createPaymentMethod(brainTreeCustomerId, paymentNonce)


        if (createPaymentMethodData.data && createPaymentMethodData.data.data) {
            console.log(createPaymentMethodData.data.data.paymentMethod.token, '1111111')
            dispatch({
                type: SET_PAYMENT_DATA, data: createPaymentMethodData.data.data
            });
            dispatch({
                type: SET_PAYMENT_METHOD_TOKEN, data: createPaymentMethodData.data.data.paymentMethod.token
            });

        } else {
            dispatch({
                type: SET_PAYMENT_DATA, data: {}
            });
        }
    }
}

const createCustomerToken = (customerId: any = "") => {
    return async (dispatch: Dispatch) => {
        let customerToken = await PaymentService.createCustomerToken(customerId);
        
        if (customerToken.data && customerToken.data.data) {
            dispatch({
                type: SET_CLIENT_TOKEN, data: customerToken.data.data.customer_token
            });
        } else {
            dispatch({
                type: SET_CLIENT_TOKEN, data: ''
            });
        }
    }
}

const getPaymentNonce = (paymentToken: any = "") => {
    return async (dispatch: Dispatch) => {
        let paymentNonceData = await PaymentService.getPaymentNonce(paymentToken);

        
        if (paymentNonceData.data && paymentNonceData.data.data) {
            dispatch({
                type: SET_PAYMENT_NONCE, data: paymentNonceData.data.data.payment_nonce
            });
        } else {
            dispatch({
                type: SET_PAYMENT_NONCE, data: ''
            });
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        clientToken: state.paymentInfo.clientToken,
        paymentMethodToken: state.paymentInfo.paymentMethodToken,
        paymentNonceData: state.paymentInfo.paymentNonceData
    };
};


const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        createCustomerToken: (customer_id: string) => dispatch(createCustomerToken(customer_id)),
        createPaymentMethod: (brainTreeCustomerId: any, paymentNonce: any) => dispatch(createPaymentMethod(brainTreeCustomerId, paymentNonce)),
        getPaymentNonce: (paymentToken: any) => dispatch(getPaymentNonce(paymentToken))
    };
};

const RidePaymentsContainer = connect(mapStateToProps, mapDispatchToProps)(RidePayments);

export default RidePaymentsContainer;