import React, { Component } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FORGOT_PASS_CODE, USER_SIGN_UP, IS_RESEND_ACTIVATION_LINK, IS_GUEST_USER, SIGN_UP_TOAST } from '../../reducer/user';
import AuthModalContent from './auth-modal-content';
import ActivateUser from './activate-user';
import logo from '../../assets/images/Image 6@2x.png';
import { AuthService } from '../../services/auth';
import { RouteComponentProps, withRouter } from 'react-router';
import { IS_GET_QUOTES_CLICKED } from '../../reducer/book-a-ride';

interface AuthProps extends RouteComponentProps<any> {
    closeModal: () => any;
    title?: string;
    updateNavData: () => any;
    isUserSingUp: any;
    updateUserSingUp: () => any;
    isForgotPassCode: any;
    fromBookARide?: boolean;
    activateUser?: boolean;
    isFromGetQuotes: boolean;
    isResendActivation: boolean;
    signUpRideRewards: any;
    isEnrolledRideReward: any;
    signInRideRewards: any;
    isEnrolledRideRewardSignIn: any;
    bookingDetails: any;
    allVehicles: any;
    continueAsGuest: () => any;
    navigateToRideDetail?: any;
    userSignUpData?: any;
    userSignUpToast: () => any;
    updateIsFromGetQetQuote: () => any;
    uniqueID?: string;
}

interface AuthState {
    name: string;
    title: string;
    passcode?: string;
    isUserSingUp: boolean;
}

class AuthModal extends Component<AuthProps, AuthState> {
    constructor(props: AuthProps) {
        super(props);
        this.state = {
            title: '',
            name: '',
            isUserSingUp: false
        }
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps !== this.props) {
            if (this.props.isUserSingUp) {
                this.setState({ isUserSingUp: true });
            }
        }
    }

    closeModal = () => {
        this.setState({ isUserSingUp: false });
        this.props.updateUserSingUp();
        this.props.closeModal();
        if(this.props.isFromGetQuotes) {
            this.props.updateIsFromGetQetQuote();
        }
    }

    afterUserSignUp = () => {
        return <Row>
            <div className="modal-img">
                <img src={logo}
                    width="180"></img>
            </div>

            <div className="alert alert-success success_box m-top-1">
                <span className="success_msg">
                    Your registration with LUXY is successful. Activate your account within 30 days.
                    {/* Your registration was successful. Please check your inbox to activate your account.
                    {
                        (this.props.isFromGetQuotes) &&
                        <div>
                            Please Close the Popup and Select the Vehicle to Continue.
                        </div>
                    } */}
                </span>
            </div>
        </Row>

    }

    continueAfterSignUp = async () => {
        let response = await this.props.continueAsGuest();
        // localStorage.setItem('token', response.data.token);
        sessionStorage.setItem('token', response.data.token);
        // localStorage.setItem('isGuestUser', 'true');
        sessionStorage.setItem('isGuestUser', 'true');

        const user  = {
            first_name: this.props.userSignUpData.first_name,
            last_name: this.props.userSignUpData.last_name,
            email_address: this.props.userSignUpData.email_address,
            mobile_number: this.props.userSignUpData.mobile_number
        }
        // localStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem("user", JSON.stringify(user));
        this.props.updateNavData();

        if (this.props.navigateToRideDetail && this.props.fromBookARide) {
            const clonedObject = JSON.parse(JSON.stringify(this.props.bookingDetails));
            const keyToRemove = 'directions';
            delete clonedObject[keyToRemove];
            this.props.history.push('/ride-details', { bookingDetails: clonedObject, vehiclesList: this.props.allVehicles });
        }


    }

    afterUserSignUpForRideRewards = () => {
        return <Row>
            <div className="modal-img">
                <img src={logo}
                    width="180"></img>
            </div>

            <div className="alert alert-success success_box m-top-1">
                <span className="success_msg">
                    Your are successfully enrolled to rewards program. Please check your inbox to activate your account.
                </span>
            </div>
        </Row>

    }

    afterUserSignInForRideRewards = () => {
        return <Row>
            <div className="modal-img">
                <img src={logo}
                    width="180"></img>
            </div>

            <div className="alert alert-success success_box m-top-1">
                <span className="success_msg">
                    Your are successfully enrolled to rewards program.
                </span>
            </div>
        </Row>

    }
    afterForgotPassCode = () => {
        return <Row>
            <div className="modal-img">
                <img src={logo}
                    width="180"></img>
            </div>

            <div className="alert alert-success success_box m-top-1">
                <span className="success_msg">Please check your inbox to reset your password.</span>
            </div>
        </Row>

    }

    afterResendActivationLink = () => {
        return <Row>
            <div className="modal-img">
                <img src={logo}
                    width="180"></img>
            </div>

            <div className="alert alert-success success_box m-top-1">
                <span className="success_msg">Please check your inbox to Activation your Account.</span>
            </div>
        </Row>
    }

    activateUser = () => {
        return;
    }

    render() {
        return (
            <>
                <Modal show={true} style={{ top: '50px' }} >
                    <div className="float-right">
                        <i className="fa fa-times float-right pointer"
                            onClick={() => this.closeModal()}
                            title="close"></i>
                    </div>
                    <Modal.Body>
                        {
                            (this.state.isUserSingUp) ?
                                <>
                                {this.afterUserSignUp()}
                                    {
                                        (this.props.isFromGetQuotes) ?
                                            //<></>
                                            <>
                                            <div className="float-right row no-gutters">
                                                <div className="col-auto spacer"></div>
                                                <div>
                                                    <button
                                                        onClick={() => this.continueAfterSignUp()}
                                                        className="btn btn-block primary custom-button">Continue Booking</button>
                                                </div>
                                            </div> </>
                                            : <></> }
                                </>
                                :
                                (this.props.isForgotPassCode)
                                    ?
                                    <>
                                        {this.afterForgotPassCode()}
                                    </>
                                    :
                                    (this.props.activateUser) ?
                                        <ActivateUser
                                            closeModal={() => this.props.closeModal()}
                                        />
                                        :
                                        (this.props.isResendActivation) ?
                                            <>
                                                {this.afterResendActivationLink()}
                                            </>
                                            :
                                            (this.props.isEnrolledRideReward)
                                                ?
                                                <>
                                                    {
                                                        this.afterUserSignUpForRideRewards()
                                                    }
                                                </>
                                                :
                                                (this.props.isEnrolledRideRewardSignIn)
                                                    ?
                                                    <>
                                                        {
                                                            this.afterUserSignInForRideRewards()
                                                        }
                                                    </>
                                                    :
                                                    <AuthModalContent
                                                        fromBookARide={this.props.fromBookARide}
                                                        updateNavData={() => this.props.updateNavData()}
                                                        signUpRideRewards={this.props.signUpRideRewards}
                                                        signInRideRewards={this.props.signInRideRewards}
                                                        isFromGetQuotes={this.props.isFromGetQuotes}
                                                        uniqueID={this.props.uniqueID}
                                                    />
                        }

                    </Modal.Body>

                </Modal>
            </>
        )
    }
}

type Dispatch = (action: any) => void;

const updateUserSingUp = () => {
    return async (dispatch: Dispatch) => {

        dispatch({
            type: USER_SIGN_UP, data: false
        });

        dispatch({
            type: FORGOT_PASS_CODE, data: false
        });

        dispatch({
            type: IS_RESEND_ACTIVATION_LINK, data: false
        });
    }
}

const continueAsGuest = () => {
    return async (dispatch: Dispatch) => {

        let data = await await AuthService.continueAsGuest()
        dispatch({
            type: IS_GUEST_USER, data: true
        });

        return data;
    }
}

const userSignUpToast = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: SIGN_UP_TOAST, data: true
        });
    }
}

const updateIsFromGetQetQuote = () => {
    return async (dispatch: Dispatch) => {

        dispatch({
            type: IS_GET_QUOTES_CLICKED, data: false
        });

    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

        updateUserSingUp: () => dispatch(updateUserSingUp()),
        continueAsGuest: () => dispatch(continueAsGuest()),
        userSignUpToast: () => dispatch(userSignUpToast()),
        updateIsFromGetQetQuote: () => dispatch(updateIsFromGetQetQuote())
    };
};

const mapStateToProps = (state: any) => {
    return {
        isUserSingUp: state.user.isUserSingUp,
        isForgotPassCode: state.user.isForgotPassCode,
        isFromGetQuotes: state.bookARide.isGetQuotesClicked,
        isResendActivation: state.user.isResendActivation,
        signUpRideRewards: state.user.signUpRideRewards,
        isEnrolledRideReward: state.user.isEnrolledRideReward,
        signInRideRewards: state.user.signInRideRewards,
        isEnrolledRideRewardSignIn: state.user.isEnrolledRideRewardSignIn,
        bookingDetails: state.bookARide.bookingState,
        allVehicles: state.bookARide.allVehicles,
        navigateToRideDetail: state.bookARide.navigateToRideDetail,
        userSignUpData: state.user.signUpData
    };
};


const AuthModalContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthModal));

export default AuthModalContainer;