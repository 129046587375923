import axios from 'axios';

const instance = axios.create({
});

instance.interceptors.request.use(

    function (config: any) {

        return config;
    },
    function (error) {
    });

instance.interceptors.response.use(function (response) {
    return response;

},
    function (error) {
    }
);

export default instance;
