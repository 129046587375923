import React from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import Alert from 'react-bootstrap/esm/Alert';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import PhoneInput from 'react-phone-number-input';
import { createEventGA4 } from '../../Utilities/helpers';

import * as Helpers from '../common/helpers';
import AgreeText from '../policy/agree-text';

import GooglePlacesComponent from '../google-places/google-places';
import { getLuxyAddress, isValidLuxyAddress } from '../../services/AddressService';
import CommonService from '../../services/CommonService';

interface CFormProps {
    saveCorporate: (payload: any) => any;
    corporateErr: boolean;
    corporateErrMsg: string;
}

interface CFormState {
    corporateErr: boolean;
    firstNameErr: boolean;
    firstName: string;
    lastNameErr: boolean;
    lastName: string;
    termsErr: boolean;
    terms: boolean;
    companyNameErr: boolean;
    companyName: string;
    email: string;
    emailErr: boolean;
    mobile: string;
    mobileErr: boolean;
    form_start: boolean;
    businessAddress: string;
    businessAddressObj: string;
    businessAddressErr: boolean;
    pinOption: boolean;
}
class CorporateForm extends React.Component<CFormProps, CFormState> {

    constructor(props: CFormProps) {
        super(props);
        this.state = {
            corporateErr: false,
            firstNameErr: false,
            firstName: '',
            lastNameErr: false,
            lastName: '',
            termsErr: false,
            terms: false,
            companyNameErr: false,
            companyName: '',
            email: '',
            emailErr: false,
            mobile: '',
            mobileErr: false,
            form_start: false,
            businessAddress: "",
            businessAddressObj:"",
            businessAddressErr: false,
            pinOption: true,
        }
    }

    validate = async () => {
        (this.state.companyName.trim() === '') ? this.setState({ companyNameErr: true }) : this.setState({ companyNameErr: false });
        (this.state.firstName.trim() === '') ? this.setState({ firstNameErr: true }) : this.setState({ firstNameErr: false });
        (this.state.lastName.trim() === '') ? this.setState({ lastNameErr: true }) : this.setState({ lastNameErr: false });
        (this.state.email === '' || !Helpers.validateEmail(this.state.email)) ? this.setState({ emailErr: true }) : this.setState({ emailErr: false });
        (this.state.mobile === '' || !Helpers.validateCountryPhone(this.state.mobile)) ? this.setState({ mobileErr: true }) : this.setState({ mobileErr: false });
        (!this.state.terms) ? this.setState({ termsErr: true }) : this.setState({ termsErr: false });
        if (!this.state.businessAddress) { this.setState({ businessAddressErr: true }) };
    }

    updateEmail = (event: any) => {
        this.setState({ email: event.target.value });
        let isValid = Helpers.validateEmail(event.target.value);
        this.setState({ emailErr: !isValid });

    }

    updateMobile = async(event: any) => {
        // this.setState({ mobile: event.target.value });
        let isValid = await Helpers.validateCountryPhone(event);
        this.setState({ mobile: event, mobileErr: !isValid });

    }

    validateOtherFields = (event: any, type: string) => {
        if (!this.state.form_start) {
            const dataLayerObj: any = {
                'eventName': 'form_start',
                'formName': "Corporate",
                'event': 'formStart'
            };
            createEventGA4(dataLayerObj);
        }
        this.setState({ form_start: true });
        if (type === 'company_name') {
            if (event.target.value.trim() === '') {
                this.setState({ companyNameErr: true, companyName: event.target.value });
            } else {
                this.setState({ companyNameErr: false, companyName: event.target.value });
            }
        }

        if (type === 'first_name') {
            if (event.target.value.trim() === '') {
                this.setState({ firstNameErr: true, firstName: event.target.value });
            } else {
                this.setState({ firstNameErr: false, firstName: event.target.value });
            }
        }
        if (type === 'last_name') {
            if (event.target.value.trim() === '') {
                this.setState({ lastNameErr: true, lastName: event.target.value });
            } else {
                this.setState({ lastNameErr: false, lastName: event.target.value });
            }
        }

        if (type === 'terms') {
            let isChecked: boolean = event.target.checked;
            if (!isChecked) {
                this.setState({ termsErr: true, terms: isChecked });
            } else {
                this.setState({ termsErr: false, terms: isChecked });
            }

        }

        if (type === 'business_address') {
            if (event.target.value.trim() === '') {
                this.setState({ businessAddressErr: true, businessAddress: event.target.value });
            } else {
                this.setState({ businessAddressErr: false, businessAddress: event.target.value });
            }
        }

    }

    createCorporateAccount = async () => {
        await this.validate();
        if (!this.state.firstNameErr &&
            !this.state.lastNameErr &&
            !this.state.emailErr &&
            !this.state.companyNameErr &&
            !this.state.mobileErr &&
            !this.state.termsErr &&
            !this.state.businessAddressErr) {
            const payload: any = {
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                mobile_number: this.state.mobile,
                email_address: this.state.email,
                company_name: this.state.companyName,
                work_address: this.state.businessAddressObj,
                create_concierge: true, 
                created_by: 1,
                pin_opt_in:this.state.pinOption
            }
            await this.props.saveCorporate(payload);
        }

    }

    handleAddrChange = (event: any) => {
        let value = event;
        this.setState({ businessAddress: value, businessAddressErr: true });
    }

    handleBusinessAddressSelect = async (selection: any) => {
        const address = selection.original[0];
        let business_address = address.name;
        Promise.resolve(getLuxyAddress(address)).then(async (businessAddress: any) => {
            business_address = businessAddress.address.full_address;
            let business_address_obj = businessAddress;

            if (!businessAddress.address.postal_code) {

                let postalCode: any = await CommonService.getPOstalCodeDetails(businessAddress.coordinates.latitude, businessAddress.coordinates.longitude);

                if (postalCode) {
                    businessAddress.address.postal_code = postalCode.postal_code;
                }
            }

            isValidLuxyAddress(businessAddress);
            this.setState({ businessAddress: business_address });
            this.setState({ businessAddressObj: business_address_obj });
            this.setState({ businessAddressErr: false });
        }).catch((err) => {
            this.setState({ businessAddress: business_address });
            this.setState({ businessAddressErr: true });
        });
    }

    render() {
        return (
            <div>
                {
                    (this.props.corporateErr) &&
                    <Alert variant='danger'>
                        {(this.props.corporateErrMsg != "Company or Email already exist") ?
                        <>
                        <b>Error!</b> {this.props.corporateErrMsg}.
                        </>:
                        <span><b>Error!</b> Company or Email already exists! Login <a target={"_blank"} href={process.env.REACT_APP_CONCIERGE_URL}>here</a></span>
                        }
                    </Alert>
                }
                <div className="sign-in">
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="companyName" className="float-left">COMPANY NAME <span className="importantfields">*</span></label>
                                <FormControl
                                    id="companyName"
                                    onChange={(event: any) =>
                                        this.validateOtherFields(event, 'company_name')
                                    }
                                    value={this.state.companyName}
                                    autoComplete="nope"
                                />

                                <div>
                                    <p className="text-danger">{
                                        (this.state.companyNameErr) ?
                                            'Company Name is required'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="firstName" className="float-left">FIRST NAME <span className="importantfields">*</span></label>
                                <FormControl
                                    id="firstName"
                                    onChange={(event: any) =>
                                        this.validateOtherFields(event, 'first_name')
                                    }
                                    value={this.state.firstName}
                                    autoComplete="nope"
                                />

                                <div>
                                    <p className="text-danger">{
                                        (this.state.firstNameErr) ?
                                            'First Name is required'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="lastName" className="float-left">LAST NAME <span className="importantfields">*</span></label>
                                <FormControl
                                    id="lastName"
                                    //placeholder={"Enter Last name"}
                                    onChange={(event: any) =>
                                        this.validateOtherFields(event, 'last_name')
                                        // this.setState({ lastName: event.target.value })
                                    }
                                    value={this.state.lastName}
                                    autoComplete="nope"
                                />
                                <div>
                                    <p className="text-danger">{
                                        (this.state.lastNameErr) ?
                                            'Last Name is required'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="email" className="float-left">EMAIL <span className="importantfields">*</span></label>
                                <FormControl
                                    id="email"
                                    //placeholder={"Enter Email"}
                                    onChange={(event: any) =>
                                        this.updateEmail(event)
                                        // this.setState({ email: event.target.value })
                                    }
                                    value={this.state.email}
                                    autoComplete="nope"
                                />
                                <div>
                                    <p className="text-danger">{
                                        (!this.state.email && this.state.emailErr) ? 'Email is required': (this.state.emailErr) ?
                                            'Enter valid email address'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <div className="form-group">
                                <label htmlFor="mobile" className="">MOBILE PHONE NUMBER <span className="importantfields">*</span></label>
                                {/* <FormControl
                                    id="mobile"
                                    // placeholder={"Enter Mobile Number"}
                                    onChange={(event: any) =>
                                        this.updateMobile(event)
                                        // this.setState({ mobile: event.target.value })
                                    }
                                    maxLength={16}
                                    value={this.state.mobile}
                                />
                                <div>
                                    <p className="text-danger">{
                                        (this.state.mobileErr) ?
                                            'Enter valid contact number'
                                            : null
                                    }</p>
                                </div> */}


                                {/* starts here */}
                                <PhoneInput
                                     defaultCountry='US'
                                     international
                                    onChange={(event: any) =>
                                        this.updateMobile(event)
                                    }
                                    value={this.state.mobile}
                                    autoComplete="nope"
                                />

                                <div>
                                    <p className="text-danger">{
                                        (!this.state.mobile && this.state.mobileErr) ? 'Mobile Number is required' : (this.state.mobileErr) ?
                                            'Enter valid mobile number'
                                            : null
                                    }</p>
                                </div>
                                {/* ends here */}
                            </div>




                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group business-address">
                                <label htmlFor="businessAddress" className="float-left"> WORK ADDRESS <span className="importantfields">*</span></label>
                                <GooglePlacesComponent
                                    id='affliliate_addr'
                                    onPlaceChanged={this.handleBusinessAddressSelect}
                                    value={this.state.businessAddress}
                                    handleChange={(event: any) => this.handleAddrChange(event)}
                                    showFavorites={false}
                                    isGroundOnly={true}
                                />

                                <div>
                                    <p className="text-danger">{
                                        (!this.state.businessAddress && this.state.businessAddressErr) ? 'Work Address is required' :
                                        (this.state.businessAddressErr) ?
                                            'Please select a complete address'
                                            : null
                                    }</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox"
                                className="custom-label"
                                // style={{textTransform: 'none'}}
                                label={'Opt in PIN Verification for security'}
                                // checked={false}
                                onChange={(event) => {
                                    this.setState({ pinOption: !this.state.pinOption });
                                  }}
                                checked={this.state.pinOption}
                            />
                            <div>
                                <p className="summary-mini-text">
                                It is advised for Corporations to employ Luxy's Pin Verification Security Measure. However, the Corporation retains the option to decline Luxy's Pin Verification by deselecting the checkbox. In the event that the Corporation exercises this option, it acknowledges and assumes all liabilities, expenses, fraudulent activities, charges, or potential conflicts arising from the decision to opt out of Luxy's Pin Verification.
                                </p>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox"
                                className="custom-label"
                                // style={{textTransform: 'none'}}
                                label={<AgreeText type="corporate" />}
                                // checked={false}
                                onChange={(event: any) =>
                                    // this.setState({ terms: event.target.checked })
                                    this.validateOtherFields(event, 'terms')
                                }
                            />
                            <div>
                                <p className="text-danger">{
                                    (this.state.termsErr) ?
                                        'You should agree with our terms of service, privacy policy and cookie policy'
                                        : null
                                }</p>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col>
                        </Col>
                        <Col>
                            <Button variant="submit float-right"
                                onClick={() => this.createCorporateAccount()}
                            >Create</Button>{' '}
                        </Col>
                    </Row>

                </div>
            </div>
        )
    }
}

export default CorporateForm;

