import React, { Component } from 'react';
import { Alert, Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { StatusCode } from '../../constants/status-codes';
import { SignInError } from '../../constants/validations';
import { USER_LOGIN, IS_USER_LOGIN, IS_GUEST_USER, IS_RESEND_ACTIVATION_LINK, SIGN_UP_RIDE_REWARDS, SIGN_IN_RIDE_REWARDS, USER_ENROLLED_RIDE_REWARDS_SIGN_IN } from '../../reducer/user';
import { AuthService } from '../../services/auth';
import * as Helpers from '../common/helpers';
import { createEventGA4 } from '../../Utilities/helpers';

interface SignInProps extends RouteComponentProps<any> {
    showForgotPassword: () => void;
    signIn: (payload: any) => any;
    dispatchUserLogin: () => void;
    updateNavData: () => any;
    continueAsGuest: () => any;
    navigateToRideDetail: any;
    fromBookARide?: boolean;
    resendActivationLink: (user: any) => any;
    user: any;
    bookingDetails: any;
    allVehicles: any;
    signInRideRewards: any;
    signedInRideRewards: (data: boolean) => any;
    isFromGetQuotes: boolean;
}

interface SignInState {
    email: string;
    password: string;
    emailErr: boolean;
    passErr: boolean;
    isSignInErr: boolean;
    errorData: any;
    saveButton: string;
}

class SignIn extends Component<SignInProps, SignInState> {
    public passCodeRef: any = React.createRef();
    constructor(props: SignInProps) {
        super(props);
        this.state = {
            email: '',
            password: '',
            emailErr: false,
            passErr: false,
            isSignInErr: false,
            errorData: {},
            saveButton: 'Sign In'

        }
    }

    componentDidMount = () => {

        if (this.props.signInRideRewards) {
            this.setState({ saveButton: 'Enroll' });
        }
    }

    signIn = async () => {

        await this.validateForm();
        this.setState({ isSignInErr: false });
        if (!this.state.emailErr && !this.state.passErr) {

            let payload: any = {
                ...this.state,
                application: process.env.REACT_APP_USER_TYPE
            }

            if (this.props.signInRideRewards) {
                payload = {
                    ...payload,
                    is_enrolled_into_riderewards: true
                }
            }

            let data: any = await this.props.signIn(payload);



            if (data.status === StatusCode.BAD_REQUEST) {

                this.setState({ isSignInErr: true, errorData: data });
            } else {

                this.setState({ isSignInErr: false });

                if(this.props.signInRideRewards) {
                    this.props.signedInRideRewards(true);
                    // ReactGA.event(googleEventAnalytics.RideRewardsSignIn);
                    return;
                }

                // localStorage.setItem('access_token', data.token);
                sessionStorage.setItem('access_token', data.token);
                // localStorage.setItem('refresh_token', data.refreshToken);
                sessionStorage.setItem('refresh_token', data.refreshToken);
                // localStorage.setItem('isLoggedIn', 'true');
                sessionStorage.setItem('isLoggedIn', 'true');
                // localStorage.removeItem('isGuestUser');
                sessionStorage.removeItem('isGuestUser');
                // localStorage.setItem('user', JSON.stringify(data.data[0]));
                sessionStorage.setItem('user', JSON.stringify(data.data[0]));
                this.props.dispatchUserLogin();
                this.props.updateNavData();
                // ReactGA.event(googleEventAnalytics.SignIn);

                let loginArea: string = 'navigation';
                if (this.props.signInRideRewards) {
                    loginArea = 'enroll';
                }
                if(this.props.isFromGetQuotes) {
                    loginArea = 'get_quote';
                }
                const dataLayerObj: any = {
                    'eventName': 'login_success',
                    'loginArea': loginArea,
                    'loginStatus': 'true',
                    'userId': (data.data[0]) ? data.data[0].id.toString() : '',
                    'event': 'loginSuccess'
                };
                createEventGA4(dataLayerObj);
                if (this.props.navigateToRideDetail && this.props.fromBookARide) {
                    // console.log("now123", this.props.bookingDetails);
                    // const selectedObj: any = this.SelectedVehicleObj(this.props.bookingDetails.selectedVehicle);
                    // const preparedObj: any = prepareSelectedItemList(selectedObj, this.props.bookingDetails.selectedVehicleIndex);
                    // createEcommerceGA4(preparedObj, ECOMMERCE.SELECT);
                    // createEcommerceGA4(preparedObj, ECOMMERCE.ADD);
                    const clonedObject = JSON.parse(JSON.stringify(this.props.bookingDetails));
                    const keyToRemove = 'directions';
                    delete clonedObject[keyToRemove];
                    this.props.history.push('/ride-details', { bookingDetails: clonedObject, vehiclesList: this.props.allVehicles });
                }
            }
        }

    }

    SelectedVehicleObj = (data: any) => {
        if(data){
            const { vehicle_type_name, vehicle_type_id, base_price } = data;
            return {
                vehicle_type_name,
                vehicle_type_id,
                base_price
            }
        }
        return {
            
        }
       
    }

    continueAsAGuest = async () => {
        let response = await this.props.continueAsGuest();
        // localStorage.setItem('token', response.data.token);
        sessionStorage.setItem('token', response.data.token);
        // localStorage.setItem('isGuestUser', 'true');
        sessionStorage.setItem('isGuestUser', 'true');
        this.props.updateNavData();
        const dataLayerObj: any = {
            'eventName': 'continue_as_guest',
            'event': 'continueAsGuest'
        };
        createEventGA4(dataLayerObj);
        if (this.props.navigateToRideDetail && this.props.fromBookARide) {
            // const selectedObj: any = this.SelectedVehicleObj(this.props.bookingDetails.selectedVehicle);
            // const preparedObj: any = prepareSelectedItemList(selectedObj, this.props.bookingDetails.selectedVehicleIndex);
            // createEcommerceGA4(preparedObj, ECOMMERCE.SELECT);
            // createEcommerceGA4(preparedObj, ECOMMERCE.ADD);
            const clonedObject = JSON.parse(JSON.stringify(this.props.bookingDetails));
            const keyToRemove = 'directions';
            delete clonedObject[keyToRemove];
            this.props.history.push('/ride-details', { bookingDetails: clonedObject , vehiclesList: this.props.allVehicles });
        }
        

    }

    validateForm = async () => {
        (this.state.email === '' || !Helpers.validateEmail(this.state.email)) ? this.setState({ emailErr: true }) : this.setState({ emailErr: false });

        (this.state.password === '') ? this.setState({ passErr: true }) : this.setState({ passErr: false });
    }

    togglePassWord = () => {
        const textType = this.passCodeRef.current.type;
        if (textType === 'password') {
            this.passCodeRef.current.type = 'text';
        } else {
            this.passCodeRef.current.type = 'password';
        }

    }

    resendActivationLink = async () => {

        await this.props.resendActivationLink(this.props.user);
    }

    handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            this.signIn();
        }
    }

    updateEmail = (event: any) => {
        this.setState({ email: event.target.value });
        let isValid = Helpers.validateEmail(event.target.value);
        this.setState({ emailErr: !isValid });
    }

    render() {
        return (
            <>
                <div className="sign-in">
                    {
                        (this.state.isSignInErr) &&
                        <>
                            <Alert variant='danger'>
                                <div>
                                    <b>Error!</b> {this.state.errorData.message}
                                </div>
                            </Alert>

                        </>
                    }
                    <>
                        <Row>

                            <Col>
                                <div className="form-group">
                                    <label htmlFor="email" className="float-left">Email <span className="importantfields">*</span></label>
                                    <FormControl
                                        type="email"
                                        id="email"
                                        onChange={(event: any) =>
                                            this.updateEmail(event)
                                        }
                                        autoComplete="nope"
                                        value={this.state.email}
                                    />

                                    <div>
                                        <p className="text-danger">{
                                            (!this.state.email && this.state.emailErr) ? 'Email is required' :
                                                this.state.emailErr ? SignInError.emailError
                                                : null
                                        }</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="password" className="float-left">Password <span className="importantfields">*</span></label>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            id="password"
                                            type="password"
                                            onChange={(event: any) =>
                                                this.setState({ password: event.target.value })
                                            }
                                            value={this.state.password}
                                            ref={this.passCodeRef}
                                            onKeyPress={(event: any) => this.handleKeyPress(event)}
                                            autoComplete="nope"
                                        />
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="basic-addon1"><i className="fa fa-eye pointer"
                                                onClick={() => this.togglePassWord()}
                                            ></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                    </InputGroup>
                                    <div>
                                        <p className="text-danger">{
                                            (!this.state.password && this.state.passErr) ? SignInError.passwordError
                                                : null
                                        }</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="pt-1 pointer"
                                    onClick={() => this.props.showForgotPassword()}
                                >Forgot Password?</p>
                            </Col>
                            <Col>
                                <Button
                                    onClick={() => this.signIn()}
                                    variant="submit float-right">{this.state.saveButton}</Button>{' '}
                            </Col>
                        </Row>
                    </>
                    <Row>
                        {

                            (this.state.errorData.is_active !== undefined && !this.state.errorData.is_active) &&
                            <Col>
                                <u className="pointer" style={{ color: '#EB8122' }}
                                    onClick={() => this.resendActivationLink()}
                                >Resend Activation</u>
                            </Col>

                        }
                        {
                            (!this.props.signInRideRewards)
                                ?
                                <Col>
                                    <p className="pt-3 pointer float-right"
                                        onClick={() => {
                                            // ReactGA.event( googleEventAnalytics.guest);
                                            this.continueAsAGuest()}
                                        }
                                    >Continue as guest</p>
                                </Col>
                                : null
                        }

                    </Row>
                </div>
            </>
        )
    }

}

type Dispatch = (action: any) => void;

/**
 * Post login.
 */
const signIn = (payload: any) => {
    return async (dispatch: Dispatch) => {
        let data = await AuthService.signIn(payload);
        

        if (data !== undefined) {
            dispatch({
                type: USER_LOGIN, data: data
            });
        }
        return data;
    }
}

const dispatchUserLogin = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: IS_USER_LOGIN, data: true
        });
    }
}

const continueAsGuest = () => {
    return async (dispatch: Dispatch) => {

        let data = await await AuthService.continueAsGuest()
        dispatch({
            type: IS_GUEST_USER, data: true
        });

        return data;
    }
}

const signedInRideRewards = (data: boolean) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: SIGN_UP_RIDE_REWARDS, data: false
        });
        dispatch({
            type: SIGN_IN_RIDE_REWARDS, data: false
        });
        dispatch({
            type: USER_ENROLLED_RIDE_REWARDS_SIGN_IN, data: true
        });
    }
}

const resendActivationLink = (user: any) => {
    return async (dispatch: Dispatch) => {
        
        await AuthService.resendActivationLink(user);
        
        dispatch({
            type: IS_RESEND_ACTIVATION_LINK, data: true
        });
    }
}

const mapStateToProps = (state: any) => {
    return {
        isUserLogin: state.user.isUserLogin,
        navigateToRideDetail: state.bookARide.navigateToRideDetail,
        user: state.user.user,
        bookingDetails: state.bookARide.bookingState,
        allVehicles: state.bookARide.allVehicles,
        signInRideRewards: state.user.signInRideRewards,
        isFromGetQuotes: state.bookARide.isGetQuotesClicked
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        signIn: (payload: any) => dispatch(signIn(payload)),
        dispatchUserLogin: () => dispatch(dispatchUserLogin()),
        continueAsGuest: () => dispatch(continueAsGuest()),
        resendActivationLink: (user: any) => dispatch(resendActivationLink(user)),
        signedInRideRewards: (data: boolean) => dispatch(signedInRideRewards(data))
    };
};

const SignInContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));

export default SignInContainer;

