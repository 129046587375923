import React, { Component } from 'react';
import './why-luxy.css';

interface LuxyAirportProps {

}

interface LuxyAirportState {

}
const TOP_AIRPORTS: any =  [
  {
    code: "LAX",
    name: "Los Angeles International Airport",
    location: "Los Angeles, California",
    link: 'https://luxyride.com/limo-service-lax/'
  },
  {
    code: "JFK",
    name: "John F. Kennedy International Airport",
    location: "New York City, New York",
    link : 'https://luxyride.com/limo-service-jfk/'
  },
  {
    code: "LGA",
    name: "LaGuardia Airport",
    location: "New York City, New York",
    link : 'https://luxyride.com/limo-service-lga-nyc'
  },
  {
    code: "DCA",
    name: "Ronald Reagan Washington National Airport",
    location: "Washington, D.C.",
    link : 'https://luxyride.com/limo-service-ronald-reagan-washington-national-airport/'
  },
  {
    code: "IAD",
    name: "Dulles International Airport",
    location: "Washington, D.C",
    link: 'https://luxyride.com/limo-service-washington-dc-dulles/'
  },
  {
      code: "SFO",
      name: "San Francisco International Airport",
      location: "San Mateo County, California",
      link : 'https://luxyride.com/limo-service-san-francisco/'
    },
    {
      code: "SAN",
      name: "San Diego International Airport",
      location: "San Diego, California",
      link : 'https://luxyride.com/limo-service-san-diego-airport/'

    },
    {
      code: "FLL",
      name: "Fort Lauderdale-Hollywood International Airport",
      location: "Fort Lauderdale, Florida",
      link : 'https://luxyride.com/limo-service-fort-lauderdale-hollywood-international-airport/'

    },
    {
      code: "MIA",
      name: "Miami International Airport",
      location: "Miami, FL",
      link : 'https://luxyride.com/limo-service-miami-florida/'
    },
    {
      code: "BOS",
      name: "Logan International Airport",
      location: "Boston, Massachusetts",
      link : 'https://luxyride.com/limo-service-boston-logan-airport/'
    }
  ];
  
class LuxyAirport extends Component<LuxyAirportProps, LuxyAirportState> {
    constructor(props: LuxyAirportProps) {
        super(props);

        this.state = {

        }
    }

    handleAirportClick = (link: string) => {
        // window.location.href = link;
        window.open(link, '_blank');
      };

    render() {
        return (
          <>
            <div className="footer_img_BANNER text-center p-4">
            <h1 className="top-airport-h1">Top Airports</h1>
            <div className="airport-cards-container">
              {TOP_AIRPORTS.map((airport: any, index: number) => (
                <div
                  className="airport-card"
                  key={index}
                  onClick={() => this.handleAirportClick(airport.link)}
                >
                  <p style={{color: "#fff"}}>{airport.name}</p>
                </div>
              ))}
            </div>
          </div>
          </>
        )
    }
}
export default LuxyAirport;