import httpService from "./http-service";
import * as EndPoints from '../constants/end-points';

var API_URL = process.env.REACT_APP_API_URL;
// var API_URL = 'http://localhost:9000';
export class NavigationService {

    /**
     * Get all Header menus.
     */
    static getHeaderMenus = async () => {
        
        const url = API_URL + EndPoints.Navigation.HeaderMenus;
        let data = await httpService<any>({ url: url, method: 'get' }, null);
        return data.data;
    }
}
