import React, { Component } from 'react';
import { FormControl, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import RideService from '../../services/RideService';
// import 'react-toastify/dist/ReactToastify.css';
import { StatusCode } from '../../constants/status-codes';
import { UPDATE_PROMO_APPLIED, UPDATE_PROMO_CODE_DATA, UPDATE_VALIDATION_DATA, API_ERROR } from '../../reducer/details';
import * as Helpers from '../common/helpers';
import { AuthService } from '../../services/auth';
import ConfirmPopup from '../modals/confirmation';
import AlertPopup from '../modals/alert-popup';

interface PromoCodeProps {
    bookingDetailsData: any;
    applyPromoCode: (promoCode: string, bookingDetails: any, userId: number) => any;
    updatePromoCodeResponse: (data: any) => any;
    updateConfirmBookingValidation: (data: any) => any;
    confirmBookingValidation: any;
    deletePromoCode: () => any;
    getReservationCharges : ()=> any;
    removeApiError : () => any;
    onRef: any;
    apiError: any;
    closePromoCodeToggle: (isFromAlert:any) => any;
}

interface PromoCodeState {
    bookingDetails: any;
    promoCode: string;
    isPromoCodeApplied: boolean;
    isDeletePromoCode: boolean;
    showPromoCodeMessage: boolean;
    promoCodeMessage: string;
    promoCodeTitle: string;
}

class PromoCode extends Component<PromoCodeProps, PromoCodeState> {
    constructor(props: PromoCodeProps) {
        super(props);
        this.state = {
            bookingDetails: {},
            promoCode: '',
            isPromoCodeApplied: false,
            isDeletePromoCode: false,
            showPromoCodeMessage: false,
            promoCodeMessage: '',
            promoCodeTitle: '',
        }
    }

    componentDidMount = () => {
        
        // this.props.onRef(this);
        // sessionStorage.getItem('promoCodeData');
        // if(promoCodeLcDataRaw !='' &&  promoCodeLcDataRaw!= undefined && promoCodeLcDataRaw !=null){
        //     const promoCodeLcDataParsed: any = JSON.parse(promoCodeLcDataRaw);
        //     console.log("promoCodeLcData",promoCodeLcDataParsed.data.promo_code_id)
        //     if(promoCodeLcDataParsed.data.promo_code_id){
        //         this.setState({
        //             promoCode : true
        //         })
        //     }
        // }
    }


    componentDidUpdate = (prevProps: any) => {
        if (prevProps.apiError.error_type !== this.props.apiError.error_type) {
            if (this.props.apiError.error_type === 'PROMOCODE') {
                this.setState({ promoCodeMessage: this.props.apiError.message });
                this.setState({ promoCodeTitle:  this.props.apiError.title });
                this.setState({ showPromoCodeMessage: true },()=>{
                this.props.removeApiError();
                this.deletePromoCode();
                });
                
            }
        }
    }

    componentWillUnmount(): void {
        this.removePromoCode();
        this.props.getReservationCharges();
        this.props.deletePromoCode();
    }

    promoCodeDetails = (event: any) => {
        // console.log("Its coming===")
        this.setState({ promoCode: event.target.value }, () => {
        });

    }

    validateData = async () => {
        // let passengerDetailsLS: any = localStorage.getItem('passengerData');
        let passengerDetailsLS: any = sessionStorage.getItem('passengerData');
        let primaryPassengerData = JSON.parse(passengerDetailsLS);

        let confirmBookingValidation = {
            primary_passenger: {
                pp_firstName: true,
                pp_lastName: true,
                pp_email: true,
                pp_mobile: true
            },
            secondary_passenger: {
                sp_firstName: true,
                sp_lastName: true,
                sp_email: true,
                sp_mobile: true
            },
            arrival_meet: {
                passenger_name: true
            }
        }

        if (!Helpers.validateEmail(primaryPassengerData.pp_email)) {
            confirmBookingValidation.primary_passenger.pp_email = false;
        }

        if (primaryPassengerData.pp_firstName == '') {
            confirmBookingValidation.primary_passenger.pp_firstName = false;
        }

        if (primaryPassengerData.pp_lastName == '') {
            confirmBookingValidation.primary_passenger.pp_lastName = false;
        }

        if (!Helpers.validatePhone(primaryPassengerData.pp_contact)) {
            confirmBookingValidation.primary_passenger.pp_mobile = false;
        }
        await this.props.updateConfirmBookingValidation(confirmBookingValidation);
    }

    applyPromoCode = async () => {
        let promoCode = this.state.promoCode;
        if (promoCode.trim() === '') {
            toast.error("Please Enter PromoCode");
            return false;
        }
        await this.validateData();

        let validateData = this.props.confirmBookingValidation;
        // check for Primary passenger data.
        if (!validateData.arrival_meet.passenger_name
            || !validateData.primary_passenger.pp_email
            || !validateData.primary_passenger.pp_firstName
            || !validateData.primary_passenger.pp_lastName
            || !validateData.primary_passenger.pp_mobile) {
            toast.error("Please verify and enter the required information");
            return false;
        }

        let userFromLocalStorage: any = sessionStorage.getItem('user');
        let userParsed = JSON.parse(userFromLocalStorage);
        let userId = 0;

        if (userParsed && userParsed.id) {
            userId = userParsed.id;
        } else {
            // Check from the API with email.
            // let passengerDetailsLS: any = localStorage.getItem('passengerData');
            let passengerDetailsLS: any = sessionStorage.getItem('passengerData');
            let primaryPassengerData = JSON.parse(passengerDetailsLS);
            let getCustomerByEmail: any = await AuthService.getUserByEmail(primaryPassengerData.pp_email);
            if (getCustomerByEmail && getCustomerByEmail.data && getCustomerByEmail.data.status === StatusCode.EVERYTHING_IS_OK) {
                let userDetails = getCustomerByEmail.data.data[0];
                userId = userDetails.id;
            } else {
                userId = 0;
            }
        }

        let responseData = await this.props.applyPromoCode(promoCode, this.props.bookingDetailsData, userId);
        if (responseData.data && responseData.data.status === StatusCode.EVERYTHING_IS_OK) {
            // localStorage.setItem('promoCodeData', JSON.stringify(responseData.data));
            sessionStorage.setItem('promoCodeData', JSON.stringify(responseData.data));
            this.setState({ isPromoCodeApplied: true });
            // Update promoCode response to store.
            await this.props.updatePromoCodeResponse(responseData.data);
            //added code for charges/v2
            this.props.getReservationCharges()
            //end
        }

        if (responseData.data.status === StatusCode.BAD_REQUEST) {
            toast.error(responseData.data.message == 'Invalid Promo code .Please re-enter' ? 'Invalid Promo Code. Please Enter Valid Promo Code' : responseData.data.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        if (responseData.data.error_type === 'PROMOCODE') {
            this.setState({ promoCodeMessage: responseData.data.message });
            this.setState({ promoCodeTitle: responseData.data.title })
            this.setState({ showPromoCodeMessage: true });
        } else if (responseData.data && responseData.data.status === StatusCode.EXPIRED) {
            let message = responseData &&responseData.data && responseData.data.message ? responseData.data.message : 'PromoCode Expired'
            toast.error(message);
        }
    }

    removePromoCodePopup = () => {
        let isDeletePromoCode: boolean = this.state.isDeletePromoCode;

        if (!isDeletePromoCode) {
            isDeletePromoCode = true;
        } else {
            isDeletePromoCode = false;
        }
        this.setState({ isDeletePromoCode: isDeletePromoCode });
    }
    removePromoCode = () => {

        // localStorage.removeItem('promoCodeData');
        sessionStorage.removeItem('promoCodeData');
        this.setState({ promoCode: '', isPromoCodeApplied: false });
        this.props.updatePromoCodeResponse({});
    }

    closeConfirmPopup = () => {
        this.setState({ isDeletePromoCode: false });
    }

    closePromoCodeMessagePopup = () => {
        this.setState({ showPromoCodeMessage: false, promoCodeMessage: '' },()=>{
                this.props.removeApiError();
                setTimeout(()=>{
                    this.props.closePromoCodeToggle(true);
                },3000)
                
        });

    }

    deletePromoCode = () => {
        this.removePromoCode();

        this.setState({ isDeletePromoCode: false },async ()=>{
          await  this.props.getReservationCharges()
          this.props.deletePromoCode();
        });
    }

    render() {

        return (

            <Row className=" mt-0 border-styling">


                <div className="col-md-12 course-info">
                    <div>
                        <ToastContainer />
                    </div>
                    <div className="row">
                        <div className="col-md-8 pl-4 col-7 pr-0 " >

                            <div className="form-group mb-0">

                                <FormControl
                                    id="promoCode"
                                    placeholder="Promo Code"
                                    style={{ borderRadius: '5px 0px 0px 5px ', padding: '20px', border: '1px solid #eb8122' }}
                                    onChange={(event: any) =>
                                        this.promoCodeDetails(event)
                                    }
                                    autoComplete={'off'}
                                    value={this.state.promoCode}
                                    disabled={this.state.isPromoCodeApplied}

                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-5 pl-0" >
                            <div>
                                <button type="button"
                                    className={(this.state.isPromoCodeApplied) ? "btn btn-small applyed-button"
                                        : "btn btn-small customized-button"} style={{ padding: '10px 25px' }}
                                    onClick={() => this.applyPromoCode()}
                                    disabled={this.state.isPromoCodeApplied}
                                >
                                    <i className="fa fa-gift"></i>  &nbsp;
                                {
                                        (!this.state.isPromoCodeApplied) ?
                                            'Apply Promo' : 'Applied'
                                    }

                                </button>
                                {
                                    (!this.state.isPromoCodeApplied) ?
                                        null : <i style={{ fontSize: '24px' }} className='font-13 text-danger fa fa-trash pointer'
                                            title='Delete'
                                            onClick={() => this.removePromoCodePopup()}
                                        ></i>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {
                    (this.state.isDeletePromoCode)
                        ? <ConfirmPopup
                            show={this.state.isDeletePromoCode}
                            closeConfirmPopup={this.closeConfirmPopup}
                            title={'Remove Promo Code'}
                            bodyText={'Would you like to remove Promo Code?'}
                            deleteMethod={this.deletePromoCode}
                        /> : null
                }
                {
                    (this.state.showPromoCodeMessage)
                        ? (<AlertPopup
                            show={this.state.showPromoCodeMessage}
                            closeAlertPopup={this.closePromoCodeMessagePopup}
                            title={this.state.promoCodeTitle}
                            bodyText={this.state.promoCodeMessage}
                            // deleteMethod={this.closePromoCodeMessagePopup}
                        />) : null
                }
            </Row>

        )

    }
}

type Dispatch = (action: any) => void;
const mapStateToProps = (state: any) => {
    return {
        bookingDetailsData: state.bookARide.bookingState,
        confirmBookingValidation: state.details.confirmBookingValidation,
        apiError: state.details.apiError,
    };
};

const applyPromoCode = (promoCode: string, bookingDetails: any, userId: number) => {
    return async () => {
        let promoCodeData = await RideService.applyPromoCode(promoCode, bookingDetails, userId);
        return promoCodeData;
    }
}
const updatePromoCodeResponse = (promoCodeData: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_PROMO_CODE_DATA, data: promoCodeData
        });
        dispatch({
            type: UPDATE_PROMO_APPLIED, data: true
        });
    }
}

const deletePromoCode = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_PROMO_APPLIED, data: false
        });
    }
}


const updateConfirmBookingValidation = (data: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_VALIDATION_DATA,
            data: data
        });
    }
}

const removeApiError = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: API_ERROR, data: {}
        });
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        applyPromoCode: (promoCode: string, bookingDetails: any, userId: number) => dispatch(applyPromoCode(promoCode, bookingDetails, userId)),
        updatePromoCodeResponse: (data: any) => dispatch(updatePromoCodeResponse(data)),
        updateConfirmBookingValidation: (data: any) => dispatch(updateConfirmBookingValidation(data)),
        deletePromoCode: () => dispatch(deletePromoCode()),
        removeApiError: () => dispatch(removeApiError())
    };
};

const PromoCodeContainer = connect(mapStateToProps, mapDispatchToProps)(PromoCode);

export default PromoCodeContainer;