import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ECOMMERCE } from '../../Consts';
import { createEcommerceGA4, getPlaceCodeNdState, prepareViewItemList } from '../../Utilities/helpers';
import VehicleList from '../book-a-ride/vehicle-list';
import VehicleConfirmationPopup from '../book-a-ride/vehicle-confirm-popup';

interface ChangeVehicleProps {
    bookingDetails: any;
    vehicleDetailsList: any;
    bookingDetailsReducer: any;
    childSeatValuesReducer: any;
    isChildSeatToggleOn: boolean;
    childSeatValues: any;
    isChangeVehicle: (data: boolean) => any;
    updateBookingState: (data: any) => any;
    updateChildSeatValues: (data: any) => any;
    changeListType: (type: string) => any;
    updateChildSeatData: (data: any) => any;
}

interface ChangeVehicleState {
    bookingDetails: any;
    vehicleDetailsList: any;
    renderData: boolean;
    isChangeVehicle: boolean;
    selectedVehicleIndex: number;
    selectedVehicleId: any;
    vehicleTypeId: any;
    selectedVehiclePopup: any;
    selectedVehicleIndexPopup: number;
    showVehicleConfirmPopup: boolean;
}

class ChangeVehicle extends Component<ChangeVehicleProps, ChangeVehicleState> {
    constructor(props: ChangeVehicleProps) {
        super(props);
        this.state = {
            bookingDetails: {},
            vehicleDetailsList: {},
            renderData: true,
            isChangeVehicle: false,
            selectedVehicleIndex: -1,
            selectedVehicleId: 0,
            vehicleTypeId: 0,
            selectedVehiclePopup: {},
            selectedVehicleIndexPopup: -1,
            showVehicleConfirmPopup: false,
        }
    }

    componentDidMount = () => {
        this.setState({selectedVehicleIndex : this.props.bookingDetails.selectedVehicleIndex})
    }

    componentWillUnmount = () => {
        this.closeVehicleChange();
    }

    componentDidUpdate = (prevProps: any) => {
        if (this.props !== prevProps) {
            if (this.props.bookingDetails !== prevProps.bookingDetails) {
                this.setState({ renderData: true, selectedVehicleIndex: this.props.bookingDetails.selectedVehicleIndex });
            }
        }
    }

    selectVehicle = (vehicle: any, index: number) => {
        


        let bookingDetails = JSON.parse(JSON.stringify(this.props.bookingDetails));
        
        bookingDetails.selectedVehicle = vehicle;
        bookingDetails.selectedVehicleIndex = index;
        bookingDetails.selectedVehicleId = vehicle.vehicle_type_id;
        //selectedVehicle: vehicle,

        this.setState({
            selectedVehicleIndex: index,
            selectedVehicleId: vehicle.vehicle_type_id,
            vehicleTypeId: vehicle.vehicle_type_id
        }, () => {
            
            this.props.updateBookingState(bookingDetails);

            if (this.props.childSeatValuesReducer && this.props.childSeatValuesReducer.length) {
                if (this.props.childSeatValuesReducer.length > (vehicle.passenger_capacity - 1)) {
                    const childSeatValues = this.props.childSeatValuesReducer;
                    childSeatValues.length = vehicle.passenger_capacity - 1;
                    
                    this.props.updateChildSeatValues(childSeatValues);
                }else{
                    const childSeatValues = this.props.childSeatValuesReducer;
                    this.props.updateChildSeatValues(childSeatValues);
                }
            }
            
            this.props.isChangeVehicle(false);
            this.setState({ isChangeVehicle: false });

            const { childSeatValues } = this.props;
            let filteredChildSeats = childSeatValues.filter((childSeat: any) => childSeat.id !== 0);
            if (this.props.isChildSeatToggleOn && filteredChildSeats && filteredChildSeats.length)  {
                this.props.updateChildSeatData([{id: 0, type: 'Select'}]);
            }
        });

    }

    changeVehicleList = () => {
        this.props.isChangeVehicle(true);
        this.setState({ isChangeVehicle: true },()=>{
            this.callViewItemListGA4();
        });
      
    }

    callViewItemListGA4 = () => {
        const viewItemList: any = prepareViewItemList(this.props.vehicleDetailsList, 'change_list');
        viewItemList.pickup = getPlaceCodeNdState(this.props.bookingDetails.pickup);
        viewItemList.dropoff = getPlaceCodeNdState(this.props.bookingDetails.dropoff);
        createEcommerceGA4(viewItemList, ECOMMERCE.VIEW);
    }
    closeVehicleChange = () => {
        this.props.isChangeVehicle(false);
        this.setState({ isChangeVehicle: false });
    }

    changeListType = (type: string) => {
        this.props.changeListType(type)
    }

    showConfirmationPopup = (vehicle: any, index: number) => {
        this.setState({selectedVehiclePopup: vehicle, selectedVehicleIndexPopup: index}, () => {
            this.setState({ showVehicleConfirmPopup: true });
        })
    }

    vehicleConfirm = () => {
        this.selectVehicle(this.state.selectedVehiclePopup, this.state.selectedVehicleIndexPopup);
        this.setState({ showVehicleConfirmPopup: false, selectedVehiclePopup: {}, selectedVehicleIndexPopup: -1 });
    }

    closeVehicleConfirm = () => {
        this.setState({ showVehicleConfirmPopup: false, selectedVehiclePopup: {}, selectedVehicleIndexPopup: -1 });
    }

    VehicleConfirmPopup = () => {
        return ( <VehicleConfirmationPopup
            show={true}
            closeConfirmPopup={() => this.closeVehicleConfirm()}
            title='Confirm your vehicle'
            // bodyText={"Please note that this vehicle can accommodate a maximum of"}
            callbackMethod={() => { this.vehicleConfirm() }}
            variant="success"
            selectedVehicle={this.state.selectedVehiclePopup}
            selectedVehicleIndex={this.state.selectedVehicleIndex}
        /> )
    }

    

    render() {
        let vehicleData: any = {};
        if (this.state.renderData ) {
            vehicleData = this.props.bookingDetails.selectedVehicle;
        }


        return (
<div className="p-0 w-100"> 
            <div className="luxy-border ">
                {
                    (this.state.renderData ) ?
                        <>
                            {
                                (!this.state.isChangeVehicle) ?
                                    <Row className="mt-3">
                                        <Col xl={8}   className="small-title col-7">Selected Vehicle</Col>
                                        <Col xl={4} className="text-right col-5">
                                            <div
                                                className="change-vehicle pointer"
                                                onClick={() => this.changeVehicleList()}><span className="luxy-btn">Change</span></div>
                                        </Col>
                                    </Row>
                                    : null
                            }

                            {
                                (!this.state.isChangeVehicle)
                                    ?
                                    <Row className="mt-3">
                                        <Col xs={12}>
                                            <div className="vehicle-name vehicle-name-bold">
                                                {vehicleData.vehicle_type_name}
                                            </div>
                                            <div>
                                                <img className="media-object" src={`${vehicleData.vehicle_image}`} alt={`${vehicleData.vehicle_image}`} />
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <Row>
                                            <Col xs={6} className="p-0">

<div className="text-left pull-left pt-2 laggage">
    <span>{vehicleData.passenger_capacity}</span> <i className="fa fa-user" aria-hidden="true"></i>
&nbsp;&nbsp;
<span>{vehicleData.luggage_capacity}</span> <i className="fa fa-suitcase" aria-hidden="true"></i>
</div>
</Col>
                                                <Col xs={6} className="p-0">
                                                    <div className="text-right  pt-1 vehicle-price font-13 ">
                                                        {vehicleData.base_price}
                                                    </div>
                                                </Col> 
                                               
                                            </Row>

                                        </Col>
                                    </Row>
                                    :
                                    <div>

                                        <VehicleList
                                            vehiclesList={this.props.vehicleDetailsList}
                                            selectVehicle={(vehicle: any, index: number) => this.showConfirmationPopup(vehicle, index)}
                                            selectedVehicleIndex={this.state.selectedVehicleIndex}
                                            isFromChangeVehicle={true}
                                            closeChangeVehicle={() => this.closeVehicleChange()}
                                            changeListType={(type: string) => this.changeListType(type)}
                                            pickup={this.props.bookingDetails.pickup}
                                            dropoff={this.props.bookingDetails.dropoff}
                                        />

                                    </div>

                            }

                        </>
                        : null
                }
            </div>
            {
            this.state.showVehicleConfirmPopup && this.VehicleConfirmPopup()
            }
            </div>
        )
    }
}
export default ChangeVehicle;
