import httpService from "./http-service";
import * as EndPoints from '../constants/end-points';
import instance from '../axios/external';

var API_URL = process.env.REACT_APP_API_URL;
// var API_URL = 'https://devapi.luxyride.com';
export class AuthService {

    static getAccessToken = async () => {
        const url = API_URL + EndPoints.Auth.GetAccessToken;
        let data = await httpService<any>({ url: url, method: 'post' }, null);
        // localStorage.setItem("accessToken", data.data.accessToken);
        sessionStorage.setItem("accessToken", data.data.accessToken);
        // localStorage.setItem("refreshToken", data.data.refreshToken);
        sessionStorage.setItem("refreshToken", data.data.refreshToken);
        return data.data;
    }

    static getPosts = async () => {
        const url = API_URL + EndPoints.Auth.GetPostsData;
        let data = await httpService<any>({ url: url, method: 'get' }, null);
        return data.data;
    }

    /**
     * Call refresh token to get access token and refresh token
     * @returns Response.
     */
    static refreshToken = async () => {
        const url = API_URL + EndPoints.Auth.RefreshToken;
        // let data = await httpService<any>({ url: url, method: 'post' }, { token: localStorage.getItem("refreshToken") });
        let data = await httpService<any>({ url: url, method: 'post' }, { token: sessionStorage.getItem("refreshToken") });
        return data.data;
    }

    static signIn = async (payload: any) => {

        let payloadData: any = {
            email: payload.email,
            password: payload.password,
            application: payload.application
        }
        if (payload.is_enrolled_into_riderewards) {
            payloadData = {
                ...payloadData,
                is_enrolled_into_riderewards: true
            }
        }
        const url = API_URL + EndPoints.Auth.SignIn;
        let data: any = await httpService<any>({ url: url, method: 'post' }, payloadData);
        if (data.data) {
            return data.data;
        } else {
            

            return data.error.data;
        }

    }

    static signUp = async (payload: any) => {
        let payloadObj = {
            ...payload,
            application: process.env.REACT_APP_USER_TYPE
        }

        const url = API_URL + EndPoints.Auth.SignUpV2;
        // let data = await httpService<any>({ url: url, method: 'post' }, payloadObj);
        // return data.data;

        let response = await instance.post(url, payloadObj, {});
        return response.data;
    }

    static forgotPassCode = async (payload: any) => {
        let payloadObj = {
            ...payload,
            application: process.env.REACT_APP_USER_TYPE
        }
        const url = API_URL + EndPoints.Auth.ForGotPassCode;
        let data = await httpService<any>({ url: url, method: 'post' }, payloadObj);
        return data.data;
    }

    static continueAsGuest = async () => {
        const url = API_URL + EndPoints.Auth.GuestToken;
        // let data = await httpService<any>({ url: url, method: 'get' }, null);

        let apiCall = url;
        let data = await instance.get(apiCall, {
            headers: {
                'authorization': process.env.REACT_APP_API_DEFAULT_KEY
            }
        });
        return data.data;
    }

    static getUserActivation = async (token: string) => {
        const url = `${API_URL + EndPoints.Auth.UserActivation}/${token}`;
        // await instance.get(url, {}).then((data: any) => {
        //     
        //     return data.data;
        // }).catch((err: any) => {
        //     
        //     let data = {
        //         status: 'Not Ok'
        //     }
        //     return data;
        // });
        let data = await instance.get(url, {});
        return data;
    }

    static updateUserActivation = async (payload: any) => {
        const url = `${API_URL + EndPoints.Auth.UpdateUserActivation}`;
        let data = await instance.put(url, payload);
        return data.data;
    }

    static customerResetPassword = async (payload: any) => {
        const url = `${API_URL}/customer/reset`;
        try {
            let response = await instance.post(url, payload, {});
            return response;
        } catch (e) {
            throw e;
        }
    }

    static verifyLinkExpiration = async (payload: any) => {
        try {
            const url = `${API_URL}/verify/link-expiration`;
            let response = await instance.post(url, payload, {});
            return response;
        } catch (e) {
            throw e;
        }
    }

    static resendActivationLink = async (user: any) => {
        const url = `${API_URL}/customer-activate/${user.id}?application=${process.env.REACT_APP_USER_TYPE}`;
        let response = await instance.get(url, {});
        return response;
    }

    static getUserByEmail = async (email: any) => {
        const url = `${API_URL}/customer-email/${email}`;
        let response = await httpService<any>({ url: url, method: 'get' }, null);
        return response;
    }

    static signUpForRideReward = async (email: any) => {
        const payload = {
            email: email
        }
        const url = `${API_URL}/ride-reward`;
        let response = await httpService<any>({ url: url, method: 'put' }, payload);
        return response;
    }

    /**
     * Attach login user to corporate or ta based on user id and unique id.
     * @param user_id 
     * @param unique_id 
     * @returns 
     */
    static enrollToCorporate = async (user_id: any, unique_id: string) => {
        try {
            const payload = {
                unique_id
            }
            const url = `${API_URL}/users/${user_id}/corporates/NA`;
            let response = await instance.put(url, payload, {});
            return response;
        } catch (e) {
            throw e;
        }
    }
}