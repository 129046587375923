import { connect } from 'react-redux';
import UserList from '../../components/user/user-list';
import * as UserService from '../../services/user';
import { GET_ALL_USERS } from '../../reducer/user';

type Dispatch = (action: any) => void;

/**
 * Get all users from user service.
 */
const getAllUsers = () => {
    return async (dispatch: Dispatch) => {
        let data = await UserService.UserService.getAllUsers();
        if (data !== undefined) {
            dispatch({
                type: GET_ALL_USERS, data: data
            });
        }
    }
}

/**
 * Update Users to Redux store.
 * @param users
 * @returns @void
 */
const updateUsers = (users: any) => {
    return async (dispatch: Dispatch) => {

        dispatch({
            type: GET_ALL_USERS, data: users
        });
    }
}

const mapStateToProps = (state: any) => {
    return {
        allUsers: state.user.allUsers
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getAllUsers: () => dispatch(getAllUsers()),
        updateUsers: (allUsers: any) => dispatch(updateUsers(allUsers))
    };
};

const UserListContainer = connect(mapStateToProps, mapDispatchToProps)(UserList);

export default UserListContainer;