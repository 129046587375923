import axios, { Method } from 'axios';

const httpService = <T>(request: { url: string, method?: Method, config?: {} }, payload: any):
    Promise<{ data?: T, error?: any }> => {
    return new Promise(resolve => {
        axios(request.url, {
            method: request.method ? request.method : 'get',
            data: (request.method === 'post' || request.method === 'put') ? payload : null,
            params: (request.method !== 'post' && request.method !== 'put') ? payload : null,
            ...request.config
        })
            .then(response => {
                resolve({ data: response.data });
            })
            .catch(reason => {
                resolve({ error: reason });
            });
    });
};
export default httpService;

