import { connect } from 'react-redux';
import Affiliate from '../components/affiliate/affiliate';

type Dispatch = (action: any) => void;

const mapStateToProps = (state: any) => {
    return {
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
    };
};

const AffiliateContainer = connect(mapStateToProps, mapDispatchToProps)(Affiliate);

export default AffiliateContainer;