import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import './widget.css';
import { RouteComponentProps } from "react-router";
import ReactGA from 'react-ga';
import { googleEventAnalytics } from '../../constants/app-constants';


interface GetLuxyProps extends RouteComponentProps<any> {

}

interface GetLuxyState {

}

class GetLuxy extends Component<GetLuxyProps, GetLuxyState> {
    constructor(props: GetLuxyProps) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            <div className='get-luxy-container'>
                <h2 className='text-left font-white'>Book Now</h2>
                <p className='txt-14 font-white'>Luxury cars, professional drivers and 24/7 live support.</p>
                <div className=' text-center'>
                    <Button
                        onClick={() => {
                            ReactGA.event({...googleEventAnalytics.BookNowWidget, label:  "Clicked on Get quotes widget from " + window.location.pathname + ""});
                            this.props.history.push('/')
                        }}
                        style={{ fontSize: '12px' }}
                        variant="submit"
                        className="custom-button"
                    ><b>GET QUOTE</b></Button>{' '}

                </div>
            </div>
        )
    }
}

export default withRouter(GetLuxy);