const defaultState = {
    
}

export function rideDetailsReducer(state: any = defaultState, action: { type: string, data?: any }){
    switch (action.type) {
        default:
            return state;
    }
}
