import React, { Component } from 'react';
import { Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

interface BookingForPros {
    updateBookingFor: (data: string) => any;
    booking_for: string;
    travel_option_addon: any;
}

interface BookingForState {
    // booking_for: string;
}
const Leisure = 'Personal';
const Business = 'Business';

class BookingFor extends Component<BookingForPros, BookingForState> {
    constructor(props: BookingForPros) {
        super(props);
        this.state = {
            // booking_for: 'Leisure'
        }
    }
    componentDidMount(): void {
        this.props.updateBookingFor(Leisure);
    }

    selectBookingOption = (ev: any) => {

        this.props.updateBookingFor(ev.target.value);
    }
    renderTooltip = () => {
        const description = (this.props.travel_option_addon && this.props.travel_option_addon.description) ? this.props.travel_option_addon.description : "";
        return <Tooltip id="button-tooltip">
            <div className="addon-meta" dangerouslySetInnerHTML={this.createMarkup(description)} ></div>
        </Tooltip>
    }

    createMarkup = (myTextFromDatabase: any) => {
        return { __html: myTextFromDatabase };
    }

    render() {
        return (
            <div>
                {/* <div className="row"> */}
                {/* <h5> Choose your Travel option for this ride </h5> */}

                <Row>
                    <Form>
                        {['radio'].map((type: any, i: number) => (
                            <div key={`default-${type}`} className="flex">
                                <Form.Check
                                    type={type}
                                    id={`default-${type}`}
                                    label={`Personal`}
                                    name="booking_for"
                                    checked={(this.props.booking_for === Leisure)}
                                    value={Leisure}
                                    onClick={(event) => this.selectBookingOption(event)}
                                />

                                <Form.Check
                                    style={{ marginLeft: '15px' }}
                                    type={type}
                                    label={`Business`}
                                    id={`disabled-default-${type}`}
                                    name="booking_for"
                                    checked={(this.props.booking_for === Business)}
                                    value={Business}
                                    onClick={(event) => this.selectBookingOption(event)}
                                />
                            </div>
                        ))}
                    </Form>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 250 }}
                        overlay={this.renderTooltip()}
                    >
                        <i className="fa fa-info-circle ml-2" style={{ marginTop: '7px' }}></i>
                    </OverlayTrigger>
                </Row>
                {/* </div> */}
            </div>

        )
    }
}

export default BookingFor;