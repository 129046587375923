import axios from 'axios';
import React, { Component } from 'react';
// import config from 'react-global-configuration';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import CheckboxAgreement from '../Agreement/checkbox';
import { checkPassword } from '../../Utilities/password';
// import AgreementService from '../../service/AgreementService';
import { RouteComponentProps } from 'react-router';
import logo from '../../assets/images/Image 6@2x.png';
import './activation.css';
import { Col, Form } from 'react-bootstrap';
import AgreeText from '../policy/agree-text';

interface ActivateProps extends RouteComponentProps<any> {

}

interface ActivateState {
    errors: any;
    fields: any;
    type: string,
    wording: string,
    isActive: boolean;
    isChecked: boolean;
    user_type_id: number;
    userType: string;
    post: {
        email: string,
        password: string,
        confirmPass: string,
        application: string,
    },
}

class ActivateCustomer extends Component<ActivateProps, ActivateState> {
    constructor(props: ActivateProps) {
        super(props);
        this.state = {
            ...this.state,
            errors: {},
            fields: {},
            type: 'password',
            wording: 'Show',
            isActive: false,
            isChecked: false,
            user_type_id: 0,
            userType: '',
            post: {
                email: this.props.match.params.email,
                password: '',
                confirmPass: '',
                application: 'BOOKING_WIDGET'
                // application: config.get('APPLICATION'),
            },
        };
        this.changeState = this.changeState.bind(this);
        this.CheckURLExpiration = this.CheckURLExpiration.bind(this);
    }

    // because it affects all available states.
    changeState() {
        var oldState = this.state.type;
        var isTextOrHide = (oldState === 'password');
        var newState = (isTextOrHide) ? 'text' : 'password';
        var newWord = (isTextOrHide) ? 'Hide' : 'Show';
        this.setState({
            type: newState,
            wording: newWord
        })
    }

    handleValidation() {
        let fields = this.state.post;
        let errors: any = {};
        let formIsValid = true;

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Password is  required.";
        }
        if (!fields["confirmPass"]) {
            formIsValid = false;
            errors["confirmPass"] = "Confirm password is  required.";
        }
        if (checkPassword(fields["password"]) == false) {
            formIsValid = false;
            errors["password"] = "Secured passwords are at least 8 characters long and include uppercase, numbers, and symbols.";
        } else if (fields["password"] != fields["confirmPass"]) {
            formIsValid = false;
            errors["confirmPass"] = "New password and confirm password must be same.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleCheckBoxChange(name: string, isChecked: boolean) {
        this.setState({ isChecked });
    }

    handleChange(field: any, e: any) {
        const post = Object.assign({}, this.state.post, { [field]: e.target.value });
        this.setState(Object.assign({}, this.state, { post }));
    }

    //form submit
    handleSubmit(e: any) {
        e.preventDefault();
        if (!this.handleValidation()) {
            toast.error("Please check all required fields and validation.");
            return false;
        }

        else if (!this.state.isChecked) {
            toast.error("Please accept Privacy Policy and Cookie Policy !");
            return false;
        }
        this.activate(this.state.post);
    }

    //page load event
    async componentDidMount() {
        this.CheckURLExpiration(); //Added for checking weather the link is expired or not.
    }

    CheckURLExpiration() {
        var formatted_token = this.props.match.params.email;
        var from = "activation";
        var data = {
            token: formatted_token,
            from: from
        };
        let ApiURL = process.env.REACT_APP_API_URL;
        axios.post(ApiURL + `/verify/link-expiration`, data)
            .then(res => {
                if (res.data != undefined && res.data.data != undefined) {
                    if (res.data.data[0].check_link_expiration == true) {
                        this.props.history.push('/expired');
                    }
                }
            });
    }

    //activate
    activate(data: any) {
        let ApiURL = process.env.REACT_APP_API_URL;
        axios.put(ApiURL + `/user/activate`, data)
            .then(res => {
                toast.success("Account has been activated", {
                    position: toast.POSITION.TOP_RIGHT
                });
                setTimeout(() => {
                    this.props.history.push('/');
                }, 2000);

            }).catch(error => {
                toast.error("Incorrect confirm password.", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
    }

    render() {
        return (
            <div className="">
                <div className="wrapper loginWrapper">
                    <div className="container-fluid customContainer">
                        <div className="loginContainer">
                            <div className="logoHead">
                                <img src={logo} className="logoSize" />
                            </div>
                            <div className="loginBody">
                                <div>
                                    <ToastContainer />
                                </div>
                                <h4>Activate</h4>
                                <form name="loginform" onSubmit={this.handleSubmit.bind(this)} data-hs-do-not-collect>
                                    <div className="card_custom">
                                        <div className="input-container mb-4">
                                            <div className="labelText">Password</div>
                                            <i className="fa fa-unlock-alt" aria-hidden="true"></i>
                                            <input type={this.state.type} required={true} style={{ marginLeft: '7px' }} value={this.state.post.password} onChange={this.handleChange.bind(this, 'password')} defaultValue="" />
                                            <span className="password-trigger custom-trigger" onClick={this.changeState}><i className="fa fa-eye" aria-hidden="true"></i></span>
                                            <span className="error">{this.state.errors["password"]}</span>
                                        </div>

                                        <div className="input-container mb-4">
                                            <div className="labelText">Confirm Password</div>
                                            <i className="fa fa-unlock-alt" aria-hidden="true"></i>
                                            <input type={this.state.type} required={true} value={this.state.post.confirmPass} onChange={this.handleChange.bind(this, 'confirmPass')} />
                                            <span className="password-trigger custom-trigger" onClick={this.changeState}><i className="fa fa-eye" aria-hidden="true"></i></span>

                                            <span className="error ml-2">{this.state.errors["confirmPass"]}</span>
                                        </div>

                                        {/* <div className="row clear"> */}
                                            {/* <CheckboxAgreement
                        type={ this.state.userType }
                        handleChange={this.handleCheckBoxChange.bind(this, "checkBox")}/> */}

                                            <Col>
                                                <Form.Group controlId="formBasicCheckbox">
                                                    <Form.Check type="checkbox"
                                                        className="custom-label"
                                                        label={<AgreeText/>}
                                                        onChange={(event: any) => this.setState({ isChecked: event.target.checked })}
                                                    />

                                                </Form.Group>
                                            </Col>
                                        {/* </div> */}
                                        <div className="text-center submitBtn">
                                            <button type="submit" className="btn-outline-orange w-100"> Activate </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default ActivateCustomer;
