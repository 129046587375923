import axios from 'axios';
import * as AuthService from '../services/auth';

const axiosGlobalConfig = function () {

    axios.interceptors.request.use(function (config: any) {
        config.headers['Content-Type'] = 'application/json';
        if (sessionStorage.getItem('access_token')) {
            config.headers['Authorization'] = sessionStorage.getItem('access_token');
        } else {
            config.headers['Authorization'] = process.env.REACT_APP_API_DEFAULT_KEY;
        }

        return config;
    }, function (error) {
    });

    axios.interceptors.response.use(function (response) {
        return response;

    }, async (error) => {

        // Store the failed request.
        let originalRequest = error.config;

        if (error.response == undefined) {
            // TODO:
        }
        // Handle error.
        if (401 === error.response.status) {

            // API for refresh token.
            let data = await AuthService.AuthService.refreshToken();

            
            // let data = await AuthService.AuthService.getAccessToken();
            
            if (data.accessToken) {
                // let errorConfig = originalRequest;
                // TODO: Call Refresh token to get new access token and refresh token.
                return axios(originalRequest);
            }

            // Check for the un authorized.
            if (401 === error.response.status &&
                (error.response.data === 'Unauthorized Token'
                    || error.response.data === 'Token not exists'
                    || error.response.data === 'Internal Server Error')) {
            }


            if (401 === error.response.status && error.response.data.token !== undefined) {
                // localStorage.setItem('accessToken', error.response.data.token);
                sessionStorage.setItem('accessToken', error.response.data.token);
                // localStorage.setItem('refreshToken', error.response.data.refreshToken);
                sessionStorage.setItem('refreshToken', error.response.data.refreshToken);

                let errorConfig = originalRequest;

                return axios(errorConfig);
            }
        }
        if (500 === error.response.status) {
            //   notifyError.NotificationService.notifyError(error.url, Config.AppName);
        }
        // TODO:
        if (404 === error.response.status) {
        }
       
        
        return Promise.reject(error.response);

    });
};

export default axiosGlobalConfig;