import { connect } from 'react-redux';
import PaymentInfo from '../components/ride-details/payment-info';
import { SET_CLIENT_TOKEN, SET_PAYMENT_METHODS, SET_PAYMENT_METHOD_TOKEN, SET_PAYMENT_NONCE } from '../reducer/payment-info';
import PaymentService from '../services/PaymentService';

type Dispatch = (action: any) => void;

const getUserPaymentMethods = (id: any) => {
    return async (dispatch: Dispatch) => {

        let brainTreeCustomerId = '';

        const customerIdRes = await PaymentService.checkForBraintreeCustomerID(id);

        
        if (customerIdRes.data && customerIdRes.data.data != null) {
            brainTreeCustomerId = customerIdRes.data.data.customer_id;
            let paymentsData = await PaymentService.getUserPaymentMethods(brainTreeCustomerId);
            if (paymentsData.data && paymentsData.data.data && paymentsData.data.data.length) {
                dispatch({
                    type: SET_PAYMENT_METHODS, data: { paymentMethods: paymentsData.data.data, brainTreeCustomerId: brainTreeCustomerId }
                });
            } else {
                dispatch({
                    type: SET_PAYMENT_METHODS, data: { paymentMethods: [], brainTreeCustomerId: brainTreeCustomerId }
                });
            }
        } else {

            let createBrainTreeCustomer = await PaymentService.createBrainTreeCustomer();
            

            if (createBrainTreeCustomer !== null) {
                dispatch({
                    type: SET_PAYMENT_METHODS, data: { paymentMethods: [], brainTreeCustomerId: 
                        createBrainTreeCustomer && createBrainTreeCustomer.data && createBrainTreeCustomer.data
                        .data && createBrainTreeCustomer.data
                        .data.customer_id }
                });
            } else {
                dispatch({
                    type: SET_PAYMENT_METHODS, data: { paymentMethods: [], brainTreeCustomerId: '' }
                });
            }

        }
    }
}


const selectedPaymentMethod = (token: any) => {
    return async (dispatch: Dispatch) => {
        let verifiedResponse = await PaymentService.verifyPaymentMethod(token);
        if (verifiedResponse.data && verifiedResponse.data.data) {
            dispatch({
                type: SET_PAYMENT_METHOD_TOKEN, data: token
            });
        } else {
            dispatch({
                type: SET_PAYMENT_METHOD_TOKEN, data: ''
            });
        }
    }
}

const deselectedPaymentMethod = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: SET_PAYMENT_METHOD_TOKEN, data: ''
        });
    }
    
}

const createCustomerToken = (customerId: any = "") => {
    return async (dispatch: Dispatch) => {
        let customerToken = await PaymentService.createCustomerToken(customerId);
        
        if (customerToken.data && customerToken.data.data) {
            dispatch({
                type: SET_CLIENT_TOKEN, data: customerToken.data.data.customer_token
            });
        } else {
            dispatch({
                type: SET_CLIENT_TOKEN, data: ''
            });
        }
    }
}


const getPaymentNonce = (paymentToken: any = "") => {
    return async (dispatch: Dispatch) => {
        let paymentNonceData = await PaymentService.getPaymentNonce(paymentToken);

        
        if (paymentNonceData.data && paymentNonceData.data.data) {
            dispatch({
                type: SET_PAYMENT_NONCE, data: paymentNonceData.data.data.payment_nonce
            });
        } else {
            dispatch({
                type: SET_PAYMENT_NONCE, data: ''
            });
        }
    }
}

const deleteSavedCard = async (token: string) => {
    return async (dispatch: Dispatch) => {
        await PaymentService.deleteSavedCard(token);
    }

}

const mapStateToProps = (state: any) => {
    return {
        paymentMethods: state.paymentInfo.paymentMethods,
        brainTreeCustomerId: state.paymentInfo.brainTreeCustomerId,
        paymentMethodToken: state.paymentInfo.paymentMethodToken,
        clientToken: state.paymentInfo.clientToken,
        paymentNonceData: state.paymentInfo.paymentNonceData,
        isUserLogin: state.user.isUserLogin,
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getUserPaymentMethods: (id: any) => dispatch(getUserPaymentMethods(id)),
        selectedPaymentMethod: (token: any) => dispatch(selectedPaymentMethod(token)),
        deselectedPaymentMethod: () => dispatch(deselectedPaymentMethod()),
        createCustomerToken: () => dispatch(createCustomerToken()),
        getPaymentNonce: (paymentToken: any) => dispatch(getPaymentNonce(paymentToken)),
        deleteSavedCard: (token: string) => dispatch(deleteSavedCard(token))
    }
}

const PaymentDetails = connect(mapStateToProps, mapDispatchToProps)(PaymentInfo);

export default PaymentDetails;
