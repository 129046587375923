import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ForgotPassword from '../auth/forgot-password';
import SignIn from '../auth/sign-in';
import SignUp from '../auth/sign-up';
import logo from '../../assets/images/Image 6@2x.png';
import { createEventGA4 } from '../../Utilities/helpers';
import queryString from 'query-string';
interface AuthModalContentProps {
    updateNavData: () => any;
    fromBookARide?: boolean;
    signUpRideRewards: boolean;
    signInRideRewards: boolean;
    isFromGetQuotes: boolean;
    uniqueID?: string;
}

interface AuthModalContentState {
    key: string;
    showForgotPassword: boolean;
}

class AuthModalContent extends Component<AuthModalContentProps, AuthModalContentState> {
    constructor(props: AuthModalContentProps) {
        super(props);
        this.state = {
            key: 'sing_in',
            showForgotPassword: false
        }
    }

    componentDidMount(): void {
        let dataLayerObj: any = {
            'eventName': '',
            'loginArea': (this.props.isFromGetQuotes) ? 'get_quote' : 'navigation',
            'event': ''
        };
        
        // TODO: add based on qury params.
        if(this.props.uniqueID && this.props.uniqueID.length) {
            this.setState({key: 'sing_up'});
        }

        // this.setState({key: 'sing_up'})
        if (this.state.key === 'sing_in') {
            dataLayerObj = {
                ...dataLayerObj,
                eventName: 'login_click',
                event: 'loginClick'
            }
        }
        createEventGA4(dataLayerObj);
    }

    setKey = (key: any) => {
        this.setState({ key: key });

        let dataLayerObj: any = {
            'eventName': '',
            'signUpArea': (this.props.isFromGetQuotes) ? 'get_quote' : 'navigation',
            'event': ''
        };
        if (key === 'sing_up') {
            dataLayerObj = {
                ...dataLayerObj,
                eventName: 'signup_click',
                event: 'signupClick'
            }
        }
        if (key === 'sing_in') {
            dataLayerObj = {
                'loginArea': (this.props.isFromGetQuotes) ? 'get_quote' : 'navigation',
                eventName: 'login_click',
                event: 'loginClick'
            }
        }
        createEventGA4(dataLayerObj);

    }

    showForgotPassword = () => {
        this.setState({ showForgotPassword: true });
    }

    componentDidUpdate = (prevProps: AuthModalContentProps) => {

    }

    render() {
        const key = this.state.key;
        return (
            <>
                <div className="modal-img">
                    <img src={logo}
                        width="180"></img>
                </div>
                {
                    (!this.state.showForgotPassword && !this.props.signUpRideRewards && !this.props.signInRideRewards)
                        ?
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => this.setKey(k)}
                        >
                            <Tab eventKey="sing_in" title="SIGN IN" className="modal-title">
                                <SignIn
                                    fromBookARide={this.props.fromBookARide}
                                    updateNavData={() => this.props.updateNavData()}
                                    showForgotPassword={() => this.showForgotPassword()} />

                            </Tab>
                            <Tab eventKey="sing_up" title="CREATE AN ACCOUNT">
                                <SignUp 
                                    setKey = {this.setKey}
                                    uniqueID={this.props.uniqueID}
                                    />
                            </Tab>

                        </Tabs>
                        :
                        (this.state.showForgotPassword)
                            ?
                            <ForgotPassword />
                            : null
                }
                {
                    (this.props.signUpRideRewards)
                        ?
                        <>
                            {/* <div className="modal-img">
                                <img src={logo}
                                    width="180"></img>

                            </div> */}
                            < SignUp/>
                        </>
                        :
                        (this.props.signInRideRewards)
                            ?
                            <>
                                {/* <div className="modal-img">
                                    <img src={logo}
                                        width="180"></img>

                                </div> */}
                                <SignIn
                                    fromBookARide={this.props.fromBookARide}
                                    updateNavData={() => this.props.updateNavData()}
                                    showForgotPassword={() => this.showForgotPassword()}
                                />
                            </>
                            :
                            null
                }
            </>
        )
    }
}

export default AuthModalContent;