import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Passenger from '../../assets/images/passenger.jpg';
import Period from '../../assets/images/period.svg';
import Exchange from '../../assets/images/exchange.png';
import { formatDistance, formatDuration, getAddressByCoordinates, getLuxyAddress, isValidLuxyAddress } from '../../services/AddressService';
import { ROUTE_DETAILS_SERVICE_TYPE, ROUTE_DETAILS_SERVICE_ID, DATE_FORMAT, SAME_LOCATION_ERR} from '../../Consts';
import { isAirPortTrip } from '../../Utilities/LocationUtilities';
import { timeFormatter, pickUpDateFormatter, getTimezoneDatetime, pickUpDateInputFormatter } from '../../Utilities/DateUtilities';
import moment from 'moment';
import Map from './map-view';
import { parseGooglePlace } from '../../Utilities/GooglePlaces/utils';
import VehicleListHome from './vehicle-list-home';
import ConfigurationService from '../../services/ConfigurationService';
import AuthModal from '../modals/auth-modal';
import DateComponent from '../date-component/date';
import { toast, ToastContainer } from 'react-toastify';
import WhyLuxy from '../why-luxy/why-luxy';
import { trackPromise } from 'react-promise-tracker';
import GooglePlacesComponent from '../google-places/google-places';

import Plus from '../../assets/images/plus.svg';
import { HoursInput, MinInput } from '../date-component/timePicker';
import clock from '../../assets/images/clock.svg';
import CommonService from '../../services/CommonService';
import NoVehicleFoundPopUp from '../modals/no-vehicle-found';
import { createEventGA4, getPlaceCodeNdState, userFromLocalStorage } from '../../Utilities/helpers';
import { USER_TYPE } from '../../Consts';
import RideService from '../../services/RideService';
import { AuthService } from '../../services/auth';
import './book-a-ride.css';
import QRCode from '../../assets/images/QR-code.png';

const banner6 = require('../../assets/images/webpimages/FC-Platinum-Seal.webp');

class BookRide extends React.Component<any, any> {
    pick_time_ptop: any;
    pick_date_ptop: any;
    constructor(props: any) {
        super(props);
        this.pick_date_ptop = React.createRef();
        this.setRef = this.setRef.bind(this);
        this.state = {
            pick_address: '',
            drop_address: '',
            pick_address_lat: '',
            pick_address_lng: '',
            drop_address_lat: '',
            drop_address_lng: '',
            trip_type_with_id: { 'type': ROUTE_DETAILS_SERVICE_TYPE.GROUND, 'id': ROUTE_DETAILS_SERVICE_ID.GROUND },
            trip_type: ROUTE_DETAILS_SERVICE_TYPE.GROUND,
            isMapView: false,
            places: [
                { latitude: 0, longitude: 0 },
                { latitude: 0, longitude: 0 }
            ],
            isValidLatLong: false,
            pickup_timezone_date: "",
            pickup: {},
            dropoff: {},
            extra_stops: [],
            buffer_expiration_time_widget: 0,
            pick_date_ptop: '',
            valid_pickup: false,
            valid_dropoff: false,
            pick_up_zone: '',
            showLoginPopup: false,
            selectedVehicleIndex: -1,
            selectedVehicleId: 0,
            selectedVehicle: null,

            distance_in_miles: 0,
            total_hours: 0,
            distance_in_meteres: 0,
            distance_in_seconds: 0,
            extra_buffer_time: 15,
            show_pickup_error: false,
            show_dropoff_error: false,

            noVehicleFoundError: false,

            extra_stop_count: 1,
            extra_stop_controls: [],
            extra_stop_error: {},
            extra_stop_values: {},
            time: null,
            selHrs: 12,
            selMin: 0,
            //region buffer code begins
            vehicleErrorMessage : '',
            //region buffer code begins,
            directions: null,
            playStore: 'https://play.google.com/store/apps/details?id=com.luxycustomer',
            appStore: 'https://apps.apple.com/us/app/Luxy%20Ride/id1473341696?ls=1'
        };
    }

    async componentDidMount() {
        
        await this.getCurrentLocation();
        // await this.getBufferExpirationTime('didmount');
        // var currentTime = this.getCurrentTime(); //Added code for setting current time in both tabs
        // this.pick_time_ptop.val(null);
        if (this.state.pick_address == '' || this.state.drop_address == '') {
            this.props.getVehiclesList();
        }
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.isUserLogin !== prevProps.isUserLogin) {
            this.getQuote();
        }
    }

    componentWillUnmount() {
        this.props.RemoveClickGetQuotes();
        this.props.updateIsSignUp();
    }

    async getCurrentLocation() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                let addreessInfo: any = await getAddressByCoordinates(position.coords.latitude, position.coords.longitude);
                let places = [];
                if (addreessInfo && addreessInfo.length) {
                    addreessInfo[0].name = addreessInfo[0].address_components[0].long_name;
                    places.push(addreessInfo[0]);
                }
                await this.handlePickAddressSelect({ original: places, parsed: places.map(parseGooglePlace) });
            });
        } else {

        }
    }

    formatDate = (date: any, islocal = true) => {
        const d = new Date(date);
        if (d.toString() === 'Invalid Date') {
            return date;
        }
        let month = `${d.getMonth() + 1}`;
        let day = `${d.getDate()}`;
        const year = `${d.getFullYear()}`;
        if (month.length < 2) {
            month = `0${month}`;
        }
        if (day.length < 2) {
            day = `0${day}`;
        }
        if (islocal) {
            return [month, day, year].join('/');
        }
        return [year, month, day].join('-');
    };

    dateTimeFormat = (date: Date | string) => {
        const d = new Date(date);
        const mins =
            d.getMinutes() < 10
                ? '0'.concat(d.getMinutes().toString())
                : d.getMinutes();
        const hour =
            d.getHours() === 0
                ? '12'
                : d.getHours() > 12
                    ? d.getHours() - 12
                    : d.getHours();
        const amOrPm = d.getHours() < 12 ? 'AM' : 'PM';
        return {
            date: this.formatDate(d),
            time: hour + ':' + mins + ' ' + amOrPm,
        };
    };

    setBufferExpirationTime(bufferTime: any = 24) {
        let bufferDate: any = moment().add(bufferTime, 'hours');
        bufferDate = moment(bufferDate).add(this.state.extra_buffer_time, 'minutes');
        let dateObject: any = this.dateTimeFormat(bufferDate);

        this.setState({
            time: dateObject.time
        }, async () => {
            await this.handleDateChangePtoP('pick_date_ptop', new Date(dateObject.date));
            // this.pick_time_ptop.val(dateObject.time);
        });

    }

    getBufferExpirationTime = async () => {
        const pickup = this.state.pickup;

        let bufferData = await ConfigurationService.getBufferTimeByState(pickup.address.state);
        if (bufferData && bufferData.window_hours_web) {
            this.setState({
                buffer_expiration_time_widget: Number(bufferData.window_hours_web),
            });
        }else {
           bufferData = await ConfigurationService.getConfigurationByName('BUFFER_EXPIRATION_TIME_WIDGET');
           
            this.setState({
                buffer_expiration_time_widget: Number(bufferData.value)
            });
        }
    }

    setRef(input: any) {
        // this.pick_time_ptop = input;
    }

    // validate same addresses.
    validateAddresses = (address: any, type: string) => {


        let extraStops = [...this.state.extra_stops];
        extraStops = extraStops.map((stop: any) => {
            return stop.place.place_id;
        });


        if (type === 'pickup' && this.state.dropoff
            && this.state.dropoff.place
            && this.state.dropoff.place.place_id) {
            if (address.place_id === this.state.dropoff.place.place_id
                || extraStops.indexOf(address.place_id) > -1
            ) {

                return true;
            }
        }

        if (type === 'dropoff' && this.state.pickup
            && this.state.pickup.place
            && this.state.pickup.place.place_id) {
            if (address.place_id === this.state.pickup.place.place_id
                || extraStops.indexOf(address.place_id) > -1
            ) {

                return true;
            }
        }

        if (type === 'extras' && (this.state.pickup
            && this.state.pickup.place
            && this.state.pickup.place.place_id
            || this.state.dropoff
            && this.state.dropoff.place
            && this.state.dropoff.place.place_id)
        ) {
            if (((this.state.pickup
                && this.state.pickup.place
                && this.state.pickup.place.place_id) && address.place_id === this.state.pickup.place.place_id)
                || (extraStops.indexOf(address.place_id) > -1)
                || ((this.state.dropoff
                    && this.state.dropoff.place
                    && this.state.dropoff.place.place_id)
                    && address.place_id === this.state.dropoff.place.place_id)
            ) {

                return true;
            }
        }
    }

    //#region pick address
    handlePickAddressSelect = async (selection: any) => {


        const address = selection.original[0];
        // console.log('address', address);
        let validate = this.validateAddresses(address, 'pickup');
        // console.log("validate", validate);

        if (validate) {
            this.setState({ pick_address: '' }, async() => {
                await this.fetchDirectionDetails()
                this.props.getVehiclesList('');
            });
            toast.error(SAME_LOCATION_ERR, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        let pick_address = address.name;
        Promise.resolve(getLuxyAddress(address)).then(async (pickup: any) => {
            // console.log('pickup data', pickup);
            pick_address = pickup.address.full_address;

            if (!pickup.address.postal_code) {

                let postalCode: any = await CommonService.getPOstalCodeDetails(pickup.coordinates.latitude, pickup.coordinates.longitude);

                if (postalCode) {
                    pickup.address.postal_code = postalCode.postal_code;
                }
            }
            // console.log('data ---1111', pickup);
            isValidLuxyAddress(pickup);
            this.setState({ pickup, isMapView: false }, () => {
                this.setTravelType();
                // this.props.onChange("full_pickup_address", this.state.pickup);
            });
            // console.log(this.state.pickup, 'pk');
            // localStorage.setItem("valid_pickup", 'true');
            sessionStorage.setItem("valid_pickup", 'true');
            // localStorage.setItem('ispickairportfield', (pickup.place.airport_code) ? 'true' : 'false');
            sessionStorage.setItem('ispickairportfield', (pickup.place.airport_code) ? 'true' : 'false');
            //this.props.onChange(ROUTE_DETAILS_FIELDS.PICK_UP_ZONE, pickup.timezone);
            const places = this.state.places;
            places[0] = { latitude: pickup.coordinates.latitude, longitude: pickup.coordinates.longitude };
            const isValidLatLong = this.validateSourceAndDestination();
            this.setState({
                pick_address: pick_address,
                pick_address_lat: pickup.coordinates.latitude,
                pick_address_lng: pickup.coordinates.longitude,
                isMapView: true,
                places: places,
                isValidLatLong: isValidLatLong,
                valid_pickup: true,
                pick_up_zone: pickup.timezone,
                show_pickup_error: false
            }, async() => {
                await this.fetchDirectionDetails()
                this.props.getVehiclesList();
            });
            //comment for region begins
            // this.getBufferExpirationTime();
                //comment for region ends
            // console.log(this.state);
            // CUSTOM SETTINGS
            // this.setTimeByPickupZone();
            // this.handleDateChangePtoP('pick_date_ptop', pickup_date_time_format(moment(new Date()).add(Number(this.state.buffer_expiration_time_widget), 'hours'), this.state.pickup.timezone))
            // this.handleDateChangePtoP('pick_date_ptop', moment(new Date()).add(Number(this.state.buffer_expiration_time_widget), 'hours'))

        }).catch((err) => {
            // console.log(`failed for ${pick_address}: ${err}`);
            // localStorage.setItem("valid_pickup", 'false');
            sessionStorage.setItem("valid_pickup", 'false');
            this.setState({ valid_pickup: false, show_pickup_error: true , pick_address: pick_address});
            // this.props.onChange("valid_pickup", localStorage.valid_pickup);
        });
    };

    validateSourceAndDestination() {
        return (this.state.places[0].latitude != 0 && this.state.places[0].longitude != 0 && this.state.places[1].latitude != 0 && this.state.places[1].longitude != 0)
    }

    //#region set time according to timezone
    setTimeByPickupZone() {
        let currenttime = new Date();
        let currtime = moment.utc(currenttime, 'YYYY-MM-DD hh:mm:ss');
        let converteddatetime = moment.tz(currtime, this.state.pick_up_zone);
        let convertedtime = moment(converteddatetime).format('h:mm A');
        // let currentTime = timeFormatter(convertedtime);
        // this.setState({ ['']: curTime });
        this.pick_time_ptop.val(convertedtime);
        // this.refs.pick_time_ptop.val(convertedtime);
        // localStorage.setItem("currentdatebyzone", converteddate + ',' + convertedtime);
    }

    //#region drop address
    handleDropAddressSelect = (selection: any) => {
        const address = selection.original[0];

        let validate = this.validateAddresses(address, 'dropoff');

        if (validate) {
            this.setState({ drop_address: '' }, async() => {
                await this.fetchDirectionDetails()
                this.props.getVehiclesList('');
            });
            toast.error(SAME_LOCATION_ERR, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        let drop_address = address.name;
        // this.setState({ drop_address });
        Promise.resolve(getLuxyAddress(address)).then(async (dropoff: any) => {
            
            // console.log('address', dropoff);
            drop_address = dropoff.address.full_address
    
            if (!dropoff.address.postal_code) {

                let postalCode: any = await CommonService.getPOstalCodeDetails(dropoff.coordinates.latitude, dropoff.coordinates.longitude);

                if (postalCode) {
                    dropoff.address.postal_code = postalCode.postal_code;
                }
            }
            isValidLuxyAddress(dropoff);
            this.setState({ dropoff, isMapView: false }, () => {
                this.setTravelType();
                // this.props.onChange("full_dropoff_address", this.state.dropoff);
            });
            // localStorage.setItem("valid_dropoff", 'true');
            sessionStorage.setItem("valid_dropoff", 'true');
            // this.props.onChange("valid_dropoff", localStorage.valid_dropoff);
            // localStorage.setItem('isdropairportfield', (dropoff.place.airport_code) ? 'true' : 'false');
            sessionStorage.setItem('isdropairportfield', (dropoff.place.airport_code) ? 'true' : 'false');
            // localStorage.setItem('drop_timeZoneId', dropoff.timezone);
            // sessionStorage.setItem('drop_timeZoneId', dropoff.timezone);
            const places = this.state.places;
            const placesLength = this.state.places.length;
            places[placesLength - 1] = { latitude: dropoff.coordinates.latitude, longitude: dropoff.coordinates.longitude };
            const isValidLatLong = this.validateSourceAndDestination();
            this.setState({
                drop_address: drop_address,
                drop_address_lat: dropoff.coordinates.latitude,
                drop_address_lng: dropoff.coordinates.longitude,
                isMapView: true,
                places: places,
                isValidLatLong: isValidLatLong,
                valid_dropoff: true,
                show_dropoff_error: false
            }, async() => {
                await this.fetchDirectionDetails()
                this.props.getVehiclesList();
            });

        }).catch((err) => {
            console.error(`failed for ${drop_address}: ${err}`);
            // localStorage.setItem("valid_dropoff", 'false');
            sessionStorage.setItem("valid_dropoff", 'false');
            this.setState({ valid_dropoff: false, show_dropoff_error: true, drop_address:drop_address });
            // this.props.onChange("valid_dropoff", localStorage.valid_dropoff);
        });
    };

    setTravelType(pickup = this.state.pickup, dropoff = this.state.dropoff) {
        let { trip_type_with_id } = this.state;
        // if (trip_type_with_id.type !== ROUTE_DETAILS_SERVICE_TYPE.HOURLY) {
        const isAirPort = isAirPortTrip(pickup, dropoff);
        trip_type_with_id.type = isAirPort ? ROUTE_DETAILS_SERVICE_TYPE.AIRPORT : ROUTE_DETAILS_SERVICE_TYPE.GROUND;
        trip_type_with_id.id = isAirPort ? ROUTE_DETAILS_SERVICE_ID.AIRPORT : ROUTE_DETAILS_SERVICE_ID.GROUND;
        const trip_type = isAirPort ? ROUTE_DETAILS_SERVICE_TYPE.AIRPORT : ROUTE_DETAILS_SERVICE_TYPE.GROUND;
        // this.props.onChange(ROUTE_DETAILS_FIELDS.TRIP_TYPE, trip_type_with_id);
        this.setState({ trip_type_with_id: trip_type_with_id, trip_type: trip_type });
        // }
    }

    // date related
    handleDateChangeRawPtoP = (e: any) => {
        if (e != undefined) {
            e.preventDefault();
        }
    }

    isInvalidPickupTime = async (selDate: any, selTime: any, pickupTimeZone: string) => {

        const currentTime = new Date().toLocaleString("en-US", { timeZone: pickupTimeZone });
        const validTime = this.state.buffer_expiration_time_widget;
        //  const currentPlaceTime = moment.tz(currentTime, this.state.pickup.timezone).format(DATE_FORMAT.DATE_AND_TIME_WITH_COMMA);
        const time = this.getCurrentTime(pickupTimeZone);
        const currentPlaceTime = moment(`${pickUpDateInputFormatter(currentTime)} ${time}`, DATE_FORMAT.DATE_TIME).format(
            DATE_FORMAT.DATE_AND_TIME_WITH_COMMA
        );

        const selectedDate = moment(`${pickUpDateInputFormatter(selDate)} ${selTime}`, DATE_FORMAT.DATE_TIME).format(
            DATE_FORMAT.DATE_AND_TIME_WITH_COMMA
        );

        const aDate = new Date(selectedDate.split('-').join('/')).getTime();
        const bDate = new Date(currentPlaceTime.split('-').join('/')).getTime();
        const dateDiff = (aDate - bDate) / 1000;
        const hours = dateDiff / 60 / 60;


        if ((Number(hours) * 60) < (Number(validTime) * 60)) { // + extraBuffertime

            return false;
        }
        // console.log('test insuccess time', Number(hours)*60, (Number(validTime) *60 + Number(extraBuffertime)), extraBuffertime);
        return true;
    }

    async handleDateChangePtoP(field: any, date: any) {
        let time = this.formatHrsAndMin(this.state.selHrs, this.state.selMin);
        const currentDate = pickUpDateInputFormatter(date);

        time = !time ? this.getCurrentTime() : time;
        const picktime = timeFormatter(time);
        const currentDateString = pickUpDateFormatter(currentDate);
        const dateString = `${currentDateString} ${picktime}`;
        const timezoneDatetime = getTimezoneDatetime(dateString, this.state.pickup.timezone);
        // console.log(dateString, date, timezoneDatetime);
        if (this.state.time != null) {
            // let isValid = await this.isInvalidPickupTime(date, time, this.state.pickup.timezone);
             //comment region buffer code begins
            // if (!isValid) {
            //     toast.error(`Please book a ride at least ${Number(this.state.buffer_expiration_time_widget)} hours from now or call 833-GET.LUXY for immediate assistance.`, {
            //         position: toast.POSITION.TOP_RIGHT
            //     });
            //     this.setState({
            //         pickup_timezone_date: timezoneDatetime,
            //         [field]: new Date(date),
            //         time: time
            //     }, () => {
            //         this.props.getVehiclesList();
            //         // this.props.onChange(ROUTE_DETAILS_FIELDS.DATE, dateAndTime);
            //     });
            //     //comment for region begins
            //     // await this.getBufferExpirationTime();
            //     //comment for region ends
            //     return false;
            // }
              //comment region buffer code ends
        }
        //validation ends here for date.
        this.setState({
            pickup_timezone_date: timezoneDatetime,
            [field]: new Date(date),
            time: time
        }, () => {
            this.props.getVehiclesList();
            // this.props.onChange(ROUTE_DETAILS_FIELDS.DATE, dateAndTime);
        });
    }

    getCurrentTime(pickupTimeZone?: string) {

        var date = new Date();
        if (pickupTimeZone) {
            date = new Date(new Date().toLocaleString("en-US", { timeZone: pickupTimeZone }));
        }
        var hours = date.getHours();
        var minutes: any = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var currtime = hours + ':' + minutes + ' ' + ampm;
        return currtime;
    }

    //#region handle time change
    async handleChangeTime(field: any, time: any) {
        const pickDate = pickUpDateFormatter(this.state.pick_date_ptop);
        const pickTime = timeFormatter(time.target.value);
        const dateString = `${pickDate} ${pickTime}`;
        const timezoneDatetime = getTimezoneDatetime(dateString, this.state.pickup.timezone);
        // let isValid = await this.isInvalidPickupTime(this.state.pick_date_ptop, pickTime, this.state.pickup.timezone);
        // console.log(dateString, time, timezoneDatetime);
        //region buffer code begins
        // if (!isValid) {

        //     toast.error(`Please book a ride at least ${Number(this.state.buffer_expiration_time_widget)} hours from now or call 833-GET.LUXY for immediate assistance.`, {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        //     this.setState({ [field]: time.target.value, pickup_timezone_date: timezoneDatetime });
        //     //comment for region begins
        //     // await this.getBufferExpirationTime();
        //     //comment for region ends
        //     this.props.getVehiclesList();
        //     return false;
        // }
  //region buffer code ends
        this.setState({ [field]: time.target.value, pickup_timezone_date: timezoneDatetime }, () => {
            // this.props.onChange(ROUTE_DETAILS_FIELDS.TIME, dateAndTime);
            this.props.getVehiclesList();
        });
    }


    formatHrsAndMin = (hours: any, min: any) => {
        let hh = hours;
        let m = min;
        let h = hh;
        let dd = "AM";
        if (h >= 12) {
            h = hh - 12;
            dd = "PM";
        }
        if (h == 0) {
            h = 12;
        }
        h = h < 10 ? "0" + h : h;
        m = m < 10 ? "0" + m : m;
        let replacement = h + ":" + m + " " + dd;
        return replacement;
    }

    handleChangeHourMin = async (type: string, event: any) => {

        let selHrs = (type == 'selHrs') ? event.target.value : this.state.selHrs;
        let selMin = (type == 'selMin') ? event.target.value : this.state.selMin;

        let timeStr = this.formatHrsAndMin(selHrs, selMin);

        this.setState({
            selHrs: selHrs,
            selMin: selMin
        },
            async () => {
                const pickDate = pickUpDateFormatter(this.state.pick_date_ptop);
                const pickTime = timeStr;
                const dateString = `${pickDate} ${pickTime}`;
                const timezoneDatetime = getTimezoneDatetime(dateString, this.state.pickup.timezone);
                // let isValid = await this.isInvalidPickupTime(this.state.pick_date_ptop, pickTime, this.state.pickup.timezone);
                //comment region buffer code begins
                // if (!isValid) {
                //     toast.error(`Please book a ride at least ${Number(this.state.buffer_expiration_time_widget)} hours from now or call 833-GET.LUXY for immediate assistance.`, {
                //         position: toast.POSITION.TOP_RIGHT
                //     });
                //     this.setState({ time: timeStr, pickup_timezone_date: timezoneDatetime });
                //     //comment for region begins
                //     // await this.getBufferExpirationTime();
                //     //comment for region ends
                //     this.props.getVehiclesList();
                //     return false;
                // }
                  //comment region buffer code begins
                this.setState({ time: timeStr, pickup_timezone_date: timezoneDatetime }, () => {
                    // this.props.onChange(ROUTE_DETAILS_FIELDS.TIME, dateAndTime);
                    this.props.getVehiclesList();
                });

            })


    }
    //#endregion

    triggerGA4ErrorEvent = (data: any) => {
        const obj: any = {
            'eventName': 'error',
            'errorSection': data.area,
            'errorMessage': data.errMessage,
            'errorFields': data.errFields,
            'event': 'error'
        }
        createEventGA4(obj);
    }

    formatAddress = (address: any) => {
        return {
            placeName: address.address.full_address,
            city: address.address.city,
            state: address.address.state,
            timezone: address.timezone,
            airportCode: address.place.airport_code,
            zipCode: address.address.postal_code
        }
    }
    async getQuote() {
        if (this.state.pickup != "" && this.state.dropoff != "" && this.state.pick_date_ptop != '') {

            // ReactGA.event(googleEventAnalytics.GetQuotes);
            const getQuoteObj: any = {
                'eventName': 'get_quote_cta',
                'getQuoteArea': 'Normal Quote',
                'event': 'getQuoteCta'
            };
            getQuoteObj.pickupName = getPlaceCodeNdState(this.state.pickup);
            getQuoteObj.dropName = getPlaceCodeNdState(this.state.dropoff);

            createEventGA4(getQuoteObj);

            // let isValid = await this.isInvalidPickupTime(this.state.pick_date_ptop, this.state.time, this.state.pickup.timezone);
              //comment region buffer code begins
            // if (!isValid) {
            //     toast.error(`Please book a ride at least ${Number(this.state.buffer_expiration_time_widget)} hours from now or call 833-GET.LUXY for immediate assistance.`, {
            //         position: toast.POSITION.TOP_RIGHT
            //     });
            //     //comment for region begins
            //     // await this.getBufferExpirationTime();
            //     //comment for region ends
            //     this.props.getVehiclesList();
            //     return false;
            // }
              //comment region buffer code begins

            if (this.state.extra_stop_controls && this.state.extra_stop_controls.length) {
                let controls = this.state.extra_stop_controls;
                let values = this.state.extra_stop_values;
                for (let i = 0; i < controls.length; i++) {

                    let extraStops = JSON.parse(JSON.stringify(this.state.extra_stops));
                    let extraStopIndex = extraStops.findIndex((item: any) => item.stop_number === i + 1);
                    if (values['extra_stop_' + (i + 1)] == '' || values['extra_stop_' + (i + 1)] == undefined || extraStopIndex == -1 || this.state.extra_stop_error['extra_stop_' + (i + 1)] )  {
                        toast.error(`Extrastop value(s) are invalid. Please verify.`, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        return false;
                    }
                }
            }

            const route = {
                total_miles: (this.state.distance_in_miles) ? Number(this.state.distance_in_miles) : Number(sessionStorage.getItem('distance_in_miles')),
                total_hours: (this.state.total_hours) ? Number(this.state.total_hours) : Number(sessionStorage.getItem('total_hours')),
            };

            // added pick up date fix
            let { time } = this.state;
            const pickDate = pickUpDateFormatter(this.state.pick_date_ptop);
            const picktime = timeFormatter(time);
            const dateString = `${pickDate} ${picktime}`;

            const timezoneDatetime = getTimezoneDatetime(dateString, this.state.pickup.timezone);
            // ends here
            let userID = 0;
            if (this.props.isUserLogin) {
                const userData: any = userFromLocalStorage();
                if (userData && userData.id) {
                    userID = userData.id;
                }
            }
            // let data = {
            //     service_type: this.state.trip_type,
            //     pickup_hour: this.state.time,
            //     // pickup_date: this.state.pickup_timezone_date,
            //     pickup_date: timezoneDatetime,
            //     pickup: this.state.pickup,
            //     dropoff: this.state.dropoff,
            //     extra_stops: this.state.extra_stops,
            //     route,
            //     is_airport_arrival: localStorage.ispickairportfield,
            //     is_airport_departure: localStorage.isdropairportfield,
            //     is_from_backend: false,
            //     application: 'web',
            //     userCorporateId: userID,
            //     userTypeId: USER_TYPE.CUSTOMER
            // };
            const payload = {
                trips: [{
                    serviceRateId:0,
                    serviceType : this.state.trip_type_with_id && this.state.trip_type_with_id.type,
                    pickup: this.formatAddress(this.state.pickup),
                    dropoff: this.formatAddress(this.state.dropoff),
                    extraStops: this.state.extra_stops,
                    route: {
                        totalMiles: (this.state.distance_in_miles) ? Number(this.state.distance_in_miles) : Number(sessionStorage.getItem('distance_in_miles')),
                        totalHours: (this.state.total_hours) ? Number(this.state.total_hours) : Number(sessionStorage.getItem('total_hours')),
                    },
                    pickupTime: this.state.time,
                    pickupDate: timezoneDatetime,
                    addons: {
                        "tripProtection": false,
                        "arrivalMeetAndGreet": {
                            "id": 0
                        },
                        "travelingWithPet": "",
                        "childSeat": ""
                    },
                    promoCodeId : 0,
                    application:'web',
                    rewardPoints:0,
                    bookingUserId: userID,
                    userCorporateId: userID,
                    userTypeId: USER_TYPE.CUSTOMER,
                    is_from_backend: false,
                    isCustomPrice: false,
                    customPrice: 0,
                    isAffiliateCustomPrice: false,
                    affiliateCustomPrice: 0,
                    isEditMode: false,
                }]
            }
            // console.log(data, 'data');
            await this.props.updateBookingState(this.state);
            await trackPromise(this.props.getVehiclesList(payload));
            this.props.clickGetQuotes();

            let duration: any = (this.state.total_hours) ? formatDuration(this.state.total_hours) : formatDuration(Number(sessionStorage.getItem('total_hours')))
            const dataLayerObj: any = {
                'eventName': 'get_quote',
                'getQuoteArea': 'Normal Quote',
                'distance': Number(route.total_miles).toFixed(2).toString(),
                'date': pickDate,
                'duration': duration.replace(':', '.'),
                'time': moment(dateString).valueOf(),
                'event': 'getQuote'
            }
            dataLayerObj.pickupName = getPlaceCodeNdState(this.state.pickup);
            dataLayerObj.dropName = getPlaceCodeNdState(this.state.dropoff);

            createEventGA4(dataLayerObj);

            if (this.props.vehicleListError) {
                // toast.error(`If you have any questions or need assistance with your booking please contact us at (833) 438-5899.`, {
                //     position: toast.POSITION.TOP_RIGHT
                // });
                this.setState({noVehicleFoundError:true});
                const data: any = {
                    area: 'get_quote_error',
                    errMessage: 'no vehicles found',
                    errFields: 'no_vehicles_found'
                }
                this.triggerGA4ErrorEvent(data);                
            }
            // console.log(this.props.vehicleDetailsList, 'props');
        }
    }

    selectVehicle = async (vehicle: any, index: any) => {

        // console.log("yesss---",vehicle)
        this.props.clickGetQuotes();
        // || this.props.isUserSingUp

        // The SigninPopup removed code begins
        // if (this.props.isUserLogin || this.props.isGuestUser) {
        //     showAuthPopup = true;
        // } else {
        //     showAuthPopup = false;
        // }



        // if (!showAuthPopup) {
        //     this.props.navigateToRideDetail();
        //     this.setState({ showLoginPopup: true, selectedVehicle: vehicle, selectedVehicleIndex: index, selectedVehicleId: vehicle.vehicle_type_id }, () => {
        //         this.props.updateBookingState(this.state);
        //     });

        // } else {
        //     this.setState({ selectedVehicle: vehicle, selectedVehicleIndex: index, selectedVehicleId: vehicle.vehicle_type_id }, () => {
        //         this.props.updateBookingState(this.state);
        //         // const selectedVehicle: any = prepareSelectedItemList(vehicle, index);
        //         // createEcommerceGA4(selectedVehicle, ECOMMERCE.SELECT);
        //         // createEcommerceGA4(selectedVehicle, ECOMMERCE.ADD);
        //         const clonedObject = JSON.parse(JSON.stringify(this.state));
        //         const keyToRemove = 'directions';
        //         delete clonedObject[keyToRemove];
        //         this.props.history.push('ride-details', { bookingDetails: clonedObject});
        //     });

        // }

        // The SigninPopup removed code ends

        // new code begins after removing singInPopUP
            if(!this.props.isUserLogin){
                let response = await AuthService.continueAsGuest()
                this.props.continueAsGuest();
                // console.log("response",response)
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('isGuestUser', 'true');
                this.props.updateNavigation()
                const dataLayerObj: any = {
                    'eventName': 'continue_as_guest',
                    'event': 'continueAsGuest'
                };
                createEventGA4(dataLayerObj);
            }
             this.setState({ selectedVehicle: vehicle, selectedVehicleIndex: index, selectedVehicleId: vehicle.vehicle_type_id }, () => {
                this.props.updateBookingState(this.state);
                // const selectedVehicle: any = prepareSelectedItemList(vehicle, index);
                // createEcommerceGA4(selectedVehicle, ECOMMERCE.SELECT);
                // createEcommerceGA4(selectedVehicle, ECOMMERCE.ADD);
                const clonedObject = JSON.parse(JSON.stringify(this.state));
                const keyToRemove = 'directions';
                delete clonedObject[keyToRemove];
                this.props.history.push('ride-details', { bookingDetails: clonedObject});
            });

          // new code ends after removing singInPopUP

    }

    closeModal = () => {
        this.setState({ showLoginPopup: false, selectedVehicle: null, selectedVehicleIndex: -1, selectedVehicleId: 0 }, () => {
            this.props.updateBookingState(this.state);
        });
    }

    handlePickChange = (event: any) => {
        // console.log(event.target.value, 'event');
        let value = event;
        if (value == '') {
            this.props.getVehiclesList();
        } else{
            this.props.vehicleDetailsList.length=0;
        }
        let places = this.state.places;
        places[0] = { latitude: 0, longitude: 0 };
        this.setState({ pick_address: value, places: places ,valid_pickup:false,show_pickup_error: false},async()=>{
            await this.fetchDirectionDetails()
        });
    }

    handleDropChange = (event: any) => {
       
        let value = event;
        if (value == '') {
    
            this.props.getVehiclesList();
        } else{
         
            this.props.vehicleDetailsList.length=0;
        }
        let places = this.state.places;
        places[places.length - 1] = { latitude: 0, longitude: 0 };
        this.setState({ drop_address: value, places: places, valid_dropoff:false,
            show_dropoff_error: false,},async()=>{
                await this.fetchDirectionDetails()
            });
    }

    updateDistanceInfo = (distanceInfo: any) => {

        this.setState({
            distance_in_miles: distanceInfo.miles,
            total_hours: distanceInfo.hours,
            distance_in_meteres: distanceInfo.meters,
            distance_in_seconds: distanceInfo.seconds
        }, () => {
            this.props.updateBookingState(this.state);
        })
    }

    renderDistanceInfo() {
        return (this.props.vehicleDetailsList && this.props.vehicleDetailsList.length) ? <div className="col p-0">
            <div className="mt-2">
                <div className="row no-gutters">
                    <div className="col-6 col-xl-4 col-md-4">
                        <span className="distance-head">
                            Distance :
                        </span>
                        <span className="distance-value">
                            &nbsp;{(this.state.distance_in_miles) ? formatDistance(this.state.distance_in_miles) : formatDistance(Number(sessionStorage.getItem('distance_in_miles')))} mi
                        </span>
                    </div>
                    <div className="col-6 col-xl-4 col-xl-4">
                        <span className="distance-head">
                            Duration :
                        </span>
                        <span className="distance-value">
                            &nbsp;{(this.state.total_hours) ? formatDuration(this.state.total_hours) : formatDuration(Number(sessionStorage.getItem('total_hours')))} (hrs)
                        </span>
                    </div>
                </div>
            </div>
        </div> : null
    }

    exchangeAddress = () => {
        let isValid = this.validateSourceAndDestination();
        if (isValid) {

            const tempDropoff: any = this.state.pickup;
            const tempPickup: any = this.state.dropoff;

            // pickup changes
            const pick_address = tempPickup.address.full_address;
            const places: any = this.state.places;
            places[0] = { latitude: tempPickup.coordinates.latitude, longitude: tempPickup.coordinates.longitude };
            const placesLength = this.state.places.length;
            places[placesLength - 1] = { latitude: tempDropoff.coordinates.latitude, longitude: tempDropoff.coordinates.longitude };

            // dropoff changes
            const drop_address = tempDropoff.address.full_address;


            this.setState({
                pick_address: pick_address,
                pick_address_lat: tempPickup.coordinates.latitude,
                pick_address_lng: tempPickup.coordinates.longitude,
                pick_up_zone: tempPickup.timezone,
                places: places,
                drop_address: drop_address,
                drop_address_lat: tempDropoff.coordinates.latitude,
                drop_address_lng: tempDropoff.coordinates.longitude,
                pickup: tempPickup,
                dropoff: tempDropoff,
            }, async() => {
                await this.fetchDirectionDetails()
                if (this.props.isGetQuotesClicked) {
                    this.getQuote();
                }

            })


        } else {

        }
    }

    addExtraStop = () => {
        let count = this.state.extra_stop_count;
        if (count > 4) {
            toast.error(`You can not enter more than 4 extra stops !`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        } else {
            // const con = this.getStopCount(count);
            let divid = "extrastop_" + count;
            let id = "extra_stop_" + count;
            let inputid = "auto_extra_" + count;

            const extrastop = {
                count,
                divid,
                id,
                inputid
            };

            this.state.extra_stop_controls.push(
                extrastop
            );
            this.setState({ extra_stop_count: this.state.extra_stop_count + 1 }, () => {
                this.props.getVehiclesList();
            });
        }
    }


    extraStopDiv = (extrastop: any) => {
        // const extraStopVal = this.state[`extra_stop_${extrastop.count}`];
        // console.log('extras1111', `extra_stop_${extrastop.count}`, this.state.extra_stop_values);
        let extraStopVal = (this.state.extra_stop_values[`extra_stop_${extrastop.count}`]) ? this.state.extra_stop_values[`extra_stop_${extrastop.count}`] : '';
        return <div className="row align-items-center no-gutters" key={extrastop.inputid}>
            <div className="col-auto">
                <img className="period-icon img-fluid" src={Period} />
            </div>
            <div className="col-10"  >
                {/* <AddressComponent
                    id={extrastop.inputid}
                    onPlaceChanged={(address) => { this.handleExtraAddressSelect(extrastop.count, address) }}
                    placeHolder={(extraStopVal && extraStopVal != '') ? extraStopVal : 'Extra Stop' + extrastop.count}
                    value={extraStopVal}
                    handleChange={(event: any) => this.handleExtraStopChange(event, extrastop.count)}
                /> */}

                <GooglePlacesComponent
                    // reference={this.setRefPickAddress}
                    id={extrastop.inputid}
                    onPlaceChanged={(address: any) => { this.handleExtraAddressSelect(extrastop.count, address) }}
                    value={extraStopVal}
                    handleChange={(event: any) => this.handleExtraStopChange(event, extrastop.count)}
                    // handleBlur={(event: any) => this.handleBlur(event)}
                    placeHolder={(extraStopVal && extraStopVal != '') ? extraStopVal : 'Extra Stop' + extrastop.count}
                    showFavorites={true}
                    supportedCountries={this.props.supportedCountries}
                    isGroundOnly={false}
                />


                {(this.state.extra_stop_error && this.state.extra_stop_error[`extra_stop_${extrastop.count}`]) ?
                    <div className="text-danger font-12  p-1 float-left">
                        Please select a complete address.
                    </div> : null
                }
            </div>
            <div className='col-1 text-center'>
                <i title="Delete" className="fa fa-trash extrastopdelete pointer"
                    onClick={e => this.removeExtraStops(e, extrastop.count)}
                >
                </i>
            </div>
        </div>
    }

    handleExtraStopChange = (event: any, count: any) => {
        // console.log(event.target.value, 'event');
        const value = event;
        let extra_stop_values = this.state.extra_stop_values;
        extra_stop_values[`extra_stop_${count}`] = value;
        this.setState({ [`extra_stop_${count}`]: value, extra_stop_values: extra_stop_values }, () => {
            if (value == '') {
                let extraStops = JSON.parse(JSON.stringify(this.state.extra_stops));
                let extraStopIndex = extraStops.findIndex((item: any) => item.stop_number === count);
                if (extraStopIndex != -1) {
                    extraStops.splice(extraStopIndex, 1);
                    // extraStops.push(removedItem);
                }
                this.setState({ extra_stops: extraStops }, async() => {
                    await this.fetchDirectionDetails()
                    this.props.getVehiclesList();
                })
                // console.log(extraStops, 'extraStops after');

            }
        });
    }

    handleExtraAddressSelect = (count: any, selection: any) => {
        const response = {
            field: `extra_stop_${count}`,
            isValid: false
        };
        const address = selection.original[0];
        let validate = this.validateAddresses(address, 'extras');
        // console.log("validate", validate)
        if (validate) {
            toast.error(SAME_LOCATION_ERR, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        Promise.resolve(getLuxyAddress(address)).then(async (extraStop: any) => {

            if (!extraStop.address.postal_code) {

                let postalCode: any = await CommonService.getPOstalCodeDetails(extraStop.coordinates.latitude, extraStop.coordinates.longitude);

                if (postalCode) {
                    extraStop.address.postal_code = postalCode.postal_code;
                }
            }

            extraStop.stop_number = count;
            this.handleExtraAddressChange(extraStop);
            
            isValidLuxyAddress(extraStop);
            response.isValid = true;
            localStorage.setItem(`valid_extra_stop_${count}`, 'true');
            
        }).catch((err) => {
            response.isValid = false;
            let extra_stop_error = this.state.extra_stop_error;
            extra_stop_error[response.field] = true;
            this.setState({ extra_stop_error: extra_stop_error }, () => {
            });
            localStorage.setItem(`valid_extra_stop_${count}`, 'false');
            });
    }

    handleExtraAddressChange(address: any) {
        let extra_stops = [...this.state.extra_stops];
        const existExtraStop = extra_stops.some(e => e.stop_number === address.stop_number);
        if (existExtraStop) {
            const index = extra_stops.map(e => e.stop_number).indexOf(address.stop_number);
            extra_stops[index] = address;
        } else {
            extra_stops.push(address);
        }
        this.setState({ isMapView: false });
        const stopNumber = address.stop_number;

        let extra_stop_error = this.state.extra_stop_error;
        extra_stop_error[`extra_stop_${stopNumber}`] = false;

        let st_extra_stop_values = this.state.extra_stop_values;
        st_extra_stop_values[`extra_stop_${stopNumber}`] = address.address.full_address;
        this.setState({ extra_stops, extra_stop_error, extra_stop_values: st_extra_stop_values, isMapView: true }, async () => {
            // console.log('st_extrastop_values added', st_extra_stop_values);
            // console.log(updatedBookingDetails, 'booking state from ride details 2');

            // await this.props.updateBookingState(updatedBookingDetails);
            // await this.updateDistanceInfo();

            await this.fetchDirectionDetails()
            // this.getQuote();
            this.props.getVehiclesList();
        });

        // console.log(this.state);
    }

    removeExtraStops(e: any, deleteitem: any) {

        e.preventDefault();
        let divid = "extrastop_" + deleteitem;
        var extra_stop_controls = [...this.state.extra_stop_controls];
        if (extra_stop_controls.length > 0) {
            for (let i = 0; i < extra_stop_controls.length; i++) {
                if (extra_stop_controls[i].divid === divid) {
                    var index = extra_stop_controls.indexOf(extra_stop_controls[i]);
                    if (index !== -1) {
                        // console.log(index, divid, 'index');
                        extra_stop_controls.splice(index, 1);
                        // code to remove extra stops address
                        let extraStops = [...this.state.extra_stops];

                        let extraStopIndex = extraStops.findIndex(item => item.stop_number === deleteitem);
                        // console.log(extraStops[extraStopIndex], deleteitem, 'booking state from ride details 3');

                        // get extrastop values fromm state

                        // update extra stop errors
                        let extrastop_errors: any = {};
                        let extrastop_values: any = {};


                        extraStops.forEach((extraStop: any, i: any) => {
                            // extraStop.stop_number = i + 1;
                            extrastop_errors[`extra_stop_${extraStop.stop_number}`] = this.state.extra_stop_error[`extra_stop_${extraStop.stop_number}`];
                            extrastop_values[`extra_stop_${extraStop.stop_number}`] = (this.state.extra_stop_values[`extra_stop_${extraStop.stop_number}`]) ? this.state.extra_stop_values[`extra_stop_${extraStop.stop_number}`] : '';
                        });

                        // let removedItem = {};
                        // if (extraStops.length) { removedItem = JSON.parse(JSON.stringify(extraStops[extraStops.length - 1])); }
                        if (extraStopIndex != -1) {
                            extraStops.splice(extraStopIndex, 1);
                            // extraStops.push(removedItem);
                        }

                        extraStops = extraStops.map((stop: any) => {
                            if (deleteitem < stop.stop_number) {
                                stop.stop_number = stop.stop_number - 1;
                            }
                            return stop;
                        });

                        let st_extra_stop_values: any = {};
                        if (extraStops.length == extra_stop_controls.length) {
                            // console.log(' extrastop_values act entered', extraStops);
                            extraStops.forEach((extraStop: any, i: any) => {
                                extraStop.stop_number = i + 1;
                                // extrastop_errors[`extra_stop_${extraStop.stop_number}`] = extrastop_errors_updated[extraStop.stop_number];
                                st_extra_stop_values[`extra_stop_${extraStop.stop_number}`] = extraStop.address.full_address;
                            });
                        } else {
                            extraStops.forEach((extraStop: any, i: any) => {
                                st_extra_stop_values[`extra_stop_${extraStop.stop_number}`] = extraStop.address.full_address;

                            });
                        }


                        // console.log(Object.assign({}, st_extra_stop_values), extraStops, 'extrastop_values act');

                        // update extra stop references in state like value;
                        // updating component
                        let newComponentList: any = [];
                        extra_stop_controls.forEach((component: any, i: any) => {
                            const count = i + 1;
                            let divid = `extrastop_${count}`;
                            let id = `extra_stop_${count}`;
                            let inputid = `auto_extra_${count}`;

                            const extrastop_c = {
                                count,
                                divid,
                                id,
                                inputid
                            };
                            newComponentList.push(
                                extrastop_c
                            );


                        });
                        // Object.assign(this.state, extraStopsRefences)
                        const extraStopCount = this.state.extra_stop_count - 1;
                        this.setState({
                            extra_stop_controls: newComponentList,
                            extra_stops: extraStops,
                            extra_stop_count: extraStopCount,
                            extra_stop_error: extrastop_errors,
                            extra_stop_values: st_extra_stop_values
                        }, async () => {
                            // let updatedBookingDetails = {
                            //     ...this.state.bookingDetails,
                            //     extra_stops: extraStops,
                            //     extra_stop_count: extraStopCount,
                            //     extra_stop_values: st_extra_stop_values
                            // }

                            // await this.props.updateBookingState(updatedBookingDetails);
                            // await this.updateDistanceInfo();
                            // this.getQuote();
                            await this.fetchDirectionDetails()
                            this.props.getVehiclesList();
                        });

                        // this.setState(
                        //     Object.assign(this.state, extrastop_values)
                        // )

                    }
                }
            }
        }
    }

    preparePayloadForFindMapRoute = ()=>{
        let places = JSON.parse(JSON.stringify(this.state.places));
        let extraStops = this.state.extra_stops;
        const origin = places.shift();
        const destination = places.pop();
        let waypoints = [];
        if (extraStops.length) {
            extraStops.map((extra: any) => {
                waypoints.push({ latitude: extra.coordinates.latitude, longitude: extra.coordinates.longitude });
            })
        }
        waypoints.unshift(origin);
        waypoints.push(destination);
        return waypoints
    }

    fetchDirectionDetails = async () => {
        const places  = this.preparePayloadForFindMapRoute() ;
        const travelMode  = 'DRIVING'
        if(places && places.length){
            const waypoints = places.map((p: any) => ({
                location: { lat: p.latitude, lng: p.longitude },
                stopover: true
            }))
    
            const origin = waypoints.shift()?.location;
            const destination = waypoints.pop()?.location;
            // this.findMapRoute(origin, destination, travelMode, waypoints);
            let routesData: any = await RideService.findMapRoute(origin, destination, travelMode, waypoints);
            const { directions, distanceInfo } = routesData;
            this.setState({ directions, distanceInfo }, () => {
                this.updateDistanceInfo(distanceInfo)
            });
       
        }
      
    }

    appIconClick = (appType: string) => {
        let appLink: string;
        appLink = (appType === 'App Store') ? this.state.appStore : this.state.playStore;
        const dataLayerObj: any = {
          'eventName': 'app_download_cta',
          'storeName': appType,
          'storeUrl': appLink,
          'event': 'appDownload'
        }
        createEventGA4(dataLayerObj);
      }


    closeNoVehicalFoundPopUp = ()=>{
        this.setState({noVehicleFoundError:false})
    }

    render() {
        let selDate: any = (this.state.pick_date_ptop != '') ? new Date(this.state.pick_date_ptop) : '';

        // selDate = (selDate == common.InvalidDate) ? new Date() : selDate;

        let selHrs = this.state.selHrs;
        let selMin = this.state.selMin;

        // console.log(selTime, 'selDate render 12345678');

        const pickup_address = (this.state.pick_address) ? this.state.pick_address : '';
        const dropoff_address = (this.state.drop_address) ? this.state.drop_address : '';


        let defaultCenter = { lat: 0, lng: 0 };
        if (this.state.pick_address_lat != '' && this.state.pick_address_lng != '') {
            defaultCenter = { lat: this.state.pick_address_lat, lng: this.state.pick_address_lng };
        } else if (this.state.drop_address_lat != '' && this.state.drop_address_lng != '') {
            defaultCenter = { lat: this.state.drop_address_lat, lng: this.state.drop_address_lng };
        } else {
            defaultCenter = { lat: 0, lng: 0 };
        }
        const isValidLatLong = this.validateSourceAndDestination();

        //for loading additional stop
        let additionalistops: any = [];
        if (this.state.extra_stop_controls.length > 0) {
            this.state.extra_stop_controls.forEach((extraStopControl: any) => {
                additionalistops.push(extraStopControl);
            });
            // additionalistops.sort((a: any, b: any) => (a.con > b.con) ? 1 : -1);
        }

        let places = JSON.parse(JSON.stringify(this.state.places));
        let extraStops = this.state.extra_stops;
        const origin = places.shift();
        const destination = places.pop();
        let waypoints = [];
        if (extraStops.length) {
            extraStops.map((extra: any) => {
                waypoints.push({ latitude: extra.coordinates.latitude, longitude: extra.coordinates.longitude });
            })
        }
        waypoints.unshift(origin);
        waypoints.push(destination);
        // console.log(waypoints, 'waypoints');       

        return <div>
            <div>
                <h1 className='qr-h1 qr-h1-media'>Goodbye rideshare, hello professional ride app!</h1>
            </div>
            <div className="RideDetailsBG"> <div className="container py-5 luxygreenBussinessPy-1"> <div className="rideDetails luxyGreenBussiness" style={{
                }}>
                <div className="ride-booking-pane luxyGreenBussiness-1" >
                        <a href="https://luxyride.com/programs/esg-compliance-black-car-industry/" target="_blank">
                        <img
                                alt="Black Car Service From Airport"
                                src={banner6}
                                className="d-inline-block  img-fluid platinum-img"
                            />
                        </a>
                    </div>
                    </div>
                <Row noGutters={true} style={{justifyContent: "center"}} className='qr-wrap-reverse'>
                    <div>
                        <ToastContainer />
                    </div>
                    <Col className="rideDetails qr-margin-top d-none d-md-block" md={6} xl={6} xs={12} >
                        <div className="ride-booking-pane">
                            <div className="block-head">
                            <h2 className='qr-h2'>Book a professional ride for yourself, your employees or your customers in one easy platform</h2>
                            </div>
                            <div className="col-xl-12 text-footer-center col-12 p-0">
                                <div className="row mt-2">
                                    <div className="col-12  p-0">
                                    <div className="flex social-btns qr-flex-direction">
                                    <img
                                        onClick={() => this.props.history.push('/')}
                                        alt=""
                                        src={QRCode}
                                        height="140px"
                                        width="184px"
                                        className="d-inline-block align-top pointer col-xl-4 col-md-6 col-6"
                                        style={{padding: "0px"}}
                                        />
                                        <h3 className='col-xl-8 col-md-6 col-12 qr-h3' style={{padding:"0px", fontSize: "18px"}}>Easily manage your trips online or on the LUXY Ride App.</h3>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 text-footer-center col-12 p-0">
                                <div className="row mt-2">
                                    <div className="col-12  p-0">
                                    <div className="flex social-btns qr-flex-direction">
                                        <a className="app-btn blu flex vert qr-app-btn"
                                            onClick={() => { this.appIconClick('App Store') }}
                                            href={this.state.appStore}>
                                            <i className="fab fa-apple"></i>
                                            <p style={{color: "white"}}>Available on the <br /> <span className="big-txt">App Store</span></p>
                                        </a>
                                        <a className="app-btn blu flex vert qr-app-btn qr-margin-top"
                                            onClick={() => { this.appIconClick('Play Store') }}
                                            href={this.state.playStore}>
                                            <i className="fab fa-google-play"></i>
                                            <p style={{color: "white"}}>Get it on <br /> <span className="big-txt">Google Play</span></p>
                                        </a>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    {/* <Col className="rideDetails qr-margin-top" md={6} xl={2} xs={12} ></Col> */}
                    <Col className="rideDetailsMap d-md-block" md={6} xl={6} xs={12} style={{background: "white", boxShadow: "none"}} >
                        <div className="ride-booking-pane">
                            <div className="block-head">
                                <h5>Book a Ride</h5>

                            </div>

                            <div className="treeview_home"></div>
                            <div style={{ position: 'relative' }}>
                                <div className="row align-items-center no-gutters">
                                    <div className="col-auto">
                                        <img alt="Black Car Service With Online Booking" style={{width: "10px", height: "10px"}} className="period-icon" src={Period} />
                                    </div>
                                    {/* <div className="col">
                                         <AddressComponent
                                            id='input-pickup-searchbox'
                                            onPlaceChanged={this.handlePickAddressSelect}
                                            placeHolder={(this.state.pick_address) ? this.state.pick_address : 'Enter Pick up location'}
                                            value={pickup_address}
                                            handleChange={(event: any) => this.handlePickChange(event)}
                                        /> 
                                    </div> */}
                                    <div className="col">
                                        <GooglePlacesComponent
                                            id='input-pickup-searchbox'
                                            onPlaceChanged={this.handlePickAddressSelect}
                                            value={pickup_address}
                                            handleChange={(event: any) => this.handlePickChange(event)}
                                            placeHolder={(this.state.pick_address) ? this.state.pick_address : 'Enter Pick up location'}
                                            showFavorites={true}
                                            supportedCountries={this.props.supportedCountries}
                                            isGroundOnly={false}
                                        />
                                    </div>
                                    {/* {(this.state.show_pickup_error) ?
                                        <div className="col font-12 text-danger p-1 ">
                                            Invalid pickup address
                                    </div> : null
                                    } */}
                                </div>


                                <div className="row align-items-center pl-4 no-gutters mt-0">
                                    {(this.state.show_pickup_error) ?
                                        <div className="col font-12 text-danger p-1 ">
                                            Please select a complete address.
                                        </div> : null}
                                 </div>

                                {
                                    (isValidLatLong && additionalistops.length == 0) ? <div className="text-center mt-2 pointer btn-icon">
                                        <img onClick={() => { this.exchangeAddress() }} style={{ height: '15px', width: '15px' }} src={Exchange} />
                                    </div> : null
                                }

                                {/* extr stops code */}
                                <img className="period-icon  img-fluid pointer" style={{ width: '16px', marginLeft: '-3px', position: 'absolute' }}
                                    onClick={() => this.addExtraStop()}
                                    alt="Black Car Cab Everywhere"
                                    src={Plus} />
                                {additionalistops.map((extrastop: any) => (
                                    this.extraStopDiv(extrastop))
                                )}
                                {/* ends here */}
                                <div className="row align-items-center no-gutters">
                                    <div className="col-auto">
                                        <img className="period-icon"  alt="Black Car Service With Online Booking" style={{width: "10px", height: "10px"}} src={Period} />
                                    </div>
                                    <div className="col">
                                        {/* <AddressComponent
                                            id='input-dropoff-searchbox'
                                            onPlaceChanged={this.handleDropAddressSelect}
                                            placeHolder={(this.state.drop_address) ? this.state.drop_address : 'Enter Drop off location'}
                                            value={dropoff_address}
                                            handleChange={(event: any) => this.handleDropChange(event)}
                                        /> */}
                                        <GooglePlacesComponent
                                            id='input-dropoff-searchbox'
                                            onPlaceChanged={this.handleDropAddressSelect}
                                            value={dropoff_address}
                                            handleChange={(event: any) => this.handleDropChange(event)}
                                            placeHolder={(this.state.drop_address) ? this.state.drop_address : 'Enter Drop off location'}
                                            showFavorites={true}
                                            supportedCountries={this.props.supportedCountries}
                                            isGroundOnly={false}
                                        />
                                        {(this.state.show_dropoff_error) ?
                                            <div className="text-danger p-1 float-left font-12">
                                                Please select a complete address.
                                            </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center no-gutters">
                                <div className="col-auto">
                                    {/* <img className="period-icon" src={Period} /> */}
                                    <i className="las la-calendar-check" style={{ fontSize: '24px', marginLeft: '-6px', }}></i>
                                </div>
                                <div className="col">
                                    <div className="row no-gutters mt-0">
                                        <div className="col-6 col-xl-4 col-md-4" style={{ paddingLeft: '7px', }}>
                                            <DateComponent
                                                reference={this.pick_date_ptop}
                                                format="MM-dd-yyyy"
                                                placeHolder="MM-DD-YYYY"
                                                minDate={new Date()}
                                                handleRaw={this.handleDateChangeRawPtoP}
                                                handleChange={this.handleDateChangePtoP.bind(this, 'pick_date_ptop')}
                                                selectedDate={selDate}
                                            />

                                        </div>
                                        <div className="col-6 col-xl-4 col-md-4" style={{ paddingRight: '4px', }}>

                                            <img src={clock} alt="Best Black Car Service" style={{ width: "16px", height: "16px"}}/> &nbsp;
                                            <HoursInput
                                                value={selHrs}
                                                id="selHrs"
                                                handleChange={this.handleChangeHourMin}
                                                style={{ width: '70px !important;' }}
                                            /> : <MinInput
                                                value={selMin}
                                                id="selMin"
                                                handleChange={this.handleChangeHourMin}
                                            />
                                            {/* <TimeInput
                                                reference={this.setRef}
                                                format={'hh:mm tt'}
                                                value={selTime}
                                                handleChange={this.handleChangeTime.bind(this, ROUTE_DETAILS_FIELDS.TIME)}
                                            /> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-auto spacer"></div>
                                <div className="col">
                                    <button
                                        disabled={(!this.state.valid_pickup || !this.state.valid_dropoff || this.state.pick_address == '' || this.state.drop_address == '' || this.state.pick_date_ptop == '')}
                                        onClick={() => this.getQuote()} className="btn btn-block primary custom-button">Get Quote</button>
                                </div>
                            </div>
                            {
                                (this.props.vehicleListError) ? <div className="row no-gutters">
                                    <div className="col-auto spacer"></div>
                                    <div className="col">
                                        <div className="col-12 text-danger p-1">No vehicles found in this route.</div>
                                    </div>
                                </div> : null
                            }


                            {this.renderDistanceInfo()}
                        </div>

                    </Col>
                    {/* <Col className="rideDetailsMap d-none d-md-block" md={8} xl={8} xs={12}>
                        {
                            (this.state.isMapView) ? <Map
                                googleMapURL={
                                    'https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_GOOGLE_API + '&libraries=geometry,drawing,places'
                                }
                                markers={waypoints}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: "100%", minHeight: '350px', borderRadius: "0px 15px 15px 0px" }} />}
                                mapElement={<div style={{ height: `100%`, borderRadius: "0px 15px 15px 0px" }} />}
                                defaultCenter={defaultCenter}
                                defaultZoom={11}
                                isValidLatLong={isValidLatLong}
                                directions={this.state.directions}
                            /> :
                                <div style={{ height: "100%", minHeight: '500px', borderRadius: "0px 15px 15px 0px" }} >
                                    <img
                                        alt="Best Luxury Black Car Service"
                                        src={Passenger}
                                        style={{ borderRadius: '0px 15px 15px 0px', height: '500px', width: '740px' }}
                                        className="d-inline-block align-top"
                                    />
                                </div>
                        }
                        {
                            <>
                                {
                                    (this.state.showLoginPopup && !this.props.isUserLogin)
                                        ? <AuthModal
                                            fromBookARide={true}
                                            updateNavData={() => this.props.updateNavigation()}
                                            closeModal={() => this.closeModal()} />
                                        : null
                                }
                            </>
                        }
                    </Col> */}
                </Row>
                {
                    (this.props.vehicleDetailsList && this.props.vehicleDetailsList.length) ? 
                            <VehicleListHome
                                vehiclesList={this.props.vehicleDetailsList}
                                selectVehicle={this.selectVehicle}
                                selectedVehicleIndex={this.state.selectedVehicleIndex}
                                pickup={this.state.pickup}
                                dropoff={this.state.dropoff}
                            />: null
                }

                    <Col className="rideDetails qr-margin-top qr-web-view" md={6} xl={6} xs={12} >
                        <div className="ride-booking-pane">
                            <div className="block-head">
                            <h2 className='qr-h2 text-footer-center'>Book a professional ride for yourself, your employees or your customers in one easy platform</h2>
                            </div>
                            <div className="col-xl-12 text-footer-center col-12 p-0">
                                <div className="row mt-2">
                                    <div className="col-12  p-0">
                                    <div className="flex social-btns qr-flex-direction">
                                    <img
                                        onClick={() => this.props.history.push('/')}
                                        alt=""
                                        src={QRCode}
                                        height="140px"
                                        width="184px"
                                        className="align-top pointer col-xl-4 col-md-6 col-6 d-none d-md-block"
                                        style={{padding: "0px"}}
                                        />
                                        <h3 className='col-xl-8 col-md-6 col-12 qr-h3' style={{padding:"0px", fontSize: "18px"}}>Easily manage your trips online or on the LUXY Ride App.</h3>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 text-footer-center col-12 p-0">
                                <div className="row mt-2">
                                    <div className="col-12  p-0">
                                    <div className="flex social-btns qr-flex-direction">
                                        <a className="app-btn blu flex vert qr-app-btn"
                                            onClick={() => { this.appIconClick('App Store') }}
                                            href={this.state.appStore}>
                                            <i className="fab fa-apple"></i>
                                            <p style={{color: "white"}}>Available on the <br /> <span className="big-txt">App Store</span></p>
                                        </a>
                                        <a className="app-btn blu flex vert qr-app-btn qr-margin-top"
                                            onClick={() => { this.appIconClick('Play Store') }}
                                            href={this.state.playStore}>
                                            <i className="fab fa-google-play"></i>
                                            <p style={{color: "white"}}>Get it on <br /> <span className="big-txt">Google Play</span></p>
                                        </a>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
            </div>

            </div>
            <WhyLuxy />
            {
                (this.state.noVehicleFoundError)?
                    <NoVehicleFoundPopUp
                    show={this.state.noVehicleFoundError}
                    message = {this.props.vehicleErrorMessage}
                    closeErrorPopUp={this.closeNoVehicalFoundPopUp}
                    isError={true}
                    isCancelButton={true}
                    confirmbuttonText='Call'
                    />
                : null
            }
        
        </div>

    }
}

export default BookRide;