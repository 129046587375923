import { connect } from 'react-redux';
import RideRewards from '../components/ride-rewards/ride-rewards';
import { SIGN_IN_RIDE_REWARDS, SIGN_UP_RIDE_REWARDS, USER_ENROLLED_RIDE_REWARDS, USER_ENROLLED_RIDE_REWARDS_SIGN_IN } from '../reducer/user';

type Dispatch = (action: any) => void;

const mapStateToProps = (state: any) => {
    return {
    };
};

const signUpForRideRewards = (data: boolean) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: SIGN_UP_RIDE_REWARDS, data: data
        });

        dispatch({
            type: SIGN_IN_RIDE_REWARDS, data: false
        });
        dispatch({
            type: USER_ENROLLED_RIDE_REWARDS, data: false
        });
        dispatch({
            type: USER_ENROLLED_RIDE_REWARDS_SIGN_IN, data: false
        });
        
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        signUpForRideRewards: (data: boolean) => dispatch(signUpForRideRewards(data))
    };
};

const RideRewardsContainer = connect(mapStateToProps, mapDispatchToProps)(RideRewards);

export default RideRewardsContainer;