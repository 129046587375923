import httpService from "./http-service";
import axios from 'axios';

class CommonService {
    saveCompany = async (payload: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/corporate/register/`, method: 'post' }, payload);
        return res;
    }

    saveAffiliate = async (payload: any) => {

        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/affiliate`, method: 'post' }, payload);
        return res;
    }

    searchPlaces = async (text: any, supportedCountries:any, isGroundOnly:any) => {
        let response;
        let query = text;
        if(supportedCountries){
            query=text+'&countries='+supportedCountries
        }
        if (isGroundOnly) {
            query=query+'&isGroundOnly='+isGroundOnly
        }

        response = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/autocomplete?input=` + query, method: 'get' }, {});

        return response;
    }

    getSelectedAddress = async (placeId: string) => {
        let response = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/place-info?place_id=` + placeId, method: 'get' }, {});
        
        return response;
    }

    getHeaders = () => {
        return {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('accessToken')
        }
    }

    async getOrderDetails(orderId: string, measurementType: string) {
        try {
            // let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/google-analytics/order-id/${orderId}/measurement-type/${measurementType}`, method: 'get' }, {"accept-version": 2});
            // return res;
            const ApiURL = process.env.REACT_APP_API_URL;
            const version = { "accept-version": 2 };
            const headers = this.getHeaders();
            const result = await axios.get(
                `${ApiURL}/google-analytics/order-id/${orderId}/measurement-type/${measurementType}`,
                {
                    headers: { headers, ...version }
                }
            );

            return result;
        } catch (error) {
            throw error;
        }
    }

    async getPOstalCodeDetails(lat: string, long: string) {
        try {
            let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/postal-code/${lat}/${long}`, method: 'get' }, {});
            if (res.data && res.data.postal_code &&  res.data.postal_code.length) {
                return {
                postal_code: res.data.postal_code[0].long_name
                }
            }
        } catch (error) {
            throw error;
        }
    }

    async getSpecialCarSeatTypes() {
        try {
        let response = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/special-car-seats`, method: 'get' }, {});
        return response;
        }catch (error) {
            throw error;
        }
    }
}

export default new CommonService();