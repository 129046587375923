import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Routes from './routing/routes';
import Footer from './components/layout/footer';
import { Container } from 'react-bootstrap';
import NavigationContainer from './containers/header';
import { RouteComponentProps, withRouter } from 'react-router';
import queryString from 'query-string';
import AuthModal from './components/modals/auth-modal';
import { AuthService } from './services/auth';
import { connect } from 'react-redux';
import { IS_USER_LOGIN, USER_ACTIVATION } from './reducer/user';
import { LoadingSpinerComponent } from './components/layout/loader';
import Affiliate from './components/affiliate/affiliate';
import Corporate from './components/corporate/corporate';
import TravelAgent from './components/travel-agent/travelagent'
import RideRewardsContainer from './containers/ride-rewards';
import { GET_CONFIG, RIDE_REWARDS } from './reducer/configuration';
import ConfigurationService from './services/ConfigurationService';
import { createEventGA4, trigerUserEvent } from './Utilities/helpers';
import ConfirmationPopup from './components/modals/confirm-popup';
import { toast } from 'react-toastify';

interface AppProps extends RouteComponentProps<any> {
  getUserActivation: (data: any) => any;
  dispatchUserLogin: () => void;
  getConfigdata: () => void;
  getRideRewardConfig: () => void;
  rideRewardsConfigData: any;
  isUserLogin: boolean;
}

interface AppState {
  isUserActivation: boolean;
  showAffiliate: boolean;
  showCorporate: boolean;
  showTravelAgent: boolean;
  showRewards: boolean;
  pathname: string;
  refferedBy : any;
  showUniqueID: boolean;
  uniqueID: string;
  orgName: string;
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      isUserActivation: false,
      showAffiliate: false,
      showCorporate: false,
      showTravelAgent: false,
      showRewards: false,
      pathname: '',
      refferedBy : '',
      showUniqueID: false,
      uniqueID: '',
      orgName: ''
    }
  }

  componentDidMount = async () => {
        
    this.setIREPath();
    if(localStorage.getItem('irclickid')){
     trigerUserEvent();
    }
    this.props.history.listen((location, action) => {
      let pathname = location.pathname;
      if(this.state.pathname !== location.pathname) {
        this.callGA4AllPageEvent(location.pathname);
      }
      this.setState({ pathname: pathname });
    });
    this.callGA4AllPageEvent(window.location.pathname);
    this.setState({ pathname: window.location.pathname });
    await this.props.getConfigdata();
    this.props.getRideRewardConfig();
    // let isLoggedIn = localStorage.getItem('isLoggedIn');
    let isLoggedIn = sessionStorage.getItem('isLoggedIn');
    if (isLoggedIn == 'true') {
      this.props.dispatchUserLogin();
    }

    let querystringArr: any = queryString.parse(this.props.location.search);

    if (querystringArr && querystringArr.affiliate === 'true') {
      this.setState({ showAffiliate: true });
    } else {
      this.setState({ showAffiliate: false });
    }
    if (querystringArr && querystringArr.corporate === 'true') {
      this.setState({ showCorporate: true });
    } else {
      this.setState({ showCorporate: false });
    }
    if (querystringArr && querystringArr.travelagent === 'true') {
      this.setState({ showTravelAgent: true });
      if(querystringArr && querystringArr.refferedBy){
        this.setState({refferedBy : Number(querystringArr.refferedBy) })
      }
    } else {
      this.setState({ showTravelAgent: false });
    }
    if (querystringArr && querystringArr.riderewards === 'true') {
      this.setState({ showRewards: true });
    } else {
      this.setState({ showRewards: false });
    }
    if (querystringArr.token) {
      let response: any = await this.getUserActivation(querystringArr.token);
      if (response !== undefined && response.status !== undefined) {
        if (response.status === 'OK') {
          await this.props.getUserActivation(response.data);
          this.setState({ ...this.state, isUserActivation: !this.state.isUserActivation });
        }
      }
    }

    if(querystringArr && querystringArr.unique_id && querystringArr.org) {
      this.setState({showUniqueID: true, uniqueID: querystringArr.unique_id, orgName: querystringArr.org});
    }
  }

  componentDidUpdate(prevProps: Readonly<AppProps>): void {
    if(this.props !== prevProps && this.props.isUserLogin && localStorage.getItem('irclickid')){
      trigerUserEvent();
    }
    if(this.props !== prevProps && !this.props.isUserLogin && localStorage.getItem('irclickid')){
      trigerUserEvent();
    }
  }

  setIREPath = () => {
    if(this.props.location && this.props.location.search && this.props.location.search.includes('irclickid')){

      let irePath:any = this.props.location.search.split('=');
          if(irePath && irePath.length){
              localStorage.setItem('irclickid', irePath[1] ? irePath[1] : 0)
          }
      }
  }

  callGA4AllPageEvent = (path: string) => {
    // let user = localStorage.getItem('user');
    let user = sessionStorage.getItem('user');
    let userId = "";
    if (user != null && user != undefined) {
      let userObj = JSON.parse(user);
      userId = userObj.id;
    }
    const obj: any = {
      'eventName': 'page_meta_data',
      'loginStatus': userId ? 'true' : 'false',
      'userId': userId.toString(),
      'environment': process.env.REACT_APP_ENVIRONMENT || 'prod',
      'event': 'pageMetaData'
    };
    createEventGA4(obj);
  }

  getUserActivation = async (token: string) => {
    let data = await AuthService.getUserActivation(token);
    if (data !== undefined) {
      return data.data;
    } else {
      return {
        data: undefined
      }
    }

  }

  closeModal = () => {
    this.setState({ isUserActivation: false, showAffiliate: false, showCorporate: false, showTravelAgent: false, showRewards: false, refferedBy : '', showUniqueID: false });
  }

  showActivationPopup = () => {
    return <AuthModal updateNavData={() => console.log()}
      closeModal={() => this.closeModal()}
      activateUser={true}
    />

  }

  /**
   * assign logged in user to corporate or ta.
   */
  enrollTOCorp = async () => {
    const errorMsg: string = `Unable to process at the moment, Please try again.`;
    try {
      let user = sessionStorage.getItem('user');
      let userId = "";
      if (user != null && user != undefined) {
        let userObj = JSON.parse(user);
        userId = userObj.id;
      }
      const resutls = await AuthService.enrollToCorporate(userId, this.state.uniqueID);
      if (!resutls) { 
        toast.error(errorMsg);

       } else { toast.success(`Sucessfully Updated to your Organization.`) }

    } catch (ex) {
      toast.error(errorMsg);
    }
    this.setState({showUniqueID: false})
  }

  showUniquIDSingUpModal = () => {
    let isLoggedIn = sessionStorage.getItem('isLoggedIn');

    const bodyTxt: string = `Are you sure want to enroll into your corporate ${this.state.orgName.toUpperCase()}`
    if(isLoggedIn === 'true') {
              return <ConfirmationPopup
                            show={true}
                            closeConfirmPopup={() => this.setState({ showUniqueID: false })}
                            title='Enroll to your Corporate'
                            bodyText={bodyTxt}
                            callbackMethod={() => { this.enrollTOCorp() }}
                            variant="success"
                        />
    }else {
      return <AuthModal updateNavData={() => console.log()}
      closeModal={() => this.closeModal()}
      activateUser={false}
      uniqueID={this.state.uniqueID}
    />
    }
    
  }


  render() {
    const userActivation = this.state.isUserActivation;
    const { showAffiliate, showCorporate, showTravelAgent, showRewards,refferedBy, showUniqueID } = this.state;
    return (

      <div className="App">
        <LoadingSpinerComponent />
        <Container fluid={true} className="px-0">
          {
            (userActivation) &&
            this.showActivationPopup()
          }
          {
            (showAffiliate) &&
            <Affiliate
              closeModal={this.closeModal}
            />
          }
          {
            (showCorporate) &&
            <Corporate
              closeModal={this.closeModal}
            />
          }
          {
            (showRewards) &&
            <RideRewardsContainer
              closeModal={this.closeModal}
            />
          }
          {
            (showTravelAgent) &&
            <TravelAgent
              closeModal={this.closeModal}
              refferedBy ={refferedBy}
              
            />
          }
          {
            (showUniqueID) &&
            this.showUniquIDSingUpModal()
          }
          {/* <SafetyAlert history={this.props.history} /> */}
          <NavigationContainer
          />
          <Routes />
          <Footer />
        </Container>
      </div>
    )
  }
}

type Dispatch = (action: any) => void;


const getConfigdata = () => {
  return async (dispatch: Dispatch) => {
    const configData = await ConfigurationService.getAllConfigurationsByName("SUPPORTED_COUNTRIES");
    dispatch({
      type: GET_CONFIG, data: configData
    });
  }
}


const getUserActivation = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: USER_ACTIVATION, data: data
    });
  }
}

const dispatchUserLogin = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: IS_USER_LOGIN, data: true
    });
  }
}

const getRideRewardConfig = () => {
  return async (dispatch: Dispatch) => {
    let configData: any = {
      value: 'false'
    }
    configData = await ConfigurationService.getAllConfigurationsByName(RIDE_REWARDS);

    if (configData && configData.length) {
      configData = configData[0]
    }

    dispatch({
      type: RIDE_REWARDS, data: configData
    });
  }
}
const mapStateToProps = (state: any) => {
  return {
    rideRewardsConfigData: state.configurations.rideRewardsConfigData,
    isUserLogin: state.user.isUserLogin
  };
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getConfigdata: () => dispatch(getConfigdata()),
    getUserActivation: (data: any) => dispatch(getUserActivation(data)),
    dispatchUserLogin: () => dispatch(dispatchUserLogin()),
    getRideRewardConfig: () => dispatch(getRideRewardConfig())
  };
};

const AppContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

export default AppContainer;
