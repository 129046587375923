import { connect } from 'react-redux';
import BookRide from '../components/book-a-ride/book-a-ride';
import { GET_ALL_VEHICLES, NAVIGATE_TO_RIDE_DETAIL, UPDATE_BOOKING_STATE, IS_GET_QUOTES_CLICKED } from '../reducer/book-a-ride';
import { IS_UPDATE_NAVIGATION_DATA } from '../reducer/navigation';
import { IS_GUEST_USER, USER_SIGN_UP } from '../reducer/user';
import RideService from '../services/RideService';


type Dispatch = (action: any) => void;

/**
 * Get all users from user service.
 */
const getVehiclesList = (payload: any = "") => {

    return async (dispatch: Dispatch) => {
        if (payload != "") {
            // let vData = await RideService.getVehiclePrice(payload);
            let vData = await RideService.getChargesV5(payload);
            if (vData.data && vData.data.serviceRates != null && vData.data.serviceRates && vData.data.serviceRates.vehicle_types.length) {
                dispatch({
                    type: GET_ALL_VEHICLES, data: vData.data.serviceRates.vehicle_types
                });
            } else {
                //comment for region code begins
                // dispatch({
                //     type: GET_ALL_VEHICLES, data: { vehicles: [], error: true }
                // });
                     //comment for region code ends
                    // console.log("okkkkk4",vData.error.data.message)
                dispatch({
                    type: GET_ALL_VEHICLES, data: { vehicles: [], error: true, vehicleErrorMessage : vData && vData.error && vData.error.data && vData.error.data.message ? vData.error.data.message : 'This trip can not be quoted at the moment. Please contact us at(833) 438-5899 for immediate assistance.'}
                });
            }
        } else {
            dispatch({
                type: GET_ALL_VEHICLES, data: { vehicles: [], error: false, }
            });
        }
    }
}

const updateBookingState = (data: any) => {
    console.log("actionData1",data)
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_BOOKING_STATE, data: data
        });
    }
}

const updateNavigation = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: IS_UPDATE_NAVIGATION_DATA, data: Date.now()
        });
    }
}

const navigateToRideDetail = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: NAVIGATE_TO_RIDE_DETAIL, data: true
        });
    }
}

const clickGetQuotes = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: IS_GET_QUOTES_CLICKED, data: true
        });
    }
}

const RemoveClickGetQuotes = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: IS_GET_QUOTES_CLICKED, data: false
        });
    }
}

const updateIsSignUp = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: USER_SIGN_UP, data: false
        });
    }
}

const continueAsGuest = () => {
    return async (dispatch: Dispatch) => {

        dispatch({
            type: IS_GUEST_USER, data: true
        });
    }
}

const mapStateToProps = (state: any) => {
    let supportedCountries='';
    if ( state.configurations.configData){
        let configs = state.configurations.configData;
        configs.forEach( (cnf: { name: string; value: any; }) => {

            if (cnf.name === "SUPPORTED_COUNTRIES"){
                supportedCountries=cnf.value;
            }
        }
        )
    }
    return {
        vehicleDetailsList: state.bookARide.allVehicles,
        vehicleListError: state.bookARide.vehicleListError,
        isUserLogin: state.user.isUserLogin,
        isUserSingUp: state.user.isUserSingUp,
        isGuestUser: state.user.isGuestUser,
        isGetQuotesClicked: state.bookARide.isGetQuotesClicked,
        supportedCountries: supportedCountries,
        vehicleErrorMessage : state.bookARide.vehicleErrorMessage
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getVehiclesList: (payload: any) => dispatch(getVehiclesList(payload)),
        updateBookingState: (data: any) => dispatch(updateBookingState(data)),
        navigateToRideDetail: () => dispatch(navigateToRideDetail()),
        updateNavigation: () => dispatch(updateNavigation()),
        clickGetQuotes: () => dispatch(clickGetQuotes()),
        RemoveClickGetQuotes: () => dispatch(RemoveClickGetQuotes()),
        updateIsSignUp: () => dispatch(updateIsSignUp()),
        continueAsGuest: ()=> dispatch(continueAsGuest())
    };
};

const BookARideContainer = connect(mapStateToProps, mapDispatchToProps)(BookRide);

export default BookARideContainer;
