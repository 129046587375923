export const formatNumber = num => {
    num = num ? num : 0; // to prevent undefined numbers.

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    return formatter.format(num);
};

export const formatMoneyToNumber = money => {
    money = money ? money : 0;
    let number = Number(money.toString().replace(/[^0-9\.]+/g, ''));

    return number;
};
