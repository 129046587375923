import moment from "moment";
import momentTz from 'moment-timezone';
import { DATE_FORMAT } from '../Consts';

export const isValidPickUpDate = (date, validTime = "24") => {
  const selectedDate = moment(date, DATE_FORMAT.DATE_TIME);
  const currtime = moment();
  const aDate = new Date(selectedDate).getTime();
  const bDate = new Date(currtime).getTime();
  const res = (aDate - bDate) / 1000;
  const hours = res / 60 / 60;

  return hours < validTime;
};

export const dateFormatter = date =>
  moment(date)
    .utc()
    .format(DATE_FORMAT.DATE_TIME);

export const getTimeDifference = (timezone, startDatetime, finalDatetime, unitOfTime = "hours") => {
  const startDatetimeWithTimezone = moment.tz(
    startDatetime,
    DATE_FORMAT.DATE_TIME,
    timezone
  );

  const finalDateTimeWithTimezone = finalDatetime
    ? moment.tz(finalDatetime, DATE_FORMAT.DATE_TIME, timezone)
    : moment.tz(timezone);

  return startDatetimeWithTimezone.diff(finalDateTimeWithTimezone, unitOfTime, true);
};

export const timeFormatter = date =>
  moment(date, [DATE_FORMAT.TIME_AM_PM]).format(DATE_FORMAT.TIME_HH_MM);

export const pickUpDateFormatter = date =>
  moment(date, DATE_FORMAT.DATE_MM_DD_YYYY).format(DATE_FORMAT.DATE_YYYY_MM_DD);

export const pickUpDateInputFormatter = date =>
  moment(date).format(DATE_FORMAT.DATE_MM_DD_YYYY);

export const getTimezoneDatetime = (date, zone) => {
let formatted = moment.tz(date, zone).format();
/*only in safari date issue*/
if (formatted === "Invalid date") {
  let parts = date.split(" ")
  let d=parts[0];
  let time=parts[1];
  let ampm=parts[2];
  d = moment(d, DATE_FORMAT.DATE_YYYY_MM_DD).format(DATE_FORMAT.DATE_MM_DD_YYYY);
  return d+ " " +time + " "+ampm;
}
 return formatted;
}

export const pastDateValidation = (date) => {
  const currentTime = moment();
  const currentDate = new Date(currentTime).getTime();
  return moment(date).isBefore(currentDate);
};

export const pickup_date_time_format = (pickup_date_time, pickup_date_timezone) => {
  let date_time = pickup_date_time;
  const time_zone = pickup_date_timezone;
  date_time = moment.tz(date_time, time_zone).format('MM-DD-YYYY');
  return date_time;
}