import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { toast } from 'react-toastify';
import { UserService } from '../../services/user';
import { createEventGA4 } from '../../Utilities/helpers';

interface PreferencesState {
    allowAll: boolean;
    notificationData: any;
}

interface PreferencesProps {
    closeModal: () => any;
    userId: any;
    notificationData: any;
}
class Preferences extends React.Component<PreferencesProps, PreferencesState>{
    constructor(props: PreferencesProps) {
        super(props);
        this.state = {
            allowAll: false,
            notificationData: []
        }
    }

    async componentDidMount() {
        let allowAll = true;
        if (this.props.notificationData.length) {
            this.props.notificationData.map((item: any) => {
                if (item.notification_email_checked == false || item.notification_sms_checked == false) {
                    allowAll = false;
                }
            });
        }
        this.setState({ notificationData: this.props.notificationData, allowAll: allowAll });
    }

    componentDidUpdate(prevProps: any) {

        if(prevProps !== this.props) {

            let allowAll = true;
            if (this.props.notificationData.length) {
                this.props.notificationData.map((item: any) => {
                    if (item.notification_email_checked == false || item.notification_sms_checked == false) {
                        allowAll = false;
                    }
                });
            }
            this.setState({ notificationData: this.props.notificationData, allowAll: allowAll });
        }
    }

    updatePreferences = async (payload: any) => {
        await UserService.updateNotificationData(payload)
    }

    handleAllowAll = async (event: any) => {
        // if (event.target.checked) {
        let updatedNotificationData: any = this.state.notificationData;
        let payloads: any = [];
        for (let i = 0; i < this.state.notificationData.length; i++) {
            let payloadEmail: any = {};
            let payloadSms: any = {};
            updatedNotificationData[i].notification_email_checked = event.target.checked;
            updatedNotificationData[i].notification_sms_checked = event.target.checked;

            // payloadEmail = {
            //     notification_event_id: updatedNotificationData[i].notification_event_id,
            //     user_id: this.props.userId,
            //     notification_type: '1',
            //     is_notification_checked: event.target.checked,
            // }

            payloadSms = {
                notification_event_id: updatedNotificationData[i].notification_event_id,
                user_id: this.props.userId,
                notification_type: '2',
                is_notification_checked: event.target.checked,
            }
            // payloads.push(payloadEmail);
            payloads.push(payloadSms);
        }
        let status = (event.target.checked) ? 'enabled' : 'disabled';
        this.setState({ notificationData: updatedNotificationData, allowAll: event.target.checked }, async () => {
            for (let j = 0; j < payloads.length; j++) {
                await this.updatePreferences(payloads[j]);
                if (j + 1 == payloads.length) {
                    this.notificationPreferenceGA4(`all_${status}`, 'all');
                    toast.success('Preferences updated successfully.');
                }
            }
        });
    }

    handleNotificationChange = async (indx: any, type: any, event: any) => {
        let updatedNotificationData: any = this.state.notificationData;
        let allow_all = this.state.allowAll;
        let payload: any = {};
        // if (type == 'email') {
        //     updatedNotificationData[indx].notification_email_checked = event.target.checked;
        //     payload = {
        //         notification_event_id: updatedNotificationData[indx].notification_event_id,
        //         user_id: this.props.userId,
        //         notification_type: '1',
        //         is_notification_checked: event.target.checked,
        //     }
        // }
        if (type == 'sms') {
            updatedNotificationData[indx].notification_sms_checked = event.target.checked;
            payload = {
                notification_event_id: updatedNotificationData[indx].notification_event_id,
                user_id: this.props.userId,
                notification_type: '2',
                is_notification_checked: event.target.checked,
            }
        }

        if (!event.target.checked) {
            allow_all = false;
        }else {
            const findSMSCheck = updatedNotificationData.find((data: any) => data.notification_sms_checked === false);
            const findEmailCheck = updatedNotificationData.find((data: any) => data.notification_email_checked === false);
            if(!findEmailCheck && !findSMSCheck) {
                allow_all = true;
            }
        }


        this.setState({ notificationData: updatedNotificationData, allowAll: allow_all }, async () => {
            await this.updatePreferences(payload);
            let status: string = (payload.is_notification_checked) ? 'enabled': 'disabled';
            this.notificationPreferenceGA4(`${type}_${status}`, updatedNotificationData[indx].event_name);
            toast.success('Preferences updated successfully.');
        });
    }

    notificationPreferenceGA4 = (preference: string, name: string) => {
        let obj: any = {
            'eventName': 'account_notification_preference',
            'preference': preference,
            'notification_name': name,
            'event': 'accountNotificationPreference'
        }
        createEventGA4(obj);
    }

    render() {
        // console.log('1111', this.state.notificationData)
        return <Modal size="lg" show={true} style={{ top: '50px' }} >
            <div className="float-right">
                <i className="fa fa-times float-right pointer"
                    onClick={() => this.props.closeModal()}
                    title="close"></i>
            </div>
            <Modal.Header>
                <h4 className="payment-span-text"> NOTIFICATION SETTINGS </h4>
            </Modal.Header>
            <Modal.Body>
                {
                    (this.state.notificationData && this.state.notificationData.length) ? <>
                        <Row className="row-preference">
                            <Col xs={4}>
                                <strong>Allow All</strong>
                                <div className="custom-control custom-switch">
                                    <input id="allow_all"
                                        name="allowAll"
                                        onChange={(event) => { this.handleAllowAll(event) }}
                                        checked={this.state.allowAll}
                                        value="allow_all"
                                        className="custom-control-input"
                                        type="checkbox" />
                                    <label className="custom-control-label" htmlFor="allow_all"></label>
                                </div>
                            </Col>
                            <Col xs={2}></Col>
                            {/* <Col xs={3}>
                                <div style={{ verticalAlign: 'middle' }}>
                                    <span style={{ textTransform: 'uppercase' }}>Email</span></div>
                            </Col> */}
                            <Col xs={3}>
                                <div style={{ verticalAlign: 'middle' }}>
                                    <span style={{ textTransform: 'uppercase' }}>Sms</span></div>
                            </Col>
                        </Row>
                        {
                            (this.state.notificationData && this.state.notificationData.length) ?

                                this.props.notificationData.map((item: any, indx: number) => {
                                    return <Row key={'not_'+indx} className="row-preference">
                                        <Col xs={6}>
                                            {item.event_name}
                                        </Col>
                                        {/* <Col xs={3}>
                                            <Form.Check aria-label="trip price email" name="tripPricesEmail"
                                                checked={item.notification_email_checked}
                                                onChange={(event) => { this.handleNotificationChange(indx, 'email', event) }} style={{ verticalAlign: 'middle' }} />

                                        </Col> */}
                                        <Col xs={3}>
                                            <Form.Check aria-label="trip price sms" name="tripPricesSms"
                                                checked={item.notification_sms_checked}
                                                onChange={(event) => { this.handleNotificationChange(indx, 'sms', event) }} style={{ verticalAlign: 'middle' }} />
                                        </Col>
                                    </Row>
                                }) : null
                        }
                    </> : null
                }

            </Modal.Body>
        </Modal>

    }
}

export default Preferences;
