import React from 'react';
import { Button, Modal } from "react-bootstrap";

interface ConfirmProps {
    show: boolean;
    closeErrorPopUp: () => any;
    title: string;
    bodyText: string;
}

function ErrorPopUp(props: ConfirmProps) {
    // const [show, setShow] = useState(false);

    const handleClose = () => {
        props.closeErrorPopUp()
    }

    const refreshThePage = ()=>{
        props.closeErrorPopUp()
        window.location.reload();
    }
    // const handleShow = () => setShow(true);

    return (
        <>

            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                 <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title>
                        {props.title}
                    </Modal.Title>
                </Modal.Header> 
                <Modal.Body>
                    {props.bodyText}
                </Modal.Body>
                <Modal.Footer className='border-top-0'>
                    <Button variant="danger" onClick={refreshThePage}>
                        Try Again
            </Button>
                    {/* <Button variant="danger"
                    onClick={() => props.deleteMethod()}
                    >Continue</Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ErrorPopUp;