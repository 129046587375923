import { formatMoneyToNumber } from "./NumberUtilities";

const { ECOMMERCE } = require("../Consts");

/**
 * This file is used to create re usable functions/vars throughout the application. 
 */
const dataLayer = window.dataLayer;

export const createEventGA4 = (eventObj) => {
    dataLayer.push(eventObj);
}

export const createEcommerceGA4 = (itemsData, event) => {
    const { pickup, dropoff } = itemsData;
    // Delete pickup, dropoff keys no longer useful in itemsData.
    delete itemsData.pickup;
    delete itemsData.dropoff;

    let user = sessionStorage.getItem('user');
    let userId = '0';
    if (user != null && user != undefined) {
        let userObj = JSON.parse(user);
        userId = userObj.id;
    }

    dataLayer.push({ ecommerce: null });
    const ecommerceObj = {
        event: event,
        pickUpName: pickup,
        dropName: dropoff,
        user_id: userId,
        ecommerce: {
            items: itemsData
        }
    }
    dataLayer.push(ecommerceObj);
}

export const prepareViewItemList = (vehicles, listName) => {
    const viewItemListArr = [];
    let viewListObj = {
        currency: "USD",
        item_list_id: listName,
        item_list_name: listName,
        quantity: 1,
    }
    let listObj = {};
    if (vehicles && vehicles.length) {
        for (let i = 0; i < vehicles.length; i++) {
            listObj = {
                ...viewListObj,
                item_id: vehicles[i].vehicle_type_id.toString(),
                item_name: vehicles[i].vehicle_type_name,
                index: i,
                item_category: vehicles[i].vehicle_type_name,
                price: formatMoneyToNumber(vehicles[i].base_price)
            }
            viewItemListArr.push(listObj);
            listObj = {};
        }
        return viewItemListArr;
    } else {
        return false;
    }
}

export const prepareSelectedItemList = (vehicleData, selectedIndex, from = '') => {
    let listName = '';
    if (from !== '') {
        listName = from
    } else {
        listName = vehicleData.item_list_name
    }
    let selectedObj = {
        currency: "USD",
        item_list_id: listName,
        item_list_name: listName,
        quantity: 1,
        item_category2: listName
    }
    if (vehicleData) {

        selectedObj = {
            ...selectedObj,
            item_id: vehicleData.vehicle_type_id.toString(),
            item_name: vehicleData.vehicle_type_name,
            index: selectedIndex,
            item_category: vehicleData.vehicle_type_name,
            price: formatMoneyToNumber(vehicleData.base_price)
        }
        // console.log(vehicleData, 'vehicleData', selectedObj)
        return [selectedObj];
    } else {
        return false;
    }
}

export const prepareBeginCheckout = (vehicleData, selectedIndex, from = '') => {

    let selectedObj = {
        currency: "USD",
        item_list_id: from,
        item_list_name: from,
        quantity: 1
    }
    if (vehicleData) {

        selectedObj = {
            ...selectedObj,
            item_id: (vehicleData.vehicle_type_id) ? vehicleData.vehicle_type_id.toString() : "0",
            item_name: vehicleData.vehicle_type_name || '',
            index: selectedIndex || '0',
            item_category: vehicleData.vehicle_type_name || '',
            price: formatMoneyToNumber(vehicleData.base_price) || ''
        }
        // console.log(vehicleData, 'vehicleData', selectedObj)
        return [selectedObj];
    } else {
        return false;
    }
}

export const prepareRemoveCartObj = (vehicleData, selectedIndex) => {

    let selectedObj = {
        currency: "USD",
        item_list_id: "change_list",
        item_list_name: "change_list",
        quantity: 1
    }
    if (vehicleData) {

        selectedObj = {
            ...selectedObj,
            item_id: vehicleData.vehicle_type_id.toString(),
            item_name: vehicleData.vehicle_type_name,
            index: selectedIndex,
            item_category: vehicleData.vehicle_type_name,
            price: formatMoneyToNumber(vehicleData.base_price)
        }
        // console.log(vehicleData, 'vehicleData', selectedObj)
        return [selectedObj];
    } else {
        return false;
    }
}

export const preparePurchaseEvent = (data) => {

    let purchaseObj = {
        'event': ECOMMERCE.PURCHASE,
        'orderId': data.orderId,
        'passengerType': 'primary',
        'secondaryPassenger': data.secondaryPassenger,
        'airlineDetails': data.airlineDetails,
        'tripProtection': data.tripProtection,
        'arrivalMeet': data.arrivalMeet,
        'travelPet': data.travelPet,
        'childSeat': data.childSeat,
        'promoCode': data.promoCode,
        'trip_protection': data.tripProtectionTotalPrice,
        'gratuity': data.gratuityPriceTotal,
        'tax': data.salesTax,
        'affiliateTotalPay': data.affiliatePayoutTotal,
        'luxyProfit': data.affiliateFeeTotal,
        ecommerce: {
            transaction_id: data.tripId,
            value: data.totalCost,
            tax: data.salesTax,
            currency: "USD",
            coupon: (data.promoCodeId) ? data.promoCodeId.toString() : "",
            items: [
                {
                    item_id: data.vehicle_type_id,
                    item_name: data.vehicle_type_name,
                    coupon: (data.promoCodeId) ? data.promoCodeId.toString() : "",
                    currency: "USD",
                    discount: data.totalDiscount,
                    index: data.selectedVehicleIndex,
                    item_category: data.vehicle_type_name,
                    price: formatMoneyToNumber(data.baseRate),
                    quantity: 1,
                    item_category2: data.affiliatePayoutTotal,
                    item_category3: data.affiliateFeeTotal,
                    item_category4: data.tripId,
                    item_list_id: data.listType,
                    item_list_name: data.listType,
                }
            ]
        }
    }
    return purchaseObj;
}

export const createPurchaseEventGA4 = (data) => {
    dataLayer.push({ ecommerce: null });
    dataLayer.push(data);
}

export const SelectedVehicleObj = (data) => {
    if(data){
        const { vehicle_type_name, vehicle_type_id, base_price } = data;
        return {
            vehicle_type_name,
            vehicle_type_id,
            base_price
        }
    }
    return {}
   
}

export const userFromLocalStorage = () => {
    const isUserLoggedIn = sessionStorage.getItem('isLoggedIn');
    if (isUserLoggedIn == 'true') {
        const userFromLS = sessionStorage.getItem('user');
        const user = JSON.parse(userFromLS);
        return user;
    } else {
        return {}
    }
}

export const getPlaceCodeNdState = (place) => {
    // Place city wil be airport or city name.
    const placeCity = (place) && (place.place) && (place.place.place_type === 'airport') ? place.place.airport_code : (place && place.address) ? place.address.city : '';
    const placeState = (place && place.address) ? place.address.state : '';
    return `${placeCity}|${placeState}`;
}

export const sendToIREEvent = (orderForIREImpact) => {
    window.ire('trackConversion', 37939, orderForIREImpact,
    {verifySiteDefinitionMatch:true}
    )
}

export const trigerUserEvent = () => {
    const userData = userFromLocalStorage();
    let userID = '';
    if (userData && userData.id) {
        userID = userData.id;
    }
    window.ire('identify', {customerId: userID, customerEmail: ''});
}

// module.exports = {
//     createEventGA4,
//     createEcommerceGA4,
//     prepareViewItemList,
//     prepareSelectedItemList,
//     prepareRemoveCartObj,
//     prepareBeginCheckout,
//     SelectedVehicleObj,
//     preparePurchaseEvent,
//     createPurchaseEventGA4
// };
