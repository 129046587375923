import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { bookARideReducer } from "./reducer/book-a-ride";
import { userReducer } from "./reducer/user";
import { navigationReducer } from "./reducer/navigation";
import { rideDetailsReducer } from "./reducer/ride-detials";
import { detailsReducer } from './reducer/details';
import { paymentReducer } from "./reducer/payment-info";
import { blogReducer } from "./reducer/blog";
import { profileReducer } from "./reducer/profile";
import { paymentSettingsReducer } from "./reducer/payment-settings";
import { configurationReducer } from "./reducer/configuration";
import { rideRewardsReducer } from "./reducer/ride-rewards";

const rootReducer = combineReducers({
  user: userReducer,
  navigation: navigationReducer,
  bookARide: bookARideReducer,
  rideDetails: rideDetailsReducer,
  details: detailsReducer,
  paymentInfo: paymentReducer,
  blogs: blogReducer,
  profile: profileReducer,
  paymentSettings: paymentSettingsReducer,
  configurations: configurationReducer,
  rideRewards: rideRewardsReducer
});

export default function configureStore() {
  const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
  );
  return store;
}
