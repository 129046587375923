import React from 'react';
import GetLuxy from '../widgets/get-luxy';
import aboutBg from '../../assets/images/about-bg.png';
interface PrivacyProps {

}

interface PrivacyState {

}

class PrivacyPolicy extends React.Component<PrivacyProps, PrivacyState> {

    constructor(props: PrivacyProps) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className='about-top'>
                    <div className='get-luxy-widget'>
                        <GetLuxy />
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 about-heading'>
                                <h1 className='text-center'>
                                    PRIVACY POLICY
                                </h1>
                            </div>
                        </div>
                        <img style={{ width: '100%', height: '135px' }} src={aboutBg} alt='privacy' />
                    </div>
                </div>
                <div className='about-content'>
                    <div className="vc_row wpb_row vc_row-fluid"><div className="wpb_column vc_column_container vc_col-sm-12"><div className="vc_column-inner"><div className="wpb_wrapper">
                        <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                                <p className="p1"><span className="s1">Luxy&#8482;, Inc. wants you to know that we value the privacy of your information and will not sell or market your personal information to any other company. However, certain profile information may be used to create specific messages or advertising to you as described further in this privacy policy. </span></p>
                                <p className="p1"><span className="s1">Luxy&#8482;, Inc. and/or its corporate affiliates and/or subsidiaries (collectively “<b>Luxy&#8482;,</b>” “<b>us,</b>” or “<b>we</b>”) collects information about you when you use our mobile applications, websites, and other online products and services provided by Luxy&#8482; (collectively, the “<b>Services</b>”), and through other interactions and communications that you may have with us. This Privacy Policy describes how Luxy&#8482; collects and uses the personal information that you provide through the Services. It also describes the choices available to you regarding our use of your personal information and how you can access and update this information.</span></p>
                                <p className="p1"><span className="s1"><b>SCOPE AND APPLICATION</b></span></p>
                                <p className="p1"><span className="s1">This Privacy Policy applies to persons who use our application or access our Services to request transportation, delivery, or other services (“<b>Clients</b>”). This Privacy Policy does not apply to information that we collect from or about vehicle drivers, couriers, partner transportation companies, or any other persons or entities (collectively “<b>Drivers”</b>). If you interact with the Services as both a Client and a Driver, the applicable Privacy Policy applies to your different interactions with Luxy&#8482;, respectively.</span></p>
                                <p className="p1"><span className="s1"><b>COLLECTION OF INFORMATION</b></span></p>
                                <p className="p3"><span className="s1"><b><i>INFORMATION YOU PROVIDE TO US</i></b></span></p>
                                <p className="p1"><span className="s1">We only collect information that you directly provide to us, such as when you create or modify your account, request on-demand services, contact customer support, or otherwise communicate with us. This information may include: name, email, phone number, postal address, profile picture, payment method, and other information you choose to provide. We receive and store any information that you enter on our website or give us in any other way. You can choose not to provide certain information, but then you will not be able to take advantage of many of the features of our Services, including receiving pricing or making a reservation.</span></p>
                                <p className="p1"><span className="s1">In order to use the Services, you must first create an account by providing your first and last name, email or phone number, and creating a password. We collect all such information that you provide in creating your account. You may also provide such information if you participate in a contest or questionnaire, or communicate with customer service; provide information in your account or profile; communicate with us by phone, email, or otherwise; provide employer information when opening a corporate account; provide reviews; or employ our personal notification services, if any. As a result of those actions, you may supply us with booking or reservation information such as your name, address, and phone numbers; credit card information; and content of reviews and emails to us. We use the information that you provide for our general commercial purposes including to allow us and our corporate affiliates and/or subsidiaries to respond to your requests, improve and customize future Services, and communicate with you.</span></p>
                                <p className="p4"><span className="s1"><b><i>INFORMATION WE COLLECT THROUGH YOUR USE OF OUR SERVICES</i></b></span></p>
                                <p className="p1"><span className="s1">When you use our Services, we collect information about you in the following general categories:</span></p>
                                <p className="p1"><span className="s2"><b>LOCATION INFORMATION</b></span><span className="s1"><b>: </b>When you permit the Services to access location services through the permission system used by your mobile operating system, we typically collect the precise location of your trip via your device when the application or website is running in the foreground or background. We may also derive your approximate location from your Internet Protocol (IP) address when necessary.</span></p>
                                <p className="p1"><span className="s2"><b>CONTACTS INFORMATION</b></span><span className="s1"><b>: </b>When you permit the Services to access the address book on your device through the permission system used by your mobile platform, we may access and store names and contact information from your address book to facilitate social interactions through the Services and for other purposes described in this Privacy Policy or at the time of consent or collection. </span></p>
                                <p className="p1"><span className="s2"><b>TRANSACTION INFORMATION</b></span><span className="s1"><b>: </b>We collect transaction details related to your use of the Services, including the type of service requested, date and time the service was provided, amount charged, distance traveled, and other related transaction details. Additionally, if someone uses your promo code, we may associate your name with that person.</span></p>
                                <p className="p1"><span className="s2"><b>USAGE AND PREFERENCE INFORMATION</b></span><span className="s1"><b>: </b>We collect information about how you and/or visitors interact with our Services, your preferences expressed, and settings chosen. In some cases, we do this through the use of cookies, pixel tags, and similar technologies that create and maintain profile identifiers.
                                    The website may use cookies to help keep track of trip bookings, including when you have abandoned a booking without completing it, and this information is used to determine when to send trip booking reminder messages via SMS and email. To learn more about these technologies, please see our cookie policy.</span></p>
                                <p className="p1"><span className="s2"><b>DEVICE INFORMATION</b></span><span className="s1"><b>: </b>We may collect information about your mobile device, including the hardware model, operating system and version, software and file names and versions, preferred language, unique device identifier, advertising identifiers, serial number, device motion information, and mobile network information.</span></p>
                                <p className="p1"><span className="s2"><b>CALL AND SMS DATA</b></span><span className="s1">: The Services may facilitate communications between Clients and Drivers. In connection with facilitating such task(s), we receive call data, including the date and time of the call or SMS message, the parties’ telephone numbers, and if applicable, the content of the SMS message.</span></p>
                                <p className="p1"><span className="s2"><b>LOG INFORMATION</b></span><span className="s1"><b>: </b>When you interact with the Services, we collect server logs, which may include information such as device IP address, access dates and times, application or website features or pages viewed, application or website crashes and other system activity, type of browser, and the third-party sites or applications you were using before interacting with our Services.</span></p>
                                <p className="p5"><span className="s1"><b><i>INFORMATION WE COLLECT FROM OTHER SOURCES</i></b></span></p>
                                <p className="p1"><span className="s1">We may also receive information from other sources and combine that with the information we collect through the Services. For example:</span></p>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">If you choose to link, create, or log into your Services account with a payment provider (e.g., Google Pay) or social media service (e.g., Facebook), or if you engage with a separate app or website that uses our Application Programming Interface (API) or whose API we use, then we may receive information about you or your connections from that site or app.</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">If your employer uses one of our enterprise solutions, we may receive information about you from your employer.</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">Drivers may provide us with a user rating after providing transport services to you.</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">If you also interact with the Services in another capacity, for instance as a Driver or user of other applications or websites that we provide, we may combine or associate that information with the information we have collected from you in your capacity as a Client or rider in a vehicle.</span></li>
                                </ul>
                                <p className="p1"><span className="s1"><b>USE OF INFORMATION</b></span></p>
                                <p className="p1"><span className="s1">We may use the information we collect about you to:</span></p>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">Provide, maintain, and improve the Services, including, for example, to facilitate payments, send receipts, provide products and services that you request (and send related information), develop new features, provide customer support to Clients and Drivers, develop safety features, authenticate Clients, and send product updates and administrative messages;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">Perform internal operations, including, for example, to prevent fraud and abuse of the Services; to troubleshoot software bugs and operational problems; to conduct data analysis, testingtests, and research; and to monitor and analyze usage and activity trends;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">Send or facilitate communications (i) between you and a Driver, such as estimated times of arrival (ETAs), or (ii) between you and a contact of yours at your direction in connection with your use of certain features, such as referrals, invites, split-fare requests, or sharing of ETAs;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">Send you communications that we think will be of interest to you, including information about products, services, promotions, news, and events of Luxy&#8482; and other companies, where permissible and according to local applicable laws; and to process contests, sweepstakes, or other promotion entries and fulfill any related awards;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">Personalize and improve the Services, including providing or recommending features, content, social connections, referrals, and advertisements.</span></li>
                                </ul>
                                <p className="p1"><span className="s1">We may transfer the information described in this Privacy Policy to, and process and store it, in the United States and other countries, some of which may have fewer protective data protection laws than the region in which you reside. Where this is the case, we will take appropriate measures to protect your personal information in accordance with this Privacy Policy.</span></p>
                                <p className="p1"><span className="s1"><b>SHARING OF INFORMATION</b></span></p>
                                <p className="p1"><span className="s1">We may share the information that we collect about you as described in this Privacy Policy or as described at the time of collection or sharing, including as follows:</span></p>
                                <p className="p5"><span className="s1"><b><i>THROUGH OUR SERVICES</i></b></span></p>
                                <p className="p1"><span className="s1">We may share your information:</span></p>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">With Drivers to enable them to provide the transportation services that you request. For example, we share your name, photo (if you provide one), average user rating given by Drivers, and pickup and/or drop-off locations with Drivers;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">With other Clients and/or other riders if using a ride-sharing option; and with other people, as directed by you, such as when you want to share your ETA or split a fare with a friend;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">With third parties to provide you a Service that you requested through a partnership or promotional offering made by a third party or us;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">With the general public if you submit content in a public forum, such as blog comments, social media posts, or other features of our Services that are viewable by the general public;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">With third parties with whom you choose to let us share information, for example other applications or websites that integrate with our API or Services, or those with an API or Service with which we integrate; </span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">With third parties to either display advertising through the Services or to manage our advertising on other sites. Our third-party partners may use technologies such as cookies to gather information about your activities based upon your browsing activities and interests on this and other sites in order to provide you advertisements. If you wish to not have this information used for the purpose of providing you with interest-based ads, you may opt-out of their use of your information on those other websites. Please note that you will continue to receive non-specific generic advertisements.</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">With your employer (or similar entity) and any necessary third parties engaged by us or your employer (e.g., an expense management service provider), if you participate in any of our enterprise solutions.</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">The above excludes text messaging originator opt-in data and consent; this information will not be shared with any third parties.</span></li>
                                </ul>
                                <p className="p8"><span className="s1"><b><i>OTHER IMPORTANT SHARING</i></b></span></p>
                                <p className="p1"><span className="s1">We may share your information:</span></p>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">With (i) Luxy&#8482; corporate affiliates and/or subsidiaries that provide the Services; (ii) independent third-party transportation providers and third-party logistics providers, including transportation network company drivers, transportation charter permit holders or holders of similar transportation permits, authorizations or licenses (collectively, “<b>Transport Providers</b>”) that provide transportation and/or logistics services through the Services; and/or (iii) entities that conduct data processing on our behalf, or for data centralization and/or logistics purposes;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">With vendors, consultants, marketing partners, and other providers who we determine need access to such information to carry out work on our behalf;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">In response to a request for information by a competent authority if we believe disclosure is in accordance with, or is otherwise required by, any applicable law, regulation, or legal process;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">With law enforcement officials, government authorities, or other third parties if we believe your actions are inconsistent with our Client agreement(s), terms and conditions of services or policies, or to protect the rights, property, or safety of Luxy&#8482; or others;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">In connection with, or during negotiations of, any merger, sale of assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by or into another company;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">If we otherwise notify you of, and you consent to, the sharing; and/or;</span></li>
                                </ul>
                                <ul className="ul1">
                                    <li className="li1"><span className="s1">In an aggregated and/or anonymized form which cannot reasonably be used to identify you.</span></li>
                                </ul>
                                <p className="p1"><span className="s1"><b>SOCIAL SHARING FEATURES</b></span></p>
                                <p className="p1"><span className="s1">The Services may integrate with social sharing features and other related tools which let you share actions that you take on our Services with other applications, sites, or media, and vice versa. Your use of such features enables the sharing of information with your friends or the public, depending on the settings that you establish with the social sharing service. Please refer to the privacy policies of those social sharing services for more information about how they handle the data that you provide to or share through them. Analytics and advertising services may allow others to provide audience measurement and analytics services for us, to serve advertisements on our behalf across the internet, and to track and report on the performance of those advertisements. These entities may use cookies, web beacons, source development kits (SDKs) and other technologies to identify your device when you visit our site and use our Services, as well as when you visit other online websites and services. For more information about these technologies and service providers, please refer to our cookie statement.</span></p>
                                <p className="p1"><span className="s1"><b>SMS/MMS MOBILE MESSAGING MARKETING PROGRAM</b></span></p>
                                <p className="p1"><span className="s1">We will only use your mobile phone numbers with explicit consent provided through our services or via our website. We will use the information you provide through the program to transmit your mobile messages and respond to you. This includes, but is not limited to, sharing information with platform providers, phone companies, and other vendors who assist us in the delivery of mobile messages. We do not share any phone numbers collected through the program to any third party. Nonetheless, we reserve the right at all times to disclose any information as necessary to satisfy any law, regulation, or governmental request, to avoid liability, or to protect our rights or property. When you complete forms online or otherwise provide us with information in connection with the program, you agree to provide accurate, complete, and true information. You agree not to use a false or misleading name or a name that you are not authorized to use. If, in our sole discretion, we believe that any such information is untrue, inaccurate, or incomplete, or you have opted into the program for an ulterior purpose, we may refuse you access to the program and pursue any appropriate legal remedies. For more information, see our <a href='https://luxy-ride.myklpages.com/p/mobile-terms-of-service' target='_blank'>Mobile Terms of Service</a>.</span></p>
                                <p className="p1"><span className="s1"><b>YOUR CHOICES</b></span></p>
                                <p className="p5"><span className="s1"><b><i>ACCOUNT INFORMATION</i></b></span></p>
                                <p className="p1"><span className="s1">You may correct your account information at any time by logging into your online Luxy&#8482; account or app. If you wish to cancel your account, please email us at info@luxyride.com. Please note that, in some cases, we may retain certain information about you as required by law, or for legitimate business purposes to the extent permitted by law. </span></p>
                                <p className="p5"><span className="s1"><b><i>ACCESS RIGHTS</i></b></span></p>
                                <p className="p1"><span className="s1">Luxy&#8482; will comply with individual’s requests regarding access, correction, objection, and/or deletion of the personal data it stores in accordance with applicable law.</span></p>
                                <p className="p5"><span className="s1"><b><i>LOCATION INFORMATION</i></b></span></p>
                                <p className="p1"><span className="s1">We request permission for the Service’s collection of precise location from your device per the permission system used by your mobile operating system. If you initially permit the collection of this information, you can later disable it by changing the location settings on your mobile device. However, this will limit your ability to use certain features of the Services. Additionally, disabling the Service’s collection of precise location from your device will not limit our ability to collect your trip location information from a Driver’s device nor our ability to derive approximate location from your device’s IP address.</span></p>
                                <p className="p5"><span className="s1"><b><i>CONTACT INFORMATION</i></b></span></p>
                                <p className="p1"><span className="s1">We may also seek permission for the Service’s collection and syncing of contact information from your device per the permission system used by your mobile operating system. If you initially permit the collection of this information, IOS users can later disable it by changing the contacts settings on your mobile device. The Android platform does not provide such a setting.</span></p>
                                <p className="p5"><span className="s1"><b><i>PROMOTIONAL COMMUNICATIONS</i></b></span></p>
                                <p className="p1"><span className="s1">You may opt out of receiving promotional messages from us by following the “unsubscribe” instructions in those messages. If you opt out by unsubscribing, we may still send you non-promotional communications, such as those about your account, about services you have requested, or our ongoing business relations.</span></p>
                                <p className="p1"><span className="s1"><b>YOUR CALIFORNIA PRIVACY RIGHTS</b></span></p>
                                <p className="p1"><span className="s1">California law permits residents of California to request certain details about how their information is shared with third parties for direct marketing purposes. Luxy&#8482; does not share <b>your personally identifiable information with third parties</b> for the third parties’ direct marketing purposes unless you provide us with consent to do so.</span></p>
                                <p className="p1"><span className="s1"><b>COOKIES AND ADVERTISING</b></span></p>
                                <p className="p1"><span className="s1">Please refer to our cookie statement for more information about your choices around cookies and related technologies.</span></p>
                                <p className="p1"><span className="s1"><b>CHANGES TO THE PRIVACY POLICY</b></span></p>
                                <p className="p1"><span className="s1">We may change this Privacy Policy from time to time. If we make significant changes in the way that we treat your personal information, we will provide you notice through the Services or by some other means, such as email. </span></p>
                                <p className="p1"><span className="s1"><b>CONTACT US</b></span></p>
                                <p className="p1"><span className="s1">If you have any questions about this privacy statement, please contact us at info@luxyride.com, or write us at 30 Controls Drive, Shelton, CT 06484.</span></p>

                            </div>
                        </div>
                    </div></div></div></div>
                </div>
            </div>
        )
    }
}

export default PrivacyPolicy;