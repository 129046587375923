import httpService from "./http-service";
import instance from '../axios/external';

export class UserService {

    static getAllUsers = async () => {
        return [
            {
                id: 1,
                firstName: 'Hari',
                lastName: 'V'
            },
            {
                id: 2,
                firstName: 'Jon',
                lastName: 'D'
            }
        ]
    }

    static getUserById = async (userID: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/customer/${userID}`, method: 'get' }, null);
        return res;
    }

    static deactivateUser = async (userInfo: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/user/de-activate/notify`, method: 'post' }, userInfo);
        return res;
    }

    static verifyOtp = async (email: string, userId: number, otp: string) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/users/${email}/${userId}/${otp}`, method: 'get' }, null);
        return res;
    }

    static confirmDelete = async (email: string, userId: number) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/users/${email}/${userId}`, method: 'delete' }, null);
        return res;
    }

    static getPastTripData = async (userID: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/customer/${userID}/past-trips`, method: 'get' }, null);
        return res;
    }

    static getUpcomingTripData = async (userID: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/customer/${userID}/upcoming-trips`, method: 'get' }, null);
        return res;
    }
    static getInprogressTripData = async (userID: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/customer/${userID}/in-progress-trips`, method: 'get' }, null);
        return res;
    }

    static getAirportsInfo = async () => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/airport`, method: 'get' }, null);
        return res;
    }

    static updateAddressInfo = async (payload: any, userID: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/customer/${userID}`, method: 'put' }, payload);
        return res;
    }

    static getAwsPresignedUrl = async (payload: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/user/profile-picture`, method: 'post' }, payload);
        return res;
    }

    static uploadToAWS = async (url: any, file: any) => {
        let ApiURL = url;
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        instance.put(ApiURL, file, config)
            .then(res => {
                if (res.data != undefined && res.data.data != undefined) {
                    if (res.data.data[0].check_link_expiration == true) {
                        // this.props.history.push('/expired');
                    }
                }
            });
    }

    static fileUpload = async (url: any, file: any) => {
        //const url = 'http://example.com/file-upload';
        // url = url.replace(/^https:\/\//i, 'http://');
        let formData = new FormData();
        formData.append('file', file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return instance.put(url, { body: file }, config);
    }

    /**
 * Upload file to S3 with previously received pre-signed POST data.
 * @param presignedPostData
 * @param file
 * @returns {Promise<any>}
 */
    static uploadFileToS3 = async (url: any, file: any) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            //   Object.keys(presignedPostData.fields).forEach(key => {
            //     formData.append(key, presignedPostData.fields[key]);
            //   });
            // Actual file has to be appended last.
            formData.append("file", file);
            const xhr = new XMLHttpRequest();
            xhr.open("POST", url, true);
            xhr.send(formData);
            xhr.onload = function () {
                this.status === 204 ? resolve(true) : reject(this.responseText);
            };
        });
    };

    static submitRideRating = async (rating: any, userId: any, tripId: any) => {
        const payload = {
            user_id: userId,
            ride_id: tripId,
            rating: rating,
            description: ''
        }
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/ride/rating`, method: 'post' }, payload);
        return res;
    }

    static changePassword = async (payload: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/change-password`, method: 'put' }, payload);
        return res;
    }

    static getNotoficationServices = async () => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/notification/service-name`, method: 'get' }, {});
        return res;
    }

    static getNotoficationEvent = async () => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/notification-event-name`, method: 'get' }, {});
        return res;
    }

    static getNotificationData = async (id: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/notification/${id}`, method: 'get' }, {});
        return res;
    }

    static updateNotificationData = async (payload: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/notification/notification-preference`, method: 'post' }, payload);
        return res;
    }

    static renderPdf = async (payload: any) => {
        let config = {
            headers: {
                // 'Accept': 'application/pdf'
            },
            // responseType: 'blob'
        }
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/generate-pdf/v1`, method: 'post', config:config }, payload);
        return res;
    }

    static downloadPdf = async (id: any) => {
        let res = await httpService<any>({
            url: `${process.env.REACT_APP_API_URL}/download-pdf/${id}`, method: 'get', config: {
                headers: {
                    'Accept': 'application/pdf'
                },
                responseType: 'blob'
            }
        }, {});
        return res;
    }






}