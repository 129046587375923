import { connect } from 'react-redux';
import * as UserService from '../../services/user';
import { GET_ALL_USERS } from '../../reducer/user';
import User from '../../components/user/user';

type Dispatch = (action: any) => void;

/**
 * Get all users from user service.
 */
const getAllUsers = () => {
    return async (dispatch: Dispatch) => {
        let data = await UserService.UserService.getAllUsers();
        if (data !== undefined) {
            dispatch({
                type: GET_ALL_USERS, data: data
            });
        }
    }
}

const saveUser = (user: any, allUsers: any, isUpdated: boolean = false) => {
    return async (dispatch: Dispatch) => {
        if (isUpdated) {

            let userIndex = allUsers.findIndex((el: any) => el.id === user.id);
            allUsers[userIndex].firstName = user.firstName;
            allUsers[userIndex].lastName = user.lastName;
        } else {
            allUsers.push(user);
        }
        dispatch({
            type: GET_ALL_USERS, data: allUsers
        });
    }
}

const mapStateToProps = (state: any) => {
    return {
        allUsers: state.user.allUsers
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getAllUsers: () => dispatch(getAllUsers()),
        saveUser: (user: any, allUsers: any, isUpdated: boolean) => dispatch(saveUser(user, allUsers, isUpdated))
    };
};

const UserContainer = connect(mapStateToProps, mapDispatchToProps)(User);

export default UserContainer;