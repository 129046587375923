import React from 'react';
import { GooglePlaces } from '../../Utilities/GooglePlaces';

const GooglePlacesComponent = (props: any) => {
    return <GooglePlaces
        onPlaceChanged={props.onPlaceChanged}
        placeholder={props.placeHolder}
        className="addressfieldinput custom-input-text"
        value={props.value}
        style={{}}
        onChange={(event: any) => props.handleChange(event)}
        id={props.id}
        showFavorites={(props.showFavorites) ? props.showFavorites : false}
        supportedCountries={props.supportedCountries}
        isGroundOnly={(props.isGroundOnly)?props.isGroundOnly:false}
    />
}
export default GooglePlacesComponent;