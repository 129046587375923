export const USER_REWARDS = "USER_REWARDS";
export const REWARD_POINTS = "REWARD_POINTS";
export const REWARD_POINTS_APPLIED = "REWARD_POINTS_APPLIED";

const defaultState = {
    userRewards: {},
    rewardPoints: 0,
    rewardPointsData: {
        points: 0,
        user_id: 0,
        is_applied: false
    }
}

export function rideRewardsReducer(state: any = defaultState, action: { type: string, data?: any }) {
    switch (action.type) {
        case USER_REWARDS: {
            return {
                ...state,
                userRewards: action.data
            }
        }
        case REWARD_POINTS: {
            return {
                ...state,
                rewardPoints: action.data
            }
        }
        case REWARD_POINTS_APPLIED: {
            return {
                ...state,
                rewardPointsData: action.data
            }
        }
        default:
            return state;
    }
}
