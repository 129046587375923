export const UPDATE_RESERVATION_CHARGES = "UPDATE_RESERVATION_CHARGES";
export const GET_ALL_ADDONS = "GET_ALL_ADDONS";
export const UPDATE_SELECTED_ADDONS = "UPDATE_SELECTED_ADDONS";
export const IS_VEHICLE_CHANGE = 'IS_VEHICLE_CHANGE';
export const UPDATE_MEET_AND_GREET = "UPDATE_MEET_AND_GREET";
export const UPDATE_CHILD_SEAT_VALUES = "UPDATE_CHILD_SEAT_VALUES";
export const GET_AIR_LINES = "GET_AIR_LINES";
export const UPDATE_PROMO_CODE_DATA = "UPDATE_PROMO_CODE_DATA";
export const UPDATE_VALIDATION_DATA = "UPDATE_VALIDATION_DATA";
export const UPDATE_PROMO_APPLIED = "UPDATE_PROMO_APPLIED";
export const GET_CAR_SEAT_TYPES = "GET_CAR_SEAT_TYPES";
export const API_ERROR = "API_ERROR";
export const UPDATE_BOOKING_FOR = "UPDATE_BOOKING_FOR";
export const TRAVEL_OPTION_ADDON = 'TRAVEL_OPTION_ADDON';

const defaultState = {
    reservationCharges: {},
    addonsData: [],
    selectedAddons: [],
    meetAndGreetData: {
        name: '',
        notes: ''
    },
    childSeatValues: [
        {
            id: 0,
            type: 'Select'
        }
    ],
    confirmBookingValidation: {
        primary_passenger: {
            pp_firstName: true,
            pp_lastName: true,
            pp_email: true,
            pp_mobile: true
        },
        secondary_passenger: {
            sp_firstName: true,
            sp_lastName: true,
            sp_email: true,
            sp_mobile: true
        },
        arrival_meet: {
            passenger_name: true
        }
    },
    promoCodeApplied: false,
    apiError: {}
}

export function detailsReducer(state: any = defaultState, action: { type: string, data?: any }) {
    switch (action.type) {
        case UPDATE_RESERVATION_CHARGES: {
            return {
                ...state,
                reservationCharges: action.data
            }
        }
        case GET_ALL_ADDONS: {
            updateLocalStorage('addonsData', action.data);
            return {
                ...state,
                addonsData: action.data
            }
        }
        case UPDATE_SELECTED_ADDONS: {
            updateLocalStorage('selectedAddons', action.data);
            return {
                ...state,
                selectedAddons: action.data
            }
        }
        case IS_VEHICLE_CHANGE: {
            return {
                ...state,
                isVehicleChange: action.data
            }
        }
        case UPDATE_MEET_AND_GREET: {
            updateLocalStorage('meetAndGreetData', action.data);
            return {
                ...state,
                meetAndGreetData: action.data
            }
        }
        case UPDATE_CHILD_SEAT_VALUES: {
            updateLocalStorage('childSeatValues', action.data);
            return {
                ...state,
                childSeatValues: action.data
            }
        }
        case GET_AIR_LINES: {
            return {
                ...state,
                airLines: action.data
            }
        }
        case GET_CAR_SEAT_TYPES: {
            return {
                ...state,
                carSeatTypes: action.data
            }
        }
        case UPDATE_PROMO_CODE_DATA: {
            return {
                ...state,
                promoCodeDetails: action.data
            }
        }
        case UPDATE_VALIDATION_DATA: {
            return {
                ...state,
                confirmBookingValidation: action.data
            }
        }
        case UPDATE_PROMO_APPLIED: {
            return {
                ...state,
                promoCodeApplied: action.data
            }
        }
        case API_ERROR: {
            return {
                ...state,
                apiError: action.data
            }
        }
        case UPDATE_BOOKING_FOR: {
            return {
                ...state,
                booking_for: action.data
            }
        }
        case TRAVEL_OPTION_ADDON: {
            return {
                ...state,
                travel_option_addon: action.data
            }
        }
        default:
            return state;
    }
}

const updateLocalStorage = (key: any, data: any) => {
    // let bookingDetailsLocalRaw = localStorage.getItem('bookingDetails');
    let bookingDetailsLocalRaw =sessionStorage.getItem('bookingDetails');
    
    if (bookingDetailsLocalRaw != '' && bookingDetailsLocalRaw != undefined && bookingDetailsLocalRaw != null) {
        const bookingDetailsLocalParsed: any = JSON.parse(bookingDetailsLocalRaw);
        bookingDetailsLocalParsed[key] = data;
        // localStorage.setItem('bookingDetails', JSON.stringify(bookingDetailsLocalParsed))
        // sessionStorage.setItem('bookingDetails',JSON.stringify(bookingDetailsLocalParsed))
        const clonedObject = JSON.parse(JSON.stringify(bookingDetailsLocalParsed));
        const keyToRemove = 'directions';
        delete clonedObject[keyToRemove];
        sessionStorage.setItem('bookingDetails',JSON.stringify(clonedObject))
    }
}
