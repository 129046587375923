import httpService from "./http-service";


class ConfigurationService {
  async getConfigurationByName(name: any) {
    const URL = `${process.env.REACT_APP_API_URL}/configurations/names/${name}`;

    try {
      const expireTime = await httpService<any>({ url: URL, method: 'get' }, null)
      if (expireTime.data) {
        const { data } = expireTime.data;
        return data;
      }else{
         return 0;
      }
    } catch (error) {
      throw error;
    }
  }

  async getConfigurationByTag(tag: any) {
    const URL = `${process.env.REACT_APP_API_URL}/configurations/tags/${tag}`;

    try {
      const expireTime = await httpService<any>({ url: URL, method: 'get' }, null);
      const { data } = expireTime.data;
      return data? data:{};
    } catch (error) {
      throw error;
    }
  }

  async getConfigurationByCod(cod: any) {
    const URL = `${process.env.REACT_APP_API_URL}/configurations/${cod}`;

    try {
      const expireTime = await httpService<any>({ url: URL, method: 'get' }, null);
      const { data } = expireTime.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getBufferTimeByState(state: string) {
   
    try {
      const URL = `${process.env.REACT_APP_API_URL}/buffer-time?state=${state}`;
      const bufferTimeData = await httpService<any>({ url: URL, method: 'get' }, null);
      const { data } = bufferTimeData.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllConfigurationsByName(names: string) {
    const URL = `${process.env.REACT_APP_API_URL}/configurations/values/${names}`;
  
    try {
      const allConfig = await httpService<any>({ url: URL, method: 'get' }, null);
      const { data } = allConfig.data;
      return data;
    } catch (error) {
      throw error;
    }
  }

}

export default new ConfigurationService();
