import React from 'react';
import Row from 'react-bootstrap/esm/Row';
import dropin from 'braintree-web-drop-in';
import { toast } from 'react-toastify';
import ConfirmPopup from '../modals/confirmation';
import PaymentService from '../../services/PaymentService';
import { Link } from 'react-router-dom';
import redirectToWP from '../../Utilities/common';
var instanceval: any = '';

interface PaymentProps {
    paymentMethods?: any;
    paymentMethodToken?: any;
    clientToken?: any;
    getUserPaymentMethods: (data: any) => any;
    selectedPaymentMethod: (token: any) => any;
    deselectedPaymentMethod: () => any;
    createCustomerToken: (customerId?: any) => any;
    getPaymentNonce: (paymentToken: any) => any;
    onRef: any;
    paymentNonceData: any;
    isUserLogin: boolean;
    deleteSavedCard: (token: string) => any;
    isSaved: (val: any) => any;
    setTermsAndConditions: (val:any) => any;
}

interface PaymentState {
    paymentMethods: any;
    payment_method_token: any;
    clientToken: string;
    showConfirmPopup: boolean;
    cardToken: string;
    previousSelectIndex: any,
    isSelected: any,
    isSelect: any,
    isAction: any,
}

const images = {
    //EXTRAS
    AMEX: require('../../assets/images/cards/amex.png'),
    DISCOVER: require('../../assets/images/cards/discover.png'),
    VISA: require('../../assets/images/cards/visa.png'),
    MASTER_CARD: require('../../assets/images/cards/mastercard.png'),
    DEFAULT: require('../../assets/images/cards/default.png'),
}

class PaymentInfo extends React.Component<PaymentProps, PaymentState>{

    constructor(props: PaymentProps) {
        super(props);
        this.state = {
            paymentMethods: [],
            payment_method_token: '',
            previousSelectIndex: "",
            isSelected: true,
            isSelect: [],
            clientToken: "sandbox_qb7qvh4p_7sj2rscpjv2zd5r8",
            showConfirmPopup: false,
            cardToken: '',
            isAction: [],
        }
    }

    loadPaymentMethods = async () => {
        let isLoggedIn = sessionStorage.getItem('isLoggedIn');
        if (isLoggedIn == 'true') {
            let userInfoRaw = sessionStorage.getItem('user');
            if (userInfoRaw != '' && userInfoRaw != undefined && userInfoRaw != null) {
                let curUserInfo: any = JSON.parse(userInfoRaw);
                if (curUserInfo.id) {
                    this.props.getUserPaymentMethods(curUserInfo.id);
                }
            }
        }
    }

    //#region create drop in
    CreateDropIn = () => {
        // $('#dropin-container').empty();
        dropin.create({
            authorization: this.props.clientToken,
            container: '#dropin-container',
            card: {
                cardholderName: {
                    required: true
                }
            }
        }, (createErr: any, instance: any) => {
            instanceval = instance;
        })
    }

    componentDidMount = async () => {
        await this.props.createCustomerToken();
        await this.loadPaymentMethods();
        this.CreateDropIn();
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }
    componentDidUpdate = async (prevProps: any) => {
        if (prevProps != this.props) {
            if (this.props.isUserLogin && this.props.isUserLogin !== prevProps.isUserLogin) {
                await this.props.createCustomerToken();
                await this.loadPaymentMethods();
            }
        }
    }

    SetPaymentMethodToken = async (token: any, i: any) => {
        let arr = [];
        arr[i] = this.state.previousSelectIndex === i ? false : true;
        arr[this.state.previousSelectIndex] = false;
        if (this.state.previousSelectIndex === i) {
            this.setState(
                {
                    previousSelectIndex: '',
                    isSelect: { ...this.state.isSelect, ...arr },
                    isSelected: true,
                    isAction: [],
                },
                () => {
                    this.props.deselectedPaymentMethod()
                    this.CreateDropIn()
                }
            );
            this.props.isSaved(false)
        } else {
            this.setState(
                {
                    previousSelectIndex: i,
                    isSelect: { ...this.state.isSelect, ...arr },
                    isSelected: false,
                    isAction: [],
                },
                () => {
                    this.props.selectedPaymentMethod(token);
                }
            );
            this.props.isSaved(true)
        }

    }

    confirmDeleteSavedCard = async (token: string) => {
        // show alert popup.
        let showConfirmPopup: boolean = this.state.showConfirmPopup;
        if (!showConfirmPopup) {
            showConfirmPopup = true;
            this.setState({ cardToken: token });
        } else {
            showConfirmPopup = false;
        }
        this.setState({ showConfirmPopup: showConfirmPopup }, () => {
        });
    }

    closeConfirmPopup = async () => {
        this.setState({ showConfirmPopup: false });
    }

    deleteSavedCard = async () => {
        let token: string = this.state.cardToken;
        await PaymentService.deleteSavedCard(token);
        await this.loadPaymentMethods();
        this.setState(
            {
                previousSelectIndex: '',
                isSelect: { ...this.state.isSelect },
                isSelected: true,
                isAction: [],
                showConfirmPopup: false
            },
            () => {
                this.props.deselectedPaymentMethod()
                this.CreateDropIn()
            }
        );
        this.props.isSaved(false)
    }

    //#region create nonce for braintree
    createNonce = async () => {
        const payment_token = this.props.paymentMethodToken;

        // Check for add new card.

        if (this.props.paymentMethods.length === 0 || payment_token == "") {

            if (payment_token == '') {
                return new Promise((resolve, reject) => {
                    if (instanceval != undefined && instanceval != '') {
                        instanceval.requestPaymentMethod((err: any, payload: any) => {

                            if (payload != undefined) {
                                resolve(payload.nonce);
                            } else {
                                toast.error("Please select a payment method !");
                                resolve(0);
                            }
                        });
                    }
                });
            } else {
                return new Promise(async (resolve, reject) => {
                    await this.props.getPaymentNonce(payment_token);
                    resolve(this.props.paymentNonceData);
                });
            }

        }
        else {
            return new Promise(async (resolve, reject) => {
                await this.props.getPaymentNonce(payment_token);
                resolve(this.props.paymentNonceData);
            });
        }
    }
    //#endregion

    resetDropin = async () => {
        instanceval.clearSelectedPaymentMethod();
    }

    renderImage = (cardType: any) => {
        let src = "";
        switch (cardType) {
            case 'AMEX':
                src = images.AMEX;
                break;
            case 'MasterCard':
                src = images.MASTER_CARD;
                break;
            case 'Visa':
                src = images.VISA;
                break;
            case 'Discover':
                src = images.DISCOVER;
                break;
            default:
                src = images.DEFAULT;
        }
        return <img className="pay-card-img" src={src} />
    }

    render() {
        let paymentMethodsUpdated = this.props.paymentMethods;

        let paymentOptions = paymentMethodsUpdated.map((details: any) => {
            let cardObj: any = {};
            cardObj.value = details.token;
            cardObj.label = details.card_type + '- xxxx xxxx xxxx ' + details.card_last4 + '- EXP: ' + details.expiration_month + '/' + details.expiration_year.slice(2, 4);
            return cardObj;
        });

        const addNewObj = {
            value: 'add_new',
            label: 'Add New'
        };
        paymentOptions.push(addNewObj);
        return (
            <div className="mt-0">
                <div className="row">
                    <div className="col-xl-12"><h5> Payment Information </h5></div>
                    {/* <Row className="p-2">
                        <Col xs={4} sm={4} md={4} lg={4}>
                            <Select
                                options={paymentOptions}
                                placeholder="Select Payment"
                                onChange={(event: any) => (event)}
                            />
                        </Col>
                    </Row> */}
                    {
                        (paymentMethodsUpdated && paymentMethodsUpdated.length) ?

                            <>



                                {paymentMethodsUpdated.map((details: any, i: any) => {
                                    // checked={selectedradio}
                                    //this.SetPaymentMethodToken.bind(this, card_token)
                                    return <Row key={'pay_' + details.token} className="float-left payment-card">

                                        <div className="col-4 p-0 col-sm-4" >
                                            <div className="skill-card">
                                                <div>
                                                    {/* <i className="far  fa-credit-card" style={{ fontSize: '40px' }}></i> */}

                                                    <i className='fa fa-trash float-right pointer'
                                                        onClick={() => this.confirmDeleteSavedCard(details.token)}
                                                    ></i>
                                                </div>
                                                <section className="skill-card__body" >
                                                    <Row>
                                                        <h2 className="skill-card__title">
                                                            <label style={{ cursor: "pointer" }}>
                                                                <input name="paymentInfo" type="radio" style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                                                                checked={this.state.isAction[i]
                                                                    ? true
                                                                    : this.state.isSelect[i]
                                                                    ? true
                                                                    : false}
                                                                    onClick={() => this.SetPaymentMethodToken(details.token, i)} value={i} />
                                                                <span style={{ fontSize: '16px', textTransform: 'capitalize', marginLeft: '10px' }}>{details.name_on_card}</span></label>
                                                        </h2>
                                                    </Row>

                                                    <span> {this.renderImage(details.card_type)}</span>&nbsp;&nbsp;<span className="skill-card__duration">xxxx-xxxx-xxxx-{details.card_last4}</span>
                                                    <ul className="skill-card__duration" style={{ padding: '0px', margin: '0px' }}>
                                                        <li> <span>{details.expiration_month + '/' + details.expiration_year.slice(2, 4)}</span></li>
                                                    </ul>

                                                </section>
                                            </div>


                                        </div>


                                    </Row>
                                })}
                            </> : null


                    }
                    {this.state.isSelected ? 

                    <Row style={{ display: 'inline-flex' }}>
                        <div className="col-sm-12" >
                            <div id="dropin-container">

                            </div>
                        </div>
                    </Row>
                    : <></>}
                    <div className="row">
                        <div className="col-xl-12 mb-3">
                            <p style={{ fontSize: '10px', marginLeft: '20px' }}>    <input type="checkbox" className="form-check-input" id="exampleCheck1" style={{marginBottom: 0}}
                                            onClick={(event: any) => this.props.setTermsAndConditions(event.target.checked)}
                                        /> To ensure that the terms of your account are applied and that you have access to your profile and ride data, please be sure to log-in. Please review our <Link className="terms-nav-menu" onClick={() => redirectToWP('TERMS')} to='#'> terms and conditions </Link> for more detail. If you modify or cancel this reservation, you may incur an additional fee. View our cancellation policy <Link className="terms-nav-menu" onClick={() => redirectToWP('TERMS')} to='#'>here</Link>.

By clicking the button below, you agree to LUXY <Link className="terms-nav-menu" onClick={() => redirectToWP('TERMS')} to='#'>Terms & Conditions</Link> and <Link className="terms-nav-menu" to='/privacy-policy'>Privacy Policy</Link>.</p>
                        </div>
                    </div>
                </div>
                {
                    (this.state.showConfirmPopup)
                        ? <ConfirmPopup
                            show={this.state.showConfirmPopup}
                            closeConfirmPopup={this.closeConfirmPopup}
                            title={'Remove'}
                            bodyText={'Would you like to remove this card from the list?'}
                            deleteMethod={this.deleteSavedCard}
                        /> : null
                }
            </div>

        )
    }

}

export default PaymentInfo;