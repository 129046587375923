import { connect } from 'react-redux';
import PaymentSettingsView from '../components/profile/payment-settings';
import { UPDATE_CLIENT_TOKEN, UPDATE_PAYMENT_DATA, UPDATE_PAYMENT_METHOD, UPDATE_PAYMENT_METHODS, UPDATE_PAYMENT_METHOD_DATA, UPDATE_PAYMENT_METHOD_TOKEN } from '../reducer/payment-settings';
import PaymentService from '../services/PaymentService';

type Dispatch = (action: any) => void;

const getUserPaymentMethods = (id: any) => {
    return async (dispatch: Dispatch) => {

        let brainTreeCustomerId = '';

        const customerIdRes = await PaymentService.checkForBraintreeCustomerID(id);
        if (customerIdRes.data && customerIdRes.data.data != null) {
            brainTreeCustomerId = customerIdRes.data.data.customer_id;
            let paymentsData = await PaymentService.getUserPaymentMethods(brainTreeCustomerId);
            if (paymentsData.data && paymentsData.data.data && paymentsData.data.data.length) {
                dispatch({
                    type: UPDATE_PAYMENT_METHODS, data: { paymentMethods: paymentsData.data.data, brainTreeCustomerId: brainTreeCustomerId }
                });
            } else {
                dispatch({
                    type: UPDATE_PAYMENT_METHODS, data: { paymentMethods: [], brainTreeCustomerId: brainTreeCustomerId }
                });
            }
        } else {

            // let createBrainTreeCustomer = await PaymentService.createBrainTreeCustomer();
            // if (createBrainTreeCustomer !== null) {
            //     dispatch({
            //         type: SET_PAYMENT_METHODS, data: { paymentMethods: [], brainTreeCustomerId: createBrainTreeCustomer.data.data.customer_id }
            //     });
            // } else {
            //     dispatch({
            //         type: SET_PAYMENT_METHODS, data: { paymentMethods: [], brainTreeCustomerId: '' }
            //     });
            // }

        }
    }
}

const createCustomerToken = (customerId: any = "") => {
    return async (dispatch: Dispatch) => {
        let customerToken = await PaymentService.createCustomerToken(customerId);

        if (customerToken.data && customerToken.data.data) {
            dispatch({
                type: UPDATE_CLIENT_TOKEN, data: customerToken.data.data.customer_token
            });
        } else {
            dispatch({
                type: UPDATE_CLIENT_TOKEN, data: ''
            });
        }
    }
}

const selectedPaymentMethod = (token: any) => {
    return async (dispatch: Dispatch) => {
        let verifiedResponse = await PaymentService.verifyPaymentMethod(token);
        if (verifiedResponse.data && verifiedResponse.data.data) {
            dispatch({
                type: UPDATE_PAYMENT_METHOD_TOKEN, data: token
            });
        } else {
            dispatch({
                type: UPDATE_PAYMENT_METHOD_TOKEN, data: ''
            });
        }
    }
}

const resetPaymentMethodToken = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_PAYMENT_METHOD_TOKEN, data: ''
        });
    }
}

const createPaymentMethod = (brainTreeCustomerId: any, paymentNonce: any) => {
    return async (dispatch: Dispatch) => {
        let createPaymentMethodData = await PaymentService.createPaymentMethod(brainTreeCustomerId, paymentNonce)


        if (createPaymentMethodData.data && createPaymentMethodData.data.data) {
            dispatch({
                type: UPDATE_PAYMENT_DATA, data: createPaymentMethodData.data.data
            });
            dispatch({
                type: UPDATE_PAYMENT_METHOD_TOKEN, data: createPaymentMethodData.data.data.paymentMethod.token
            });

        } else {
            dispatch({
                type: UPDATE_PAYMENT_DATA, data: {}
            });
        }
    }
}

const savePaymentMethodDetails = (brainTreePaymentData: any, userId: number, brainTreeCustomerId: any) => {
    return async (dispatch: Dispatch) => {
        let savePaymentMethodDetails = await PaymentService.savePaymentMethodDetails(brainTreePaymentData, userId, brainTreeCustomerId);
        if (savePaymentMethodDetails.data && savePaymentMethodDetails.data.data) {
            dispatch({
                type: UPDATE_PAYMENT_METHOD, data: savePaymentMethodDetails.data.data
            });
        } else {
            dispatch({
                type: UPDATE_PAYMENT_METHOD, data: {}
            });
        }
    }
}

const updatePaymentMethodDetails = (payload: any) => {
    return async (dispatch: Dispatch) => {
        let updateDetails = await PaymentService.updateCardDetails(payload);
        if (updateDetails.data && updateDetails.data.data) {
            dispatch({
                type: UPDATE_PAYMENT_METHOD_DATA, data: updateDetails.data.data
            });
        } else {
            dispatch({
                type: UPDATE_PAYMENT_METHOD_DATA, data: {}
            });
        }
    }
}


const mapStateToProps = (state: any) => {
    return {
        paymentMethods: state.paymentSettings.paymentMethods,
        brainTreeCustomerId: state.paymentSettings.brainTreeCustomerId,
        paymentMethodToken: state.paymentSettings.paymentMethodToken,
        clientToken: state.paymentSettings.clientToken,
        paymentNonceData: state.paymentSettings.paymentNonceData,
        paymentCreatedData: state.paymentSettings.createdPaymentData,
        savedPaymentMethodData: state.paymentSettings.savedPaymentMethodData,
        updatePaymentMethodData: state.paymentSettings.updatePaymentMethodData
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getUserPaymentMethods: (id: any) => dispatch(getUserPaymentMethods(id)),
        createCustomerToken: () => dispatch(createCustomerToken()),
        selectedPaymentMethod: (token: any) => dispatch(selectedPaymentMethod(token)),
        createPaymentMethod: (brainTreeCustomerId: any, paymentNonce: any) => dispatch(createPaymentMethod(brainTreeCustomerId, paymentNonce)),
        savePaymentMethodDetails: (brainTreePaymentData: any, userId: number, brainTreeCustomerId: any) => dispatch(savePaymentMethodDetails(brainTreePaymentData, userId, brainTreeCustomerId)),
        updatePaymentMethodDetails: (payload: any) => dispatch(updatePaymentMethodDetails(payload)),
        resetPaymentMethodToken:() => dispatch(resetPaymentMethodToken())
    }
}

const PaymentSettings = connect(mapStateToProps, mapDispatchToProps)(PaymentSettingsView);

export default PaymentSettings;