export const GET_ALL_USERS = "ALL_USERS";
export const USER_LOGIN = "USER_LOGIN";
export const IS_USER_LOGIN = "IS_USER_LOGIN";
export const USER_SIGN_UP = "USER_SIGN_UP";
export const USER_ENROLLED_RIDE_REWARDS = "USER_ENROLLED_RIDE_REWARDS";
export const USER_ENROLLED_RIDE_REWARDS_SIGN_IN = "USER_ENROLLED_RIDE_REWARDS_SIGN_IN";
export const SIGN_IN_RIDE_REWARDS = "SIGN_IN_RIDE_REWARDS";
export const FORGOT_PASS_CODE = "FORGOT_PASS_CODE";
export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const USER_ACTIVATION = "USER_ACTIVATION";
export const IS_GUEST_USER = 'IS_GUEST_USER';
export const IS_RESEND_ACTIVATION_LINK = 'IS_RESEND_ACTIVATION_LINK';
export const UPDATE_PASSENGER_INFO = 'UPDATE_PASSENGER_INFO';
export const UPDATE_AIRLINE_INFO = 'UPDATE_AIRLINE_INFO';
export const SIGN_UP_RIDE_REWARDS = 'SIGN_UP_RIDE_REWARDS';
export const SIGN_UP_DATA = 'SIGN_UP_DATA';
export const SIGN_UP_TOAST = 'SIGN_UP_TOAST';
export const USER_DELETE = 'USER_DELETE';

export function userReducer(state: any = {}, action: { type: string, data?: any }) {
    // console.log("userReducer", action);

    switch (action.type) {
        case GET_ALL_USERS:
            return {
                ...state,
                allUsers: action.data
            };
        case USER_LOGIN:
            return {
                ...state,
                user: action.data
            }
        case IS_USER_LOGIN:
            return {
                ...state,
                isUserLogin: action.data
            }
        case USER_SIGN_UP:
            return {
                ...state,
                isUserSingUp: action.data
            }
        case USER_ENROLLED_RIDE_REWARDS:
            return {
                ...state,
                isEnrolledRideReward: action.data
            }
        case USER_ENROLLED_RIDE_REWARDS_SIGN_IN:
            return {
                ...state,
                isEnrolledRideRewardSignIn: action.data
            }
        case SIGN_IN_RIDE_REWARDS:
            return {
                ...state,
                signInRideRewards: action.data
            }
        case FORGOT_PASS_CODE: {
            return {
                ...state,
                isForgotPassCode: action.data
            }
        }
        case USER_SIGN_OUT: {
            return {
                ...state,
                isUserLogin: action.data
            }
        }
        case USER_ACTIVATION: {
            return {
                ...state,
                userActivationData: action.data
            }
        }
        case IS_GUEST_USER: {
            return {
                ...state,
                isGuestUser: action.data
            }
        }
        case IS_RESEND_ACTIVATION_LINK: {
            return {
                ...state,
                isResendActivation: action.data
            }
        }
        case UPDATE_PASSENGER_INFO: {
            // localStorage.setItem('passengerData', JSON.stringify(action.data));
            sessionStorage.setItem('passengerData', JSON.stringify(action.data));
            return {
                ...state,
                passengerData: action.data
            }
        }
        case UPDATE_AIRLINE_INFO: {
            // localStorage.setItem('airLineInfo', JSON.stringify(action.data))
            sessionStorage.setItem('airLineInfo', JSON.stringify(action.data))
            return {
                ...state,
                airLineData: action.data
            }
        }
        case SIGN_UP_RIDE_REWARDS: {
            return {
                ...state,
                signUpRideRewards: action.data
            }
        }
        case SIGN_UP_DATA: {
            return {
                ...state,
                signUpData: action.data
            }
        }
        case SIGN_UP_TOAST: {
            return {
                ...state,
                signUpToast: action.data
            }
        }
        case USER_DELETE: {
            return {
                ...state,
                isUserDeleted: action.data
            }
        }        
        default:
            return state;
    }
}
