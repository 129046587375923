import { connect } from 'react-redux';
import Details from '../components/details/details';
import { LIST_TYPE, UPDATE_BOOKING_STATE } from '../reducer/book-a-ride';
import { GET_CAR_SEAT_TYPES, GET_AIR_LINES, GET_ALL_ADDONS, IS_VEHICLE_CHANGE, UPDATE_CHILD_SEAT_VALUES, UPDATE_MEET_AND_GREET, UPDATE_PROMO_CODE_DATA, UPDATE_RESERVATION_CHARGES, UPDATE_SELECTED_ADDONS, UPDATE_VALIDATION_DATA,TRAVEL_OPTION_ADDON ,API_ERROR} from '../reducer/details';
import PaymentService from '../services/PaymentService';
import RideService from '../services/RideService';
import { SET_PAYMENT_DATA, SAVE_PAYMENT_METHOD, CREATE_RESERVATION, SET_PAYMENT_METHOD_TOKEN, TRANSACTION_DATA, SET_PAYMENT_METHODS, CREATE_ORDER } from '../reducer/payment-info';
import { IS_GUEST_USER } from '../reducer/user';
import { USER_REWARDS } from '../reducer/ride-rewards';
import { GET_ALL_VEHICLES } from '../reducer/book-a-ride';
import { RideRewardService } from '../services/RideRewardService';
import { toast } from 'react-toastify';
import CommonService from '../services/CommonService';

type Dispatch = (action: any) => void;

const getCharges = (payload: any) => {
    return async (dispatch: Dispatch) => {
        // let chargesData = await RideService.getCharges(payload);
        let chargesData = await RideService.getChargesV5(payload)
        // console.log(chargesData.data, 'chargesData');
        if (chargesData.data && chargesData.data.charges && chargesData.data.charges  != null) {
            // console.log(chargesData.data, 'chargesData');
            if(typeof chargesData.data.charges === 'object' && Object.keys(chargesData.data.charges).length > 0){
                chargesData.data.charges.requestQuoteId = chargesData.data.requestQuoteId
            }
            dispatch({
                type: UPDATE_RESERVATION_CHARGES, data: chargesData.data.charges,
               
            });
            dispatch({
                type : GET_ALL_VEHICLES, data :chargesData.data.serviceRates.vehicle_types
            })
        } else {
            if (chargesData && chargesData.error && chargesData.error.data) {
                const { error_type } = chargesData.error.data;
                dispatch({
                    type: API_ERROR, data: chargesData.error.data
                });
                if (error_type !== 'PROMOCODE') {
                    const message = chargesData.error.data.message || 'Oops something wrong, Please try again';
                    toast.error(message);
                }
                return false;
                // dispatch({
                //     type: UPDATE_RESERVATION_CHARGES, data: chargesData.error
                // });
            }
            const message = 'Oops something wrong, Please try again';
            toast.error(message);
            return false;
            // dispatch({
            //     type: UPDATE_RESERVATION_CHARGES, data: {}
            // });
        }
    }
}

const getAirlines = () => {
    return async (dispatch: Dispatch) => {
        let airlinesData = await RideService.getAirlinesData();


        if (airlinesData && airlinesData.data) {
            dispatch({
                type: GET_AIR_LINES, data: airlinesData.data
            });
        } else {
            dispatch({
                type: GET_AIR_LINES, data: []
            });
        }
    }
}

const getCarSeatTypes = () => {
    return async (dispatch: Dispatch) => {
        let types = await CommonService.getSpecialCarSeatTypes();


        if (types && types.data) {
            dispatch({
                type: GET_CAR_SEAT_TYPES, data: types.data.data
            });
        } else {
            dispatch({
                type: GET_CAR_SEAT_TYPES, data: []
            });
        }
    }
}

const getAddons = () => {
    return async (dispatch: Dispatch) => {
        let addonsData = await RideService.getAddons();
        // console.log(addonsData, 'chargesData');
        if (addonsData.data.data && addonsData.data.data != null) {
            const finalAddons = filterAddons(addonsData.data.data);
            dispatch({
                type: GET_ALL_ADDONS, data: finalAddons?.finalData
            });
            if (finalAddons && finalAddons.travelOption && finalAddons.travelOption.id) {
                dispatch({
                    type: TRAVEL_OPTION_ADDON, data: finalAddons.travelOption
                });
            }
        } else {
            dispatch({
                type: GET_ALL_ADDONS, data: []
            });
        }
    }
}

const filterAddons = (addonsData: any) => {
    if (addonsData && addonsData.length) {
        let travelOption: any;
        const finalData = addonsData.filter((data: any, index: number) => {
            if (data.code && data.code.toUpperCase() === 'TRAVEL_OPTION') {
                travelOption = addonsData[index];
            }
            return data.code && data.code.toUpperCase() !== 'TRAVEL_OPTION';
        });
        return {
            travelOption,
            finalData
        }
    }
}

const updateAddons = (data: any) => {
    let payload = (data && data.length) ? data : [];
    if(payload.length) {
        payload = filterAddons(payload);
    }
    return async (dispatch: Dispatch) => {
        dispatch({
            type: GET_ALL_ADDONS, data: payload.finalData
        });
    }
}

const isChangeVehicle = (data: boolean) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: IS_VEHICLE_CHANGE, data: data
        });
    }
}

const updateSelectedAddons = (data: any) => {
    const payload = (data && data.length) ? data : [];
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_SELECTED_ADDONS, data: payload
        });
    }
}

const updateMeetandGreetData = (data: any) => {


    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_MEET_AND_GREET, data: data
        });
    }
}

const updateChildSeatValues = (data: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_CHILD_SEAT_VALUES, data: data
        });
    }
}

const updateBookingState = (data: any) => {
    console.log("actionData3",data)
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_BOOKING_STATE, data: data
        });
    }
}

const selectedPaymentMethod = (data: string) => {
    return async (dispatch: Dispatch) => {

        dispatch({
            type: SET_PAYMENT_METHOD_TOKEN, data: data
        });

        dispatch({
            type: SET_PAYMENT_METHODS, data: { paymentMethods: [], brainTreeCustomerId: '' }
        });

        dispatch({
            type: UPDATE_PROMO_CODE_DATA, data: {}
        });
    }
}

const createPaymentMethod = (brainTreeCustomerId: any, paymentNonce: any) => {
    return async (dispatch: Dispatch) => {
        let createPaymentMethodData = await PaymentService.createPaymentMethod(brainTreeCustomerId, paymentNonce)


        if (createPaymentMethodData.data && createPaymentMethodData.data.data) {
            dispatch({
                type: SET_PAYMENT_DATA, data: createPaymentMethodData.data.data
            });
            dispatch({
                type: SET_PAYMENT_METHOD_TOKEN, data: createPaymentMethodData.data.data.paymentMethod.token
            });

        } else {
            dispatch({
                type: SET_PAYMENT_DATA, data: {}
            });
        }
    }
}

const getUserPaymentMethods = (id: any) => {
    return async (dispatch: Dispatch) => {

        let brainTreeCustomerId = '';

        const customerIdRes = await PaymentService.checkForBraintreeCustomerID(id);


        if (customerIdRes.data && customerIdRes.data.data != null) {
            brainTreeCustomerId = customerIdRes.data.data.customer_id;
            dispatch({
                type: SET_PAYMENT_METHODS, data: { paymentMethods: [], brainTreeCustomerId: brainTreeCustomerId }
            });

        } else {

            let createBrainTreeCustomer = await PaymentService.createBrainTreeCustomer();


            if (createBrainTreeCustomer !== null) {
                dispatch({
                    type: SET_PAYMENT_METHODS, data: { paymentMethods: [], brainTreeCustomerId: createBrainTreeCustomer.data.data.customer_id }
                });
            } else {
                dispatch({
                    type: SET_PAYMENT_METHODS, data: { paymentMethods: [], brainTreeCustomerId: '' }
                });
            }

        }
    }
}

const savePaymentMethodDetails = (brainTreePaymentData: any, userId: number, brainTreeCustomerId: any) => {
    return async (dispatch: Dispatch) => {
        let savePaymentMethodDetails = await PaymentService.savePaymentMethodDetails(brainTreePaymentData, userId, brainTreeCustomerId);


        if (savePaymentMethodDetails.data && savePaymentMethodDetails.data.data) {
            dispatch({
                type: SAVE_PAYMENT_METHOD, data: savePaymentMethodDetails.data.data
            });
        } else {
            dispatch({
                type: SAVE_PAYMENT_METHOD, data: {}
            });
        }
    }
}

const createReservation = (payload: any) => {
    return async (dispatch: Dispatch) => {
        let createReservationResponse = await PaymentService.createReservation(payload);


        if (createReservationResponse.data && createReservationResponse.data.data) {

            dispatch({
                type: CREATE_RESERVATION, data: createReservationResponse.data.data
            });
        } else {
            dispatch({
                type: CREATE_RESERVATION, data: {}
            });
        }
    }
}


const createOrder = (payload: any) => {
    return async (dispatch: Dispatch) => {
        let createOrderResponse = await PaymentService.createOrder(payload);
        // console.log("Its coming v7", createOrderResponse)
        if (createOrderResponse && createOrderResponse.data && createOrderResponse.data.order_id) {
            dispatch({
                type: CREATE_ORDER, data: createOrderResponse.data
            });
        } else {
            if (createOrderResponse && createOrderResponse.error && createOrderResponse.error.data) {
            dispatch({
                type: CREATE_ORDER, data: createOrderResponse.error.data
            });
            } else{
                dispatch({
                    type: CREATE_ORDER, data: {}
                }); 
            }
        }
    }
}

const createTransaction = (data: any) => {
    return async (dispatch: Dispatch) => {
        let createTransactionResponse = await PaymentService.createTransaction(data);


        if (createTransactionResponse.data) {
            dispatch({
                type: TRANSACTION_DATA,
                data: createTransactionResponse
            });
        } else {
            dispatch({
                type: TRANSACTION_DATA,
                data: createTransactionResponse
            });
        }
        return createTransactionResponse;
    }
}

const updateConfirmBookingValidation = (data: any) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UPDATE_VALIDATION_DATA,
            data: data
        });
    }
}

const sendTripMail = async (transactionData: any, tripId: any) => {
    await PaymentService.sendTripMail(transactionData, tripId);
}

const clearContinueAsGuest = () => {
    let isGuestUser = sessionStorage.getItem('isGuestUser');
    if (isGuestUser == 'true') {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('isGuestUser');
        sessionStorage.removeItem('token');
    }
    sessionStorage.removeItem('showSecondaryPassenger');
    sessionStorage.removeItem('airLineInfo');
    // sessionStorage.removeItem('promoCodeData');
    sessionStorage.removeItem('promoCodeData');
    sessionStorage.removeItem('iamNotPassenger');
    sessionStorage.removeItem('passengerData');

    return async (dispatch: Dispatch) => {
        dispatch({
            type: IS_GUEST_USER, data: false
        });
        dispatch({
            type: GET_ALL_ADDONS, data: []
        });
        dispatch({
            type: UPDATE_SELECTED_ADDONS, data: []
        });


    }
}

const changeListType = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: LIST_TYPE, data: undefined
        });
    }
}

const getUserRewards = () => {
    return async (dispatch: Dispatch) => {
        let userFromLS: any = sessionStorage.getItem('user');
        if (userFromLS) {
            let user = JSON.parse(userFromLS);
            const rewardData: any = await RideRewardService.getUserRewardPoints(user.id);

            if (rewardData && Object.keys(rewardData).length) {
                dispatch({
                    type: USER_REWARDS, data: rewardData
                });
            } else {
                dispatch({
                    type: USER_REWARDS, data: {}
                });
            }
        } else {
            dispatch({
                type: USER_REWARDS, data: {}
            });
        }
    }
}
const mapStateToProps = (state: any) => {
    return {
        bookingDetails: state.bookARide.bookingState,
        reservationCharges: state.details.reservationCharges,
        addonsData: state.details.addonsData,
        selectedAddons: state.details.selectedAddons,
        meetAndGreetData: state.details.meetAndGreetData,
        childSeatValues: state.details.childSeatValues,
        // vehicleListError: state.bookARide.vehicleListError,
        vehiclesList: state.bookARide.allVehicles,
        isVehicleChange: state.details.isVehicleChange,
        brainTreeCustomerId: state.paymentInfo.brainTreeCustomerId,
        paymentCreatedData: state.paymentInfo.createdPaymentData,
        savedPaymentMethodData: state.paymentInfo.savedPaymentMethodData,
        user: state.user.user,
        airLines: state.details.airLines,
        createReservationData: state.paymentInfo.createReservationData,
        invoiceAmountData: state.paymentInfo.invoiceAmountData,
        transactionData: state.paymentInfo.transactionData,
        confirmBookingValidation: state.details.confirmBookingValidation,
        createOrderData: state.paymentInfo.createOrderData,
        isFromGetQuotes: state.bookARide.isGetQuotesClicked,
        listType: state.bookARide.listType,
        isUserLogin: state.user.isUserLogin,
        rewardPoints: state.rideRewards.rewardPoints,
        rewardPointsData: state.rideRewards.rewardPointsData,
        rideRewardsConfigData: state.configurations.rideRewardsConfigData,
        carSeatTypes:state.details.carSeatTypes,
        promoCodeDetails: state.details.promoCodeDetails,
        booking_for: state.details.booking_for
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getCharges: (payload: any) => dispatch(getCharges(payload)),
        getAddons: () => dispatch(getAddons()),
        updateAddons: (data: any) => dispatch(updateAddons(data)),
        updateSelectedAddons: (data: any) => dispatch(updateSelectedAddons(data)),
        isChangeVehicle: (data: boolean) => dispatch(isChangeVehicle(data)),
        updateMeetandGreetData: (data: any) => dispatch(updateMeetandGreetData(data)),
        updateChildSeatValues: (data: any) => dispatch(updateChildSeatValues(data)),
        updateBookingState: (data: any) => dispatch(updateBookingState(data)),
        getAirlines: () => dispatch(getAirlines()),
        getCarSeatTypes:() =>dispatch(getCarSeatTypes()),
        createPaymentMethod: (brainTreeCustomerId: any, paymentNonce: any) => dispatch(createPaymentMethod(brainTreeCustomerId, paymentNonce)),
        savePaymentMethodDetails: (brainTreePaymentData: any, userId: number, brainTreeCustomerId: any) => dispatch(savePaymentMethodDetails(brainTreePaymentData, userId, brainTreeCustomerId)),
        createReservation: (payload: any) => dispatch(createReservation(payload)),
        createTransaction: (data: any) => dispatch(createTransaction(data)),
        sendTripMail: (transactionData: any, tripId: any) => sendTripMail(transactionData, tripId),
        updateConfirmBookingValidation: (data: any) => dispatch(updateConfirmBookingValidation(data)),
        selectedPaymentMethod: (data: string) => dispatch(selectedPaymentMethod(data)),
        getUserPaymentMethods: (userId: any) => dispatch(getUserPaymentMethods(userId)),
        clearContinueAsGuest: () => dispatch(clearContinueAsGuest()),
        createOrder: (payload: any) => dispatch(createOrder(payload)),
        changeListType: () => dispatch(changeListType()),
        getUserRewards: () => dispatch(getUserRewards())
    }
}

const DetailsContainer = connect(mapStateToProps, mapDispatchToProps)(Details);

export default DetailsContainer;