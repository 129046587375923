import React, { Component } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import { StatusCode } from '../../constants/status-codes';
import CommonService from '../../services/CommonService';
import CorporateForm from './corporate-form';
import logo from '../../assets/images/Image 6@2x.png';
import Alert from 'react-bootstrap/esm/Alert';
import './corporate.css';

import ReactGA from 'react-ga';
import { googleEventAnalytics } from '../../constants/app-constants';
import { createEventGA4 } from '../../Utilities/helpers';

interface CorporateProps {
    closeModal: any;
}

interface CorporateState {
    showModal: boolean;
    corporateErr: boolean;
    corporateErrMsg: string;
    isSuccess: boolean;
}

class Corporate extends Component<CorporateProps, CorporateState> {

    constructor(props: CorporateProps) {
        super(props);
        this.state = {
            showModal: false,
            corporateErr: false,
            corporateErrMsg: '',
            isSuccess: false

        }
    }

    componentDidMount() {
    }

    handleJoinCorporate = () => {
        this.setState({
            showModal: true,
            corporateErr: false,
            corporateErrMsg: '',
            isSuccess: false
        });
    }

    closeModal = () => {
        this.props.closeModal();
        this.setState({ showModal: false });
    }

    handleSaveCorporate = async (payload: any) => {
        let response: any = await CommonService.saveCompany(payload);

        if (response.data.status === StatusCode.EVERYTHING_IS_OK) {
            this.setState({
                showModal: true,
                corporateErr: false,
                corporateErrMsg: '',
                isSuccess: true
            });
            ReactGA.event(googleEventAnalytics.corporate);
        } else {
            this.setState({
                showModal: true,
                corporateErr: true,
                corporateErrMsg: response.data.message,
                isSuccess: false
            });
        }
    }

    renderModalPopup = () => {
        if (this.state.isSuccess) {
            const dataLayerObj: any = {
                'eventName': 'form_submit_success',
                'formName': 'Corporate',
                'event': 'formSubmitSuccess'
            };
            createEventGA4(dataLayerObj);
        }
        return <Modal show={true} style={{ top: '50px' }} >
            <div className="float-right">
                <i className="fa fa-times float-right pointer"
                    onClick={() => this.closeModal()}
                    title="close"></i>
            </div>
            <Modal.Body>
                <div>
                    <div className="modal-img">
                        <img src={logo}
                            width="180"></img>
                    </div>
                </div>
                {
                    (!this.state.isSuccess) ? <CorporateForm saveCorporate={this.handleSaveCorporate}
                        corporateErr={this.state.corporateErr}
                        corporateErrMsg={this.state.corporateErrMsg} /> : <div>
                        <div className="sign-in">
                            <Alert variant='success'>
                                <b>Success!</b> Thank you signing up for our LUXY™ Corporate program. Check your email and click the link to activate your account.
                            </Alert>
                        </div>

                    </div>
                }

            </Modal.Body>
        </Modal>
    }



    render() {
        return (
            <div>
                {/* <GetLuxy />
                <div className='corporate-1'>

                    <div className="corporate_img_BANNER text-center   p-4" >
                        <h1 style={{ textAlign: 'center', color: '#fff', marginTop: '115px' }} >JOIN OUR CORPORATE PROGRAM</h1>
                        <div style={{ color: '#fff' }} className="footerbottom mx-auto">
                            As a travel arranger your goal is to make life easier for your clients by helping them manage their travel programs and itineraries. At LUXY™, <b> we're here to help you do that </b> - and make your life a little easier, too.
                    </div>
                    </div> */}

                {/* <Row className='row-1'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 about-heading'>
                            <h1 className='text-center'>
                                JOIN AS A CORPORATE
                                </h1>
                            <p>
                            As a travel corporate client, your goal is to make life easier for your clients by helping them manage their travel 
                            programs and itineraries. 
                            LUXY, <b>we’re here to help you do that</b> — and to make your life a little easier, too.
                            </p>
                        </div>
                    </Row>
                    <img style={{ width: '100%', height: '50vh' }} src={corporate_1} alt='' /> */}
                {/* </div>
                <div className='corporate-2'>
                    <Row className='row mt-5'>
                        <div className='col-12 col-sm-6 text-center col-md-6 col-lg-6'>
                            <img src={commissions} alt='About Car' className="img-fluid" />
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 pt-3'>
                            <h2 className='cor-p'>Manage Your Dashboard</h2>
                            <p className='cor-p'>
                                Our simple, two-step process requires only moments to book and confirm your clients' rides all right from the LUXY™ Concierge dashboard. Add as many clients as you'd like and manage their profiles with just a click.
                            </p>
                        </div>

                    </Row>
                    <hr className="mt-5"></hr>
                    <Row className='row mt-5'>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 pt-3'>
                            <h2 className='cor-p2'>Book Anywhere, Anytime</h2>
                            <p className='about-p2'>
                                Near On-Demand or well in-advance, we provide service in over 100+ major airports, 500+ cities and all 50 states in the US. Last minute travel plans or the annual end of year event, we've got you covered.
                            </p>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6'>
                            <img src={corporate_2} alt='About Car' className="img-fluid"/>
                        </div>
                    </Row>
                </div>
                <div className='corporate-3'>
                    <Row className='row-1'>
                        <h4>Join Us Today</h4>
                        <p>
                            The power to seamlessly complete the final step in your travel planning.
                            </p>
                        <div>
                            <Button variant="submit"
                                onClick={() => this.handleJoinCorporate()}
                            >JOIN US</Button>
                        </div> */}

                {
                    this.renderModalPopup()
                }
                {/* </Row>
                </div> */}
            </div>

        )
    }
}

export default Corporate;

