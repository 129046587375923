import httpService from "./http-service";


class PaymentService {
    getUserPaymentMethods = async (brainTreeCustomerId: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/payments/payment-methods/${brainTreeCustomerId}`, method: 'get' }, null);
        return res;
    }

    checkForBraintreeCustomerID = async (id: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/payments/customer/${id}`, method: 'get' }, null);
        return res;
    }

    verifyPaymentMethod = async (token: any) => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/payments/payment-method/${token}`, method: 'get' }, null);
        return res;
    }

    createCustomerToken = async (customerId: any = "") => {
        let res = await httpService<any>({ url: `${process.env.REACT_APP_API_URL}/payments/customer-token`, method: 'post' }, {});
        return res;
    }

    getPaymentNonce = async (payment_token: any) => {
        let res = await httpService<any>({
            url: `${process.env.REACT_APP_API_URL}/payments/nonce/${payment_token}`,
            method: 'get'
        },
            {});
        return res;
    }

    createPaymentMethod = async (brainTreeCustomerId: any, paymentNonce: any) => {
        let res = await httpService<any>({
            url: `${process.env.REACT_APP_API_URL}/payments/payment-method/${brainTreeCustomerId}/${paymentNonce}`,
            method: 'post'
        }, {});
        return res;
    }

    savePaymentMethodDetails = async (brainTreePaymentData: any, userId: number, brainTreeCustomerId: any) => {
        let payload: any = {
            booking_user_id: userId,
            transaction_id: null,
            created_at: brainTreePaymentData.createdAt,
            customer_id: brainTreeCustomerId,
            payment_instrument_type: '',
            credit_card_type: brainTreePaymentData.cardType,
            card_expiration_month: brainTreePaymentData.expirationMonth,
            card_expiration_year: brainTreePaymentData.expirationYear,
            card_last4_digits: brainTreePaymentData.last4,
            card_masked_digits: brainTreePaymentData.maskedNumber,
            card_token: brainTreePaymentData.token,
            card_holder_name: brainTreePaymentData.cardholderName
        };
        let res = await httpService<any>({
            url: `${process.env.REACT_APP_API_URL}/payments/card-details`,
            method: 'post'
        }, payload);
        return res;
    }

    createReservation = async (payload: any) => {
        // `${process.env.REACT_APP_API_URL}/reservation`

        let res = await httpService<any>({
            url: `${process.env.REACT_APP_API_URL}/reservation/v2`,
            method: 'post'
        }, payload);
        return res;
    }

    createOrder = async (payload: any) => {
        // `${process.env.REACT_APP_API_URL}/reservation`

        let res = await httpService<any>({
            url: `${process.env.REACT_APP_API_URL}/orders/v4`,
            method: 'post'
        }, payload);
        return res;
    }

    createTransaction = async (data: any) => {
        
        let res = await httpService<any>({
            url: `${process.env.REACT_APP_API_URL}/payments/create/transaction/${data.amount.totalAmount}/${data.paymentNonce}/${data.userId}/${data.brainTreeCustomerId}/${data.createReservationData.id}/${data.createReservationData.order_id}/false`,
            method: 'post'
        }, {});
        return res;
    }

    createBrainTreeCustomer = async () => {
        
        let user: any = sessionStorage.getItem('user');

        if (user !== '' && user !== null) {
            user = JSON.parse(user);
            let payload = {
                user_id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                email_address: user.email_address,
                mobile_number: user.mobile_number
            }
            let res = await httpService<any>({
                url: `${process.env.REACT_APP_API_URL}/payments/customer`,
                method: 'post'
            }, payload);
            return res;
        }
        return null;
    }

    sendTripMail = async (transactionData: any, tripId: any) => {
        

        let payload = {
            trip_id: tripId,
            card_type: transactionData.data.transaction.creditCard.cardType,
            last_4digit: transactionData.data.transaction.creditCard.last4
        }
        //`${process.env.REACT_APP_API_URL}/trip/send-mail`
        let res = await httpService<any>({
            url: `${process.env.REACT_APP_API_URL}/trip/send-mail/v2`,
            method: 'post'
        }, payload);
        return res;
    }

    deleteSavedCard = async (token: string) => {
        let res = await httpService<any>({
            url: `${process.env.REACT_APP_API_URL}/payments/payment-method/${token}`,
            method: 'DELETE'
        }, {});
        return res;
    }

    updateCardDetails = async (payload: any) => {
        let res = await httpService<any>({
            url: `${process.env.REACT_APP_API_URL}/payments/payment-method`,
            method: 'put'
        }, payload);
        return res;
    }

    processVendorPayments = async (payload: any) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/vendors/payments`;
            let data = await httpService<any>({ url: url, method: 'post'}, payload);

            if(data && data.data) {
                return data.data;
            }else {
                return data;
            }
            
        } catch (err) {

            throw err;
        }
    }

    getVendorPayments = async (payload: any) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/vendors/payments`;
            let data = await httpService<any>({ url: url, method: 'get'}, payload);

            if(data && data.data) {
                return data.data;
            }else {
                return data;
            }
            
        } catch (err) {

            throw err;
        }
    }
}

export default new PaymentService();