import React, { Component } from 'react';
import { Alert, Button, Col, FormControl, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { StatusCode } from '../../constants/status-codes';
import { FORGOT_PASS_CODE } from '../../reducer/user';
import { AuthService } from '../../services/auth';
import { SignInError } from '../../constants/validations';
import * as Helpers from '../common/helpers';

interface ForgotPasswordProps {
    forgotPassCode: (payload: any) => any;
}

interface ForgotPasswordState {
    email: string;
    forgotError: boolean;
    errorObj: any;
    emailErr: boolean;
}

class ForgotPassword extends Component<ForgotPasswordProps, ForgotPasswordState> {
    constructor(props: ForgotPasswordProps) {
        super(props);
        this.state = {
            email: '',
            forgotError: false,
            errorObj: {},
            emailErr: false
        }
    }

    validate = () => {
        (this.state.email.trim() === '' || !Helpers.validateEmail(this.state.email)) ? this.setState({ emailErr: true })
            : this.setState({ emailErr: false });
    }

    forgotPassCode = async () => {
        await this.validate();
        if (!this.state.emailErr) {
            const payload = {
                email_address: this.state.email
            }
            let response = await this.props.forgotPassCode(payload);

            if (response.status === StatusCode.CONFLICT) {

                this.setState({ forgotError: true, errorObj: response });
            }
        }

    }

    handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            this.forgotPassCode()
        }
    }

    updateEmail = (event: any) => {
        this.setState({ email: event.target.value });
        let isValid = Helpers.validateEmail(event.target.value);
        this.setState({ emailErr: !isValid });
    }


    render() {
        return (
            <>
                <div className="sign-in">
                    {/* <div className="modal-img">
                        <img src={logo}
                            width="180"></img>
                    </div> */}
                    {
                        (this.state.forgotError) &&
                        <Alert variant='danger'>
                            <b>Error!</b> {this.state.errorObj.message}
                        </Alert>

                    }

                    <Row>

                        <Col>
                            <p> Reset Your Password </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="email" className="float-left">EMAIL <span className="importantfields">*</span></label>
                                <FormControl
                                    id="email"
                                    placeholder={"Enter Email Address"}
                                    onChange={(event: any) =>
                                        this.updateEmail(event)
                                    }
                                    value={this.state.email}
                                    onKeyPress={(event: any) => this.handleKeyPress(event)}
                                />
                                <div>
                                    <p className="text-danger">{
                                        (this.state.emailErr) ?
                                            SignInError.emailError
                                            : null
                                    }</p>
                                </div>
                            </div>
                            <p> You will receive an email with instructions on how to reset your password. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                        <Col>
                            <Button
                                onClick={() => this.forgotPassCode()}
                                variant="submit float-right">Send Email</Button>{' '}
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

type Dispatch = (action: any) => void;

const mapStateToProps = (state: any) => {
    return {
        isForgotPassCode: state.user.isForgotPassCode
    };
};

const forgotPassCode = (payload: any) => {
    return async (dispatch: Dispatch) => {
        let data = await AuthService.forgotPassCode(payload);
        

        if (data.status === StatusCode.EVERYTHING_IS_OK) {
            dispatch({
                type: FORGOT_PASS_CODE, data: true
            });
        }
        return data;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        forgotPassCode: (payload: any) => dispatch(forgotPassCode(payload))
    };
};

const ForgotPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

export default ForgotPasswordContainer;