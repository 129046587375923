import React from 'react';
import { FAV_ADD_TYPE } from '../../Consts';

interface FavAddressProps {
    onSelection: any;
    suggestion: any;
}
const Child = (props: FavAddressProps) => {

    const getIconType = (type: string) => {
        let iClassName = "";
        switch (type) {
            case FAV_ADD_TYPE.AIRPORT:
                iClassName = 'fa fa-plane';
                break;
            case FAV_ADD_TYPE.HOME:
                iClassName = 'fa fa-home'
                break;
            case FAV_ADD_TYPE.WORK:
                iClassName = 'fa fa-briefcase';
                break;
            default:
                iClassName = 'fa fa-heart-o';
                break;
        }
        return <div style={{
            fontSize: '15px',
            minWidth: 26,
            width: 26,
            height: 26, borderRadius: '50%',
            alignItems: 'center',
            justifyContent: 'center', backgroundColor: '#ED8002'
        }}><i style={{ alignItems: 'center', marginLeft: '5px', marginTop: '6px', color: 'white' }}
            className={iClassName + ' favI'} aria-hidden="true"></i>
        </div>

    }

    return (
        <div>

            <li
                onClick={() => props.onSelection(props.suggestion)}
            >
                <div>
                   <div style={{ display: "flex" }}> {getIconType(props.suggestion.type_desc)}
               <p style={{marginTop: '0px'}}>&nbsp; &nbsp; {props.suggestion.full_address} </p></div>
                    {/* {(props.suggestion.full_address.length > 40) ? '...' : ''} */}
                    {/* {props.suggestion.description} */}
                </div>
            </li>

        </div>
    )
};
export default Child;