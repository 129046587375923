/**
 * All API related end points will be configured in this file.
 * 
 */

export const Auth = {
    RefreshToken: '/user/refreshToken',
    GetAccessToken: '/user/login',
    GetPostsData: '/posts',
    SignIn: '/v2/login',
    SignUp: '/customer/register/web',
    SignUpV2: '/customer/register/v2',
    ForGotPassCode: '/customer/forget',
    GuestToken: '/guest-token',
    UserActivation: '/user/get-details-to-activate',
    UpdateUserActivation: '/user/activate-account'
}

export const RideRewards = {
    GET_REWARDS: '/ride-rewards'
}

export const Navigation = {
    HeaderMenus: '/header-menu'
}