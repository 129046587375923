import React from 'react';
import Accordion from 'react-bootstrap/Accordion'
import { Card, Col, Container, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import Period from '../../assets/images/period.svg';
import arrival from '../../assets/images/landing.svg';
import departure from '../../assets/images/departure.svg';
import ground from '../../assets/images/ground.svg';
import shield from '../../assets/images/shield.svg';
import { formatDuration } from '../../services/AddressService';
import sedan from '../../assets/images/icn_sedan.svg';
import suv from '../../assets/images/icn_suv.svg';
import suvXL from '../../assets/images/icn_suv_xl.svg';

import iconCalendar from '../../assets/images/icon_calendar.svg';
import clock from '../../assets/images/clock.svg';
import plane from '../../assets/images/plane.svg';
import user from '../../assets/images/user.svg';
import email from '../../assets/images/email.svg';
import telephone from '../../assets/images/telephone.svg';
import checked from '../../assets/images/checked.svg';
import luggage from '../../assets/images/luggage.svg';
import star from '../../assets/images/star.svg';
import Rating from 'react-simple-star-rating';
import iconCar from '../../assets/images/Icon_car_side.svg';
import invoice from '../../assets/images/Icon_invoice.svg';
import web from '../../assets/images/web.png';
import phone from '../../assets/images/phone.png';
import concierge from '../../assets/images/concierge.png';
import ground_span from '../../assets/images/ground_span.png';
import ta from '../../assets/images/ta.png';
import mobile from '../../assets/images/mobile.png';

// import jsPDF from "jspdf";
import PdfContent from './pdf';
import html2canvas from 'html2canvas';

const ProfileImg = require('../../assets/images/user-profile.png');


interface TripProps {
    trip: any;
    index: number;
    type: String;
    addonsList: any
    handleTripRating: (rating: any, tripId: any) => any;
    handlePdfDownload: (data: any) => any;
}

interface TripState {
    showModal: boolean,
    tripSummary: any,
    renderPrint: boolean,
    rating: number
}

class Trip extends React.Component<TripProps, TripState>{
    invoice: any;
    constructor(props: TripProps) {
        super(props);
        this.setRef = this.setRef.bind(this);
        this.state = {
            showModal: false,
            tripSummary: null,
            renderPrint: false,
            rating: 0
        }
    }

    setRef(input: any) {
        this.invoice = input;
    }

    showTripSummary = (trip: any) => {
        this.setState({
            showModal: true,
            tripSummary: trip
        });
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            tripSummary: null
        })
    }

    renderAddon = (addon: any) => {
        if(addon.id == 3) {
            return;
        }
        const addonsList = this.props.addonsList;
        const list = addonsList && addonsList.length ? addonsList.filter((item: any) => item.id == addon.id) : [];
        
        return <Col key={'addon_' + addon.id} className="col-auto">  <p className="summanry-data"> <img src={checked} height="15px" width="15px" className="mr-2" /> {list  && list.length && list[0].addon_name}</p></Col>

    }

    renderChildSeatAddon = (childSeattype: any) => {
        return <><p className="summanry-data"> {childSeattype.type}</p></>
    }


    renderSummaryContent = async (trip: any, vehicleTypeSrc: any) => {
        return <PdfContent
            reference={this.setRef}
            trip={trip} vehicleTypeSrc={vehicleTypeSrc} addonsList={this.props.addonsList} />
    }

    handleRating = async (rate: any) => {
        this.setState({
            rating: rate
        }, async () => {
            await this.props.handleTripRating(rate, this.props.trip.id);
        })

    }

    renderTripSummary = () => {
        const trip = this.state.tripSummary;
        const vehicleTypeSrc = (trip.vehicle_type_name == 'Sedan') ? sedan : (trip.vehicle_type_name == 'SUV') ? suv : suvXL;
        const dr_rating = (trip.dr_rating && trip.dr_rating != null) ? parseFloat(trip.dr_rating).toFixed(1) : '4.8';
        const delta_base_price = (trip && trip.delta_base_price )? trip.delta_base_price!=='0.00' ? trip.delta_base_price : 0 : 0
        const childSeactArr = trip && trip.extra_services.filter((extra : any) => { 
            if(extra.id == '3') {
                return extra;
            } 
        })
        return <Modal size="lg"
            show={true} style={{ top: '50px' }} className="summary-content">
            <div className="float-right">
                <i className="fa fa-times float-right pointer"
                    onClick={() => this.closeModal()}
                    title="close"></i>
            </div>
            <Modal.Header>
                <h4 className="payment-span-text"> Trip Summary </h4>
            </Modal.Header>
            <Modal.Body style={{ alignItems: 'center' }}>
                <Container >

                    <Row className="mb-2">
                        <Col className="col-auto">  <p className="summanry-data"> Booking Id :  {trip.booking_id}</p></Col>
                        <Col className="col-auto"> <p className="summanry-data"> </p></Col>
                        <Col className="col-auto"> <p className="summanry-data"> </p></Col>


                    </Row>
                    <Row>
                        <Col className="col-auto" >
                            <div className="summary-card">
                                <Row>
                                    <Col><h6>Driver Profile</h6></Col>
                                </Row>
                                <Row>
                                    <Col className="col-auto" >
                                        <img src={ProfileImg} height="20px" width="20px" />
                                    </Col>
                                    <Col className="col-auto" >
                                        {
                                            (trip.driver_id) ? <> <span className="summanry-data"> {(trip.driver_name && trip.driver_name != '') ? trip.driver_name : 'N/A'} </span> <br />
                                                <small>{(trip.affiliate_company_name) ? trip.affiliate_company_name : 'N/A'}</small> </> : 'N/A'
                                        }
                                    </Col>
                                    <Col className="col-auto">
                                        <img src={star} height="15px" width="15px" className="mr-2" />  <span className="summanry-data">
                                            {(trip.driver_id) ? dr_rating : 'N/A'}
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-auto">
                                        <img src={vehicleTypeSrc} height="15px" width="15px" className="mr-2" />
                                        <span className="summary-mini-text">{(trip.driver_id && trip.vehicle_type.vehicle_type_name) ? trip.vehicle_type.vehicle_type_name : 'N/A'}</span>
                                    </Col>
                                    <Col className="col-auto">
                                        <span className="summary-mini-text">  {(trip.color) ? <span>{trip.color} </span> : 'N/A'}</span>
                                    </Col>
                                    <Col className="col-auto">
                                        <span className="summary-mini-text"> {(trip.year) ? trip.year : 'N/A'} </span>
                                    </Col>
                                    <Col className="col-auto">
                                        <img src={user} height="15px" width="15px" className="mr-2" /> { (trip.driver_id) ? trip.vehicle_type.number_of_seats: 'N/A'}
                                    </Col>
                                </Row>

                            </div>
                        </Col>

                        <Col className="col-auto">
                            <div className="summary-card">
                                <Row>
                                    <Col> <h6>Trip Date & Time</h6></Col>
                                </Row>
                                <Row>
                                    <Col className="col-auto">
                                        <img src={iconCalendar} height="15px" width="15px" className="mr-2" />
                                    </Col>
                                    <Col className="col-auto">
                                        <span className="summanry-data">  {moment.tz(trip.pick_up_date, trip.pick_up_address.timezone).format('MMM D, YYYY')} </span>

                                    </Col>
                                    <Col className="col-auto">
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-auto">
                                        <img src={clock} height="15px" width="15px" className="mr-2" />
                                    </Col>
                                    <Col className="col-auto">
                                        <span className="summanry-data">  {moment.tz(trip.pick_up_date, trip.pick_up_address.timezone).format('hh:mm A')} </span>
                                    </Col>
                                    <Col className="col-auto">

                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        (trip.is_ride_safe && trip.is_ride_safe != null) ? <>
                                            <Col className="col-auto">
                                                <img src={shield} height="15px" width="15px" className="mr-2" />
                                            </Col>
                                            <Col className="col-auto">

                                                <span className="summanry-data text-success"> RIDE SAFE</span>
                                            </Col>
                                            <Col className="col-auto">

                                            </Col></> : null
                                    }
                                </Row>

                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col> <h6 className="payment-span-text float-left"> Trip Details </h6></Col>
                    </Row>

                    <Row>
                        <Col className="col-auto">
                            <div className="treeview"></div>
                            <Row className="align-items-left no-gutters">
                                <div className="col-auto">
                                    <img className="period-icon  img-fluid  img-fluid" src={Period} />
                                </div>
                                <div className="col ">
                                    <span className="summary-mini-text">Pick Up Address</span>
                                    <p className="summanry-data">{trip.pick_up_address.address.full_address}</p>

                                </div>
                            </Row>

                            {
                                (trip.extra_stop_address && trip.extra_stop_address.length) ? trip.extra_stop_address.map((stop: any, index: number) => {
                                    return <Row key={'extra_' + index} className="align-items-left no-gutters">
                                        <div className="col-auto">
                                            <img className="period-icon  img-fluid  img-fluid" src={Period} />
                                        </div>
                                        <div className="col ">
                                            <span className="summary-mini-text">Extrastop {stop.stop_number}</span>
                                            <p className="summanry-data">{stop.address.full_address}</p>
                                        </div>
                                    </Row>
                                }) : null
                            }

                            <Row className="align-items-left no-gutters">
                                <div className="col-auto">
                                    <img className="period-icon  img-fluid  img-fluid" src={Period} />
                                </div>
                                <div className="col ">
                                    <span className="summary-mini-text">Dropoff Address</span>
                                    <p className="summanry-data">{trip.drop_off_address.address.full_address}</p>
                                </div>
                            </Row>
                        </Col>
                        <Col className="col-auto">
                            <Row>
                                <div className="col col-auto">
                                    <span className="summary-mini-text">Trip Duration</span>

                                    <p className="summanry-data">
                                        <img src={clock} height="15px" width="15px" className="mr-2" />
                                        {formatDuration(trip.ride_total_hours)} (hrs)
                            </p>
                                </div>
                            </Row>
                            <Row>
                                <div className="col col-auto">
                                    <span className="summary-mini-text">Flight Information</span>

                                    <p className="summanry-data">
                                        <img src={plane} height="15px" width="15px" className="mr-2" />
                                        {(trip.airline_name != '') ? trip.airline_name + ' ' + trip.flight_number : 'N/A'} </p>
                                </div>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col> <h6 className="payment-span-text float-left"> Primary Passenger Details </h6></Col>
                    </Row>

                    <Row>
                        <Col className="col-auto">  <p className="summanry-data"> <img src={user} height="15px" width="15px" className="mr-2" /> {trip.passenger_name}</p></Col>
                        <Col className="col-auto"> <p className="summanry-data"> <img src={email} height="15px" width="15px" className="mr-2" /> {trip.passenger_email}</p></Col>
                        <Col className="col-auto"> <p className="summanry-data"> <img src={telephone} height="15px" width="15px" className="mr-2" />  {trip.passenger_mobile}</p></Col>
                    </Row>

                    {/* based on the key we need to add condition. */}
                    {
                        (trip.secondary_passenger_email != null && trip.secondary_passenger_email != '') ? <>
                            <Row className="mt-2">
                                <Col> <h6 className="payment-span-text float-left"> Secondary Passenger Details </h6></Col>
                            </Row>

                            <Row>
                                <Col className="col-auto"> <p className="summanry-data"> <img src={user} height="15px" width="15px" className="mr-2" /> {trip.secondary_passenger_name}</p></Col>
                                <Col className="col-auto"> <p className="summanry-data"><img src={email} height="15px" width="15px" className="mr-2" />  {trip.secondary_passenger_email}</p></Col>
                                <Col className="col-auto"> <p className="summanry-data"><img src={telephone} height="15px" width="15px" className="mr-2" /> {trip.secondary_passenger_mobile}</p></Col>
                            </Row>
                        </> : null
                    }



                    {
                        (trip.trip_protection || (trip.extra_services != null && trip.extra_services.length)) ? <>
                            <Row className="mt-2">
                                <Col> <h6 className="payment-span-text float-left">Extra Services </h6></Col>
                            </Row>
                            <Row>
                                {
                                    (trip.trip_protection) ? <Col className="col-auto">  <p className="summanry-data">
                                        <img src={checked} height="15px" width="15px" className="mr-2" />
                Trip Protection </p></Col> : null
                                }

                                {
                                    (trip.extra_services != null && trip.extra_services.length) ? trip.extra_services.map((service: any) => {
                                        return (service.id) ? this.renderAddon(service) : null;
                                    }) : null
                                }
                            </Row>
                            {
                                    (childSeactArr && childSeactArr.length && childSeactArr[0].value && childSeactArr[0].value.length) ?
                                    <>
                                    <Col key='child_seat' className="col-auto">  <p className="summanry-data"> <img src={checked} height="15px" width="15px" className="mr-2" /> Child Seat</p>
                                    <div style={{display: 'flex',
                                        marginLeft: '27px',
                                        flexWrap: 'wrap',
                                        marginTop: -8,
                                        opacity: 0.7,
                                        columnGap: 20
                                    }}>
                                    { childSeactArr && childSeactArr.length && childSeactArr[0].value.map((service: any) => {
                                        return (service.id) ? 
                                            <>
                                            {this.renderChildSeatAddon(service)}
                                            </>
                                        : null;
                                    })} 
                                    </div>
                                    </Col>
                                    </>: null
                                }
                        </> : null
                    }


                    <Row className="mt-2">
                        <Col> <h6 className="payment-span-text float-left">Vehicle Details </h6></Col>
                    </Row>
                    <Row>
                        <Col className="col-auto"> <p className="summanry-data">
                            <img src={vehicleTypeSrc} height="15px" width="15px" style={{ marginRight: '5px' }} />
                            {trip.vehicle_type_name}</p></Col>
                        <Col className="col-auto">  <p className="summanry-data">
                            <img src={user} height="15px" width="15px" className="mr-2" />
                            {trip.vehicle_type.number_of_seats} Max</p></Col>
                        <Col className="col-auto">  <p className="summanry-data">
                            <img src={luggage} height="15px" width="15px" className="mr-2" />
                            {trip.vehicle_type.number_of_luggage} Max</p></Col>
                    </Row>
                    {
                        (!trip.is_from_travel_arranger)
                         ?
                         <>
                    <Row className="mt-2">
                        <Col> <h6 className="payment-span-text float-left">Charges </h6></Col>
                    </Row>
                    <Row>
                        <Col xs={6}>Trip Price</Col>
                        <Col xs={6}><span className="summanry-data">{trip.base_price}</span></Col>
                    </Row>
                    {
                        (trip.extra_services && trip.extra_services.length) ? <Row>
                            <Col xs={6}>Addons Price</Col>
                            <Col xs={6}><span className="summanry-data">{trip.addons_price}</span></Col>
                        </Row> : null
                    }

                    {
                        (trip.extra_stop_address && trip.extra_stop_address.length) ? <Row>
                            <Col xs={6}>Extra Stops price</Col>
                            <Col xs={6}><span className="summanry-data">{trip.extra_stops_price}</span></Col>
                        </Row> : null
                    }

                    {
                        (trip.trip_protection) ? <Row>
                            <Col xs={6}>Trip Protection</Col>
                            <Col xs={6}><span className="summanry-data">{trip.trip_protection_amount}</span></Col>
                        </Row> : null
                    }

                    <Row>
                        <Col xs={6}>Gratuity</Col>
                        <Col xs={6}><span className="summanry-data">{(trip.gratuity && trip.gratuity.gratuity_price) ? trip.gratuity.gratuity_price : 'N/A'}</span></Col>
                    </Row>
                    {
                        (trip.sales_tax && trip.sales_tax.total_taxes) ? <Row>
                            <Col xs={6}>Sales Tax</Col>
                            <Col xs={6}><span className="summanry-data">${trip.sales_tax.total_taxes}</span></Col>
                        </Row> : null
                    }


                    {
                        delta_base_price ? <Row>
                            <Col xs={6}>Adjusted Amount</Col>
                            <Col xs={6}><span className="summanry-data">${delta_base_price}</span></Col>
                        </Row> : null
                    }

                    {
                        (trip.promo_code_details && trip.promo_code_details.promo_code_id) ? <Row>
                            <Col xs={6}>Discount</Col>
                            <Col xs={6}><span className="summanry-data">-{trip.promo_code_details.discount_amount}</span></Col>
                        </Row> : null
                    }

                    <Row className="mt-2">
                        <Col xs={6}> <h6 className="payment-span-text float-left">Total Cost </h6></Col>
                        <Col xs={6}> <h6 className="payment-span-text float-left">{trip.total_fee} </h6></Col>
                    </Row>
                    </>
                    : <></>
                    }
                </Container>
            </Modal.Body>
        </Modal >
    }

    generatePdf = async (trip: any) => {
        this.setState({ renderPrint: true }, () => {
            const vehicleTypeSrc = (trip.vehicle_type_name == 'Sedan') ? sedan : (trip.vehicle_type_name == 'SUV') ? suv : suvXL;
            // const content = await this.renderSummaryContent(trip, vehicleTypeSrc);
            // await this.renderSummaryContent(trip, vehicleTypeSrc);
            // alert(this.invoice);
            if (this.invoice != null && this.invoice != undefined) {
                html2canvas(this.invoice, {
                    scrollX: 0,
                    scrollY: -window.scrollY,
                    onclone: (clonedDoc: any) => {
                        clonedDoc.getElementById('div_' + trip.id).style.visibility = 'visible';
                    }
                }
                ).then((canvas) => {
                    const imgData = canvas.toDataURL('image/png', 0.1);
                    // const pdf = new jsPDF('p', 'mm', 'a4');
                    // pdf.addFont('FontAwesome', 'FontAwesome', 'normal');
                    // pdf.setFont('FontAwesome');

                    // var width = pdf.internal.pageSize.getWidth();
                    // var height = pdf.internal.pageSize.getHeight();
                    // var width = 150;
                    // var height = 100;
                    // console.log(imgData);

                    var margin = 5;
                    var imgWidth = 210 - 5 * margin;
                    var pageHeight = 295;
                    var imgHeight = canvas.height * imgWidth / canvas.width;
                    var heightLeft = imgHeight;
                    var position = 10;
                    // pdf.addImage(imgData, 'JPEG', 2, 0, width, height);
                    // pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);

                    // pdf.save(trip.id + ".pdf");
                    this.setState({ renderPrint: false });
                });
            }

        });

        // one
        // const margins = {
        //     top: 80,
        //     bottom: 60,
        //     left: 40,
        //     width: 522
        // };
        // const stringData = renderToString(content);
        // const pdf = new jsPDF();
        // pdf.fromHTML(stringData,
        //     margins.left, // x coord
        //     margins.top, { // y coord
        //     'width': margins.width, // max width of content on PDF
        //     'elementHandlers': this.specialElementHandlers
        // },
        //     () => {
        //         pdf.save(trip.id);
        //     }, margins
        // );
    }

    specialElementHandlers = {
        // element with id of "bypass" - jQuery style selector
        '#bypassme': function (element: any, renderer: any) {
            // true = "handled elsewhere, bypass text extraction"
            return true
        }
    };

    returnTripSourceIcon = (trip_source:any)=> {
        let icon;
        let source = '';
        if (trip_source === "customer_web") {
          icon = web;
          source = 'Customer Web';
        } else if (trip_source === "phone") {
          icon = phone
          source = 'Luxy Dispatch';
        } else if (trip_source === "concierge") {
          icon = concierge
          source = 'Concierge'
        } else if (trip_source.toUpperCase() === 'GROUNDSPAN') {
          icon = ground_span;
          source = 'External Source';
        } else if (trip_source.toUpperCase() === 'TRAVEL_ARRANGER') {
          icon = ta;
          source = 'Travel Arranger';
        } else {
          icon = mobile;
          source = 'Customer App';
        }
        return icon;
      }

      returnTripSourceTitle = (trip_source:any)=> {
        let icon;
        let source = '';
        if (trip_source === "customer_web") {
          icon = web;
          source = 'Customer Web';
        } else if (trip_source === "phone") {
          icon = phone
          source = 'Luxy Dispatch';
        } else if (trip_source === "concierge") {
          icon = concierge
          source = 'Concierge'
        } else if (trip_source.toUpperCase() === 'GROUNDSPAN') {
          icon = ground_span;
          source = 'External Source';
        } else if (trip_source.toUpperCase() === 'TRAVEL_ARRANGER') {
          icon = ta;
          source = 'Travel Arranger';
        } else {
          icon = mobile;
          source = 'Customer App';
        }
        return source;
      }

    render() {
        const { trip, index, type } = this.props;
        const key = type + '_div_' + index;
        const eventKey = type + '_' + index;
        const strikeClass = (trip.status_id == 13) ? 'strike-through col-auto rem-padding' : 'col-auto rem-padding';


        let rideMode = 'ground';
        if (trip.is_pick_airport_address) {
            rideMode = 'arrival';
        }
        if (!trip.is_pick_airport_address && trip.is_drop_airport_address) {
            rideMode = 'departure';
        }

        // console.log(trip.booking_id, trip.is_pick_airport_address, trip.is_drop_airport_address, 'checking', rideMode);
        const vehicleTypeSrc = (trip.vehicle_type_name == 'Sedan') ? sedan : (trip.vehicle_type_name == 'SUV') ? suv : suvXL;
        const rating = (this.state.rating != 0) ? this.state.rating : trip.rating;
        // const className = (rideMode == 'arrival') ? 'card-header-arrival pointer' : (rideMode == 'departure') ? 'card-header-departure pointer' : 'card-header-ground pointer'
        const className = 'card-header-black pointer';
        return <div key={key}>
            <Accordion.Toggle as={Card.Header} className={className} eventKey={eventKey}>

                <Row >
                    <Col xl={2} className="col-auto rem-padding">
                        {
                            (rideMode == 'arrival') ? <div><img src={arrival} height="20px" width="20px" /> <span className={strikeClass + ' ml-4'}>Arrival </span></div> :
                                (rideMode == 'departure') ? <div><img src={departure} height="20px" width="20px" /> <span className={strikeClass + ' ml-4'}>Departure </span></div> : <div><img src={ground} height="20px" width="20px" /> <span className={strikeClass + ' ml-4'}>Ground </span></div>
                        }
                    </Col>
                    <Col xl={2} className="col-auto rem-padding">
                       {
                        <div title={this.returnTripSourceTitle(trip.trip_source)}><img src={this.returnTripSourceIcon(trip.trip_source)} style={(trip && trip.trip_source == "concierge") ? {width:"20px", height: "15px"} : {width:"15px", height: "15px"}} /> <span className={strikeClass + ' ml-4'}>Source </span></div>
                       }
                    </Col>

                    
                    <Col xl={2} className={strikeClass}>
                        <i className="las la-calendar-check ride-type-icon" style={{ fontSize: '16px', marginLeft: '10px', marginRight: '2px' }}></i>
                        <span>  {moment.tz(trip.pick_up_date, trip.pick_up_address.timezone).format('MMM D, YYYY')}</span>
                    </Col>
                    <Col xl={2} className={strikeClass}>
                        <i className="far fa-clock" style={{ fontSize: '14px', marginLeft: '10px', marginRight: '5px' }}></i>
                        <span>{moment.tz(trip.pick_up_date, trip.pick_up_address.timezone).format('hh:mm A')}</span>

                    </Col>
                    <Col xl={2} className="col-auto">
                        <div style={{ pointerEvents: 'none' }}>
                            {
                                (this.props.type == 'past' && trip.rating && trip.rating != null && trip.status_id != 13) ? <Rating
                                    // disabled={true}
                                    onClick={() => { }}
                                    ratingValue={rating}
                                    size={15}
                                    transition
                                    fillColor='orange'
                                    emptyColor='gray'
                                    className='foo' // Will remove the inline style if applied
                                /> : null
                            }
                        </div>

                    </Col>
                    <Col xl={2} className="pull-right col-auto">
                        <div className="col-auto pull-right">
                            <span className={strikeClass}>{
                            (!trip.is_from_travel_arranger) ? trip.total_fee : ''
                            }</span> &nbsp;
                    <i className="fa fa-chevron-circle-down" aria-hidden="true">
                            </i>
                        </div>
                    </Col>
                </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={eventKey}>
                <div className="accordion-data-block text-left">
                    <Row className="text-left">
                        <Col xl={6}>
                            <Row>
                                <Col xl={12} className="trip-field"><small><b>Trip Id :</b></small></Col>
                                <Col xl={12}>
                                    <b> <span className="trip-value-text">{trip.booking_id}</span></b>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={3}>
                            <Row>
                                <Col xl={12} className="trip-field"><small><b>Date/Time :</b></small></Col>
                                <Col xl={12}>
                                    <b> <span className="trip-value-text">
                                        {moment.tz(trip.pick_up_date, trip.pick_up_address.timezone).format('MM-DD-YYYY')}
&nbsp;&nbsp;
{moment.tz(trip.pick_up_date, trip.pick_up_address.timezone).format('hh:mm A')}
                                    </span></b>
                                </Col>

                            </Row>
                        </Col>
                        <Col xl={3}>
                            <Row>
                                <Col className="pull-right" style={{ textAlign: "right" }}>
                                    {
                                        (this.props.type == 'past' && trip.status_id != 13) ? (trip.rating && trip.rating != null) ? <div style={{ pointerEvents: 'none' }}>
                                            <Rating
                                                // disabled={true}
                                                onClick={() => { }}
                                                ratingValue={rating}
                                                size={15}
                                                transition
                                                fillColor='orange'
                                                emptyColor='gray'
                                                className='foo' // Will remove the inline style if applied
                                            /> </div> : <div><Rating
                                                // disabled={true}
                                                onClick={this.handleRating}
                                                ratingValue={rating}
                                                size={15}
                                                transition
                                                fillColor='orange'
                                                emptyColor='gray'
                                                className='foo' // Will remove the inline style if applied
                                            /> </div> : null
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="text-left">
                        <Col md={6}>
                            <Row>
                                <Col md={12} className="trip-field"><small><b>Pick-Up :</b></small></Col>
                                <Col md={12}>
                                    <b> <span className="trip-value-text">
                                        {trip.pick_up_address.address.full_address}
                                    </span></b>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={12} className="trip-field"> <small><b>Drop-Off :</b></small></Col>
                                <Col md={12}>
                                    <b> <span className="trip-value-text">
                                        {trip.drop_off_address.address.full_address}
                                    </span></b>
                                </Col>
                            </Row>  </Col>
                    </Row>

                    {(trip.airline_name != '' && trip.airline_name != undefined) ?
                        <Row className="text-left">
                            <Col xl={6}>
                                <Row>
                                    <Col xs={12} className="trip-field"><small><b>Flight Number :</b></small></Col>
                                    <Col xs={12}>
                                        <b> <span className="trip-value-text">{(trip.flight_number != '') ? trip.flight_number : 'N/A'}</span></b>
                                    </Col>
                                </Row>
                            </Col><Col xl={6}>
                                <Row>
                                    <Col xs={12} className="trip-field"> <small><b>Flight Details :</b></small></Col>
                                    <Col xs={12}>
                                        <b> <span className="trip-value-text">{trip.airline_name}</span></b>
                                    </Col>
                                </Row>  </Col> </Row> : null
                    }

                    <Row className="text-left">
                        <Col xl={6}>
                            {/* {
                                (this.props.type == 'upcoming') ? <button className="btn  custom-button btn-trip-summary"
                                    onClick={() => { }}
                                >
                                    <span> <small><b>Cancel Ride</b></small></span>
                                </button> : null
                            } */}
                        </Col>
                        <Col xl={3}>
                            <button className="btn btn-block primary custom-button btn-trip-summary"
                                onClick={() => { this.showTripSummary(trip) }}
                            >
                                <img src={iconCar} height="15px" width="15px" />
                                <span> <small><b>Trip Summary</b></small></span></button>
                        </Col>
                        {/* // this.generatePdf(trip) */}
                        {
                        (!trip.is_from_travel_arranger) ?
                        <Col xl={3}>
                            <button className="btn btn-block primary custom-button btn-trip-summary" onClick={() => { this.props.handlePdfDownload(trip) }}>
                                <img src={invoice} height="15px" width="15px" />
                                <span><small><b> Download Invoice </b></small> </span> </button>
                        </Col>
                        : <></>
                        }


                    </Row>
                    {
                        (this.state.renderPrint) ?
                            <div id={'div_' + trip.id} style={{ width: '100%', visibility: 'hidden', height: '0px' }}>
                                <PdfContent
                                    reference={this.setRef}
                                    trip={trip} vehicleTypeSrc={vehicleTypeSrc} addonsList={this.props.addonsList} /></div> : null
                    }
                </div>
            </Accordion.Collapse>

            {
                (this.state.showModal) ? this.renderTripSummary() : null
            }

        </div>

    }

}

export default Trip;